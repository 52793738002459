import React, { useState, useEffect, useRef } from "react";
import Rating from "@mui/material/Rating";
import {
  JobberProfileBuilder,
  Email,
  JobberOrgRatingClientResp,
  JobberPerformanceOrg,
  CorpDataObj,
  OrgMetaData,
} from "../orgViews/orgConstants";
import { CorpDataObjSimpleFromLocalStorage, LocalStorageObjects, OrgLocation, OrgLocationMapDetails } from "../common/CommonInterfaces";
import { UserType } from "../remoteExecution/RemoteExecutionServiceImpl";
import { v4 as uuidv4 } from 'uuid';
uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

const CopyToClipboard = async (value: string) => {
  try {
    await navigator.clipboard.writeText(value);
    console.log("Copied to clipboard: " + value);
  } catch (err) {
    console.error("Unable to copy to clipboard:", err);
  }
};

function extractRatingFromRatingCategory(ratingString){
  console.log("rating value" + ratingString)
  switch (ratingString) {
      case "DEFAULT_WAITING_FOR_REVIEW":
        return 0;
      case "OUTSTANDING":
        return 5;
      case "EXCEEDS_EXPECTATION":
        return 4;
      case "MEET_EXPECTATION":
        return 3;
      case "UNSATISFACTORY":
        return 2;
      default:
        return 4;
    }
}


const ExtractStarRating = (jobberRating: Map<Email, JobberPerformanceOrg>) => {
  if (jobberRating) {
    const orgEmail = localStorage.getItem("userEmail");
    const emailMapKey = `EmailAddress(value=${orgEmail})`;
    const jobberRatingEnum =
      jobberRating[emailMapKey]?.jobberPerformanceOrgDetails?.category;

    switch (jobberRatingEnum) {
      case "DEFAULT_WAITING_FOR_REVIEW":
        return 0;
      case "OUTSTANDING":
        return 5;
      case "EXCEEDS_EXPECTATION":
        return 4;
      case "MEET_EXPECTATION":
        return 3;
      case "UNSATISFACTORY":
        return 2;
      default:
        return 4;
    }
  }

  return 4;
};

const JobberStarRating = ({
  jobberRating,
}: {
  jobberRating: JobberProfileBuilder;
}) => {
  const starValue = ExtractStarRating(jobberRating.jobberOrgRatingDetailMap);
  if (jobberRating) {
    return (
      <div className="jobber-profile-modal-stars-holder">
        <Rating
          name="half-rating-read"
          defaultValue={starValue}
          precision={0.5}
          readOnly
        />
      </div>
    );
  }
};

const JobberViewRating = ({
    jobberRating,
  }: {
    jobberRating: JobberProfileBuilder;
  }) => {
  // Component to extract reviewer name
  const ViewRatingBuilderV2 = () => {
    // const jobberRating = responseData.data; // Assuming responseData is the name of your response object
    const jobberOrgRatingDetailMap = jobberRating.jobberOrgRatingDetailMap;

    return (
      <>
        {Object.keys(jobberOrgRatingDetailMap).map((emailKey) => {
          const performanceDetails =
            jobberOrgRatingDetailMap[emailKey].jobberPerformanceOrgDetails;

          return (
            <div key={emailKey}>
              <div className="org-rating">
                <div className="org-rating-name">
                  <ExtractedReviewerName
                    firstName={performanceDetails.requestFirstName}
                    lastName={performanceDetails.requestLastName}
                  />
                </div>
                <div className="org-rating-value">
                  <div className="org-rating-value-star">
                    <ExtractedReviewerStarRating
                      category={performanceDetails.category}
                    />
                  </div>
                  <div className="org-rating-value-text">
                    <ExtractedReviewerReason
                      reason={performanceDetails.reason}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  const ExtractedReviewerName = ({ firstName, lastName }) => {
    return (
      <>
        {firstName} {lastName}
      </>
    );
  };

  // Component to extract reviewer star rating
  const ExtractedReviewerStarRating = ({ category }) => {
    switch (category) {
      case "DEFAULT_WAITING_FOR_REVIEW":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={0}
            precision={0.5}
            readOnly
          />
        );
      case "OUTSTANDING":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={5}
            precision={0.5}
            readOnly
          />
        );
      case "EXCEEDS_EXPECTATION":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={4}
            precision={0.5}
            readOnly
          />
        );
      case "MEET_EXPECTATION":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={3}
            precision={0.5}
            readOnly
          />
        );
      case "UNSATISFACTORY":
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={2}
            precision={0.5}
            readOnly
          />
        );
      default:
        return (
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={0}
            precision={0.5}
            readOnly
          />
        );
    }
  };

  // Component to extract reviewer reason
  const ExtractedReviewerReason = ({ reason }) => {
    return <>{reason}</>;
  };

  const JobberViewRating = ({
    jobberRating,
  }: {
    jobberRating: JobberProfileBuilder;
  }) => {
    // Component to extract reviewer name
    const ExtractedReviewerName = ({ firstName, lastName }) => {
      return (
        <>
          {firstName} {lastName}
        </>
      );
    };

    // Component to extract reviewer star rating
    const ExtractedReviewerStarRating = ({ category }) => {
      switch (category) {
        case "DEFAULT_WAITING_FOR_REVIEW":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={0}
              precision={0.5}
              readOnly
            />
          );
        case "OUTSTANDING":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={5}
              precision={0.5}
              readOnly
            />
          );
        case "EXCEEDS_EXPECTATION":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={4}
              precision={0.5}
              readOnly
            />
          );
        case "MEET_EXPECTATION":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={3}
              precision={0.5}
              readOnly
            />
          );
        case "UNSATISFACTORY":
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={2}
              precision={0.5}
              readOnly
            />
          );
        default:
          return (
            <Rating
              size="small"
              name="half-rating-read"
              defaultValue={0}
              precision={0.5}
              readOnly
            />
          );
      }
    };

    // Component to extract reviewer reason
    const ExtractedReviewerReason = ({ reason }) => {
      return <>{reason}</>;
    };

    

    return (
      <div className="jobber-profile-view-ratings">
        <div className="jobber-profile-view-ratings-title">Ratings</div>
        <ViewRatingBuilderV2 />
      </div>
    );
  };

  return (
    <div className="jobber-profile-view-ratings">
      <div className="jobber-profile-view-ratings-title">Ratings</div>
      <ViewRatingBuilderV2 />
    </div>
  );
};


const DetermineUserType = (userTypeString: string): UserType | null => {
  if (userTypeString === UserType.JOBBER) {
    return UserType.JOBBER;
  } else if (userTypeString === UserType.ORG_MANAGER) {
    return UserType.ORG_MANAGER;
  } else if (userTypeString === UserType.AGENCY_STAFF) {
    return UserType.AGENCY_STAFF;
  } else if (userTypeString === UserType.AGENCY_ADMIN) {
    return UserType.AGENCY_ADMIN;
  } else if (userTypeString === UserType.WORKY_STAFF) {
    return UserType.WORKY_STAFF;
  } else if (userTypeString === UserType.WORKY_ADMIN) {
    return UserType.WORKY_ADMIN;
  }
  return null;
};

/**
 * 
 * @param epochSeconds epoch time in seconds
 * @returns string in the format of "YYYY-MM-DD HH:MM:SS"
 */
function convertEpochToHumanFriendlyDate(epochSeconds: number): string {
  const date = new Date(epochSeconds * 1000);
  
  // Pad with leading zeros where necessary
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


/**
 * 
 * @param epochSeconds epoch time in seconds
 * @returns date in the format of "MMM DD" (e.g. "Jan 01")
 */
function formatEpochToShortDate(epochSeconds: number): string {
    const date = new Date(epochSeconds * 1000);
    return date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric'
    });
}


const convertToCorpDataObjSafely = (rawStringJson: string): CorpDataObj | null => {
  try {
    console.log("rawStringJson: ", rawStringJson);
    const parsedData = typeof rawStringJson === "string" ? JSON.parse(rawStringJson) : rawStringJson;
    
    if (!parsedData || typeof parsedData !== "object") {
      console.error("Invalid JSON structure");
      return null;
    }

    const corpData: CorpDataObj = {
      corpJobberDetails: parsedData.corpJobberDetails,
      addressDto: parsedData.addressDto,
      agencyNames: Array.isArray(parsedData.agencyNames) ? parsedData.agencyNames : [],
      iconImageS3Key: parsedData.iconImageS3Key ?? "",
      weeklyBudgetAmount: parsedData.weeklyBudgetAmount ?? 0,
      budgetNotificationThreshold: parsedData.budgetNotificationThreshold ?? 0,
      corpCustomerShiftDetails: parsedData.corpCustomerShiftDetails,
      orgLocationMapDetailsMap: new Map(
        Object.entries(parsedData.orgLocationMapDetailsMap || {}).map(([key, value]) => [key, value as OrgLocationMapDetails])
      ),
      jobberDetails: parsedData.jobberDetails ?? {},
    };

    console.log("corpData Parsed JSON: ", corpData);

    return corpData;
  } catch (error) {
    console.error("Failed to parse JSON: ", error);
    return null;
  }
};

const getOrgLocationMapDetailsV2 = ({orgData, corpName}: {orgData: CorpDataObj, corpName: string}):OrgLocationMapDetails  => {
  const locationMap = new Map(Object.entries(orgData.orgLocationMapDetailsMap || {}));
  console.log("locationMaplocationMap ", locationMap)
  const locations: OrgLocationMapDetails = locationMap.get(corpName);
  console.log("locationsKK: ", locations)
  return locations;
}

const getOrgLocationMapDetails = ({orgData, corpName}: {orgData: any, corpName: string}):OrgLocationMapDetails  => {
  if (!orgData || !corpName) return undefined;
  console.log("orgData12 ", orgData)
  
  const { orgLocationMapDetailsMap } = orgData;
  
  if (!orgLocationMapDetailsMap) return undefined;
  console.log("orgLocationMapDetailsMap ", orgLocationMapDetailsMap)
  
  // If it's a Map instance, use get method
  if (orgLocationMapDetailsMap instanceof Map) {
      const resp1= orgLocationMapDetailsMap.get(corpName);
      console.log("resp1 ", resp1)
    return resp1;
  }

  console.log("typeof ff ", typeof orgLocationMapDetailsMap)

  const res = orgLocationMapDetailsMap[corpName];
  console.log("resresresresres ", res)
  
  // Otherwise treat it as a plain object
  return res;
};


/**
 * Checks if a string is present and does not contain the word "undefined" or is blank.
 * @param str - The string to check.
 * @returns True if the string is present and valid, false otherwise.
 */
const isValidString = (str: string): boolean => {
  return str && str.trim() !== "" && !str.includes("undefined");
};


const retrieveLocalStorageAttributes = (): LocalStorageObjects => {
  const attributesMap: LocalStorageObjects = {
    attributes: {}
  };

  try{
  attributesMap.attributes["worky_session_id"] = localStorage.getItem("worky_session_id");

  attributesMap.attributes["auth_code"] = localStorage.getItem("auth_code");
  attributesMap.attributes["locationPermission"] = localStorage.getItem("locationPermission");

  // Authentication attributes
  attributesMap.attributes["auth_code"] = localStorage.getItem("auth_code");
  attributesMap.attributes["access_token"] = localStorage.getItem("access_token");
  attributesMap.attributes["id_token"] = localStorage.getItem("id_token");
  attributesMap.attributes["refresh_token"] = localStorage.getItem("refresh_token");
  attributesMap.attributes["expire_time"] = localStorage.getItem("expire_time");

  // User details
  attributesMap.attributes["userEmail"] = localStorage.getItem("userEmail");
  attributesMap.attributes["userPhoneNumber"] = localStorage.getItem("userPhoneNumber");
  attributesMap.attributes["firstName"] = localStorage.getItem("firstName");
  attributesMap.attributes["lastName"] = localStorage.getItem("lastName");
  attributesMap.attributes["userId"] = localStorage.getItem("userId");

  // App permissions
  attributesMap.attributes["locationPermission"] = localStorage.getItem("locationPermission");

  // Organization details
  attributesMap.attributes["orgName"] = localStorage.getItem("orgName");
  attributesMap.attributes["orgIcon"] = localStorage.getItem("orgIcon");
  attributesMap.attributes["user_type"] = localStorage.getItem("user_type");


  }catch(e){
      console.log("retrieveLocalStorageAttributes ", retrieveLocalStorageAttributes)
  }
  return attributesMap;
}
  
  /**
 * Saves or retrieves a unique session ID for the current user session
 * @returns The session ID string (either existing or newly created)
 */
const saveWorkySessionIdToLocalStorage = (): string => {
  // Check if there already exists a session ID in local storage

  try{
    const existingSessionId = localStorage.getItem("worky_session_id");
  
  if (existingSessionId) {
    console.log("Using existing Worky session ID:", existingSessionId);
    return existingSessionId;
  }
  
  // If no session ID exists, create one using uuidv4
  const newSessionId = uuidv4();
  
  try {
    // Save the new session ID to localStorage
    localStorage.setItem("worky_session_id", newSessionId);
    console.log("Created and saved new Worky session ID:", newSessionId);
  } catch (error) {
    console.error("Failed to save Worky session ID to localStorage:", error);
    // Continue with the session ID even if storage fails
  }
  
  return newSessionId;
  }catch(err){
    console.log("major error in session", err)
    return "error";
  }
  
};

const  toStringSafely = (obj: any): string  => {
  const seen = new WeakSet();
  try{
    // return String(obj)
    return JSON.stringify(obj, (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) return "[Circular]";
        seen.add(value);
      }
      return value;
    });
  }catch(e){
    console.log("error while converting to string safely, ", obj, e )
    return "[Stringify Error]"
  }
  
}


/**
 * Safely retrieves and parses the organization data from localStorage
 * @returns The parsed organization data or null if not available/invalid
 */
const getLocalStorageOrgData = (): CorpDataObjSimpleFromLocalStorage | null => {
  try {
    // Get the raw string from localStorage
    const orgDataString = localStorage.getItem('orgData');
    
    // Check if data exists
    if (!orgDataString) {
      console.log("No organization data found in localStorage");
      return null;
    }
    
    // Attempt to parse the JSON
    const orgMetaData: CorpDataObjSimpleFromLocalStorage = JSON.parse(orgDataString);
    
    // Validate the parsed data
    if (!orgMetaData || typeof orgMetaData !== 'object') {
      console.warn("Invalid organization data format in localStorage");
      return null;
    }
    
    return orgMetaData;
  } catch (error) {
    console.error("Failed to retrieve or parse organization data from localStorage: ", localStorage.getItem('orgData'), error);
    return null;
  }
};


const rawStringCharsEquals = (s1: string | null | undefined, s2: string | null | undefined): boolean => {
  // Check if both strings are not present (null, undefined, or empty)
  if (!s1 && !s2) return true;

  // Remove whitespace and convert to lowercase
  const clearedS1 = removeAllWhiteSpaceAndToLowerCase(s1);
  const clearedS2 = removeAllWhiteSpaceAndToLowerCase(s2);

  // Check if cleared strings are not present
  if (!clearedS1 && !clearedS2) return true;

  // Check for null/undefined mismatches
  if (!clearedS1 && clearedS2) return false;
  if (clearedS1 && !clearedS2) return false;

  // Compare strings case-insensitively in both directions
  return clearedS1.toLowerCase() === clearedS2.toLowerCase() && 
         clearedS2.toLowerCase() === clearedS1.toLowerCase();
}

// Helper function to remove whitespace and convert to lowercase
const removeAllWhiteSpaceAndToLowerCase = (input?: string | null): string => {
  return input ? input.replace(/\s/g, '').toLowerCase() : '';
}

const findMapKeyByValue = (
  map: Map<string, OrgLocationMapDetails>,
  searchValue: string | null 
): OrgLocationMapDetails  => {
  // If search value is empty or null, return undefined
  if (!searchValue) return undefined;

  // Iterate through map entries
  for (const [key, value] of map.entries()) {
      // Convert value to string, handling different types
      const stringValue = value !== null && value !== undefined
          ? String(value).trim()
          : '';

      // Use our previously created rawStringCharsEquals method to compare
      if (rawStringCharsEquals(key, searchValue)) {
          return value;
      }
  }

  return {
    orgLocationMap: new Map<string, OrgLocation>()
  };
}





export { isValidString, formatEpochToShortDate, convertEpochToHumanFriendlyDate, extractRatingFromRatingCategory, CopyToClipboard, ExtractStarRating, JobberStarRating, JobberViewRating, DetermineUserType,
  convertToCorpDataObjSafely,
  getOrgLocationMapDetails,
  getOrgLocationMapDetailsV2,
  retrieveLocalStorageAttributes,
  saveWorkySessionIdToLocalStorage,
  toStringSafely,
  getLocalStorageOrgData,
  findMapKeyByValue
 };
