interface DownloadFileReq {
  fileUrl: string;
  fileName: string;
}
const DownloadPDF = (req: DownloadFileReq) => {
    window.open(req.fileUrl, '_blank');
    
    
    // const link = document.createElement("a");
    // link.href = req.fileUrl;
    // link.setAttribute("download", req.fileName);
    // link.setAttribute("target", "_blank"); // Open in a new tab
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };


export { DownloadPDF };