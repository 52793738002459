import { JobberStatus, CorpType, CorpJobberDetails, ServicedCity } from '../../common/CommonInterfaces';
const PROVINCES = [
	{ key: 'AB', display: 'Alberta' },
	{ key: 'BC', display: 'British Columbia' },
	{ key: 'MB', display: 'Manitoba' },
	{ key: 'NB', display: 'New Brunswick' },
	{ key: 'NL', display: 'Newfoundland and Labrador' },
	{ key: 'NS', display: 'Nova Scotia' },
	{ key: 'ON', display: 'Ontario' },
	{ key: 'PE', display: 'Prince Edward Island' },
	{ key: 'QC', display: 'Quebec' },
	{ key: 'SK', display: 'Saskatchewan' },
	{ key: 'NT', display: 'Northwest Territories' },
	{ key: 'NU', display: 'Nunavut' },
	{ key: 'YT', display: 'Yukon' },
];

const VALID_MONTH_ARRAY = [
	'00',
	'01',
	'02',
	'03',
	'04',
	'05',
	'06',
	'07',
	'08',
	'09',
	'10',
	'11',
	'12',
];

interface Address {
	street: string;
	unitNumber?: string;
	city: string;
	province: string;
	postalCode: string;
	country: string;
	majorIntersection?: string;
}

enum ShiftTime {
	MIDNIGHT = 0,
	MORNING = 8,
	NOON = 12,
	NIGHT = 20,
}

enum DowValue {
	MON = 'MON',
	TUE = 'TUE',
	WED = 'WED',
	THR = 'THR',
	FRI = 'FRI',
	SAT = 'SAT',
	SUN = 'SUN',
}

const DAYS_OF_THE_WEEK = [
	{ value: DowValue.MON, label: 'Monday' },
	{ value: DowValue.TUE, label: 'Tuesday' },
	{ value: DowValue.WED, label: 'Wednesday' },
	{ value: DowValue.THR, label: 'Thursday' },
	{ value: DowValue.FRI, label: 'Friday' },
	{ value: DowValue.SAT, label: 'Saturday' },
	{ value: DowValue.SUN, label: 'Sunday' },
];

enum WorkPermitType {
	CANADIAN_CITIZEN = 'CANADIAN_CITIZEN',
	PERMANENT_RESIDENT = 'PERMANENT_RESIDENT',
	OPEN_WORK_PERMIT = 'OPEN_WORK_PERMIT',
	STUDENT_WORK_PERMIT = 'STUDENT_WORK_PERMIT',
	TEMPORARY_FOREIGN_WORKER = 'TEMPORARY_FOREIGN_WORKER',
	REFUGEE = 'REFUGEE',
}

const WORK_PERMIT_TYPE = [
	{ value: WorkPermitType.CANADIAN_CITIZEN, label: 'Canadian Citizen' },
	{ value: WorkPermitType.PERMANENT_RESIDENT, label: 'Permanent Resident' },
	{ value: WorkPermitType.OPEN_WORK_PERMIT, label: 'Open Work Permit' },
	{ value: WorkPermitType.STUDENT_WORK_PERMIT, label: 'Student Work Permit' },
	{ value: WorkPermitType.TEMPORARY_FOREIGN_WORKER, label: 'Temporary Foreign Worker' },
	{ value: WorkPermitType.REFUGEE, label: 'Refugee' },
];

interface DOW {
	day?: DowValue;
	availStartTime?: number;
	availEndTime?: number;
}

interface userEmail {
	value: string;
}
interface userPhoneNumber {
	value: string;
}

interface userDetails {
	lastname: string;
	firstname: string;
	userEmail: userEmail;
	userPhoneNumber: userPhoneNumber;
}

interface JobberConfigForCorp{
	nextUpJobberStatus: JobberStatus;
	finalJobberStatus: JobberStatus;
}

interface JobberDTO {
	jobberStatus: JobberStatus;
	corpJobberDetails: CorpJobberDetails;
	userDetails: userDetails;
	workPermitType?: WorkPermitType | null;
	isLegallyAllowedToWork?: boolean | null;
	previousTempEmployers?: string[] | null;
	socialInsuranceNumber?: string | null;
	dateOfBirth: string | null;
	sex: string;
	address?: AddressDto | null;
	transportation: string;
	hasPersonalVehicle: boolean;
	hasSafetyBoots: boolean;
	specialSkills: string[];
	hasForkliftLicense: boolean;
	isVerifiedEmail: boolean;
	isVerifiedPhoneNumber: boolean;
	jobberConfigForCorp: JobberConfigForCorp
	isTestData: boolean;
}

interface AddressDto {
	street: string;
	unitNumber?: string;
	city: string;
	province: string;
	postalCode: string;
	country: string;
	majorIntersection?: string;
}

interface JobberRegistrationFormRequest {
	socialInsuranceNumber?: string;
	dateOfBirth?: string;
	sex: string;
	address: Address;
	transportation?: string;
	previousTempEmployers?: string[];
	isLegallyAllowedToWork?: boolean;
	hasPersonalVehicle?: boolean;
	hasSafetyBoots?: boolean;
	specialSkills?: string[];
	hasForkliftLicense?: boolean;
	availTimes?: string[];
	shiftTimes?: string[];
	workPermitType: WorkPermitType;
	servicedCities: ServicedCity[];
}

interface DirectDepositRequest {
	jobberEmail?: string | null;
	bankNumber: number;
	transitBranchNumber: number;
	accountNumber: number;
  }

export {
	Address,
	ShiftTime,
	DOW,
	WorkPermitType,
	JobberDTO,
	AddressDto,
	JobberRegistrationFormRequest,
	DowValue,
	VALID_MONTH_ARRAY,
	PROVINCES,
	DAYS_OF_THE_WEEK,
	WORK_PERMIT_TYPE,
	ServicedCity,
	DirectDepositRequest,
};


