import React, { useState, useEffect, useRef } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { DayPicker } from "react-day-picker";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { TimePicker, WorkyTimeObj } from "./TimePicker";

const StepperSequenceOrgView = ({ pageNumber }: { pageNumber: number }) => {
  type StepButtonProps = {
    idName: "active-step" | "passed-step" | "future-step";
    number: 1 | 2 | 3 | 4 | 5 | 6;
    title: string;
    subtitle: string;
  };

  const StepButton = ({ idName, number, title, subtitle }: StepButtonProps) => {
    return (
      <button
        id={idName === "active-step" ? "new-shift-v5-stepper-step-container-active" : ""}
        className="new-shift-v5-stepper-step-container"
        disabled={idName === "future-step"}
      >
        <div id={idName} className="new-shift-v5-stepper-step-icon">
          {idName === "passed-step" ? <CheckIcon style={{ fontSize: "22px" }} /> : number}
        </div>
        <div className="new-shift-v5-stepper-step-info">
          <div className="new-shift-v5-stepper-step-title">{title}</div>
          <div className="new-shift-v5-stepper-step-subtitle">{subtitle}</div>
        </div>
      </button>
    );
  };

  if (pageNumber === 1) {
    return (
      <>
        <StepButton idName="active-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="future-step" number={2} title="Agency" subtitle="Select Agency" />
        <StepButton idName="future-step" number={3} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="future-step" number={4} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="future-step" number={5} title="Time" subtitle="Select Time" />
        <StepButton idName="future-step" number={6} title="Confirm" subtitle="Confirm Details" />
      </>
    );
  }
  if (pageNumber === 2) {
    return (
      <>
        <StepButton idName="passed-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="active-step" number={2} title="Agency" subtitle="Select Agency" />
        <StepButton idName="future-step" number={3} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="future-step" number={4} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="future-step" number={5} title="Time" subtitle="Select Time" />
        <StepButton idName="future-step" number={6} title="Confirm" subtitle="Confirm Details" />
      </>
    );
  }
  if (pageNumber === 3) {
    return (
      <>
        <StepButton idName="passed-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="passed-step" number={2} title="Agency" subtitle="Select Agency" />
        <StepButton idName="active-step" number={3} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="future-step" number={4} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="future-step" number={5} title="Time" subtitle="Select Time" />
        <StepButton idName="future-step" number={6} title="Confirm" subtitle="Confirm Details" />
      </>
    );
  }
  if (pageNumber === 4) {
    return (
      <>
        <StepButton idName="passed-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="passed-step" number={2} title="Agency" subtitle="Select Agency" />
        <StepButton idName="passed-step" number={3} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="active-step" number={4} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="future-step" number={5} title="Time" subtitle="Select Time" />
        <StepButton idName="future-step" number={6} title="Confirm" subtitle="Confirm Details" />
      </>
    );
  }
  if (pageNumber === 5) {
    return (
      <>
        <StepButton idName="passed-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="passed-step" number={2} title="Agency" subtitle="Select Agency" />
        <StepButton idName="passed-step" number={3} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="passed-step" number={4} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="active-step" number={5} title="Time" subtitle="Select Time" />
        <StepButton idName="future-step" number={6} title="Confirm" subtitle="Confirm Details" />
      </>
    );
  }
  if (pageNumber === 6) {
    return (
      <>
        <StepButton idName="passed-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="passed-step" number={2} title="Agency" subtitle="Select Agency" />
        <StepButton idName="passed-step" number={3} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="passed-step" number={4} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="passed-step" number={5} title="Time" subtitle="Select Time" />
        <StepButton idName="active-step" number={6} title="Confirm" subtitle="Confirm Details" />
      </>
    );
  }
};

type PageDetailsSequenceProps = {
  pageNumber: number;
};

const PageDetailsSequence: React.FC<PageDetailsSequenceProps> = ({ pageNumber }) => {
  const PageHeaders = (number: number, title: string, subtitle: string) => (
    <div className="new-shift-v5-shift-page">
      <div className="new-shift-v5-shift-page-title-holder">
        <div className="new-shift-v5-shift-page-step">Step {number}/6</div>
        <div className="new-shift-v5-shift-page-title">{title}</div>
        <div className="new-shift-v5-shift-page-subtitle">{subtitle}</div>
      </div>
    </div>
  );

  switch (pageNumber) {
    case 1:
      return PageHeaders(1, "Select Facility", "Choose your Organization and Location");
    case 2:
      return PageHeaders(2, "Select Agency", "Choose your Agency");
    case 3:
      return PageHeaders(3, "Select Worker Type", "Choose the Worker Type");
    case 4:
      return PageHeaders(4, "Select Date", "Choose the Individual Date(s) or Recurring Date(s)");
    case 5:
      return PageHeaders(5, "Select Time", "Choose the Timing for your Shift(s)");
    case 6:
      return PageHeaders(6, "Submit", "Submit Your Shift(s)");
    default:
      return PageHeaders(1, "Select Facility", "Choose your Organization and Location");
  }
};

interface LocationAddress {
  street: string;
  city: string;
  province: string;
  postalCode: string;
  country: string;
  majorIntersection: string | null;
  unitNumber: string;
  entranceInstructions: string | null;
}

interface Location {
  locationName: string;
  locationAddress: LocationAddress;
}

interface Organization {
  orgName: string;
  locations: Location[];
}

const StepperSequenceMobileOrgView = ({ pageNumber }: { pageNumber: number }) => {
  const StepperPageDetailsMobile = () => {
    switch (pageNumber) {
      case 1:
        return (
          <div className="new-shift-v5-stepper-holder-mobile">
            <div className="new-shift-v5-stepper-step-mobile">
              <div className="new-shift-v5-shift-page-step">Step {pageNumber}/6</div>
            </div>
            <div className="new-shift-v5-stepper-title-mobile">Select Facility</div>
            <div className="new-shift-v5-stepper-subtitle-mobile">Choose your Organization and Location</div>
          </div>
        );
      case 2:
        return (
          <div className="new-shift-v5-stepper-holder-mobile">
            <div className="new-shift-v5-stepper-step-mobile">
              <div className="new-shift-v5-shift-page-step">Step {pageNumber}/6</div>
            </div>
            <div className="new-shift-v5-stepper-title-mobile">Select Agency</div>
            <div className="new-shift-v5-stepper-subtitle-mobile">Choose your Agency</div>
          </div>
        );
      case 3:
        return (
          <div className="new-shift-v5-stepper-holder-mobile">
            <div className="new-shift-v5-stepper-step-mobile">
              <div className="new-shift-v5-shift-page-step">Step {pageNumber}/6</div>
            </div>
            <div className="new-shift-v5-stepper-title-mobile">Select Worker Type</div>
            <div className="new-shift-v5-stepper-subtitle-mobile">Choose your Worker-Type or Auto-Assign Worker-Type</div>
          </div>
        );
      case 4:
        return (
          <div className="new-shift-v5-stepper-holder-mobile">
            <div className="new-shift-v5-stepper-step-mobile">
              <div className="new-shift-v5-shift-page-step">Step {pageNumber}/6</div>
            </div>
            <div className="new-shift-v5-stepper-title-mobile">Select Date</div>
            <div className="new-shift-v5-stepper-subtitle-mobile">Choose the Individual Date(s) or Recurring Date(s)</div>
          </div>
        );
      case 5:
        return (
          <div className="new-shift-v5-stepper-holder-mobile">
            <div className="new-shift-v5-stepper-step-mobile">
              <div className="new-shift-v5-shift-page-step">Step {pageNumber}/6</div>
            </div>
            <div className="new-shift-v5-stepper-title-mobile">Select Time</div>
            <div className="new-shift-v5-stepper-subtitle-mobile">Choose the Timing for your Shift(s)</div>
          </div>
        );
      case 6:
        return (
          <div className="new-shift-v5-stepper-holder-mobile">
            <div className="new-shift-v5-stepper-step-mobile">
              <div className="new-shift-v5-shift-page-step">Step {pageNumber}/6</div>
            </div>
            <div className="new-shift-v5-stepper-title-mobile">Submit</div>
            <div className="new-shift-v5-stepper-subtitle-mobile">Submit Your Shift(s)</div>
          </div>
        );
      default:
        return (
          <div className="new-shift-v5-stepper-holder-mobile">
            <div className="new-shift-v5-stepper-step-mobile">
              <div className="new-shift-v5-shift-page-step">Step {pageNumber}/6</div>
            </div>
            <div className="new-shift-v5-stepper-title-mobile">Select Facility</div>
            <div className="new-shift-v5-stepper-subtitle-mobile">Choose your Organization and Location</div>
          </div>
        );
    }
  };
  return (
    <>
      <div className="new-shift-v5-stepper-bottom-mobile">
        <div id={`progress-step${pageNumber}`} className="new-shift-v5-stepper-bottom-progress"></div>
      </div>
      <StepperPageDetailsMobile />
    </>
  );
};

interface RecurringDowProps {
  // External state
  isFistModalOpenForReOccurringDayModal: boolean;
  setIsFistModalOpenForReOccurringDayModal: (isOpen: boolean) => void;
  selectedRecurringDaysRefObj: React.MutableRefObject<number[]>;

  // For logging (optional)
  isReOccurringShift?: boolean;
}

const RecurringDow: React.FC<RecurringDowProps> = ({
  isFistModalOpenForReOccurringDayModal,
  setIsFistModalOpenForReOccurringDayModal,
  selectedRecurringDaysRefObj,
  isReOccurringShift,
}) => {
  const [confirmedSelection, setConfirmedSelection] = useState(false);
  const [tempSelectedDays, setTempSelectedDays] = useState<number[]>([]);

  console.log("TEST 12345 : " + selectedRecurringDaysRefObj);
  console.log(
    "isReOccurringShift " +
      isReOccurringShift +
      " isOpenInternalRecurringDaySelectionModal " +
      false +
      " selectedRecurringDaysRefObj " +
      JSON.stringify(selectedRecurringDaysRefObj.current)
  );

  const toggleDaySelection = (day: number) => {
    const index = tempSelectedDays.indexOf(day);
    if (index === -1) {
      setTempSelectedDays([...tempSelectedDays, day]); // Add the day if it's not already selected
    } else {
      const newSelectedDays = [...tempSelectedDays];
      newSelectedDays.splice(index, 1); // Remove the day if it's already selected
      setTempSelectedDays(newSelectedDays);
    }
  };

  const confirmSelection = () => {
    selectedRecurringDaysRefObj.current = [...tempSelectedDays];
    setConfirmedSelection(true);
    setIsFistModalOpenForReOccurringDayModal(false);
  };

  const isDaySelected = (day: number) => tempSelectedDays.includes(day);

  return (
    <Modal open={isFistModalOpenForReOccurringDayModal} onClose={() => setIsFistModalOpenForReOccurringDayModal(false)}>
      <div className="recurring-shift-modal">
        <button onClick={() => setIsFistModalOpenForReOccurringDayModal(false)} className="modal-close">
          <CloseIcon />
        </button>

        <div className="recurring-shift-modal-title">Select Recurring Days</div>
        <div className="recurring-shift-modal-dow-holder">
          {[0, 1, 2, 3, 4, 5, 6].map((day) => (
            <button key={day} className={`recurring-dow ${isDaySelected(day) ? "selected" : ""}`} onClick={() => toggleDaySelection(day)}>
              {["SU", "MO", "TU", "WE", "TH", "FR", "SA"][day]}
            </button>
          ))}
        </div>
        <button className="recurring-confirm" disabled={!tempSelectedDays || tempSelectedDays.length < 1} onClick={confirmSelection}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

interface ShiftDatePageProps {
  // State variables
  selectedDays: Date[];
  setSelectedDays: (days: Date[]) => void;
  selectedDaysRefObj: React.MutableRefObject<Date[]>;
  selectedRecurringDaysRefObj: React.MutableRefObject<number[] | null>;
  isReOccurringShift: boolean;
  setIsReocurringShift: (isReoccurring: boolean) => void;
  currentMonth: Date;
  handleMonthChange: (month: Date) => void;
  today: Date;
  isMobile: boolean;
  isDateBeforeToday: (date: Date) => boolean;
  isFistModalOpenForReOccurringDayModal: boolean;
  setIsFistModalOpenForReOccurringDayModal: (isOpen: boolean) => void;
}

const ShiftDatePage: React.FC<ShiftDatePageProps> = ({
  selectedDays,
  setSelectedDays,
  selectedDaysRefObj,
  selectedRecurringDaysRefObj,
  isReOccurringShift,
  setIsReocurringShift,
  currentMonth,
  handleMonthChange,
  today,
  isMobile,
  isDateBeforeToday,
  isFistModalOpenForReOccurringDayModal,
  setIsFistModalOpenForReOccurringDayModal,
}) => {
  const handleWeekNumberClickv2 = (weekNumber: number, startOfWeek: Date[]) => {
    console.log("selectedRecurringDaysRefObj " + JSON.stringify(selectedRecurringDaysRefObj.current) + " startOfWeek " + startOfWeek);

    const handleWeekClick = (weekNumberInAYear: Date) => {
      const dayValuesWithInWeek: Date[] = [];
      for (let i = 0; i < 7; i++) {
        const day = new Date(weekNumberInAYear);
        day.setDate(weekNumberInAYear.getDate() + i);

        if (!selectedRecurringDaysRefObj.current) {
          dayValuesWithInWeek.push(day);
        } else if (selectedRecurringDaysRefObj.current.includes(i)) {
          dayValuesWithInWeek.push(day);
        }
      }
      return dayValuesWithInWeek;
    };

    if (startOfWeek.length > 0) {
      const startOfClickedWeek = new Date(startOfWeek[0]);
      const daysInNewWeek = handleWeekClick(startOfClickedWeek);

      console.log("daysInNewWeek " + daysInNewWeek);
      let valuesfound = 0;

      for (let i = 0; i < daysInNewWeek.length; i++) {
        const potStartWeekDay = daysInNewWeek[i];
        if (selectedDays.some((date) => date.getTime() === potStartWeekDay.getTime())) {
          valuesfound++;
          console.log(potStartWeekDay);
        }
      }
      console.log(valuesfound);
      // deselect
      if (valuesfound === daysInNewWeek.length) {
        const filteredDates: Date[] = [];
        for (let i = 0; i < selectedDays.length; i++) {
          if (!daysInNewWeek.some((date) => date.getTime() === selectedDays[i].getTime())) {
            filteredDates.push(selectedDays[i]);
          }
        }
        setSelectedDays(filteredDates);
        selectedDaysRefObj.current = [...filteredDates];
      }
      // add elements
      else {
        console.log("here in sssss " + JSON.stringify(daysInNewWeek));
        const resultingSelectedDays: Date[] = [...selectedDays, ...daysInNewWeek];
        setSelectedDays([...resultingSelectedDays]);
        selectedDaysRefObj.current = [...resultingSelectedDays];
      }
    }
  };

  const handleClickForRecourring = (newRecourringStatus: boolean) => {
    selectedDaysRefObj.current = [];
    setSelectedDays([]);
    setIsReocurringShift(newRecourringStatus);
    console.log("isReOccurringShift " + isReOccurringShift);
  };

  return (
    <>
      <div className="new-shift-v5-shift-page-1">
        <label className="new-shift-v5-shift-page-1-selection-title">Is this an Individual or Reoccurring Shift?</label>
        <div className={isMobile ? "datepicker-holder-mobile" : "datepicker-holder"}>
          <div className={isMobile ? "isrecurring-holder-mobile" : "isrecurring-holder"}>
            <button className="recurring-button" onClick={() => handleClickForRecourring(false)} disabled={!isReOccurringShift}>
              Individual Shift
            </button>
            <button className="recurring-button" onClick={() => handleClickForRecourring(true)} disabled={isReOccurringShift}>
              Reocurring Shift
            </button>
          </div>
          <span style={{ height: "10px" }}></span>
          <label className="new-shift-v5-shift-page-1-selection-title">
            Select your Dates <span style={{ color: "red" }}>*</span>
          </label>

          <div className={isMobile ? "new-shift-daypicker-holder-mobile" : "new-shift-daypicker-holder"}>
            {isReOccurringShift ? (
              <DayPicker
                showWeekNumber
                showOutsideDays={false}
                min={1}
                className="cal"
                selected={selectedDays}
                month={currentMonth}
                onMonthChange={handleMonthChange}
                onWeekNumberClick={handleWeekNumberClickv2}
                modifiers={{
                  disabled: { before: today },
                }}
                modifiersStyles={{
                  selected: { backgroundColor: "#2c68bd" },
                  disabled: { backgroundColor: "#ffffff", borderColor: "#fff" },
                }}
                disabled={isDateBeforeToday}
                styles={{
                  day: {
                    fontSize: "14px",
                  },
                }}
              />
            ) : (
              <DayPicker
                mode="multiple"
                style={isMobile ? { transform: "scale(130%)" } : {}}
                className="cal"
                min={1}
                selected={selectedDays}
                onSelect={(daysSelected: Date[]) => {
                  setSelectedDays(daysSelected);
                  selectedDaysRefObj.current = [...daysSelected];
                }}
                month={currentMonth}
                onMonthChange={handleMonthChange}
                modifiers={{
                  disabled: { before: today },
                }}
                modifiersStyles={{
                  selected: { backgroundColor: "#2c68bd" },
                  disabled: { backgroundColor: "#ffffff", borderColor: "#fff" },
                }}
                disabled={isDateBeforeToday}
                styles={{
                  day: {
                    fontSize: "14px",
                  },
                }}
              />
            )}
          </div>
        </div>

        {isReOccurringShift && isFistModalOpenForReOccurringDayModal ? (
          <RecurringDow
            isFistModalOpenForReOccurringDayModal={isFistModalOpenForReOccurringDayModal}
            setIsFistModalOpenForReOccurringDayModal={setIsFistModalOpenForReOccurringDayModal}
            selectedRecurringDaysRefObj={selectedRecurringDaysRefObj}
            isReOccurringShift={isReOccurringShift}
          />
        ) : null}
      </div>
    </>
  );
};


// Define the interface for ShiftTimeSelectionPage props
interface ShiftTimeSelectionPageProps {
  workyStartTimeRefObj: React.MutableRefObject<WorkyTimeObj>;
  workyEndTimeRefObj: React.MutableRefObject<WorkyTimeObj>;
}

// Refactored component with props
const ShiftTimeSelectionPage: React.FC<ShiftTimeSelectionPageProps> = ({
  workyStartTimeRefObj,
  workyEndTimeRefObj
}) => {
  const [startTime, setStartTime] = useState<WorkyTimeObj>(workyStartTimeRefObj.current);    
  const [endTime, setEndTime] = useState<WorkyTimeObj>(workyEndTimeRefObj.current);

  const handleV3StartTimeChange = ({ newWorkyTime }: { newWorkyTime: WorkyTimeObj }): void => {
    console.log("prev: " + JSON.stringify(workyStartTimeRefObj.current));
    workyStartTimeRefObj.current = { 
      hour: newWorkyTime.hour, 
      minute: newWorkyTime.minute, 
      isAM: newWorkyTime.isAM 
    };
    console.log(" new workyStartTimeRefObj " + JSON.stringify(workyStartTimeRefObj.current));
    setStartTime(workyStartTimeRefObj.current);
  };

  const handleV3EndTimeChange = ({ newWorkyTime }: { newWorkyTime: WorkyTimeObj }): void => {
    workyEndTimeRefObj.current = { 
      hour: newWorkyTime.hour, 
      minute: newWorkyTime.minute, 
      isAM: newWorkyTime.isAM 
    };
    setEndTime(workyEndTimeRefObj.current);
  };
  
  return (            
    <div className="new-shift-v5-shift-page-1">
      <label className="new-shift-v5-shift-page-1-selection-title">
        Select Shift Start Time <span style={{color:"red"}}>*</span>
      </label>
      <TimePicker 
        onTimeChange={handleV3StartTimeChange}
        newWorkyTimeObj={startTime}
        onChangeHelper={() => {setStartTime(startTime)}}
      />
      <label className="new-shift-v5-shift-page-1-selection-title">
        Select Shift End Time <span style={{color:"red"}}>*</span>
      </label>
      <TimePicker 
        onTimeChange={handleV3EndTimeChange}
        newWorkyTimeObj={endTime}
        onChangeHelper={() => {setEndTime(endTime)}}
      />
    </div>            
  );
};

export {
  StepperSequenceOrgView,
  PageDetailsSequence,
  LocationAddress,
  Location,
  Organization,
  StepperSequenceMobileOrgView,
  ShiftDatePageProps,
  ShiftDatePage,
  RecurringDow,
  ShiftTimeSelectionPageProps,
  ShiftTimeSelectionPage
};
