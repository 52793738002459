import React from "react";
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';

const EmptyInfoState = ({ pageName}:{ pageName:String}) => {

    return(
        <div className="empty-state">
            <div className="sync-icon">
                <SyncRoundedIcon sx={{fontSize:"80px", color:"#2c68bd", }}/>
            </div>
            <div>Sorry, <span className="simple-bold">{pageName}</span> information not available.</div>
        </div>
    )
}

export {EmptyInfoState};