import React, { useCallback, useState, useEffect, useRef } from "react";
import { AgencyOrgBillingCycleSummaryDTO } from '../agency-data/AgencyInterfaces';
import { GetOrgBillsAPICall } from '../agency-api-calls/AgencyAPI';

import { EmptyInfoState } from "./EmptyInfoState";
import { StyleLinks } from "../../orgViews/OrgCommonComponents";
import { useIsMobile } from "../../custom-hooks/useIsMobile";
import { WorkyContactButton, WorkyFooter, WorkyTopShelf, WorkyAppHeader } from "../agency-components/AgencyComponentSetOne";
import { AgencyBillingTable } from "./AgencyBillingTable";

const PageHeader = ({isMobile}:{isMobile:boolean}) => {
  
    return(
            <div className={isMobile?'payments-header-holder-mobile':'payments-header-holder'}>
                <div className='payments-header'>
                    <div className='payments-header-title-holder'>
                        <button id="payments-header-active" className='payments-header-title-switch'>Payments</button>
                    </div>
                    <div className='payments-header-subtitle'>Manage your Outbound Bills and Payment Details</div>
                </div>
            </div>
    )
}


const AgencyOrgBillingPage = () => {
    const [orgBillingData, setOrgBillingData] = useState<AgencyOrgBillingCycleSummaryDTO[] | null>(null);

    useEffect(() => {
        const fetchOrgBillingData = async () => {
            try {
                const billingData = await GetOrgBillsAPICall();
                setOrgBillingData(billingData);
                // console.log("orgBillingData: " + JSON.stringify(billingData));
            } catch (error) {
                console.error("Error fetching org billing data", error);
            }
        };

        fetchOrgBillingData();
    }, []);
    const isMobile = useIsMobile();


    return (
        <>
            <StyleLinks/>
            <WorkyAppHeader/>
            <PageHeader isMobile={isMobile}/>
            
            {orgBillingData?.length === 0 || orgBillingData === null ? 
                <div style={{background:"#f5f5f7"}}><EmptyInfoState pageName={"Payment"}/></div>
                :
                <AgencyBillingTable isMobile={isMobile} agencyBillingData={orgBillingData}/>
            }

            <WorkyContactButton/>
        </>
    );
};

export { AgencyOrgBillingPage };