import {ShiftStartEndDetails} from './CommonInterfaces'
import {ShiftIngressRequestAPIObj, ShiftIngressRequestUnitAPIObj} from '../jobberViews/objects/ShiftRequestConstants'
import {Workertype, ShiftRequestUnitDtoV2, EmailAddress} from '../jobberViews/objects/ShiftRequestConstants'
import { JobberWorkerTypeDTO } from '../sr-ingress/sr-ingress-data/SRIngressInterfaces';

interface ShiftRequestContent {
    numJobbers: number;
    shiftStartEndDetails:ShiftStartEndDetails[];
    simpleLocation: string;
    workertype: Workertype;
    requestOrgName: string;
    jobberHourlyRate: number;
    selectedJobbersSubset: EmailAddress[]; // You might want to define a specific type for selectedJobbersSubset
    isTestData?: boolean; // Optional property
}

interface RequestInputSourceData {
    orgName: string,
    orgLocationName: string;
    workertype: Workertype;
    numJobbers: number;    
    shiftStartEndDetails:ShiftStartEndDetails[];    
    jobberHourlyRate: number;
    selectedJobbersSubset: EmailAddress[]; // You might want to define a specific type for selectedJobbersSubset
    isTestData?: boolean; // Optional property
    jobberWorkerTypes: JobberWorkerTypeDTO[];
    selectedAgencyName : string;
}

const populateShiftRequestContentV2 = ({
    numJobbers,
    simpleLocation,
    shiftStartEndDetails,
    workertype,
    requestOrgName,
    jobberHourlyRate,
    selectedJobbersSubset,
    isTestData = false
}: ShiftRequestContent): ShiftRequestUnitDtoV2[] => {
    const apiShiftReqContent: ShiftRequestUnitDtoV2[] = [];

    
    const selectedJobbersActual:{ value: string }[] =
			selectedJobbersSubset && selectedJobbersSubset.length > 0 ? selectedJobbersSubset : [];
		
		for (let i = 0; i < numJobbers; i++) {
            const alreadyProcessedShifts : number[] = [];
			for (let j = 0; j < shiftStartEndDetails.length; j++) {
                
                if(alreadyProcessedShifts.includes(shiftStartEndDetails[j].startDateTimeEpochSec)) continue;

                alreadyProcessedShifts.push(shiftStartEndDetails[j].startDateTimeEpochSec);
				
                apiShiftReqContent.push({
					simpleLocation: simpleLocation, // api call - done
					workerType: workertype, // client side - done
					requestOrgName: requestOrgName, // api call - done
					jobberHourlyRate: jobberHourlyRate, // api call - done
					startDateOfSRUnitEpochSeconds: shiftStartEndDetails[j].startDateTimeEpochSec, // client side - done
					endDateOfSRUnitEpochSeconds: shiftStartEndDetails[j].endDateTimeEpochSec, // client side - done
					preferredJobbers: selectedJobbersActual, // api call + client side - done
					isTestData: isTestData, // api call - done
				});
			}
		}

		return apiShiftReqContent;
};


const CalcShiftDuration = ({ startTimeHour, startTimeMinute, endTimeHour, endTimeMinute }
    : { startTimeHour: number, startTimeMinute: number, endTimeHour: number, endTimeMinute: number }): number => {
        const hours1 = endTimeHour;
        const minutes1 = endTimeMinute;
        const hours2 = startTimeHour;
        const minutes2 = startTimeMinute;
        const totalMinutes1 = hours1 * 60 + minutes1;
        const totalMinutes2 = hours2 * 60 + minutes2;
        const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);
        let shiftDuration = differenceInMinutes / 60;

        if (shiftDuration > 10) {
            shiftDuration -= 1;
        } else if (shiftDuration >= 5) {
            shiftDuration -= 0.5;
        }
    return shiftDuration; // Return the calculated duration
}

const CalcWorkyChargeNotIncludingTax = ({
    shiftDuration,
    numWorkers,
    numDays,
    workerHourlyPayRate,
    markUpPrecentage
  }: {
    shiftDuration: number;
    numDays: number;
    numWorkers: number;
    workerHourlyPayRate: number;
    markUpPrecentage: number;
  }): number => {
    const charge = shiftDuration * numWorkers * numDays * workerHourlyPayRate * (1 + markUpPrecentage);
    return Math.round(charge * 100) / 100; // Round to 2 decimal places
};

const CalcTax= ({amount, taxRate} :{amount:number, taxRate:number}) :number =>{
    return  Math.round((amount * taxRate) * 100) / 100; // Round to 2 decimal places
}


interface BuildShiftRequestUnitClientObjRequest{
    numOfJobbers: number;
    shiftStartEndDetails: ShiftStartEndDetails[]
}
const BuildShiftRequestUnitClientObj = (buildShiftRequestUnitClientObjRequest: BuildShiftRequestUnitClientObjRequest):ShiftIngressRequestUnitAPIObj[] =>{
    const shiftRequestContent: ShiftIngressRequestUnitAPIObj[] = [];
    
    for(let i = 0; i < buildShiftRequestUnitClientObjRequest.numOfJobbers; i++){
        const alreadyProcessedShifts : number[] = [];
        for(let j = 0; j < buildShiftRequestUnitClientObjRequest.shiftStartEndDetails.length; j++){
            if(alreadyProcessedShifts.includes(buildShiftRequestUnitClientObjRequest.shiftStartEndDetails[j].startDateTimeEpochSec)) continue;
            alreadyProcessedShifts.push(buildShiftRequestUnitClientObjRequest.shiftStartEndDetails[j].startDateTimeEpochSec);
            shiftRequestContent.push({                
                startDateOfSRUnitEpochSeconds: buildShiftRequestUnitClientObjRequest.shiftStartEndDetails[j].startDateTimeEpochSec,
                endDateOfSRUnitEpochSeconds: buildShiftRequestUnitClientObjRequest.shiftStartEndDetails[j].endDateTimeEpochSec
            });
        }
    }
    return shiftRequestContent;
}

const BuildSRIngressRequestObj = ({requestInputSourceData}:{requestInputSourceData: RequestInputSourceData}): ShiftIngressRequestAPIObj=>{

    const shiftRequestContent: ShiftIngressRequestUnitAPIObj[] = [];
    
    for(let i = 0; i < requestInputSourceData.numJobbers; i++){
        const alreadyProcessedShifts : number[] = [];
        for(let j = 0; j < requestInputSourceData.shiftStartEndDetails.length; j++){
            if(alreadyProcessedShifts.includes(requestInputSourceData.shiftStartEndDetails[j].startDateTimeEpochSec)) continue;
            alreadyProcessedShifts.push(requestInputSourceData.shiftStartEndDetails[j].startDateTimeEpochSec);
            shiftRequestContent.push({
                jobberHourlyRate: requestInputSourceData.jobberHourlyRate,
                startDateOfSRUnitEpochSeconds: requestInputSourceData.shiftStartEndDetails[j].startDateTimeEpochSec,
                endDateOfSRUnitEpochSeconds: requestInputSourceData.shiftStartEndDetails[j].endDateTimeEpochSec
            });
        }
    }
    
    const shiftIngressRequestAPIObj: ShiftIngressRequestAPIObj = {
        orgName: requestInputSourceData.orgName,
        orgLocationName: requestInputSourceData.orgLocationName,        
        preferredJobbers: requestInputSourceData.selectedJobbersSubset,
        shiftRequestContent: shiftRequestContent,
        isTestData: requestInputSourceData.isTestData,
        jobberWorkerTypes: requestInputSourceData.jobberWorkerTypes,
        possibleAgenciesForSR: [requestInputSourceData.selectedAgencyName]
    }

    return shiftIngressRequestAPIObj;
}

export{ populateShiftRequestContentV2,
     CalcWorkyChargeNotIncludingTax,
      CalcShiftDuration,
       CalcTax, 
       BuildSRIngressRequestObj,
       RequestInputSourceData,
       BuildShiftRequestUnitClientObj,
       BuildShiftRequestUnitClientObjRequest}