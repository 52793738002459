import { AxiosResponse } from "axios";
import { RemoteExecutionServiceImpl, RemoteGETCallInputObject,
    RemotePOSTCallInputObject,
    ServerResponse } from "../../remoteExecution/RemoteExecutionServiceImpl";

import {JobberWorkerTypeDTO, ShiftRequestClientObjPreHook, JobberAvailabilityResponse} from "../sr-ingress-data/SRIngressInterfaces"

const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();


const GetOrgBillsAPICall = async (orgName: string): Promise<JobberWorkerTypeDTO[] | null> => {
    const getCallInput: RemoteGETCallInputObject = {
      apiUrl: `${backendApiBasePathLocal}agency/jobber-worker-types/`,
      headers: { },
      queryParams: {corpName: orgName},
    };
  
    const jobberWorkerTypes: AxiosResponse<ServerResponse<JobberWorkerTypeDTO[], Error>> = await remoteCallObject.remoteGETCallV2<
      ServerResponse<JobberWorkerTypeDTO[], Error>
    >(getCallInput);
  
    if (jobberWorkerTypes && jobberWorkerTypes.data && jobberWorkerTypes.data.data) {
      return jobberWorkerTypes.data.data;
    } else {
      return null;
    }
  };



  const SRIngressPreHookPOSTAPICall = async (srIngressPreHookReq: ShiftRequestClientObjPreHook): Promise<JobberAvailabilityResponse[] | null> => {
    console.log("ssas: ", srIngressPreHookReq)
    const postCallInput: RemotePOSTCallInputObject<ShiftRequestClientObjPreHook> = {
      apiUrl: `${backendApiBasePathLocal}shiftrequest/pre-hook/check`,
      headers: { },
      object:srIngressPreHookReq,    
    };
  
    const orgBillsResponse: AxiosResponse<ServerResponse<JobberAvailabilityResponse[], Error>> = 
    await remoteCallObject.remotePOSTCallV2<ServerResponse<JobberAvailabilityResponse[], Error>,ShiftRequestClientObjPreHook>(postCallInput);
  
    if (orgBillsResponse && orgBillsResponse.data && orgBillsResponse.data.data) {
      return orgBillsResponse.data.data;
    } else {
      return null;
    }
  
  };

export { GetOrgBillsAPICall, SRIngressPreHookPOSTAPICall };