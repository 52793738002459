import { ShiftRequest, formatTimeString } from "../orgViews/OrgUtils";
import{ShiftRequestUnit} from "../common/CommonInterfaces";
import { RemoteExecutionServiceImpl, ServerResponse } from "../remoteExecution/RemoteExecutionServiceImpl";

const remoteCallObject = RemoteExecutionServiceImpl();
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;


interface SRIPResponseData {
	workyWorkdayShift: string;
	inProgressShiftRequests: ShiftRequestUnit[];
	assignedShiftRequests: ShiftRequestUnit[];
}

// interface SRIPRespObj {
// 	shiftRequestWorkDays: Map<string, SRIPResponseData[]>;
// }

interface SRIPRespObj {
    shiftRequestWorkDays: SRIPResponseData[];
  }


const sortDataByWorkyWorkdayShift = (data: SRIPResponseData[]) => {
    // console.log("test6 : " + data)
	return data.sort((a, b) => {
		const dateA = a.workyWorkdayShift.split('T')[0];
		const timeA = a.workyWorkdayShift.split('_')[0].split('T')[1];

		const dateB = b.workyWorkdayShift.split('T')[0];
		const timeB = b.workyWorkdayShift.split('_')[0].split('T')[1];

		const dateTimeA = new Date(`${dateA}T${timeA}`).getTime();
		const dateTimeB = new Date(`${dateB}T${timeB}`).getTime();
		// console.log('dateTimeA' + dateTimeA);
		// console.log('dateTimeB' + dateTimeB);
		// console.log('dateTimeA - dateTimeB' + (dateTimeA - dateTimeB));

		return dateTimeA - dateTimeB;
	});
};


const shiftToEventBuilder = (srip: SRIPResponseData[], setShiftEvents: (data: any[]) => void) => {
    const events = [];
    

    srip.forEach((shiftRequest) => {
      const { assignedShiftRequests, inProgressShiftRequests } = shiftRequest;
  
      // Process assigned shift requests
      assignedShiftRequests.forEach((assignedShift) => {
        const { workyWorkdayShift, startDateTimeEpochSeconds, endDateTimeEpochSeconds } = assignedShift;


        events.push({
            title: formatTimeString(workyWorkdayShift.substring(11, 22)),
            start: new Date(startDateTimeEpochSeconds * 1000),
            end: new Date(endDateTimeEpochSeconds * 1000),  
            id: JSON.stringify([workyWorkdayShift,assignedShift]),
        });
      });
  
      // Process in-progress shift requests
      inProgressShiftRequests.forEach((inProgressShift) => {
        const { workyWorkdayShift, startDateTimeEpochSeconds, endDateTimeEpochSeconds } = inProgressShift;
      
        
        events.push({
          title: formatTimeString(workyWorkdayShift.substring(11, 22)),
          start: new Date(startDateTimeEpochSeconds * 1000), // Convert seconds to milliseconds
          end: new Date(endDateTimeEpochSeconds * 1000), // Convert seconds to milliseconds
          id: JSON.stringify([workyWorkdayShift,inProgressShift]),
        });
      });
    });

    // Third loop to combine events with the same workyWorkdayShift
    const combinedEvents = events.reduce((accumulator, currentEvent) => {
      const currentWorkyWorkdayShift = JSON.parse(currentEvent.id)[0];
      const currentEventData = JSON.parse(currentEvent.id)[1];
      const existingEventIndex = accumulator.findIndex(
          (e) => JSON.parse(e.id)[0] === currentWorkyWorkdayShift
      );

      if (existingEventIndex !== -1) {
          // Combine the current event data with the existing event data
          const existingEvent = accumulator[existingEventIndex];
          const existingEventData = JSON.parse(existingEvent.id)[1];
          const combinedEventData = [existingEventData, currentEventData]; // Combine event data as an array
          const combinedEvent = {
              ...existingEvent,
              id: JSON.stringify([currentWorkyWorkdayShift, combinedEventData]) // Update id with combined event data array
          };
          accumulator[existingEventIndex] = combinedEvent;
      } else {
          // Add the current event as it is unique
          accumulator.push(currentEvent);
      }

      return accumulator;
    }, []);

    // Replace the events array with combinedEvents
    const updatedEvents = [...combinedEvents];
    
    setShiftEvents(updatedEvents);

};

/**
 * 
 * @param param0 can work for both org and agency
 */


const GetCorpSRIP = async ({setOrgSRIPResponse,setShiftEvents,}: {setOrgSRIPResponse: (SRIPResponseData: SRIPResponseData[]) => void;setShiftEvents: (data: any[]) => void;}) => {
    const getCallInput = {
      apiUrl: backendApiBasePathLocal + 'shiftrequest/SRIP-V3',
      headers: {},
      queryParams: null,
    };
  
    try {
      const orgSRIPResponse = await remoteCallObject.remoteGETCallV2<ServerResponse<SRIPRespObj, Error>>(getCallInput);

      if (orgSRIPResponse?.data?.data) {
        const shiftRequestWorkDayMapValues: SRIPResponseData[] = Object.values(orgSRIPResponse.data.data.shiftRequestWorkDays);
        const sortedData: SRIPResponseData[] = sortDataByWorkyWorkdayShift(shiftRequestWorkDayMapValues);
  
        // Set states using callbacks
        setOrgSRIPResponse(sortedData);
        shiftToEventBuilder(sortedData, setShiftEvents);
  
        // Return the data for use elsewhere
        return sortedData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  
    return null; // Return null if no data is available
  };


export {GetCorpSRIP, SRIPResponseData, SRIPRespObj, sortDataByWorkyWorkdayShift, shiftToEventBuilder}