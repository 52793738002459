import React, { useState } from 'react';
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Modal, Slide } from '@mui/material';
import { AgencyOrgBillingCycleSummaryDTO, DownloadOrgBillReqDTO, AgencyOrgBillRowObj, DownloadOrgBillRespDTO } from '../agency-data/AgencyInterfaces';
import {PostRetrieveOrgAgencyBillPDF} from '../agency-api-calls/AgencyAPI';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import {DownloadPDF} from '../../shared-engineering-services/LocalExecutionService';
import './agencybillingpage.css';

const AgencyBillingTable = ({ agencyBillingData, isMobile }: { agencyBillingData: AgencyOrgBillingCycleSummaryDTO[], isMobile:boolean}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalParams, setModalParams] = useState<any>(null); // Replace `any` with a specific type if needed
    const handleClose = () => setModalOpen(false);

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        setModalOpen(true);
        setModalParams(params.row);
    };

    const handleButtonClick = async (reqObj: AgencyOrgBillRowObj) => {
        console.log(`Button clicked for row ID: ${JSON.stringify(reqObj)}`);
        // const resp = await PostRetrieveOrgAgencyBillPDF();

        const downloadPDfReq: DownloadOrgBillReqDTO = {
            orgName: reqObj.orgName,
            billingCycleStartTimeEpochSec: reqObj.billingCycleStartTimeEpochSec,
            billingCycleEndTimeEpochSec: reqObj.billingCycleEndTimeEpochSec,    
          };
          const resp = await PostRetrieveOrgAgencyBillPDF(downloadPDfReq);
          if (resp && resp.fileDownloadURL) {
            console.log('PDF download link:', resp);
            DownloadPDF({ fileUrl: resp.fileDownloadURL, fileName: resp.fileName });
          } else {
            console.log('Failed to retrieve PDF');
          }
    };


    const AgencyBillingStatusTagBuilder = ({ billingTagVal }: { billingTagVal: string }) => {
        const UNPAID_CREATED = () => { return (<div id='bill-unpaid' className='billing-tag'>Unpaid + Created</div>) }
        const UNPAID_ORG_PAYMENT_CREATED = () => { return (<div id='bill-unpaid' className='billing-tag'>Unpaid + Payment Created</div>) }
        const UNPAID_ORG_PAYMENT_APPROVED_AND_ORG_NOTIFIED = () => { return (<div id='bill-overdue' className='billing-tag'>Unpaid + Notified</div>) }
        const UNPAID_ORG_PAYMENT_DELAYED = () => { return (<div id='bill-partially-paid' className='billing-tag'>Unpaid + Payment Delayed</div>) }
        const PAID_ORG_PAYMENT_RECEIVED_FULLY_COMPLETE = () => { return (<div id='bill-paid' className='billing-tag'>Payed</div>) }

        switch (billingTagVal) {
            case "UNPAID_CREATED": return <UNPAID_CREATED/>;
            case "UNPAID_ORG_PAYMENT_CREATED": return <UNPAID_ORG_PAYMENT_CREATED/>;
            case "UNPAID_ORG_PAYMENT_APPROVED_AND_ORG_NOTIFIED": return <UNPAID_ORG_PAYMENT_APPROVED_AND_ORG_NOTIFIED/>;
            case "UNPAID_ORG_PAYMENT_DELAYED": return <UNPAID_ORG_PAYMENT_DELAYED/>;
            case "PAID_ORG_PAYMENT_RECEIVED_FULLY_COMPLETE": return <PAID_ORG_PAYMENT_RECEIVED_FULLY_COMPLETE/>;
            default: return (null);
        }
    }

    const formatDateRange = (dateRange) => {
        // Check if dateRange is valid
        if (typeof dateRange !== 'string' || !dateRange.includes('_')) {
            console.error("Invalid dateRange:", dateRange);
            return "Invalid Date Range";
        }
    
        const [startDateStr, endDateStr] = dateRange.split('_');
    
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
    
        // Additional check if dates are valid
        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            console.error("Invalid dates from dateRange:", startDateStr, endDateStr);
            return "Invalid Dates";
        }
    
        return `${startDate.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })} - ${endDate.toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })}`;
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Invoice Date',
            headerClassName: 'billing-header',
            width: 360,
            renderHeader: () => (<div className='billing-header'>Invoice Date</div>),
            renderCell: (params: GridRenderCellParams) => (
                <div className='invoice-cell-holder'>
                    <div className='invoice-cell-icon'><DescriptionRoundedIcon style={{ fontSize: "18px" }} /></div>
                    <span className='invoice-cell-text'><strong>Invoice Range: {formatDateRange(params?.row?.id.substring(0, 20))}</strong></span>
                </div>
            ),
        },
        {
            field: 'orgName',
            headerName: 'Org Name',
            headerClassName: 'billing-header',
            headerAlign: "left",
            align: "left",
            width: 150,
            renderHeader: () => (<div className='billing-header'>Org Name</div>),
            renderCell: (params: GridRenderCellParams) => (<span className='invoice-cell-text'>{params?.row?.orgName}</span>),
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'billing-header',
            width: 150,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (<div className='billing-header'>Status</div>),
            renderCell: (params: GridRenderCellParams) => (
                <div className='billing-tag-holder'>
                    <AgencyBillingStatusTagBuilder billingTagVal={params.row.status} />
                </div>
            ),
        },
        {
            field: 'outstandingCost',
            headerName: 'Outstanding Cost',
            headerClassName: 'billing-header',
            type: 'number',
            flex: 1,
            headerAlign: "right",
            align: "right",
            renderHeader: () => (<div className='billing-header'>Outstanding Cost</div>),
            renderCell: (params: GridRenderCellParams) => (<span className='invoice-cell-text'>{"$" + params.row.outstandingCost}</span>),
        },
        {
            field: 'totalCost',
            headerName: 'Total Cost',
            headerClassName: 'billing-header',
            type: 'number',
            flex: 1,
            headerAlign: "right",
            align: "right",
            renderHeader: () => (<div className='billing-header'>Total Cost</div>),
            renderCell: (params: GridRenderCellParams) => (
                <div>
                    <span className='invoice-cell-text'>{"$" + params.row.totalCost}</span>
                </div>
            ),
        },
        {
            field: '.',
            headerName: 'Download',
            headerClassName: 'billing-header',
            type: 'number',
            flex: 0.5, // Smaller flex to reduce space
            headerAlign: "right",
            align: "right",
            renderHeader: () => (<div style={{background:"white"}} className='billing-header'></div>),
            renderCell: (params: GridRenderCellParams) => (
                <div className='billing-tag-holder'>
                    <button className='invoice-cell-btn' onClick={() => handleButtonClick(params.row)}>
                        <FileDownloadRoundedIcon sx={{ fontSize: "22px" }} />
                    </button>
                </div>
            ),
        },
    ];
    
    const buildRowsFromBillingData = ({ billingDataArray }: { billingDataArray: AgencyOrgBillingCycleSummaryDTO[] }): AgencyOrgBillRowObj[] => {
        const rows:AgencyOrgBillRowObj[] = [];
    
        if (!Array.isArray(billingDataArray)) {
            console.error("billingDataArray should be an array", billingDataArray);
            return [];
        }
    
        for (const billingData of billingDataArray) {
            const row = {
                id: `${billingData.billingCyclePartitionKey}-${billingData.orgName}`,
                orgName: billingData.orgName,
                status: billingData.shiftPaymentOrgStatus,
                outstandingCost: billingData.currentOutstandingTotalAmountOwed,
                totalCost: billingData.originalTotalAmountOwed,
                shiftBillingDetailsMap: billingData.shiftBillingDetailsMap,
                billingCycleStartTimeEpochSec: billingData.billingCycleStartTimeEpochSec,
                billingCycleEndTimeEpochSec: billingData.billingCycleEndTimeEpochSec
        
            };
            rows.push(row);
        }
    
        // console.log("rows ->" + JSON.stringify(rows));
        return rows;
    };

    

    const rows = buildRowsFromBillingData({ billingDataArray: agencyBillingData });

    function calculateShiftTotals(response) {
        let totalShifts = 0;
        let totalHoursWorked = 0;
      
        try {
          // Check if response and shiftBillingDetailsMap exist and are valid objects
          if (!response || typeof response !== 'object' || !response.shiftBillingDetailsMap) {
            throw new Error("Invalid response object: Missing shiftBillingDetailsMap.");
          }
      
          // Iterate over each date in the shiftBillingDetailsMap
          for (const date in response.shiftBillingDetailsMap) {
            const shiftBillingDetailsData = response.shiftBillingDetailsMap[date].shiftBillingDetailsData;
      
            // Check if shiftBillingDetailsData is a valid object
            if (!shiftBillingDetailsData || typeof shiftBillingDetailsData !== 'object') {
              console.warn(`Skipping invalid shiftBillingDetailsData for date: ${date}`);
              continue;
            }
      
            // Iterate over each shift in shiftBillingDetailsData for the given date
            for (const shiftId in shiftBillingDetailsData) {
              const shift = shiftBillingDetailsData[shiftId];
      
              // Validate shift and workyBillPaymentBreakdown structure
              if (!shift || !shift.workyBillPaymentBreakdown || typeof shift.workyBillPaymentBreakdown.totalTimeWorked !== 'number') {
                console.warn(`Skipping invalid shift data for shift ID: ${shiftId}`);
                continue;
              }
      
              // Increment the total number of shifts
              totalShifts += 1;
      
              // Add to the total hours worked
              totalHoursWorked += shift.workyBillPaymentBreakdown.totalTimeWorked;
            }
          }
      
          // Return the results as an array
          return [totalShifts, totalHoursWorked];
      
        } catch (error) {
          console.error("Error calculating shift totals:", error.message);
          // Return a default array indicating no shifts or hours if an error occurs
          return [0, 0];
        }
      }

        
 
    const AgencyBillingInnerShiftsTable = ({ shiftData }) => {
        const columns: GridColDef[] = [
            // { field: 'shiftId', headerName: 'Shift ID', width: 70 },
            { field: 'firstName', headerName: 'First Name', headerClassName:"agency-billing-inner-table-header", width: 90, editable: false },
            { field: 'lastName', headerName: 'Last Name', headerClassName:"agency-billing-inner-table-header",width: 90, editable: false },
            { field: 'email', headerName: 'Email', headerClassName:"agency-billing-inner-table-header",width: 150 },
            { field: 'phone', headerName: 'Phone Number', headerClassName:"agency-billing-inner-table-header",width: 120 },
            { field: 'totalTimeWorked', headerName: 'Total Time Worked (hrs)', headerClassName:"agency-billing-inner-table-header",type: 'number', width: 150 },
            { field: 'totalCost', headerName: 'Total Cost ($)', type: 'number', headerClassName:"agency-billing-inner-table-header",width: 150 },
            { field: 'workyHourlyMarginRate', headerName: 'Hourly Margin Rate', headerClassName:"agency-billing-inner-table-header",type: 'number', width: 180 },
            { field: 'jobberHourlyRate', headerName: 'Jobber Hourly Rate ($)', headerClassName:"agency-billing-inner-table-header",type: 'number', width: 180 },
            { field: 'orgHourlyRate', headerName: 'Org Hourly Rate ($)', headerClassName:"agency-billing-inner-table-header",type: 'number', width: 180 },
            { field: 'totalHourlyCost', headerName: 'Total Hourly Cost ($)', headerClassName:"agency-billing-inner-table-header",type: 'number', width: 180 },
            { field: 'taxRate', headerName: 'Tax Rate (%)', type: 'number', headerClassName:"agency-billing-inner-table-header",width: 120 },
            { field: 'taxAmount', headerName: 'Tax Amount ($)', type: 'number', headerClassName:"agency-billing-inner-table-header",width: 180 },
            { field: 'wsipAmount', headerName: 'WSIP Amount ($)', type: 'number', headerClassName:"agency-billing-inner-table-header",width: 180 },
            { field: 'otherExpenses', headerName: 'Other Expenses ($)', type: 'number',headerClassName:"agency-billing-inner-table-header", width: 180 },
            { field: 'originalTotalCost', headerName: 'Original Total Cost ($)', headerClassName:"agency-billing-inner-table-header",type: 'number', width: 180 },
            { field: 'currentOutstandingTotalCost',headerClassName:"agency-billing-inner-table-header", headerName: 'Outstanding Total Cost ($)', type: 'number', width: 220 },
        ];
    
        const dateKey = Object.keys(shiftData)[0];
        const rows = [];
    
        if (shiftData[dateKey]?.shiftBillingDetailsData) {
            const shiftDetails = shiftData[dateKey].shiftBillingDetailsData;
            for (const shiftId in shiftDetails) {
                const shift = shiftDetails[shiftId];
                const jobberDetails = shift.jobberDetails;
                const billingBreakdown = shift.workyBillPaymentBreakdown;
    
                rows.push({
                    id: shiftId,
                    shiftId: shift.shiftId,
                    firstName: jobberDetails.firstName,
                    lastName: jobberDetails.lastName,
                    email: jobberDetails.userEmail.value,
                    phone: jobberDetails.userPhoneNumber.value,
                    totalTimeWorked: billingBreakdown.totalTimeWorked,
                    totalCost: billingBreakdown.totalCost,
                    workyHourlyMarginRate: billingBreakdown.workyHourlyMarginRate,
                    jobberHourlyRate: billingBreakdown.jobberHourlyRate,
                    orgHourlyRate: billingBreakdown.orgHourlyRate,
                    totalHourlyCost: billingBreakdown.totalHourlyCost,
                    taxRate: billingBreakdown.taxRate,
                    taxAmount: billingBreakdown.taxAmount,
                    wsipAmount: billingBreakdown.wsipAmount,
                    otherExpenses: billingBreakdown.otherExpenses,
                    originalTotalCost: billingBreakdown.originalTotalCost,
                    currentOutstandingTotalCost: billingBreakdown.currentOutstandingTotalCost,
                });
            }
        }
    
        return (
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 4 },
                    },
                }}
                pageSizeOptions={[4, 8]}
                sx={{
                    borderRadius: '10px',
                    border: '1px solid',
                    borderColor: '#ebebeb',
                    background: 'white',
                    maxWidth: '100%',
                }}
            />
        );
    };


    return (
        <>  
            <div className={isMobile?'timeclock-table-holder-mobile':'agency-billing-table-holder'}>
                <DataGrid
                    sx={{
                        borderRadius: '10px',
                        border: '1',
                        borderColor: '#ebebeb',
                        background:'white'
                    }}
                    rows={rows}
                    onRowClick={handleRowClick}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>
            <Modal open={modalOpen} onClose={handleClose}>
                <Slide direction={isMobile?"up":"left"} in={modalOpen} mountOnEnter unmountOnExit>
                    <div className={isMobile?"agency-billing-modal-mobile":"agency-billing-modal"}>
                        {isMobile?(<button onClick={handleClose} className='timeclock-close-mobile'/>):(<button onClick={handleClose} className="agency-billing-close"><CloseRoundedIcon /></button>)}
                        <div className='agency-billing-modal-icon'></div>
                        <div className='agency-billing-modal-orgname'>{modalParams?.orgName}</div>

                        <div className='agency-billing-modal-inner-holder'>
                            <div className='agency-billing-modal-inner-price'>${modalParams?.outstandingCost}</div>
                            <div className='agency-billing-modal-inner-daterange'>{formatDateRange(modalParams?.id.substring(0, 20))}</div>
                        </div>
                        
                        <div className='agency-billing-modal-payments-title'>Payments:</div>
                        <div className='agency-billing-modal-payments'>
                            <div style={{color: modalParams?.outstandingCost !== 0 ? '' : '',}} className='agency-billing-modal-payments-inner-txt'>${modalParams?.outstandingCost} / ${modalParams?.totalCost} Remaining</div>
                            <div style={{width: `${(1 - modalParams?.outstandingCost / modalParams?.totalCost) * 100}%`}} className='agency-billing-modal-payments-inner'></div>
                        </div>

                        <div className='agency-billing-modal-inner-holder-two'>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'>Status:</div>
                                <div className='agency-billing-modal-inner-value'><AgencyBillingStatusTagBuilder billingTagVal={modalParams?.status} /></div>
                            </div>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'>Number of Shifts:</div>
                                <div className='agency-billing-modal-inner-value'>{calculateShiftTotals(modalParams)[0]} Shifts</div>
                            </div>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'>Total Worked Hours:</div>
                                <div className='agency-billing-modal-inner-value'>{calculateShiftTotals(modalParams)[1]} Hours</div>
                            </div>


                        </div>

                        {modalParams && (modalParams.shiftBillingDetailsMap !== null && typeof modalParams.shiftBillingDetailsMap === 'object' && Object.keys(modalParams.shiftBillingDetailsMap).length > 0) ? 
                        (<>
                            <div className='agency-billing-modal-payments-title'>Shifts:</div>
                            <div className='agency-billing-modal-shift-holder'>
                                <AgencyBillingInnerShiftsTable shiftData={modalParams?.shiftBillingDetailsMap} />
                            </div>

                        </>):(null)}

                    </div>
                </Slide>
            </Modal>
        </>
    );
};

export { AgencyBillingTable };