import React, { useState, useEffect, useRef } from "react";
import { SRIPResponseData } from "../../common/ShiftReadAPIs";
import { ShiftRequestUnit } from "../../common/CommonInterfaces";
import './workyagencycarouselv2.css';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { extractDateTitleFromWorkyWorkdayShift, extractTimeFromWorkyWorkdayShift, shiftRequestAggregator } from "./CarouselHelper";
import { AgencyShiftClusterModal } from "./AgencyShiftClusterModal";


const WorkyAgencyCarouselV2 = ({SRIP,isMobile}:{SRIP:SRIPResponseData[];isMobile:boolean;}) => {
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [selectedDateFilter, setSelectedDateFilter] = useState("All"); // Default to "All"
    const [selectedDateToOpen, setSelectedDateToOpen] = useState("");
    const [shiftClusterOpen, setShiftClusterOpen] = useState(false);
    
    const closeShiftCluster = () => {setShiftClusterOpen(false)}
    const openShiftCluster = (shiftDate) => {setSelectedDateToOpen(shiftDate);setShiftClusterOpen(true);}
    const pushToMyShifts = () => {window.location.assign("/my-shifts")}
    const AggregatedSRIP = shiftRequestAggregator(SRIP);


    const allOrgNames = Array.from(
        new Set(
        AggregatedSRIP?.flatMap((shiftData) => [
            ...shiftData.inProgressShiftRequests,
            ...shiftData.assignedShiftRequests,
        ]).map((request) => request.orgName)
        )
    );

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const filterShifts = (request: ShiftRequestUnit) => {
        const requestDate = new Date(request.startDateTimeEpochSeconds * 1000);
        if (selectedDateFilter === "Today") {
            return (
                requestDate.getDate() === today.getDate() &&
                requestDate.getMonth() === today.getMonth() &&
                requestDate.getFullYear() === today.getFullYear()
            );
        } else if (selectedDateFilter === "Tomorrow") {
            return (
                requestDate.getDate() === tomorrow.getDate() &&
                requestDate.getMonth() === tomorrow.getMonth() &&
                requestDate.getFullYear() === tomorrow.getFullYear()
            );
        } else {
            return true;
        }
    };

    const handleOrgClick = (orgName) => {setSelectedOrg(orgName === selectedOrg ? null : orgName);};
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollBy({ left: -400, behavior: "smooth" });
        }
    };
    
    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 400, behavior: "smooth" });
        }
    };



    return (
        <>
            <div className={isMobile ?'top-agency-mobile':'worky-carousel-container-v2'}>

                <div className={isMobile ? "srstatus-title-mobile" : "srstatus-title-agency"}>
                    {isMobile?(<div style={{paddingTop:"0px", marginTop:"-5px"}} className="worky-topshelf-card-title-mobile">Current Shifts</div>):(<>Current Shifts</>)}
                    <div className={isMobile ? "worky-topshelf-option-holder-mobile":"worky-topshelf-option-holder"}>
                        <button onClick={pushToMyShifts} className={isMobile ? "calendar-button-mobile" : "calendar-button-agency"}><CalendarMonthRoundedIcon/></button>
                        <button onClick={() => setSelectedDateFilter("All")} id={selectedDateFilter === "All" ? "my-shifts-option-active":""} className="my-shifts-option">All</button>
                        <button onClick={() => setSelectedDateFilter("Today")} id={selectedDateFilter === "Today" ? "my-shifts-option-active":""} className="my-shifts-option">Today</button>
                        <button onClick={() => setSelectedDateFilter("Tomorrow")} id={selectedDateFilter === "Tomorrow" ? "my-shifts-option-active":""} className="my-shifts-option">Tomorrow</button>
                        {allOrgNames.map((orgName) => (
                            <button key={orgName} id={orgName === selectedOrg ? "my-shifts-option-active" : ""} className="my-shifts-option" onClick={() => handleOrgClick(orgName)}>{orgName}</button>
                        ))}
                    </div>   
                </div>

                <div className="worky-carousel-v2">
                    <button className="agency-shift-cluster-top-shelf-arrow" onClick={scrollLeft}><ArrowBackIosNewRoundedIcon /></button>

                    <div className="worky-carousel-v2-inner" ref={scrollContainerRef}>
                            {AggregatedSRIP.filter(
                                (shiftData) =>
                                    shiftData.inProgressShiftRequests.some((request) => {
                                        return filterShifts(request) && (!selectedOrg || request.orgName === selectedOrg);}) 
                                    ||
                                    shiftData.assignedShiftRequests.some((request) => {
                                        return filterShifts(request) && (!selectedOrg || request.orgName === selectedOrg);})
                            ).map((shiftData) => (

                            <button 
                                onClick={()=>openShiftCluster(shiftData.workyWorkdayShift)}
                                className="carousel-card-v2" 
                                key={shiftData.workyWorkdayShift}
                                id={shiftData.inProgressShiftRequests.filter((request) =>filterShifts(request) &&(!selectedOrg || request.orgName === selectedOrg)).length === 0 ? "carousel-card-v2-assigned":""}
                            >
                                <div className="carousel-card-v2-date">{extractDateTitleFromWorkyWorkdayShift(shiftData.workyWorkdayShift)}</div>
                                <div className="carousel-card-v2-time">{extractTimeFromWorkyWorkdayShift(shiftData.workyWorkdayShift)}</div>
                                
                                <div className="carousel-card-v2-ratio">
                                    {shiftData.assignedShiftRequests.filter((request) =>filterShifts(request) &&(!selectedOrg || request.orgName === selectedOrg)).length}
                                    /
                                    {(shiftData.assignedShiftRequests.filter((request) =>filterShifts(request) &&(!selectedOrg || request.orgName === selectedOrg)).length) + shiftData.inProgressShiftRequests.filter((request) =>filterShifts(request) &&(!selectedOrg || request.orgName === selectedOrg)).length}
                                </div>                               

                            </button>
                        ))}
                    </div>

                    <button className="agency-shift-cluster-top-shelf-arrow" onClick={scrollRight}><ArrowForwardIosRoundedIcon /></button>  
                </div>
            </div>

            {shiftClusterOpen?
                <AgencyShiftClusterModal
                    SRIP={AggregatedSRIP}
                    isMobile={isMobile}
                    modalOpen={shiftClusterOpen}
                    selectedDate={selectedDateToOpen}
                    handleClose={closeShiftCluster}
                />
            :null}
        </>
    );
};



export {WorkyAgencyCarouselV2}