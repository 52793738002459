import React from "react";
import { OrgLocation, OrgLocationMapDetails } from "../../common/CommonInterfaces";

interface NewShiftLocationSelectPageProps {
  isMobile: boolean;
  selectedOrgName: string;
  selectedLocation: OrgLocation;
  locations: Map<string, OrgLocation>;
  setOrgLocation: (location: OrgLocation) => void;
}

interface NewShiftAgencySelectPageProps {
  isMobile: boolean;
  selectedOrgName: string;
  selectedLocation: OrgLocation;
  selectedAgencyName: string;  
  setAgencyName: (agencyName: string) => void;
  agencyNames: string[];
}

const NewShiftLocationSelectPage = ({
  isMobile,
  selectedOrgName,
  selectedLocation,  
  locations,
  setOrgLocation,
}: NewShiftLocationSelectPageProps) => {
  console.log("locationsvv: " ,locations, "Object.keys(locations) ", Object.keys(locations));

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const locationName = e.target.value;
    const selectedLocationDetails = locations[locationName];
    setOrgLocation(selectedLocationDetails);
    console.log("selectedLocationDetails " + JSON.stringify(selectedLocationDetails));
  };

  console.log("Selected Organization: " + selectedOrgName);
  console.log("Selected Location: " + selectedLocation);

  return (
    <div className="new-shift-v5-shift-page-1">
      <div className="new-shift-v5-shift-page-1-selection-holder">
        <label className="new-shift-v5-shift-page-1-selection-title">
          Select Location <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={isMobile ? "new-shift-v5-shift-page-1-selection-select-mobile" : "new-shift-v5-shift-page-1-selection-select"}
          value={selectedLocation?.locationName}
          onChange={handleLocationChange}
          disabled={!selectedOrgName}
        >
          <option value="">Select a location</option>
          {Object.keys(locations).map((locationName) => (
            <option key={locationName} value={locationName}>
              {locationName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};


const NewShiftAgencySelectPage = ({
  isMobile,
  selectedOrgName,
  selectedLocation,
  selectedAgencyName,
  setAgencyName,
  agencyNames
}: NewShiftAgencySelectPageProps) => {
  console.log("agencyNames: " + JSON.stringify(agencyNames));

  const handleAgencyNameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const agencyName = e.target.value;
    setAgencyName(agencyName);
    console.log("agencyName " + JSON.stringify(agencyName));
  };
  

  return (
    <div className="new-shift-v5-shift-page-1">
      <div className="new-shift-v5-shift-page-1-selection-holder">
        <label className="new-shift-v5-shift-page-1-selection-title">
          Select Agency <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={isMobile ? "new-shift-v5-shift-page-1-selection-select-mobile" : "new-shift-v5-shift-page-1-selection-select"}
          value={selectedAgencyName}
          onChange={handleAgencyNameChange}
        //   disabled={!selectedOrgName}
        >
          <option value="">Select an agency</option>
          {agencyNames.map((agencyNameOption) => (
            <option key={agencyNameOption} value={agencyNameOption}>
              {agencyNameOption}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export { NewShiftLocationSelectPage,
     NewShiftLocationSelectPageProps,
     NewShiftAgencySelectPage,
     NewShiftAgencySelectPageProps};
