import React, { useState, useEffect} from 'react';
import { WorkyTime } from './CommonInterfaces';
import "./timepicker.css"
import { useIsMobile } from '../custom-hooks/useIsMobile';


const WorkyTimePicker = ({ onFinalTimeChangeHour, onFinalTimeChangeMinute }) => {
  // const initialAMPMState = new Date().getHours() <= 12 ? true : false;
  const [amActive, setamActive] = useState(true);
  const [pmActive, setpmActive] = useState(false);

  // hours
  const [hourInputValue, setHourInputValue] = useState(9);
  const hourNow = new Date().getHours();
  const hour12 = ((hourNow % 12) || 12).toString();

  const handleHourChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');
    const clampedValue = Math.min(12, Math.max(0, parseInt(numericValue, 10)));
    setHourInputValue(clampedValue);
  };

  // mins
  const [minsInputValue, setMinsInputValue] = useState(0);
  const minsNow = new Date().getMinutes().toString().padStart(2, '0');

  const handleMinsChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');
    const clampedValue = Math.min(59, Math.max(0, parseInt(numericValue, 10)));
    setMinsInputValue(clampedValue);
  };

    useEffect(() => {
        if (pmActive || amActive) {
            onFinalTimeChangeHour(hourInputValue + (pmActive ? 12 : 0));
            onFinalTimeChangeMinute(minsInputValue);
        }
    }, [amActive, pmActive, hourInputValue, minsInputValue, onFinalTimeChangeHour, onFinalTimeChangeMinute]);


  const handleAMClick = () => {
    setamActive(true);
    setpmActive(false);
  };

  const handlePMClick = () => {
    setamActive(false);
    setpmActive(true);
  };


  return (
      <div className='timepicker2'>
        <input
          className='tt2-hour'
          type="number"
          value={hourInputValue}
          onChange={handleHourChange}
          placeholder={"9"}
        />
        <div className='tt2-col'>:</div>
        <input
          className='tt2-mins'
          type="number"
          value={minsInputValue.toString().padStart(2, '0')}
          onChange={handleMinsChange}
          placeholder={"00"}          
        />
        <div className='tt2-ampm'>
          {amActive ? <button onClick={handleAMClick} id='ampm-active' className='tt2-am'>AM</button> : <button onClick={handleAMClick} className='tt2-am'>AM</button>}
          {pmActive ? <button onClick={handlePMClick} id='ampm-active' className='tt2-am'>PM</button> : <button onClick={handlePMClick} className='tt2-am'>PM</button>}
        </div>
      </div>
  );
};


const WorkyTimePickerFuture = ({ onFinalTimeChangeHour, onFinalTimeChangeMinute }) => {
  // const initialAMPMState = new Date().getHours()+8 <= 12 ? true : false;
  const [amActive, setamActive] = useState(false);
  const [pmActive, setpmActive] = useState(true);

  // hours
  const [hourInputValue, setHourInputValue] = useState(5);
  const hourNow = new Date().getHours()+8;
  const hour12 = ((hourNow % 12) || 12).toString();

  const handleHourChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');
    const clampedValue = Math.min(12, Math.max(0, parseInt(numericValue, 10)));
    setHourInputValue(clampedValue);
  };

  // mins
  const [minsInputValue, setMinsInputValue] = useState(0);
  const minsNow = new Date().getMinutes().toString().padStart(2, '0');

  const handleMinsChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');
    const clampedValue = Math.min(59, Math.max(0, parseInt(numericValue, 10)));
    setMinsInputValue(clampedValue);
  };

    useEffect(() => {
        if (pmActive || amActive) {
            onFinalTimeChangeHour(hourInputValue + (pmActive ? 12 : 0));
            onFinalTimeChangeMinute(minsInputValue);
        }
    }, [amActive, pmActive, hourInputValue, minsInputValue, onFinalTimeChangeHour, onFinalTimeChangeMinute]);


  const handleAMClick = () => {
    setamActive(true);
    setpmActive(false);
  };

  const handlePMClick = () => {
    setamActive(false);
    setpmActive(true);
  };



  return (
      <div className='timepicker2'>
        <input
          className='tt2-hour'
          type="number"
          value={hourInputValue}
          onChange={handleHourChange}
          placeholder={"5"}
        />
        <div className='tt2-col'>:</div>
        <input
          className='tt2-mins'
          type="number"
          value={minsInputValue.toString().padStart(2, '0')}
          onChange={handleMinsChange}
          placeholder={"00"}
        />
        <div className='tt2-ampm'>
          {amActive ? <button onClick={handleAMClick} id='ampm-active' className='tt2-am'>AM</button> : <button onClick={handleAMClick} className='tt2-am'>AM</button>}
          {pmActive ? <button onClick={handlePMClick} id='ampm-active' className='tt2-am'>PM</button> : <button onClick={handlePMClick} className='tt2-am'>PM</button>}
        </div>
      </div>
  );
}
 


const WorkyTimePickerV2 = ({prevHour, prevMin, onFinalTimeChangeHour, onFinalTimeChangeMinute }) => {
  const initialAMPMState = prevHour <= 12 ? true : false;
  const [amActive, setamActive] = useState(initialAMPMState);
  const [pmActive, setpmActive] = useState(!initialAMPMState);

  // hours
  const [hourInputValue, setHourInputValue] = useState<number>();
  const hourNow = new Date().getHours();
  const hour12 = (prevHour % 12).toString();
  console.log("hour12---->"+hour12)

  const handleHourChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');
    const clampedValue = Math.min(12, Math.max(0, parseInt(numericValue, 10)));
    setHourInputValue(clampedValue);
  };

  // mins
  const [minsInputValue, setMinsInputValue] = useState<number>();
  const minsNow = prevMin.toString().padStart(2, '0');

  const handleMinsChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');
    const clampedValue = Math.min(59, Math.max(0, parseInt(numericValue, 10)));
    setMinsInputValue(clampedValue);
  };

    useEffect(() => {
        if (pmActive || amActive) {
            onFinalTimeChangeHour(hourInputValue + (pmActive ? 12 : 0));
            onFinalTimeChangeMinute(minsInputValue);
        }
    }, [amActive, pmActive, hourInputValue, minsInputValue, onFinalTimeChangeHour, onFinalTimeChangeMinute]);


  const handleAMClick = () => {
    setamActive(true);
    setpmActive(false);
  };

  const handlePMClick = () => {
    setamActive(false);
    setpmActive(true);
  };


  return (
      <div className='timepicker2'>
        <input
          className='tt2-hour'
          type="number"
          value={hourInputValue}
          onChange={handleHourChange}
          placeholder={hour12}
        />
        <div className='tt2-col'>:</div>
        <input
          className='tt2-mins'
          type="number"
          value={minsInputValue}
          onChange={handleMinsChange}
          placeholder={minsNow}
        />
        <div className='tt2-ampm'>
          {amActive ? <button onClick={handleAMClick} id='ampm-active' className='tt2-am'>AM</button> : <button onClick={handleAMClick} className='tt2-am'>AM</button>}
          {pmActive ? <button onClick={handlePMClick} id='ampm-active' className='tt2-am'>PM</button> : <button onClick={handlePMClick} className='tt2-am'>PM</button>}
        </div>
      </div>
  );
};
const WorkyTimePickerV3 = ({prevHour, prevMin, onFinalTimeChangeHour, onFinalTimeChangeMinute }) => {
  const initialAMPMState = prevHour <= 12 ? true : false;
  const [amActive, setamActive] = useState(initialAMPMState);
  const [pmActive, setpmActive] = useState(!initialAMPMState);
  const isMobile = useIsMobile();
  // hours
  const [hourInputValue, setHourInputValue] = useState<number>();
  const hourNow = new Date().getHours();
  const hour12 = (prevHour % 12).toString();
  console.log("hour12---->"+hour12)

  const handleHourChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');
    const clampedValue = Math.min(12, Math.max(0, parseInt(numericValue, 10)));
    setHourInputValue(clampedValue);
  };

  // mins
  const [minsInputValue, setMinsInputValue] = useState<number>();
  const minsNow = prevMin.toString().padStart(2, '0');

  const handleMinsChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, '');
    const clampedValue = Math.min(59, Math.max(0, parseInt(numericValue, 10)));
    setMinsInputValue(clampedValue);
  };

    useEffect(() => {
        if (pmActive || amActive) {
            onFinalTimeChangeHour(hourInputValue + (pmActive ? 12 : 0));
            onFinalTimeChangeMinute(minsInputValue);
        }
    }, [amActive, pmActive, hourInputValue, minsInputValue, onFinalTimeChangeHour, onFinalTimeChangeMinute]);


  const handleAMClick = () => {
    setamActive(true);
    setpmActive(false);
  };

  const handlePMClick = () => {
    setamActive(false);
    setpmActive(true);
  };


  return (
      <div style={{paddingLeft:"15px", paddingRight:"15px"}} className={isMobile?"timepickerv4-mobile":"timepickerv4"}>
        <input
          className={isMobile? "time-input-mobile":"time-input"}
          type="number"
          value={hourInputValue}
          onChange={handleHourChange}
          placeholder={hour12}
        />
        :
        <input
          className={isMobile? "time-input-mobile":"time-input"}
          type="number"
          value={minsInputValue}
          onChange={handleMinsChange}
          placeholder={minsNow}
        />
        <div className={isMobile?"ampm-button-holder-mobile":"ampm-button-holder"}>
          {amActive ? <button onClick={handleAMClick} id='ampm-active' className='am-button'>AM</button> : <button onClick={handleAMClick} className='am-button'>AM</button>}
          {pmActive ? <button onClick={handlePMClick} id='ampm-active' className='pm-button'>PM</button> : <button onClick={handlePMClick} className='pm-button'>PM</button>}
        </div>
      </div>
  );
};



export {WorkyTimePicker, WorkyTimePickerFuture, WorkyTimePickerV2, WorkyTimePickerV3}