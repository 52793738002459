import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import "./newshiftv4.css";
import "./rdpstyles.css";
import CloseIcon from "@mui/icons-material/Close";
import { GetCorpDetails, SendErrorEmailv2, GetOrgMetadata } from "../shared-engineering-services/SharedAPICalls";
import { OrgLocation, OrgLocationMapDetails } from "../common/CommonInterfaces";
import { CorpDataObj } from "../orgViews/orgConstants";
import { CorpType, EmailAddress } from "../common/CommonInterfaces";
import { DayPicker } from "react-day-picker";
import { useIsMobile } from "../custom-hooks/useIsMobile";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import { WorkyAppHeader, WorkyContactButton, WorkyFooter } from "../agency-logic/agency-components/AgencyComponentSetOne";
import { RemoteExecutionServiceImpl, getGlobalUserDetails } from "../remoteExecution/RemoteExecutionServiceImpl";
import { handleSRSubmit, RetrievePreviousJobbersAPICall } from "./SrIngressHelperService";
import { TimePicker, WorkyTimeObj, DetermineHourForTweelve } from "./TimePicker";
import "./newshift.css";
import { TopNav, StyleLinks } from "../orgViews/OrgCommonComponents";
import { ShiftRecieptPage, JobberSelectionPage, SelectOrgLocationDetailsPage, SelectAgencyComponentPage } from "./NewShiftV4Components";
import {
  StepperSequenceMobile,
  PageDetailsSequence,
  StepperSequence,
  LocationAddress,
  Location,
  Organization,
} from "./NewShiftV4ComponentSetTwo";
import { JobberDetailsForOrg } from "../orgViews/OrgUtils";
import { JobberWorkerTypeDTO } from "./sr-ingress-data/SRIngressInterfaces";
import { convertToCorpDataObjSafely } from "../common/CommonUtilsMethods";
import { OrgSRIngressPage } from "../orgViews/org-sr-ingress/OrgSRIngressPage";
import { ShiftDatePage, RecurringDow, ShiftTimeSelectionPage } from "../sr-ingress/NewShiftV4ComponentSetTwoOrgView";

// const orgData = JSON.parse(localStorage.getItem("orgData"));
// const orgDataConverted: CorpDataObj = convertToCorpDataObjSafely(orgData);
const userInfo = getGlobalUserDetails();

const today = new Date();
const isDateBeforeToday = (date) => {
  return date < today;
};

const NewShiftV4 = () => {
  const isMobile = useIsMobile();
  const [currentPagev2, setCurrentPagev2] = useState(1);
  
  const [selectedJobbers, setSelectedJobbers] = useState<EmailAddress[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const selectedDaysRefObj = useRef<Date[]>([]);
  const [finalNumJobbers, setFinalNumJobbers] = useState(1);
  const [isReOccurringShift, setIsReocurringShift] = useState(false);  
  const [orgLocation, setOrgLocation] = useState<OrgLocation | null>(null);
  const [orgAllLocations, setOrgAllLocations] = useState<OrgLocation[] | null>([]);
  const selectedRecurringDaysRefObj = useRef<number[]>([]);
  const workyStartTimeRefObj = useRef<WorkyTimeObj>({ hour: 9, minute: 0, isAM: true });
  const workyEndTimeRefObj = useRef<WorkyTimeObj>({ hour: 5, minute: 0, isAM: false });
  const [currentMonth, setCurrentMonth] = useState(today);
  const [prevJobbers, setPrevJobbers] = useState<JobberDetailsForOrg[] | null>(null);
  const handleMonthChange = (newMonth) => {
    setCurrentMonth(newMonth);
  };

  useEffect(() => {
    
    const fetchOrgMetadata = async () => {
            try {
              const metaData: CorpDataObj = await GetCorpDetails();
              // metaData.orgLocationMapDetailsMap = new Map(Object.entries(metaData.orgLocationMapDetailsMap));
              setCorpDataObj(metaData);
              setSelectedOrganization(metaData.corpJobberDetails.corpName);
              setOrganizations(metaData.orgLocationMapDetailsMap);
              console.log("here:s metaData.orgLocationMapDetailsMap", metaData.orgLocationMapDetailsMap)
              const keys = Array.from(metaData.orgLocationMapDetailsMap.keys());
              setOrgNames(keys);
            } catch (error) {
              console.error(error);
            }
          };

          if(currentPagev2 === 1 && !corpDataObj){
            fetchOrgMetadata();
          }
    
          
    const fetchData = async () => {
      await RetrievePreviousJobbersAPICall({ setPrevJobbers: setPrevJobbers });
    };
    try {
      fetchData();
    } catch (e) {
      console.log("RetrievePreviousJobbersAPICall err: ", e);
    }
  }, []);

  const [isFistModalOpenForReOccurringDayModal, setIsFistModalOpenForReOccurringDayModal] = useState(true);

  

  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const [modalErrOpen, setModalErrOpen] = useState(false);
  const handleErrModalClose = () => setModalErrOpen(false);
  const ShiftRecieptPageButtons = () => {
    handleSRSubmit({
      selectedRecurringDays: selectedRecurringDaysRefObj.current,
      selectedDaysRefObj: selectedDaysRefObj.current,
      numJobbers: finalNumJobbers,
      selectedJobbers: selectedJobbers,
      v3StartTimeHourRef: DetermineHourForTweelve(workyStartTimeRefObj.current),
      v3StartTimeMinuteRef: workyStartTimeRefObj.current.minute,
      v3EndTimeHourRef: DetermineHourForTweelve(workyEndTimeRefObj.current),
      v3EndTimeMinuteRef: workyEndTimeRefObj.current.minute,
      handleModelCallback: setModalOpen,
      handleModelCallbackError: setModalErrOpen,
      selectedOrgLocationClient: orgLocation,
      selectedAgencyName: corpDataObj.corpJobberDetails.corpName,
      selectedJobberWorkerType: selectedWorkerType,
    });
  };

  const handleBackClick = () => {
    if (currentPagev2 !== 1) {
      setCurrentPagev2(currentPagev2 - 1);
    }
  };
  const handleNextClick = () => {
    if (currentPagev2 !== 5) {
      setCurrentPagev2(currentPagev2 + 1);
    } else {
      ShiftRecieptPageButtons();
    }
  };

  const [corpDataObj, setCorpDataObj] = useState<CorpDataObj>();
  // const [organizations, setOrganizations] = useState<Record<string, Organization>>({});
  const [organizations, setOrganizations] = useState<Map<string, OrgLocationMapDetails>>(new Map());
  const [orgNames, setOrgNames] = useState<string[]>([]);
  const [selectedOrgName, setSelectedOrganization] = useState<string>("");
  const [selectedWorkerType, setSelectedWorkerType] = useState<JobberWorkerTypeDTO>(null);
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [locationDetails, setLocationDetails] = useState<LocationAddress | null>(null);
  const [locations, setLocations] = useState<Map<string, OrgLocation>>(new Map());
  
  useEffect(() => {
    // Disable the button if either field is empty
    if (currentPagev2 === 1) {
      setIsDisabled(!(selectedOrgName && selectedLocation));
    }
    if (currentPagev2 === 2) {
      setIsDisabled(!(selectedWorkerType && finalNumJobbers));
    }
    if (currentPagev2 === 3) {
      setIsDisabled(selectedDays.length === 0);
    }
  }, [currentPagev2, selectedOrgName, selectedLocation, selectedWorkerType, finalNumJobbers, selectedDays]);

  const NewShiftLocationSelectPage = () => {
    const handleOrganizationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const org = e.target.value;
      console.log("org2222 " + org);
      setSelectedOrganization(org);
      setSelectedLocation(""); // Reset location
      setLocationDetails(null); // Reset location details
      // setLocations(organizations[org]?.locations || []); // Update locations based on selected organization
      // setLocations(organizations.get(org)?.orgLocationMap || new Map()); // Update locations based on selected organization
      // Assuming organizations is an object and org is a key
      setLocations(organizations[org]?.orgLocationMap || new Map()); // Update locations based on selected organization
    };

    const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const locationName = e.target.value;
      setSelectedLocation(locationName);
      setOrgLocation(locations[locationName]);
      const selectedLocationDetails = locations[locationName];
      // const selectedLocationDetails = Array.from(locations.values()).find((location) => location.locationName === locationName);
      console.log("selectedLocationDetails " + JSON.stringify(selectedLocationDetails));
      // setLocationDetails(selectedLocationDetails ? selectedLocationDetails.locationAddress : null);
    };

    console.log("Selected Organization: " + selectedOrgName);
    console.log("Selected Location: " + selectedLocation);
    console.log("Location Details: " + JSON.stringify(locationDetails));

    return (
      <div className="new-shift-v5-shift-page-1">
        <div className="new-shift-v5-shift-page-1-selection-holder">
          <label className="new-shift-v5-shift-page-1-selection-title">
            Select Organization <span style={{ color: "red" }}>*</span>
          </label>
          <select
            className={isMobile ? "new-shift-v5-shift-page-1-selection-select-mobile" : "new-shift-v5-shift-page-1-selection-select"}
            value={selectedOrgName}
            onChange={handleOrganizationChange}
          >
            <option value="">Select an organization</option>
            {Object.keys(organizations).map((org) => (
              <option key={org} value={org}>
                {org}
              </option>
            ))}
          </select>
        </div>

        <div className="new-shift-v5-shift-page-1-selection-holder">
          <label className="new-shift-v5-shift-page-1-selection-title">
            Select Location <span style={{ color: "red" }}>*</span>
          </label>
          <select
            className={isMobile ? "new-shift-v5-shift-page-1-selection-select-mobile" : "new-shift-v5-shift-page-1-selection-select"}
            value={selectedLocation}
            onChange={handleLocationChange}
            disabled={!selectedOrgName}
          >
            <option value="">Select a location</option>
            {Object.keys(locations).map((locationName) => (
              <option key={locationName} value={locationName}>
                {locationName}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };
  // PAGE 1 FINISH

  console.log("currentPagev2 : " + currentPagev2);
  console.log("PREV JOBBERS " + prevJobbers);
  console.log("IN NEW SHIFT PAGE SELECTED JOBBERS " + JSON.stringify(selectedJobbers));

  const [isDisabled, setIsDisabled] = useState(true);

  const isCorpTypeValueEqualToOrgType: boolean = corpDataObj && corpDataObj.corpJobberDetails.corpType == CorpType.ORG;
  console.log("isCorpTypeValueEqualToOrgType ", isCorpTypeValueEqualToOrgType);
  return (
    <div>
      {!corpDataObj ? (
        "no data"
      ) : isCorpTypeValueEqualToOrgType ? (
        <OrgSRIngressPage orgData={corpDataObj} />
      ) : (
        <div className="worky-app">
          <StyleLinks />
          <WorkyAppHeader />
  
          {isMobile ? (
            <div className="new-shift-container-v5-mobile">
              <StepperSequenceMobile pageNumber={currentPagev2} />
              <div className="new-shift-container-v5-main-mobile">
                {currentPagev2 === 1 && <NewShiftLocationSelectPage />}
                {currentPagev2 === 2 && (
                  <JobberSelectionPage
                    setSelectWorkerType={setSelectedWorkerType}
                    selectWorkerType={selectedWorkerType}
                    selectCorpName={selectedOrgName}
                    finalNumJobbers={finalNumJobbers}
                    setFinalNumJobbers={setFinalNumJobbers}
                  />
                )}
                {currentPagev2 === 3 && (
                  <ShiftDatePage
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                    selectedDaysRefObj={selectedDaysRefObj}
                    selectedRecurringDaysRefObj={selectedRecurringDaysRefObj}
                    isReOccurringShift={isReOccurringShift}
                    setIsReocurringShift={setIsReocurringShift}
                    currentMonth={currentMonth}
                    handleMonthChange={handleMonthChange}
                    today={today}
                    isMobile={isMobile}
                    isDateBeforeToday={isDateBeforeToday}
                    isFistModalOpenForReOccurringDayModal={isFistModalOpenForReOccurringDayModal}
                    setIsFistModalOpenForReOccurringDayModal={setIsFistModalOpenForReOccurringDayModal}
                  />
                )}
                {currentPagev2 === 4 && (
                  <ShiftTimeSelectionPage
                    workyStartTimeRefObj={workyStartTimeRefObj}
                    workyEndTimeRefObj={workyEndTimeRefObj}
                  />
                )}
                {currentPagev2 === 5 && (
                  <ShiftRecieptPage
                    workyStartTimeRefObj={workyStartTimeRefObj.current}
                    workyEndTimeRefObj={workyEndTimeRefObj.current}
                    selectedDaysRefObj={selectedDaysRefObj.current}
                    finalNumJobbers={finalNumJobbers}
                    selectOrgName={selectedOrgName}
                    selectedDays={selectedDaysRefObj.current}
                    selectedOrgLocationClient={orgLocation}
                    selectedJobbers={selectedJobbers}
                    agencyName={corpDataObj.corpJobberDetails.corpName}
                    selectedWorkerType={selectedWorkerType.workerTypeName}
                    modelComponentsProps={{
                      modalErrOpen,
                      modalOpen,
                      handleClose,
                      setModalErrOpen,
                      handleErrModalClose,
                    }}
                    corpData={corpDataObj}
                    setSelectedJobbers={setSelectedJobbers}
                  />
                )}
              </div>
              <div className="new-shift-v5-shift-submission-button-holder-mobile">
                <button
                  onClick={handleBackClick}
                  id="new-shift-v5-back-mobile"
                  className="new-shift-v5-shift-submission-button-mobile"
                >
                  <WestRoundedIcon />
                </button>
                <button
                  onClick={handleNextClick}
                  id="new-shift-v5-next-mobile"
                  className="new-shift-v5-shift-submission-button-mobile"
                  disabled={isDisabled}
                >
                  {currentPagev2 === 5 ? "Submit" : "Next"}
                </button>
              </div>
            </div>
          ) : (
            <div className="new-shift-container-v5">
              <div className="new-shift-container-v5-stepper-desktop">
                <div className="new-shift-container-v5-stepper-desktop-inner">
                  <div className="master-page-title-desktop">New Shift</div>
                  <div className="new-shift-v5-stepper-holder">
                    <StepperSequence pageNumber={currentPagev2} />
                  </div>
                </div>
              </div>
              <div className="new-shift-container-v5-shift-submission">
                <PageDetailsSequence pageNumber={currentPagev2} />
                <div className="new-shift-v5-shift-page-content">
                  {currentPagev2 === 1 && <NewShiftLocationSelectPage />}
                  {currentPagev2 === 2 && (
                    <JobberSelectionPage
                      selectCorpName={selectedOrgName}
                      setSelectWorkerType={setSelectedWorkerType}
                      selectWorkerType={selectedWorkerType}
                      finalNumJobbers={finalNumJobbers}
                      setFinalNumJobbers={setFinalNumJobbers}
                    />
                  )}
                  {currentPagev2 === 3 && (
                    <ShiftDatePage
                      selectedDays={selectedDays}
                      setSelectedDays={setSelectedDays}
                      selectedDaysRefObj={selectedDaysRefObj}
                      selectedRecurringDaysRefObj={selectedRecurringDaysRefObj}
                      isReOccurringShift={isReOccurringShift}
                      setIsReocurringShift={setIsReocurringShift}
                      currentMonth={currentMonth}
                      handleMonthChange={handleMonthChange}
                      today={today}
                      isMobile={isMobile}
                      isDateBeforeToday={isDateBeforeToday}
                      isFistModalOpenForReOccurringDayModal={
                        isFistModalOpenForReOccurringDayModal
                      }
                      setIsFistModalOpenForReOccurringDayModal={
                        setIsFistModalOpenForReOccurringDayModal
                      }
                    />
                  )}
                  {currentPagev2 === 4 && (
                    <ShiftTimeSelectionPage
                      workyStartTimeRefObj={workyStartTimeRefObj}
                      workyEndTimeRefObj={workyEndTimeRefObj}
                    />
                  )}
                  {currentPagev2 === 5 && (
                    <ShiftRecieptPage
                      workyStartTimeRefObj={workyStartTimeRefObj.current}
                      workyEndTimeRefObj={workyEndTimeRefObj.current}
                      selectedDaysRefObj={selectedDaysRefObj.current}
                      finalNumJobbers={finalNumJobbers}
                      selectOrgName={selectedOrgName}
                      selectedDays={selectedDaysRefObj.current}
                      selectedOrgLocationClient={orgLocation}
                      selectedJobbers={selectedJobbers}
                      agencyName={corpDataObj.corpJobberDetails.corpName}
                      selectedWorkerType={selectedWorkerType.workerTypeName}
                      modelComponentsProps={{
                        modalErrOpen,
                        modalOpen,
                        handleClose,
                        setModalErrOpen,
                        handleErrModalClose,
                      }}
                      corpData={corpDataObj}
                      setSelectedJobbers={setSelectedJobbers}
                    />
                  )}
                </div>
                <div className="new-shift-v5-shift-submission-button-holder">
                  <button
                    onClick={handleBackClick}
                    id="new-shift-v5-back"
                    className="new-shift-v5-shift-submission-button"
                  >
                    Back
                  </button>
                  <button
                    onClick={handleNextClick}
                    id="new-shift-v5-next"
                    className="new-shift-v5-shift-submission-button"
                    disabled={isDisabled}
                  >
                    {currentPagev2 === 5 ? "Submit" : "Next"}
                  </button>
                </div>
              </div>
            </div>
          )}
          <WorkyContactButton />
        </div>
      )}
    </div>
  );
}
  

export { NewShiftV4 };
