import React, { useCallback, useState, useEffect, useRef } from "react";
import { useIsMobile } from "../../custom-hooks/useIsMobile";
import { WorkyAppHeader, WorkyContactButton, WorkyTopShelf } from "../../agency-logic/agency-components/AgencyComponentSetOne";
import { StyleLinks } from "../OrgCommonComponents";
import {
  StepperSequenceMobileOrgView,
  StepperSequenceOrgView,
  PageDetailsSequence,
} from "../../sr-ingress/NewShiftV4ComponentSetTwoOrgView";
import { NewShiftLocationSelectPage, NewShiftAgencySelectPage, NewShiftAgencySelectPageProps } from "./org-sr-ingress-components";
import { CorpDataObj } from "../orgConstants";
import { EmailAddress, OrgLocation, OrgLocationMapDetails } from "../../common/CommonInterfaces";
import { JobberWorkerTypeDTO } from "../../sr-ingress/sr-ingress-data/SRIngressInterfaces";
import { JobberSelectionPage, ShiftRecieptPage } from "../../sr-ingress/NewShiftV4Components";
import { ShiftDatePage, ShiftTimeSelectionPage } from "../../sr-ingress/NewShiftV4ComponentSetTwoOrgView";
import { DetermineHourForTweelve, WorkyTimeObj } from "../../sr-ingress/TimePicker";
import { handleSRSubmit } from "../../sr-ingress/SrIngressHelperService";
import { findMapKeyByValue } from "../../common/CommonUtilsMethods";




const OrgSRIngressPage = ({ orgData }: { orgData: CorpDataObj }) => {
 const stringConvorgData = JSON.stringify(orgData)
 orgData = JSON.parse(stringConvorgData)
 orgData.orgLocationMapDetailsMap = new Map(Object.entries(orgData.orgLocationMapDetailsMap));

 console.log("orgData.orgLocationMapDetailsMap ", orgData.orgLocationMapDetailsMap)

  const isMobile = useIsMobile();
  const [currentPagev2, setCurrentPagev2] = useState(1);
  const [isDisabled, setIsDisabled] = useState(true);
  // const [orgData, setCorpDataObj] = useState<CorpDataObj | null>(null);

  // Add state variables for NewShiftLocationSelectPage  
  const [orgLocation, setOrgLocation] = useState<OrgLocation>(null);
  const [selectedAgencyName, setAgencyName] = useState<string>(null);
  const [selectedWorkerType, setSelectedWorkerType] = useState<JobberWorkerTypeDTO>(null);
  const [finalNumJobbers, setFinalNumJobbers] = useState(1);
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);
  const selectedDaysRefObj = useRef<Date[]>([]);
  const selectedRecurringDaysRefObj = useRef<number[]>([]);
  const [isReOccurringShift, setIsReocurringShift] = useState(false);
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today);
  const [isFistModalOpenForReOccurringDayModal, setIsFistModalOpenForReOccurringDayModal] = useState(true);
  const workyStartTimeRefObj = useRef<WorkyTimeObj>({ hour: 9, minute: 0, isAM: true });
  const workyEndTimeRefObj = useRef<WorkyTimeObj>({ hour: 5, minute: 0, isAM: false });
  const [selectedJobbers, setSelectedJobbers] = useState<EmailAddress[]>([]);
  const [modalErrOpen, setModalErrOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const handleErrModalClose = () => setModalErrOpen(false);
  
  
  const handleMonthChange = (newMonth) => {
    setCurrentMonth(newMonth);
  };

  const isDateBeforeToday = (date) => {
    return date < today;
  };
  


  


  // Update the disabled state based on validation
  useEffect(() => {
    // Disable the button if either field is empty
    if (currentPagev2 === 1) {      
      setIsDisabled(!( orgLocation));
    } else if (currentPagev2 === 2) {
      console.log("selectedAgencyName", selectedAgencyName);
      setIsDisabled(!selectedAgencyName);
    } else if (currentPagev2 === 3) {
      setIsDisabled(!(selectedWorkerType && finalNumJobbers));
    } else if (currentPagev2 === 4) {
      setIsDisabled(selectedDays.length === 0);
    }
  }, [currentPagev2, selectedAgencyName, orgLocation, selectedWorkerType, finalNumJobbers, selectedDays]);
  // Render the appropriate component based on the current page
  const renderCurrentPage = () => {
    switch (currentPagev2) {
      case 1:
        const orgLocationMapDetails = findMapKeyByValue(orgData.orgLocationMapDetailsMap, orgData.corpJobberDetails.corpName);
      const locationsSet: Map<string, OrgLocation>= orgLocationMapDetails ? orgLocationMapDetails.orgLocationMap : new Map();
      const orgLocationDetailsFull: OrgLocationMapDetails = {orgLocationMap:locationsSet}
      console.log("orgLocationDetailsFull", orgLocationDetailsFull)
        return (
          <NewShiftLocationSelectPage
            isMobile={isMobile}            
            selectedOrgName={orgData.corpJobberDetails.corpName}
            selectedLocation={orgLocation}            
            locations={findMapKeyByValue(orgData.orgLocationMapDetailsMap, orgData.corpJobberDetails.corpName).orgLocationMap}            
            setOrgLocation={setOrgLocation}
          />
        );
      case 2:
        return (
          <NewShiftAgencySelectPage
            isMobile={isMobile}
            selectedOrgName={orgData.corpJobberDetails.corpName}
            selectedLocation={orgLocation}
            selectedAgencyName={selectedAgencyName}
            setAgencyName={setAgencyName}
            agencyNames={orgData.agencyNames || []}
          />
        );
      case 3:
        return (
          <JobberSelectionPage
            setSelectWorkerType={setSelectedWorkerType}
            selectWorkerType={selectedWorkerType}
            selectCorpName={selectedAgencyName}
            finalNumJobbers={finalNumJobbers}
            setFinalNumJobbers={setFinalNumJobbers}
          />
        );
      case 4:
        return (
          <ShiftDatePage
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            selectedDaysRefObj={selectedDaysRefObj}
            selectedRecurringDaysRefObj={selectedRecurringDaysRefObj}
            isReOccurringShift={isReOccurringShift}
            setIsReocurringShift={setIsReocurringShift}
            currentMonth={currentMonth}
            handleMonthChange={handleMonthChange}
            today={today}
            isMobile={isMobile}
            isDateBeforeToday={isDateBeforeToday}
            isFistModalOpenForReOccurringDayModal={isFistModalOpenForReOccurringDayModal}
            setIsFistModalOpenForReOccurringDayModal={setIsFistModalOpenForReOccurringDayModal}
          />
        );

      case 5:
        return <ShiftTimeSelectionPage workyStartTimeRefObj={workyStartTimeRefObj} workyEndTimeRefObj={workyEndTimeRefObj} />;
      case 6:
        return(<ShiftRecieptPage
          workyStartTimeRefObj={workyStartTimeRefObj.current}
          workyEndTimeRefObj={workyEndTimeRefObj.current}
          selectedDaysRefObj={selectedDaysRefObj.current}
          finalNumJobbers={finalNumJobbers}
          selectOrgName={orgData.corpJobberDetails.corpName}
          selectedDays={selectedDaysRefObj.current}
          selectedOrgLocationClient={orgLocation}
          selectedJobbers={selectedJobbers}
          agencyName={selectedAgencyName}
          selectedWorkerType={selectedWorkerType.workerTypeName}
          modelComponentsProps={{
            modalErrOpen: modalErrOpen,
            modalOpen: modalOpen,
            handleClose: handleClose,
            setModalErrOpen: setModalErrOpen,
            handleErrModalClose: handleErrModalClose,
          }}
          corpData={orgData}
          setSelectedJobbers={setSelectedJobbers}
        />)
      default:
        return null;
    }
  };

  const handleBackClick = () => {
    if (currentPagev2 !== 1) {
      setCurrentPagev2(currentPagev2 - 1);
    }
  };


  const ShiftRecieptPageButtons = () => {
    handleSRSubmit({
      selectedRecurringDays: selectedRecurringDaysRefObj.current,
      selectedDaysRefObj: selectedDaysRefObj.current,
      numJobbers: finalNumJobbers,
      selectedJobbers: selectedJobbers,
      v3StartTimeHourRef: DetermineHourForTweelve(workyStartTimeRefObj.current),
      v3StartTimeMinuteRef: workyStartTimeRefObj.current.minute,
      v3EndTimeHourRef: DetermineHourForTweelve(workyEndTimeRefObj.current),
      v3EndTimeMinuteRef: workyEndTimeRefObj.current.minute,
      handleModelCallback: setModalOpen,
      handleModelCallbackError: setModalErrOpen,
      selectedOrgLocationClient: orgLocation,
      selectedAgencyName: selectedAgencyName,
      selectedJobberWorkerType: selectedWorkerType,
    });
  };

  const handleNextClick = () => {
    if (currentPagev2 !== 6) {
      setCurrentPagev2(currentPagev2 + 1);
    }else{
      ShiftRecieptPageButtons();
    }
  };

  return (
    <div className={isMobile ? "worky-app-mobile" : "worky-app"}>
      <StyleLinks />
      <WorkyAppHeader />

      {isMobile ? (
        <div className="new-shift-container-v5-mobile">
          <StepperSequenceMobileOrgView pageNumber={currentPagev2} />
          <div className="new-shift-container-v5-main-mobile">{renderCurrentPage()}</div>
          <div className="new-shift-v5-shift-submission-button-holder-mobile">
            <button onClick={handleBackClick} id="new-shift-v5-back-mobile" className="new-shift-v5-shift-submission-button-mobile">
              Back
            </button>
            <button
              onClick={handleNextClick}
              id="new-shift-v5-next-mobile"
              className="new-shift-v5-shift-submission-button-mobile"
              disabled={isDisabled}
            >
              {currentPagev2 === 5 ? "Submit" : "Next"}
            </button>
          </div>
        </div>
      ) : (
        <div className="new-shift-container-v5">
          <div className="new-shift-container-v5-stepper-desktop">
            <div className="new-shift-container-v5-stepper-desktop-inner">
              <div className="master-page-title-desktop">New Shift</div>
              <div className="new-shift-v5-stepper-holder">
                <StepperSequenceOrgView pageNumber={currentPagev2} />
              </div>
            </div>
          </div>
          <div className="new-shift-container-v5-shift-submission">
            {/* STEPPER SECTION */}
            <PageDetailsSequence pageNumber={currentPagev2} />

            {/* PAGE SECTION */}
            <div className="new-shift-v5-shift-page-content">{renderCurrentPage()}</div>

            {/* BUTTONS SECTION */}
            <div className="new-shift-v5-shift-submission-button-holder">
              <button onClick={handleBackClick} id="new-shift-v5-back" className="new-shift-v5-shift-submission-button">
                Back
              </button>
              <button
                onClick={handleNextClick}
                id="new-shift-v5-next"
                className="new-shift-v5-shift-submission-button"
                disabled={isDisabled}
              >
                {currentPagev2 === 6 ? "Submit" : "Next"}
              </button>
            </div>
          </div>
        </div>
      )}

      <WorkyContactButton />
    </div>
  );
};

export { OrgSRIngressPage };
