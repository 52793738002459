import{ShiftStartEndDetails, WorkyTime} from './CommonInterfaces'

const convertWorkyTimeToEpochSec = (workyTime: WorkyTime): number =>{
    const dateObj = new Date();
    dateObj.setHours(workyTime.hour, workyTime.minute);

    const estEpochTime = new Date(
        dateObj.toLocaleString('en-US', { timeZone: 'America/New_York' })
    );

    return Math.floor(estEpochTime.getTime() / 1000);
} 


const convertDateToEpochSec = (dateObj: Date): number =>{
    

    const estEpochTime = new Date(dateObj.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    return Math.floor(estEpochTime.getTime() / 1000);
} 


const convertDatesToEpochSec = ({dates, workyStartTime, workyEndTime} 
    : {dates: Date[], workyStartTime: WorkyTime, workyEndTime: WorkyTime})
    : ShiftStartEndDetails[] =>{
    
        const epochTimes: ShiftStartEndDetails[] = dates.map((date) => {
            const dateStartObj = new Date(date);
            dateStartObj.setHours(workyStartTime.hour, workyStartTime.minute);
            
            const dateEndObj = new Date(date);
            dateEndObj.setHours(workyEndTime.hour, workyEndTime.minute);

        return {startDateTimeEpochSec: convertDateToEpochSec(dateStartObj), endDateTimeEpochSec: convertDateToEpochSec(dateEndObj)}
    });

    return epochTimes;
} 


const epochToDate = (epochTimeInSeconds: number): string => {
    const date = new Date(epochTimeInSeconds * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const convertDateEpochAndTimeToEpochSec = ({epochTimeInSeconds, hours, minutes}:{epochTimeInSeconds: number, hours: number, minutes: number}): number => {
    const date = new Date(epochToDate(epochTimeInSeconds));
    date.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds
    return Math.floor(date.getTime() / 1000); // Convert to epoch seconds
};

const convertDateStringAndTimeToEpochSec = (dateString: string, hours: number, minutes: number): number => {
    const date = new Date(dateString);
    date.setHours(hours, minutes, 0, 0); // Set hours, minutes, seconds, and milliseconds
    return Math.floor(date.getTime() / 1000); // Convert to epoch seconds
};


export{epochToDate, convertWorkyTimeToEpochSec, convertDatesToEpochSec, convertDateEpochAndTimeToEpochSec}