import React, { useState, useEffect } from 'react';
import './jobberhomepage2.css';
import emailjs from 'emailjs-com';
import Modal from '@mui/material/Modal';
import { useLocation } from 'react-router-dom';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import PhoneEnabledRoundedIcon from '@mui/icons-material/PhoneEnabledRounded';
import { JobberContactModal } from './JobberHomePage';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';

const pushToHome = () => {
	window.location.assign('/jobber-homepage');
};
const pushToUpcomingShifts = () => {
	window.location.assign('/jobber-homepage-upcoming-shifts');
};
const pushToPreviousShifts = () => {
	window.location.assign('/jobber-previous-shifts');
};
const pushToAccountSetting = () => {
	window.location.assign('/jobber-account-settings');
};
const pushToImportantDocs = () => {
	window.location.assign('/jobber-important-docs');
};
const pushToWorkyPay = () => {
	window.location.assign('/jobber/worky-pay');
};

const handleGeneralEmailSubmit = async (e) => {
	e.preventDefault();

	const serviceID = 'service_3mlm2ls';
	const templateID = 'template_xl6xpjw';
	const userID = 'zKdgUxA7H3dMaCf-m';

	try {
		await emailjs.sendForm(serviceID, templateID, e.target, userID);
		alert('Email sent successfully!');
	} catch (error) {
		alert('An error occurred while sending the email.');
		console.error(error);
	}
};

const MobileNav = () => {
	const [expanded, setExpanded] = useState(false);
	const [modalOpen, setModalOpen] = React.useState(false);
	const handleOpen = () => setModalOpen(true);
	const handleClose = () => setModalOpen(false);
	const jobberEmail = localStorage.getItem('userEmail');
	const toggleHeight = () => {
		setExpanded(!expanded);
	};

	return (
		<div className='mobile-nav'>
			<div className='mobile-nav-left'></div>
			<div className={`mobile-nav-middle ${expanded ? 'expanded' : ''}`}>
				<div className='mobile-nav-middle-top'>worky</div>
				{expanded && (
					<div className='mobile-nav-dropdown'>
						{window.location.pathname === '/jobber-homepage' ? (
							<button onClick={pushToHome} className='dropdown-option-selected'>
								Home
							</button>
						) : (
							<button onClick={pushToHome} className='dropdown-option'>
								Home
							</button>
						)}
						{window.location.pathname === '/jobber-important-docs' ? (
							<button onClick={pushToImportantDocs} className='dropdown-option-selected'>
								Documents
							</button>
						) : (
							<button onClick={pushToImportantDocs} className='dropdown-option'>
								Documents
							</button>
						)}
						{window.location.pathname === '/jobber-account-settings' ? (
							<button onClick={pushToAccountSetting} className='dropdown-option-selected'>
								Profile & Account Settings
							</button>
						) : (
							<button onClick={pushToAccountSetting} className='dropdown-option'>
								Profile & Account Settings
							</button>
						)}

						<button onClick={handleOpen} className='dropdown-option'>
							Contact Us
						</button>
						<Modal open={modalOpen} onClose={handleClose}>
							<form
								id='jobber-modal'
								className='modal-contact'
								onSubmit={handleGeneralEmailSubmit}>
								<div style={{paddingTop:"0px"}} className='srstatus-date'>Having an Issue?</div>
								<div id='modal-title2' className='srstatus-time'>
									We guarantee to get back to you in under 1 hour!
								</div>
								<div id='modal-title3' className='modal-title'>
									Email: <span className='modal-info'>info@worky.pro</span>
								</div>
								<div id='modal-title-2' className='modal-title'>
									Phone: <span className='modal-info'>(647) 494-9090</span>
								</div>
								<input
									className='modal-email'
									name='user_email'
									defaultValue={
										jobberEmail === null || jobberEmail === undefined
											? 'info@worky.pro'
											: jobberEmail
									}
								/>
								<div className='modal-title'>Message:</div>
								<textarea name='user_message' className='modal-message'></textarea>
								<button className='modal-submit'>Send</button>
							</form>
						</Modal>
					</div>
				)}
				<div
					className={`mobile-nav-middle-bottom ${expanded ? 'expanded' : ''}`}
					onClick={toggleHeight}></div>
			</div>
			<div className='mobile-nav-right'></div>
		</div>
	);
};

const MobileNavV2 = () => {
	const [modalOpen, setModalOpen]= useState(false);
	const handleOpen = () => setModalOpen(true);
	const handleclose = () => setModalOpen(false);
	return (
		<>
		<JobberContactModal modalOpen2={modalOpen} handleClose2={handleclose}/>
		<div className='jobber-mobile-nav-v2'>
			<div className='jobber-mobile-nav-v2-btn-holder'>
				<button
					
					onClick={pushToHome}
					className='jobber-mobile-nav-v2-btn'
					id={window.location.pathname === '/jobber-homepage' ? 'jobber-mobile-nav-v2-btn-active' : undefined}
				>
					<HomeRoundedIcon sx={{ fontSize: '22px' }} />
					Home
				</button>
				<button
					
					onClick={pushToImportantDocs}
					className='jobber-mobile-nav-v2-btn'
					id={window.location.pathname === '/jobber-important-docs' ? 'jobber-mobile-nav-v2-btn-active' : undefined}
				>
					<ArticleRoundedIcon sx={{ fontSize: '22px' }} />
					Docs
				</button>
				<button
					
					onClick={pushToWorkyPay}
					className='jobber-mobile-nav-v2-btn'
					id={window.location.pathname === '/jobber/worky-pay' ? 'jobber-mobile-nav-v2-btn-active' : undefined}
				>
					<PaymentsRoundedIcon sx={{ fontSize: '22px' }} />
					Pay Stubs
				</button>
				<button
					
					onClick={pushToAccountSetting}
					className='jobber-mobile-nav-v2-btn'
					id={window.location.pathname === '/jobber-account-settings' ? 'jobber-mobile-nav-v2-btn-active' : undefined}
				>
					<ManageAccountsRoundedIcon sx={{ fontSize: '22px' }} />
					Settings
				</button>
				<button
					onClick={handleOpen}
					className='jobber-mobile-nav-v2-btn'
				>
					<PhoneEnabledRoundedIcon sx={{ fontSize: '22px' }} />
					Contact
				</button>
			</div>
		</div>
		</>
	);
};


export {
	pushToHome,
	pushToUpcomingShifts,
	pushToPreviousShifts,
	pushToAccountSetting,
	MobileNav,
	MobileNavV2,
};
