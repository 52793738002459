import React, { createContext, useContext, useCallback, useEffect } from "react";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { GlobalErrorPage } from "./GlobalErrorPage";
import { toStringSafely } from "../common/CommonUtilsMethods";

// Enhanced error type to include API context
interface EnhancedError extends Error {
  httpUrl?: string;
  inputObj?: any;
  apiDetails?: {
    url: string;
    method?: string;
    requestData?: any;
    timestamp: number;
  };
}

let globalErrorHandler: (error: EnhancedError) => void = (error: EnhancedError) => {
  console.error("Error occurred but global error handler not initialized:", error);
};

/**
 * Show a global error with additional API context information
 * @param error The error that occurred
 * @param httpUrl The API URL that was being called
 * @param inputObj The input object/payload sent to the API
 */
const showGlobalError = (error: Error, httpUrl?: string, inputObj?: any) => {
  // Create an enhanced error with API details
  const enhancedError = error as EnhancedError;
  
  if (httpUrl) {
    enhancedError.httpUrl = httpUrl;
  }
  
  if (inputObj) {
    try {
      // Store the original input object
      enhancedError.inputObj = inputObj;
      
      // Also provide a safe string representation
      if (typeof inputObj !== 'string') {
        enhancedError.inputObj = toStringSafely(inputObj);
      }
    } catch (e) {
      console.error("Failed to stringify input object", e);
      enhancedError.inputObj = "[Failed to stringify]";
    }
  }
  
  // Add API details in a structured format for easier processing
  enhancedError.apiDetails = {
    url: httpUrl || "unknown",
    method: inputObj?.method || "unknown",
    requestData: inputObj,
    timestamp: Date.now()
  };
  
  // Store last error details in sessionStorage for debugging
  try {
    sessionStorage.setItem('last_error_url', httpUrl || 'unknown');
    sessionStorage.setItem('last_error_time', new Date().toISOString());
  } catch (e) {
    // Silent fail for storage errors
  }
  
  // Call the global error handler
  globalErrorHandler(enhancedError);
};

const registerGlobalErrorHandler = (handler: (error: EnhancedError) => void) => {
  globalErrorHandler = handler;
  console.log("Global error handler registered");
};

interface ErrorBoundaryContextType {
  showGlobalError: (error: Error, httpUrl?: string, inputObj?: any) => void;
}

const ErrorBoundaryContext = createContext<ErrorBoundaryContextType | undefined>(undefined);

// This is a nested provider that uses the useErrorBoundary hook
const ErrorBoundaryInnerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { showBoundary } = useErrorBoundary();

  const handleGlobalError = useCallback(
    (error: Error, httpUrl?: string, inputObj?: any) => {
      // Enhance error with API context
      const enhancedError = error as EnhancedError;
      
      if (httpUrl) {
        enhancedError.httpUrl = httpUrl;
      }
      
      if (inputObj) {
        enhancedError.inputObj = inputObj;
      }
      
      // Pass the enhanced error to the error boundary
      showBoundary(enhancedError);
    },
    [showBoundary]
  );

  // Register the error handler when the component mounts
  useEffect(() => {
    registerGlobalErrorHandler(handleGlobalError);
  }, [handleGlobalError]);

  return (
    <ErrorBoundaryContext.Provider value={{ showGlobalError: handleGlobalError }}>
      {children}
    </ErrorBoundaryContext.Provider>
  );
};

// This is the main provider that sets up the ErrorBoundary
const ErrorBoundaryContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ErrorBoundary 
      FallbackComponent={GlobalErrorPage} 
      onError={(error, info) => {
        console.error("Error caught within ErrorBoundaryContextProvider", error);
        
        // Store error details for debugging
        try {
          sessionStorage.setItem('last_error_component', info.componentStack || 'unknown');
          sessionStorage.setItem('last_error_message', error.message);
          sessionStorage.setItem('last_error_time', new Date().toISOString());
        } catch (e) {
          // Silent fail for storage errors
        }
      }}
    >
      <ErrorBoundaryInnerProvider>
        {children}
      </ErrorBoundaryInnerProvider>
    </ErrorBoundary>
  );
};

/**
 * Hook to access the global error boundary functionality
 * @returns Object with showGlobalError function
 */
const useGlobalErrorBoundary = () => {
  const context = useContext(ErrorBoundaryContext);
  if (!context) {
    throw new Error("useGlobalErrorBoundary must be used within a ErrorBoundaryProvider");
  }
  return context;
};

export { 
  ErrorBoundaryContextProvider, 
  useGlobalErrorBoundary, 
  registerGlobalErrorHandler, 
  showGlobalError,
  EnhancedError // Export the enhanced error type
};