import React, { useState } from "react";
import GpsFixedRoundedIcon from '@mui/icons-material/GpsFixedRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import CrisisAlertRoundedIcon from '@mui/icons-material/CrisisAlertRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const OnTimeTagBuilder = ({ shiftTime, checkTime }: { shiftTime: number, checkTime?: number }) => {
    const FIVE_MINUTES = 5 * 60 * 1000;
    if (!checkTime || isNaN(checkTime)) {return (<div id="notclocked" className="ontime-tag"><ErrorRoundedIcon sx={{fontSize:"19px"}}/>Not Clocked</div>);}
    if (checkTime < shiftTime - FIVE_MINUTES) {return (<div id="clockedearly" className="ontime-tag"><CheckCircleRoundedIcon sx={{fontSize:"19px"}}/>Early</div>);}
    else if (checkTime >= shiftTime - FIVE_MINUTES && checkTime <= shiftTime + FIVE_MINUTES) {return (<div id="clockedontime" className="ontime-tag"><CheckCircleRoundedIcon sx={{fontSize:"19px"}}/>On Time</div>);}
    else {return (<div id="clockedlate" className="ontime-tag"><CancelRoundedIcon sx={{fontSize:"19px"}}/>Late</div>);}
};

const OnTimeStatusBuilder = ({ shiftTime, checkTime }: { shiftTime: number, checkTime?: number }) => {
    const FIVE_MINUTES = 5 * 60 * 1000;
    if (!checkTime || isNaN(checkTime)) {return (<div id="notclocked" className="clock-card-status-bar"></div>);}
    if (checkTime < shiftTime - FIVE_MINUTES) {return (<div id="clockedearly" className="clock-card-status-bar"></div>);}
    else if (checkTime >= shiftTime - FIVE_MINUTES && checkTime <= shiftTime + FIVE_MINUTES) {return (<div id="clockedontime" className="clock-card-status-bar"></div>);}
    else {return (<div id="clockedlate" className="clock-card-status-bar"></div>);}
};

const CheckInTypeTagBuilder = ({checkintype}:{checkintype:string}) => {
    if(checkintype === "TIME_CLOCK_CODE")return(<div className="checkin-tag"><StorefrontRoundedIcon sx={{fontSize:"17px", color:"#FF9800"}}/>Time Clock</div>)
    else if(checkintype === "GEO_LOCATION")return(<div className="checkin-tag"><GpsFixedRoundedIcon sx={{fontSize:"17px", color:"#4CAF50"}}/>Geo Location</div>)
    else return(<div className="checkin-tag"><CrisisAlertRoundedIcon sx={{fontSize:"17px", color:"#F44336"}}/>None</div>)
}

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
}



export {
    OnTimeTagBuilder,
    CheckInTypeTagBuilder,
    formatDate,
    OnTimeStatusBuilder,

}