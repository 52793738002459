import React, { useCallback, useState, useEffect, useRef } from "react";
import { SRIPResponseData } from "../../common/ShiftReadAPIs";
import { convertToTitleCase, extractAndConvertTime, ShiftRequest, WorkyWorkdayShift, formatTimeString, generateWorkyWorkdayShiftInput } from "../../orgViews/OrgUtils";
import CloseIcon from '@mui/icons-material/Close';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import { Tooltip } from '@mui/material';
import {CorpDataObjSimpleFromLocalStorage, CorpType, ShiftRequestUnit} from "../../common/CommonInterfaces"
import { JobberProfileBuilder } from "../../orgViews/orgConstants";
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import Modal from '@mui/material/Modal';
import WestIcon from "@mui/icons-material/West";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import EastIcon from "@mui/icons-material/East";
import { PushToNewSr } from "../../orgViews/OrgCommonComponents";
import HistoryEduRoundedIcon from '@mui/icons-material/HistoryEduRounded';
import HistoryIcon from '@mui/icons-material/History';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TrackChangesRoundedIcon from '@mui/icons-material/TrackChangesRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import PhoneEnabledRoundedIcon from '@mui/icons-material/PhoneEnabledRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentsIcon from '@mui/icons-material/Payments';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useIsMobile } from "../../custom-hooks/useIsMobile";
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { getLocalStorageOrgData } from "../../common/CommonUtilsMethods";
//aggregateShifts 

const signOutURI = process.env.REACT_APP_IDP_SIGN_OUT_URL;
const userType = localStorage.getItem('user_type')?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
const firstName = localStorage.getItem('firstName');
const lastName = localStorage.getItem('lastName');
const orgName = localStorage.getItem('orgName');

function ensureString(input: string | null | undefined): string {
    return input ? input : "";
  }

const SRStatusComponentCarosul = ({
  orgSRIPResponse,
  re1f1,
  isMobile,
  handleOpenModalCalendar,
  handleOpen,
  allShifts,
  todayShifts,
  tmrwShifts,
  handleAllShifts,
  handleTmrwShifts,
  handleTodayShifts,
}: {
  orgSRIPResponse: SRIPResponseData[];
  re1f1: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
  allShifts: boolean;
  todayShifts: boolean;
  tmrwShifts: boolean;
  handleOpenModalCalendar: () => void;
  handleAllShifts: () => void;
  handleTmrwShifts: () => void;
  handleTodayShifts: () => void;
  handleOpen: (
    shiftDate: string,
    shiftTime: string,
    shiftId: string,
    jobberClassification: string,
    orgHourlyPay: number,
    totalJobbers: number,
    actualEndtime: string,
    actualStartTime: string,
    actualDate: string,
    assignedJobbers: string[],
    jobberHourlyRate: number,
    requester: string,
    workyWorkdayShift: string,
  ) => void;
}) => {
  const shiftArr: JSX.Element[] = [];

  const SRInProgressChildComponent = (srDetails: SRIPResponseData) => {
    if (!srDetails || !srDetails.inProgressShiftRequests?.length) return null;

    const workyShift: WorkyWorkdayShift = generateWorkyWorkdayShiftInput({
      workyWorkdayShiftInput: srDetails.workyWorkdayShift,
      assignedSRCount: srDetails.assignedShiftRequests.length,
      inProgressSRCount: srDetails.inProgressShiftRequests.length,
    });
    if (!workyShift) return null;
    
    const {inProgressShiftRequests: [inProgressShift],assignedShiftRequests: [assignedShift],} = srDetails;

    const {
      orgHourlyRate: orgHourlyPay,
      jobberHourlyRate,
      shiftRequestUnitId: SRID,
      workerType: jobberClassification,
      requestingUserDetails: {
        userDetails: { firstName, lastName },
      },
    } = inProgressShift || assignedShift || {};

    const requester = `${firstName} ${lastName}`;
    const totalJobbers = workyShift.assigned + workyShift.unassigned;
    const actualStartTime = srDetails.workyWorkdayShift.substring(11, 16);
    const actualEndtime = srDetails.workyWorkdayShift.substring(17, 22);
    const actualDate = srDetails.workyWorkdayShift.substring(0, 10);
    const displayTime = formatTimeString(srDetails.workyWorkdayShift.substring(11, 22));
    const shiftDate = workyShift.date;
    const shiftTime = displayTime;
    const workDayShift = srDetails?.workyWorkdayShift;

    return (
      <button
        key={SRID}
        onClick={() =>
          handleOpen(
            shiftDate,
            shiftTime,
            SRID,
            jobberClassification,
            orgHourlyPay,
            totalJobbers,
            actualEndtime,
            actualStartTime,
            actualDate,
            [],
            jobberHourlyRate,
            requester,
            workDayShift
          )
        }
        className="srstatus"
        id={workyShift.assigned / totalJobbers !== 1 ? "sr-inprogress" : "sr-assigned"}
      >
        <div className="srstatus-inner">
          <div className="srstatus-date">{workyShift.date}</div>
          <div className="srstatus-time">{displayTime}</div>
          <div className="srstatus-ratio-holder">
            <div className="srstatus-ratio">
              {workyShift.assigned} / {totalJobbers}
            </div>
          </div>
        </div>
      </button>
    );
  };

  const ref = useRef<HTMLDivElement>(null);

  if (!orgSRIPResponse?.length) return null;

  const handleLeftScroll = () => {
    ref.current?.scrollBy({ left: -400, behavior: 'smooth' });
  };

  const handleRightScroll = () => {
    ref.current?.scrollBy({ left: 400, behavior: 'smooth' });
  };

  orgSRIPResponse.forEach((srObject) => {
    Object.values(srObject).forEach((sr: SRIPResponseData) => {
      const childComponent = SRInProgressChildComponent(sr);
      if (childComponent) {
        shiftArr.push(childComponent);
      }
    });
  });


  const getAllShifts = () => {}
  const getShiftsToday = () => {}
  const getShiftsTmrw = () => {}
  const getShiftsBasedOnOrgName = () => {}

  const ShiftButtons = (srDetails: SRIPResponseData[]) => {
    // Extract unique orgNames from srDetails

    function extractOrgNames(srDetails: SRIPResponseData[]): string[] {
      let orgNames: string[] = [];

      if (!srDetails || srDetails.length === 0) {
          return orgNames; // Return empty array if srDetails is undefined or empty
      }

      const srDetailsInner = srDetails[0];
      console.log(srDetailsInner); // Check the first element

      // Check if srDetailsInner is an array (it shouldn't be, but just in case)
      if (Array.isArray(srDetailsInner)) {
          srDetailsInner.forEach(srData => {
              if (srData.assignedShiftRequests) {
                  srData.assignedShiftRequests.forEach(assignedSR => {
                      orgNames.push(assignedSR.orgName);
                  });
              }

              if (srData.inProgressShiftRequests) {
                  srData.inProgressShiftRequests.forEach(inProgressSR => {
                      orgNames.push(inProgressSR.orgName);
                  });
              }
          });
      } else {
          // Directly handle srDetailsInner as SRIPResponseData
          if (srDetailsInner.assignedShiftRequests) {
              srDetailsInner.assignedShiftRequests.forEach(assignedSR => {
                  orgNames.push(assignedSR.orgName);
              });
          }

          if (srDetailsInner.inProgressShiftRequests) {
              srDetailsInner.inProgressShiftRequests.forEach(inProgressSR => {
                  orgNames.push(inProgressSR.orgName);
              });
          }
      }

      return orgNames;
    }

    

    const uniqueOrgs = [...new Set(extractOrgNames(srDetails))]
    const handlePlannedShiftsClick = ()=>{ window.location.assign('/my-shifts')}
    return (
        <div className={isMobile ? "srstatus-title-mobile" : "srstatus-title-agency"}>
            {isMobile?(<div style={{paddingTop:"0px", marginTop:"-5px"}} className="worky-topshelf-card-title-mobile">Current Shifts</div>):(<>Current Shifts</>)}
            <div className={isMobile ? "worky-topshelf-option-holder-mobile":"worky-topshelf-option-holder"}>
              <button onClick={handlePlannedShiftsClick} className={isMobile ? "calendar-button-mobile" : "calendar-button-agency"}><CalendarMonthRoundedIcon/></button>
              <button className="my-shifts-option">All</button>
              <button className="my-shifts-option">Today</button>
              <button className="my-shifts-option">Tomorrow</button>
              {uniqueOrgs.map((company: string, index: number) => (
                <button key={index} className="my-shifts-option">
                    {company}
                </button>
            ))}
            </div>
            
        </div>
    );
};



  // console.log("TESTING TESTING orgSRIPResponse : " + JSON.stringify(orgSRIPResponse));

  return (
    <div className={isMobile ? "srstatus-container-hp-mobile" : "srstatus-container-agency"}>
      {ShiftButtons(orgSRIPResponse)}
      <button onClick={handleLeftScroll} className={isMobile ? "srstatus-left-mobile" : "srstatus-left-hp"}>
        <WestIcon />
      </button>
      <button onClick={handleRightScroll} className={isMobile ? "srstatus-right-mobile" : "srstatus-right-agency"}>
        <EastIcon />
      </button>
      <div ref={ref} className={isMobile ? "srstatus-holder-mobile" : "srstatus-holder-hp"}>
        {shiftArr}
      </div>
    </div>
  );
};



// v1 modal
const SRModelDetailsComponent = ({ modalOpen, handleClose,
     isMobile, modalShiftDate, modalShiftTime, modalTotalJobbers,modalOrgHourlyPay,
     modalRequester, modalAssignedJobbers, handleOpenExtendShift,
     handleOpenReportIncident, handleCancelModalOpen } 
    : { modalOpen: boolean; handleClose: () => void, isMobile: boolean,
        modalShiftDate: string, modalShiftTime: string, modalTotalJobbers: string, modalOrgHourlyPay: string,
        modalRequester: string, modalAssignedJobbers: JobberProfileBuilder[],
        handleOpenExtendShift: () => void, handleOpenReportIncident: () => void 
        handleCancelModalOpen: () => void
     }) => {
  return (
    <div>
      <Modal open={modalOpen} onClose={handleClose}>
        <div className={isMobile ? "srstatus-modal-mobile" : "srstatus-modal"}>
          <button onClick={handleClose} className="modal-close">
            <CloseIcon />
          </button>
          <div className="srstatus-date">{modalShiftDate}</div>
          <div className="srstatus-time">{modalShiftTime}</div>
          {/* {modalShiftId} */}
          <div className="srstatus-modal-title">Shift Details</div>
          <div className="srstatus-modal-details">
            <div className="srstatus-modal-detail">
              <span id="modal-detail-heading">Number of Workers:</span>
              {modalTotalJobbers}
            </div>
            <div className="srstatus-modal-detail">
              <span id="modal-detail-heading">Worker Type:</span>Warehouse Associate
            </div>
            <div className="srstatus-modal-detail">
              <span id="modal-detail-heading">Worker Pay:</span>${modalOrgHourlyPay}/hr
            </div>
            <div className="srstatus-modal-detail">
              <span id="modal-detail-heading">Requester:</span>
              {/* {modalRequester && modalRequester !== undefined ? modalRequester:" "} */}
              {ensureString(modalRequester)}
            </div>

          </div>
          <div className="srstatus-modal-title">Modify Shift</div>
          <div className="srstatus-modal-actions">
            <button onClick={PushToNewSr} className="modal-action" id="modal-action-add">
              Add Workers
            </button>
            <button onClick={handleOpenExtendShift} className="modal-action" id="modal-action-extend">
              Extend Shift
            </button>
            <button onClick={handleOpenReportIncident} className="modal-action" id="modal-action-report">
              Report Incident
            </button>
            <button onClick={handleCancelModalOpen} className="modal-action" id="modal-action-cancel">
              Cancel Shift
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};







const WorkyAppHeader = () => {
  const url = window.location.href;
  const handleHomeClick = ()=>{ window.location.assign('/homepage')}
  const handlePlannedShiftsClick = ()=>{ window.location.assign('/my-shifts')}
  const handleNewShiftClick = ()=>{ window.location.assign('/new-shift')}
  const handlePaymentsClick = ()=>{ window.location.assign('/agency/payments')}
  const handleAccountSettingsClick = ()=>{ window.location.assign('/account')}
  const handleLogOutClick = () => {window.location.assign(signOutURI);}
  const handleTimePortalClick = ()=>{ window.location.assign('/agency/worky-time-clock')}
  const handleWorkforceClick = ()=>{ window.location.assign('/agency/workforce')}
  const handleWorkyPayClick = ()=>{ window.location.assign('/agency/worky-pay')}
  const handleWorkyBillingClick = ()=>{ window.location.assign('/agency/client-billing')}
  const handleWorkyLeadsClick = ()=>{window.location.assign('/agency/leads')}
  const isMobile = useIsMobile();
  const [workyUserInfo, setworkyUserInfo] = useState(false);
  const handleProfileIconClick = () => { setworkyUserInfo(!workyUserInfo) }
  const userInfoRef = useRef(null);
  const handleClickOutside = (event) => {
    if (userInfoRef.current && !userInfoRef.current.contains(event.target)) {
      setworkyUserInfo(false);
    }
  };
  useEffect(() => {
    if (workyUserInfo) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [workyUserInfo]);


  const [contactOpen, setContactOpen] = useState(false);

  const WorkyAppHeaderMobile = () => {


    const corpDataObj:CorpDataObjSimpleFromLocalStorage = getLocalStorageOrgData();
    
    if(!corpDataObj){
       console.log("in WorkyAppHeaderDesktop orgData from getLocalStorageOrgData is null")
    }    

    const WorkyAppHeaderMobileForAgencyMobile = () => {

      return(

          <div>
          {/* bottom mobile nav */}
          <div className="worky-app-nav-mobile">
            <button onClick={handleHomeClick} id={url.includes('homepage') ? 'worky-app-nav-mobile-option-active' : ''} className="worky-app-nav-mobile-option"><HomeRoundedIcon/>Home</button>
            <button onClick={handlePlannedShiftsClick} id={url.includes('my-shifts') ? 'worky-app-nav-mobile-option-active' : ''} className="worky-app-nav-mobile-option"><EventRoundedIcon/>My Shifts</button>
            <button onClick={handleNewShiftClick} id={url.includes('new-shift') ? 'worky-app-nav-mobile-option-active' : ''} className="worky-app-nav-mobile-option"><AddCircleRoundedIcon/>New Shift</button>
            <button onClick={handleWorkyBillingClick} id={url.includes('agency/client-billing') ? 'worky-app-nav-mobile-option-active' : ''} className="worky-app-nav-mobile-option"><PaymentsIcon/>Client Invoices</button>
            <button onClick={() => setContactOpen(!contactOpen)} id={contactOpen ? 'worky-app-nav-mobile-option-active' : ''} className="worky-app-nav-mobile-option"><AppsRoundedIcon/>More</button>        
          </div>

          {/* contact popper */}
          <div id={contactOpen ? "worky-app-nav-mobile-popup-open":"worky-app-nav-mobile-popup-closed"}>
            <button onClick={() => setContactOpen(!contactOpen)} className="worky-app-nav-mobile-popup-close-btn"></button>
            <div className="worky-app-nav-mobile-popup-open-info-holder">          
              <div className="worky-app-nav-mobile-popup-open-apptray">            
                <button className="worky-app-nav-mobile-popup-open-app"><button id={url.includes('agency/worky-pay') ? 'worky-app-nav-mobile-option-active' : ''} onClick={handleWorkyPayClick} className="worky-app-nav-mobile-tray-option"><CurrencyExchangeRoundedIcon style={{fontSize:"22px"}}/>Worky Pay</button></button>
                <button className="worky-app-nav-mobile-popup-open-app"><button id={url.includes('agency/workforce') ? 'worky-app-nav-mobile-option-active' : ''} onClick={handleWorkforceClick} className="worky-app-nav-mobile-tray-option"><Diversity3RoundedIcon style={{fontSize:"22px"}}/>Workforce</button></button>
                <button className="worky-app-nav-mobile-popup-open-app"><button id={url.includes('agency/worky-time-clock') ? 'worky-app-nav-mobile-option-active' : ''} onClick={handleTimePortalClick} className="worky-app-nav-mobile-tray-option"><TrackChangesRoundedIcon style={{fontSize:"22px"}}/>Time Clock</button></button>            
                <button className="worky-app-nav-mobile-popup-open-app"><button id={url.includes('agency/leads') ? 'worky-app-nav-mobile-option-active' : ''} onClick={handleWorkyLeadsClick} className="worky-app-nav-mobile-tray-option"><TrendingUpRoundedIcon style={{fontSize:"22px"}}/>Leads</button></button>            
              </div>
            </div>
          </div>
        </div>
      )
    }

    const WorkyAppHeaderMobileForOrgMobile = () => {
      return(

        <div>
        {/* bottom mobile nav */}
        <div className="worky-app-nav-mobile">
          <button onClick={handleHomeClick} id={url.includes('homepage') ? 'worky-app-nav-mobile-option-active' : ''} className="worky-app-nav-mobile-option"><HomeRoundedIcon/>Home</button>
          <button onClick={handlePlannedShiftsClick} id={url.includes('my-shifts') ? 'worky-app-nav-mobile-option-active' : ''} className="worky-app-nav-mobile-option"><EventRoundedIcon/>My Shifts</button>
          <button onClick={handleNewShiftClick} id={url.includes('new-shift') ? 'worky-app-nav-mobile-option-active' : ''} className="worky-app-nav-mobile-option"><AddCircleRoundedIcon/>New Shift</button> 
        </div>        
      </div>
    )
    }

    return(
    <>
      {/* top mobile header */}
      <div className="worky-app-nav-top-mobile">

        <div className="worky-app-nav-top-company-holder-mobile">
          <div className="worky-app-nav-top-company-inner-mobile">{orgName}</div>
        </div>

        <div className="worky-app-nav-top-icon-holder-mobile">
          <div onClick={handleProfileIconClick} className="worky-app-nav-top-icon-inner-mobile"><AccountCircleIcon style={{fontSize:"37px"}}/></div>
        </div>

      </div>      

      { corpDataObj?.corpJobberDetails?.corpType == CorpType.AGENCY 
          ? <WorkyAppHeaderMobileForAgencyMobile/>
          : <WorkyAppHeaderMobileForOrgMobile/> 
      }

      {/* account settings popper */}
      <div ref={userInfoRef} className={workyUserInfo ? (isMobile?("worky-app-header-right-user-account-holder-mobile"):("worky-app-header-right-user-account-holder")):"worky-app-header-right-user-account-holder-closed"}>

      <div className={isMobile?"worky-app-header-right-user-account-holder-item-top-mobile":"worky-app-header-right-user-account-holder-item-top"}>
        {firstName +" "+lastName}
        <div className="worky-app-header-right-title">{userType}</div>
      </div>

      <div className={isMobile?"worky-app-header-right-user-account-holder-item-mobile":"worky-app-header-right-user-account-holder-item"}>
        <div style={{fontSize:"12px"}} className="worky-contact-agency-button-info-item-btn"><PhoneEnabledRoundedIcon style={{fontSize:"18px", rotate:"90deg"}}/>(647) 494-9090</div>
        <div style={{fontSize:"12px"}} className="worky-contact-agency-button-info-item-btn"><EmailRoundedIcon style={{fontSize:"18px"}}/>info@worky.pro</div>
      </div>

      <div onClick={handleAccountSettingsClick} className={isMobile?"worky-app-header-right-user-account-holder-item-mobile":"worky-app-header-right-user-account-holder-item"}>
        <SettingsIcon style={{fontSize:"18px"}}/> Account Settings
      </div>

      <div className={isMobile?"worky-app-header-right-user-account-holder-item-mobile":"worky-app-header-right-user-account-holder-item"}>
        <RocketLaunchIcon style={{fontSize:"18px"}}/> Upgrade Plan
      </div>

      <div onClick={handleLogOutClick} className={isMobile?"worky-app-header-right-user-account-holder-item-mobile":"worky-app-header-right-user-account-holder-item"}>
        <LogoutIcon style={{fontSize:"18px"}}/> Log Out
      </div>

      </div>


    </>
    )
  }











  const WorkyAppHeaderDesktop = () => {

    const corpDataObj:CorpDataObjSimpleFromLocalStorage = getLocalStorageOrgData();
    
    if(!corpDataObj){
       console.log("in WorkyAppHeaderDesktop orgData from getLocalStorageOrgData is null")
    }    
    
    const WorkyAppHeaderDesktopForAgency = () => {
      return (
              <div className="worky-app-header-middle">
                <div className="worky-app-header-middle-left">
                    <button onClick={handleHomeClick} className="worky-app-header-middle-option" id={url.includes('homepage') ? 'worky-app-header-middle-option-active' : ''}>Home</button>
                    <button onClick={handleNewShiftClick} className="worky-app-header-middle-option" id={url.includes('new-shift') ? 'worky-app-header-middle-option-active' : ''}>New Shift</button>
                    <button onClick={handlePlannedShiftsClick} className="worky-app-header-middle-option" id={url.includes('my-shifts') ? 'worky-app-header-middle-option-active' : ''}>My Shifts</button>
                    <button onClick={handleWorkforceClick} className="worky-app-header-middle-option" id={url.includes('workforce') ? 'worky-app-header-middle-option-active' : ''}>Workforce</button>
                    <button onClick={handleWorkyPayClick} className="worky-app-header-middle-option" id={url.includes('/agency/worky-pay') ? 'worky-app-header-middle-option-active' : ''}>Worky Pay</button>
                    <button onClick={handleWorkyBillingClick} className="worky-app-header-middle-option" id={url.includes('/agency/client-billing') ? 'worky-app-header-middle-option-active' : ''}>Client Invoices</button>
                    <button onClick={handleTimePortalClick} className="worky-app-header-middle-option" id={url.includes('time-clock') ? 'worky-app-header-middle-option-active' : ''}>Time Clock</button>          
                    <button onClick={handleWorkyLeadsClick} className="worky-app-header-middle-option" id={url.includes('/agency/leads') ? 'worky-app-header-middle-option-active' : ''}>Worky Leads</button>          
                  </div>
              </div>
       )
    }

    const WorkyAppHeaderDesktopForOrg = () => {
      return (
        <div className="worky-app-header-middle">
          <div className="worky-app-header-middle-left">
              <button onClick={handleHomeClick} className="worky-app-header-middle-option" id={url.includes('homepage') ? 'worky-app-header-middle-option-active' : ''}>Home</button>
              <button onClick={handleNewShiftClick} className="worky-app-header-middle-option" id={url.includes('new-shift') ? 'worky-app-header-middle-option-active' : ''}>New Shift</button>
              <button onClick={handlePlannedShiftsClick} className="worky-app-header-middle-option" id={url.includes('my-shifts') ? 'worky-app-header-middle-option-active' : ''}>My Shifts</button>              
            </div>
        </div>
 )
    }

    return(
      <div className="worky-app-header">
      <button className="worky-app-header-left">
        <div className="worky-app-header-left-logo">w</div>
        |
        <div className="worky-app-header-left-label">{orgName}</div>
      </button>

      { corpDataObj?.corpJobberDetails?.corpType == CorpType.AGENCY 
          ? <WorkyAppHeaderDesktopForAgency/>
          : <WorkyAppHeaderDesktopForOrg/> 
      }
      
      
      
      <div className="worky-app-header-right">
        <div className="worky-app-header-right-inner">          
          <button onClick={handleProfileIconClick} className="worky-app-header-right-icon">{firstName?.[0] && lastName?.[0] ? `${firstName[0]}${lastName[0]}` : null}</button>
        </div>


        <div ref={userInfoRef} className={workyUserInfo?"worky-app-header-right-user-account-holder":"worky-app-header-right-user-account-holder-closed"}>

          <div className="worky-app-header-right-user-account-holder-item-top">
            {firstName +" "+lastName}
            <div className="worky-app-header-right-title">{userType}</div>
          </div>

          <div onClick={handleAccountSettingsClick} className="worky-app-header-right-user-account-holder-item">
            <SettingsIcon style={{fontSize:"18px"}}/> Account Settings
          </div>

          <div className="worky-app-header-right-user-account-holder-item">
            <RocketLaunchIcon style={{fontSize:"18px"}}/> Upgrade Plan
          </div>

          <div onClick={handleLogOutClick} className="worky-app-header-right-user-account-holder-item">
            <LogoutIcon style={{fontSize:"18px"}}/> Log Out
          </div>

        </div>



      </div>
    </div>
    )
  }


  return(
    <>
    {isMobile ? <WorkyAppHeaderMobile/>:<WorkyAppHeaderDesktop/>}
    </>
  )


}



const WorkyTopShelf = () => {
  const isMobile = useIsMobile();
  const WorkyTopShelfDesktop =()=>{
    return(
      <div className="worky-top-shelf">
  
        <div className="worky-top-shelf-side">
          <div className="worky-top-shelf-side-title">New Shift</div>
          <button onClick={()=>window.location.assign('/new-shift')} id="shelf-newshift" className="worky-top-shelf-side-button">
            <div className="worky-top-shelf-side-button-icon-holder">
              <div className="worky-top-shelf-side-button-icon-holder-inner"><span style={{fontSize:"100px", color:"white", fontWeight:"500"}}>+</span></div>
            </div>
            <div className="worky-top-shelf-side-button-right">
              <div className="worky-top-shelf-side-button-right-title">Hire Workers Now!</div>
              <div className="worky-top-shelf-side-button-right-subtitle">Get hiring! In a few simple steps, get your team hired today!</div>
            </div>
          </button>
        </div>
  
  
  
  
        <div className="worky-top-shelf-side">
          <div className="worky-top-shelf-side-title">Upcoming Shifts</div>
          <button onClick={()=>window.location.assign('/my-shifts')} id="shelf-prevshift" className="worky-top-shelf-side-button">
            <div className="worky-top-shelf-side-button-icon-holder">
              <div className="worky-top-shelf-side-button-icon-holder-inner"><HistoryIcon style={{fontSize:"75px", color:"white", fontWeight:"500",marginLeft:"-2.5px"}}/></div>
            </div>
            <div className="worky-top-shelf-side-button-right">
              <div className="worky-top-shelf-side-button-right-title">Upcoming Shifts</div>
              <div className="worky-top-shelf-side-button-right-subtitle">View all of your upcoming shifts!</div>
            </div>
          </button>
        </div>
  
      </div>
    )
  }
  
  const WorkyTopShelfMobile = () => {

    const TimeBasedGreeting = () => {
      const [greeting, setGreeting] = useState('');
    
      useEffect(() => {
        const determineGreeting = () => {
          const currentHour = new Date().getHours();
          if (currentHour < 12) {setGreeting('Good morning,');
          } else if (currentHour < 18) {setGreeting('Good afternoon,');
          } else {setGreeting('Good evening,');}
        };
    
        determineGreeting();
        const interval = setInterval(determineGreeting, 60000);
        return () => clearInterval(interval);

      }, []);
    
      return <div className="worky-topshelf-greeting-mobile"><span className="gradient-blue">{greeting}</span></div>;
    };

    const handlePlannedShiftsClick = ()=>{ window.location.assign('/my-shifts')}
    const handleNewShiftClick = ()=>{ window.location.assign('/new-shift')}
    const handleTimePortalClick = ()=>{ window.location.assign('/agency/worky-time-clock')}
    const handleWorkforceClick = ()=>{ window.location.assign('/agency/workforce')}
    const handleWorkyPayClick = ()=>{ window.location.assign('/agency/worky-pay')}
    const handleWorkyBillingClick = ()=>{ window.location.assign('/agency/client-billing')}

    const corpDataObj:CorpDataObjSimpleFromLocalStorage = getLocalStorageOrgData();
    
    if(!corpDataObj){
       console.log("in WorkyAppHeaderDesktop orgData from getLocalStorageOrgData is null")
    }    

    const WorkyTopShelfMobileQuickActionsForAgency = () =>{
        return(
            <div>
              {/* Quick Actions */}
              <div className="worky-topshelf-card-title-mobile">Quick Actions</div>
              <div className="worky-topshelf-quick-action-holder-mobile">        
                <button onClick={handleWorkyBillingClick} className="worky-topshelf-quick-action"><div className="worky-topshelf-quick-action-inner-icon"><RequestQuoteRoundedIcon style={{color:"#5689fc", fontSize:"22px"}}/></div><span className="quick-action-title">Client Invoices</span></button>
                <button onClick={handleWorkforceClick} className="worky-topshelf-quick-action"><div className="worky-topshelf-quick-action-inner-icon"><Diversity3RoundedIcon style={{color:"#5689fc", fontSize:"22px"}}/></div><span className="quick-action-title">Workforce</span></button>
                <button onClick={handleTimePortalClick} className="worky-topshelf-quick-action"><div className="worky-topshelf-quick-action-inner-icon"><TrackChangesRoundedIcon style={{color:"#5689fc", fontSize:"22px"}}/></div><span className="quick-action-title">Time Clock</span></button>
                <button onClick={handleWorkyPayClick} className="worky-topshelf-quick-action"><div className="worky-topshelf-quick-action-inner-icon"><CurrencyExchangeRoundedIcon style={{color:"#5689fc", fontSize:"22px"}}/></div><span className="quick-action-title">Worky Pay</span></button>
              </div>
            </div>   
        )
    }

    const WorkyTopShelfMobileQuickActionsForOrg = () =>{
      return(
            <div>
              {/* Quick Actions */}
              {/* <div className="worky-topshelf-card-title-mobile">Quick Actions</div>
              <div className="worky-topshelf-quick-action-holder-mobile">        
                <button onClick={handleWorkyBillingClick} className="worky-topshelf-quick-action"><div className="worky-topshelf-quick-action-inner-icon"><RequestQuoteRoundedIcon style={{color:"#5689fc", fontSize:"22px"}}/></div><span className="quick-action-title">Billing</span></button>
                <button onClick={handleWorkforceClick} className="worky-topshelf-quick-action"><div className="worky-topshelf-quick-action-inner-icon"><Diversity3RoundedIcon style={{color:"#5689fc", fontSize:"22px"}}/></div><span className="quick-action-title">Workforce</span></button>
                <button onClick={handleTimePortalClick} className="worky-topshelf-quick-action"><div className="worky-topshelf-quick-action-inner-icon"><TrackChangesRoundedIcon style={{color:"#5689fc", fontSize:"22px"}}/></div><span className="quick-action-title">Time Clock</span></button>
                <button onClick={handleWorkyPayClick} className="worky-topshelf-quick-action"><div className="worky-topshelf-quick-action-inner-icon"><CurrencyExchangeRoundedIcon style={{color:"#5689fc", fontSize:"22px"}}/></div><span className="quick-action-title">Worky Pay</span></button>
              </div> */}
          </div>   
      )
  }


    return(
    <div className="worky-topshelf-mobile">

      <div className="worky-topshelf-intro-mobile">
        <TimeBasedGreeting/>
        <div className="worky-topshelf-intro-title-mobile"><span className="gradient-blue">{firstName + " " + lastName}</span></div>
      </div>


      <div className="worky-topshelf-test">
        <button onClick={handleNewShiftClick} id="shelf-newshift" className="worky-topshelf-button">
            <div className="worky-topshelf-button-top-icon"><AddCircleOutlineRoundedIcon style={{fontSize:"30px",marginLeft:"-1.5px",marginTop:"-1.5px",opacity:1, color:"#fff"}}/></div>
            <div className="worky-topshelf-button-top-title">New Shift</div>
        </button>
        <button onClick={handlePlannedShiftsClick} id="shelf-prevshift" className="worky-topshelf-button">
            <div className="worky-topshelf-button-top-icon"><HistoryEduRoundedIcon style={{fontSize:"30px",marginLeft:"-1.5px",marginTop:"-1.5px",opacity:1, color:"#4c4c4c"}}/></div>
            <div className="worky-topshelf-button-top-title">My Shifts</div>
        </button>
      </div>
      
      
      
      

      { corpDataObj?.corpJobberDetails?.corpType == CorpType.AGENCY 
          ? <WorkyTopShelfMobileQuickActionsForAgency/>
          : <WorkyTopShelfMobileQuickActionsForOrg/>
      }
      <br/>
     
    

    </div>
    )
  }


  return(
    <>
    {isMobile ? <WorkyTopShelfMobile/>:<WorkyTopShelfDesktop/>}
    </>
  )


}

const WorkyContactButton = () => {
  const [contactOpen, setContactOpen] = useState(false);
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? 
        null 
        : 
        (
          <>
            <button onClick={() => setContactOpen(!contactOpen)} className={contactOpen ? "worky-contact-agency-button-spun" : "worky-contact-agency-button"}><PhoneEnabledRoundedIcon /></button>
            <div id={contactOpen ? "worky-contact-button-active" : ""} className="worky-contact-agency-button-info">
              <div className="worky-contact-agency-button-info-item">(647) 494-9090</div>
              |
              <div className="worky-contact-agency-button-info-item">info@worky.pro</div>
            </div>
          </>
        )
      }
    </>
  );
};

const WorkyFooter = ()=>{
  const isMobile = useIsMobile();
  return(
    <div className={isMobile?"worky-footer-label-mobile":"worky-footer-label"}>worky</div>
  )
}



const modalShiftDateConverter = (shiftDate: string): string => {
  const date = new Date(shiftDate + ', 2024');
  const options: Intl.DateTimeFormatOptions = { 
      weekday: 'short', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
  };
  return date.toLocaleDateString('en-US', options);
};




const TimeCheckTag = ({ startTimeString, clockInTimeString, endTimeString, clockOutTimeString, isClockOut = false}: {
  startTimeString?: string;
  clockInTimeString?: string;
  endTimeString?: string;
  clockOutTimeString?: string;
  isClockOut?: boolean;
}) => {
  // Regular expression to extract the ISO date-time part from the strings
  const isoDateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

  // Extract the ISO date-time strings using regex
  const timeString = isClockOut ? endTimeString : startTimeString;
  const clockTimeString = isClockOut ? clockOutTimeString : clockInTimeString;
  const timeMatch = timeString?.match(isoDateRegex);
  const clockTimeMatch = clockTimeString?.match(isoDateRegex);

  if (!timeMatch || !clockTimeMatch) {
    throw new Error('Invalid date time string format');
  }

  // Parse the extracted strings into Date objects
  const time = new Date(timeMatch[0]);
  const clockTime = new Date(clockTimeMatch[0]);

  // Convert Date objects to milliseconds
  const timeMillis = time.getTime();
  const clockTimeMillis = clockTime.getTime();

  // Calculate the difference in milliseconds
  const timeDifference = isClockOut
    ? timeMillis - clockTimeMillis // Clock-out: Time - Clock Time
    : clockTimeMillis - timeMillis; // Clock-in: Clock Time - Time

  // Convert the difference to minutes
  const differenceInMinutes = timeDifference / (1000 * 60);

  // Check if the difference is 2 minutes or more
  if (isClockOut) {
    // For clock-out, check if clock-out is 2 minutes or more earlier than end time
    if (differenceInMinutes >= 2) {
      return (<div id="card-status-tag-late" className="worky-shift-details-card-worker-status-tag">Early</div>);
    } else {
      return (<div id="card-status-tag-ontime" className="worky-shift-details-card-worker-status-tag">On Time</div>);
    }
  } else {
    // For clock-in, check if clock-in is 2 minutes or more later than start time
    if (differenceInMinutes >= 2) {
      return (<div id="card-status-tag-late" className="worky-shift-details-card-worker-status-tag">Late</div>);
    } else {
      return (<div id="card-status-tag-ontime" className="worky-shift-details-card-worker-status-tag">On Time</div>);
    }
  }
};



const WorkyShiftDetailsCardClocked = ({SRID, startTime, endTime, shiftRequester, workerPay, workerRole,clockInTime, clockOutTime, assignedJobberName, assignedJobberEmail,assignedJobberIcon }: {SRID:string, startTime:string, endTime:string, shiftRequester:string, workerPay:number, workerRole:string, clockInTime:string, clockOutTime:string, assignedJobberName:string, assignedJobberEmail:string,assignedJobberIcon:string}) => {
  const [expandCard, setExpandCard] = useState(false);
  const isMobile = useIsMobile();

  const WorkyShiftDetailsCardDesktop = () => {
    return(
      <div className={expandCard ? "worky-shift-details-card-expanded":"worky-shift-details-card"}>
      <div className="worky-shift-details-card-top">

        <div className="worky-shift-details-card-top-shift-details-holder">

          <div className="worky-shift-details-card-profile">
            <div className="worky-shift-details-card-worker-icon"><img src={assignedJobberIcon}/></div>
            <div className="worky-shift-details-card-worker-name">{assignedJobberName || "Jobber Name"}</div>
          </div>

          <div className="worky-shift-details-card-worker-checked-holder">
            <div className="worky-shift-details-card-worker-checked-title">Clocked-In <TimeCheckTag startTimeString={startTime} clockInTimeString={clockInTime} /> </div>
            <div className="worky-shift-details-card-worker-checked-value">{extractAndConvertTime(clockInTime)}</div>
          </div>
          <div className="worky-shift-details-card-worker-checked-holder">
            <div className="worky-shift-details-card-worker-checked-title">Clocked-Out <TimeCheckTag endTimeString={endTime} clockOutTimeString={clockOutTime} isClockOut={true}/></div>
            <div className="worky-shift-details-card-worker-checked-value">{extractAndConvertTime(clockOutTime)}</div>
          </div>
        </div>

        <div className="worky-shift-details-card-top-btn-holder">
          <Tooltip style={{fontSize:"18px"}} title="Cancel Shift" placement='top'><button className="worky-shift-details-card-action"><CloseIcon style={{fontSize:"20px"}}/></button></Tooltip>
          <Tooltip style={{fontSize:"18px"}} title="Extend Shift" placement='top'><button className="worky-shift-details-card-action"><MoreTimeRoundedIcon style={{fontSize:"20px"}}/></button></Tooltip>
          <Tooltip style={{fontSize:"18px"}} title="Swap Worker" placement='top'><button className="worky-shift-details-card-action"><SyncRoundedIcon style={{rotate:"65deg", fontSize:"20px"}}/></button></Tooltip>
        </div>

        <button onClick={()=>setExpandCard(!expandCard)} className="worky-shift-details-card-bottom-expand-btn">{expandCard? (<><ExpandLessIcon/></>):(<><ExpandMoreIcon/></>)}</button>

      </div>

      <div className={expandCard ? "worky-shift-details-card-bottom-expanded":"worky-shift-details-card-bottom"}>
        {expandCard ? (
          <div className="worky-shift-details-card-bottom-expanded-inner">
              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Shift Requester:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{shiftRequester}</div>
              </div>
          
              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Clocked-In:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{extractAndConvertTime(clockInTime)} <TimeCheckTag startTimeString={startTime} clockInTimeString={clockInTime} /></div>
              </div>

              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Pay:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">${workerPay}/hr</div>
              </div>

          
              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Role:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{convertToTitleCase(workerRole)}</div>
              </div>

              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Clocked-Out:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{extractAndConvertTime(clockOutTime)} <TimeCheckTag endTimeString={endTime} clockOutTimeString={clockOutTime} isClockOut={true}/></div>
              </div>
          </div>
        ):(null)}
      </div>
    </div>
    )
  }

  const WorkyShiftDetailsCardMobile = () => {
    return(
      <div className={expandCard ? "worky-shift-details-card-expanded-mobile":"worky-shift-details-card"}>
      <div className="worky-shift-details-card-top">

        <div className="worky-shift-details-card-top-shift-details-holder-mobile">


          <div className="worky-shift-details-card-profile">
            <div className="worky-shift-details-card-worker-icon"><img src={assignedJobberIcon}/></div>
            <div className="worky-shift-details-card-worker-name">{assignedJobberName || "Jobber Name"}</div>
          </div>


          <div className="worky-shift-details-card-worker-checked-holder-mobile">

            <div className="checked-holder-inner-mobile">
              <div className="checked-holder-inner-title-mobile">Clocked-In</div>
              <div className="checked-holder-inner-value-mobile">{extractAndConvertTime(clockInTime)}</div>
            </div>

            <div className="checked-holder-inner-line-mobile"></div>

            <div className="checked-holder-inner-mobile">
              <div className="checked-holder-inner-title-mobile">Clocked-Out</div>
              <div className="checked-holder-inner-value-mobile">{extractAndConvertTime(clockOutTime)}</div>
            </div>

          </div>


          <button style={{marginRight:"10px"}} onClick={()=>setExpandCard(!expandCard)} className="worky-shift-details-card-bottom-expand-btn">{expandCard? (<><ExpandLessIcon/></>):(<><ExpandMoreIcon/></>)}</button>
        </div>

      </div>

      <div className={expandCard ? "worky-shift-details-card-bottom-expanded-mobile":"worky-shift-details-card-bottom"}>
        {expandCard ? (
          <div className="worky-shift-details-card-bottom-expanded-inner-mobile">
          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Shift Requester:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{shiftRequester}</div>
          </div>

          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Pay:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">${workerPay}/hr</div>
          </div>



          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Role:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{convertToTitleCase(workerRole)}</div>
          </div>          

          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Clocked-In:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{extractAndConvertTime(clockInTime)} <TimeCheckTag startTimeString={startTime} clockInTimeString={clockInTime} /></div>
          </div>

          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Clocked-Out:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{extractAndConvertTime(clockOutTime)} <TimeCheckTag endTimeString={endTime} clockOutTimeString={clockOutTime} isClockOut={true}/></div>
          </div>
      </div>
        ):(null)}
      </div>
    </div>
    )
  }


  return(
    <>{isMobile? <WorkyShiftDetailsCardMobile/>:<WorkyShiftDetailsCardDesktop/>}</>
  )
}

const WorkyShiftDetailsCardPreClocked = ({SRID, startTime, endTime, shiftRequester, workerPay, workerRole,clockInTime, clockOutTime, assignedJobberName, assignedJobberEmail,assignedJobberIcon }: {SRID:string, startTime:string, endTime:string, shiftRequester:string, workerPay:number, workerRole:string, clockInTime:string, clockOutTime:string, assignedJobberName:string, assignedJobberEmail:string,assignedJobberIcon:string}) => {
  const [expandCard, setExpandCard] = useState(false);
  const isMobile = useIsMobile();


  const WorkyShiftDetailsCardDesktop = () => {
    return(
      <div className={expandCard ? "worky-shift-details-card-expanded":"worky-shift-details-card"}>
      <div className="worky-shift-details-card-top">

        <div className="worky-shift-details-card-top-shift-details-holder">

          <div className="worky-shift-details-card-profile">
            <div className="worky-shift-details-card-worker-icon"><img src={assignedJobberIcon}/></div>
            <div className="worky-shift-details-card-worker-name">{assignedJobberName || "Jobber Name"}</div>
          </div>

          <div className="worky-shift-details-card-worker-checked-holder">
            <div className="worky-shift-details-card-worker-checked-title">Start Time</div>
            <div className="worky-shift-details-card-worker-checked-value">{extractAndConvertTime(startTime)}</div>
          </div>
          <div className="worky-shift-details-card-worker-checked-holder">
            <div className="worky-shift-details-card-worker-checked-title">End Time</div>
            <div className="worky-shift-details-card-worker-checked-value">{extractAndConvertTime(endTime)}</div>
          </div>
        </div>

        <div className="worky-shift-details-card-top-btn-holder">
          <Tooltip style={{fontSize:"18px"}} title="Cancel Shift" placement='top'><button className="worky-shift-details-card-action"><CloseIcon style={{fontSize:"20px"}}/></button></Tooltip>
          <Tooltip style={{fontSize:"18px"}} title="Extend Shift" placement='top'><button className="worky-shift-details-card-action"><MoreTimeRoundedIcon style={{fontSize:"20px"}}/></button></Tooltip>
          <Tooltip style={{fontSize:"18px"}} title="Swap Worker" placement='top'><button className="worky-shift-details-card-action"><SyncRoundedIcon style={{rotate:"65deg", fontSize:"20px"}}/></button></Tooltip>
        </div>

        <button onClick={()=>setExpandCard(!expandCard)} className="worky-shift-details-card-bottom-expand-btn">{expandCard? (<><ExpandLessIcon/></>):(<><ExpandMoreIcon/></>)}</button>

      </div>

      <div className={expandCard ? "worky-shift-details-card-bottom-expanded":"worky-shift-details-card-bottom"}>
        {expandCard ? (
          <div className="worky-shift-details-card-bottom-expanded-inner">
              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Shift Requester:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{shiftRequester}</div>
              </div>

              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Start Time:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{extractAndConvertTime(startTime)}</div>
              </div>

              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Pay:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">${workerPay}/hr</div>
              </div>

              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Role:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{convertToTitleCase(workerRole)}</div>
              </div>
              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">End Time:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{extractAndConvertTime(endTime)}</div>
              </div>
          </div>
        ):(null)}
      </div>
    </div>
    )
  }

  const WorkyShiftDetailsCardMobile = () => {
    return(
      <div className={expandCard ? "worky-shift-details-card-expanded-mobile":"worky-shift-details-card"}>
      <div className="worky-shift-details-card-top">

        <div className="worky-shift-details-card-top-shift-details-holder-mobile">


          <div className="worky-shift-details-card-profile">
            <div className="worky-shift-details-card-worker-icon"><img src={assignedJobberIcon}/></div>
            <div className="worky-shift-details-card-worker-name">{assignedJobberName || "Jobber Name"}</div>
          </div>


          <div className="worky-shift-details-card-worker-checked-holder-mobile">

            <div className="checked-holder-inner-mobile">
              <div className="checked-holder-inner-title-mobile">Start Time</div>
              <div className="checked-holder-inner-value-mobile">{extractAndConvertTime(startTime)}</div>
            </div>

            <div className="checked-holder-inner-line-mobile"></div>

            <div className="checked-holder-inner-mobile">
              <div className="checked-holder-inner-title-mobile">End Time</div>
              <div className="checked-holder-inner-value-mobile">{extractAndConvertTime(endTime)}</div>
            </div>

          </div>


          <button style={{marginRight:"10px"}} onClick={()=>setExpandCard(!expandCard)} className="worky-shift-details-card-bottom-expand-btn">{expandCard? (<><ExpandLessIcon/></>):(<><ExpandMoreIcon/></>)}</button>
        </div>

      </div>

      <div className={expandCard ? "worky-shift-details-card-bottom-expanded-mobile":"worky-shift-details-card-bottom"}>
        {expandCard ? (
          <div className="worky-shift-details-card-bottom-expanded-inner-mobile">
          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Shift Requester:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{shiftRequester}</div>
          </div>

          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Pay:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">${workerPay}/hr</div>
          </div>

          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Start Time:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{extractAndConvertTime(startTime)}</div>
          </div>

          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Role:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{convertToTitleCase(workerRole)}</div>
          </div>

          {/* <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Clock In Method:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">Portal</div>
          </div> */}

          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Clock-Out:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{extractAndConvertTime(endTime)}</div>
          </div>
      </div>
        ):(null)}
      </div>
    </div>
    )
  }


  return(
    <>{isMobile? <WorkyShiftDetailsCardMobile/>:<WorkyShiftDetailsCardDesktop/>}</>
  )
}

const WorkyShiftDetailsCardUnassigned = ({SRID, startTime, endTime, shiftRequester, workerPay, workerRole }: {SRID:string, startTime:string, endTime:string, shiftRequester:string, workerPay:number, workerRole:string}) => {
  const [expandCard, setExpandCard] = useState(false);
  const isMobile = useIsMobile();


  const WorkyShiftDetailsCardDesktop = () => {
    return(
      <div className={expandCard ? "worky-shift-details-card-expanded":"worky-shift-details-card"}>
      <div className="worky-shift-details-card-top">

        <div className="worky-shift-details-card-top-shift-details-holder">

          <div className="worky-shift-details-card-profile-unassigned">
            <div className="worky-shift-details-card-worker-name"><AutoAwesomeRoundedIcon style={{fontSize:"16px", marginRight:"5px", color:"#2c68bd"}}/> Assigning...</div>
          </div>

          <div className="worky-shift-details-card-worker-checked-holder">
            <div className="worky-shift-details-card-worker-checked-title">Start Time</div>
            <div className="worky-shift-details-card-worker-checked-value">{extractAndConvertTime(startTime)}</div>
          </div>
          <div className="worky-shift-details-card-worker-checked-holder">
            <div className="worky-shift-details-card-worker-checked-title">End Time</div>
            <div className="worky-shift-details-card-worker-checked-value">{extractAndConvertTime(endTime)}</div>
          </div>
        </div>

        <div className="worky-shift-details-card-top-btn-holder">
          <Tooltip style={{fontSize:"18px"}} title="Cancel Shift" placement='top'><button className="worky-shift-details-card-action"><CloseIcon style={{fontSize:"20px"}}/></button></Tooltip>
          <Tooltip style={{fontSize:"18px"}} title="Extend Shift" placement='top'><button className="worky-shift-details-card-action"><MoreTimeRoundedIcon style={{fontSize:"20px"}}/></button></Tooltip>          
        </div>

        <button onClick={()=>setExpandCard(!expandCard)} className="worky-shift-details-card-bottom-expand-btn">{expandCard? (<><ExpandLessIcon/></>):(<><ExpandMoreIcon/></>)}</button>

      </div>

      <div className={expandCard ? "worky-shift-details-card-bottom-expanded":"worky-shift-details-card-bottom"}>
        {expandCard ? (
          <div className="worky-shift-details-card-bottom-expanded-inner">
              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Shift Requester:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{shiftRequester}</div>
              </div>              

              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Pay:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">${workerPay}/hr</div>
              </div>

              <div className="worky-shift-details-card-bottom-expanded-info-holder">
                <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Role:</div>
                <div className="worky-shift-details-card-bottom-expanded-info-value">{convertToTitleCase(workerRole)}</div>
              </div>              

          </div>
        ):(null)}
      </div>
    </div>
    )
  }

  const WorkyShiftDetailsCardMobile = () => {
    return(
      <div className={expandCard ? "worky-shift-details-card-expanded-mobile":"worky-shift-details-card"}>
      <div className="worky-shift-details-card-top">

        <div className="worky-shift-details-card-top-shift-details-holder-mobile">


        <div className="worky-shift-details-card-profile-unassigned-mobile">
            <div className="worky-shift-details-card-worker-name"><AutoAwesomeRoundedIcon style={{fontSize:"16px", marginRight:"5px", color:"#2c68bd"}}/> Assigning...</div>
          </div>


          <div className="worky-shift-details-card-worker-checked-holder-mobile">

            <div className="checked-holder-inner-mobile">
              <div className="checked-holder-inner-title-mobile">Start Time</div>
              <div className="checked-holder-inner-value-mobile">{extractAndConvertTime(startTime)}</div>
            </div>

            <div className="checked-holder-inner-line-mobile"></div>

            <div className="checked-holder-inner-mobile">
              <div className="checked-holder-inner-title-mobile">End Time</div>
              <div className="checked-holder-inner-value-mobile">{extractAndConvertTime(endTime)}</div>
            </div>

          </div>


          <button style={{marginRight:"10px"}} onClick={()=>setExpandCard(!expandCard)} className="worky-shift-details-card-bottom-expand-btn">{expandCard? (<><ExpandLessIcon/></>):(<><ExpandMoreIcon/></>)}</button>
        </div>

      </div>

      <div className={expandCard ? "worky-shift-details-card-bottom-expanded-mobile":"worky-shift-details-card-bottom"}>
        {expandCard ? (
          <div className="worky-shift-details-card-bottom-expanded-inner-mobile">
          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Shift Requester:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{shiftRequester}</div>
          </div>

          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Pay:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">${workerPay}/hr</div>
          </div>


          <div className="worky-shift-details-card-bottom-expanded-info-holder">
            <div className="worky-shift-details-card-bottom-expanded-info-title">Worker Role:</div>
            <div className="worky-shift-details-card-bottom-expanded-info-value">{convertToTitleCase(workerRole)}</div>
          </div>

          
      </div>
        ):(null)}
      </div>
    </div>
    )
  }


  return(
    <>{isMobile? <WorkyShiftDetailsCardMobile/>:<WorkyShiftDetailsCardDesktop/>}</>
  )
}







interface ShiftDetails {
  shiftRequestUnitId: string;
  jobberAssigned: string | null;
  jobberHourlyRate: number;
  requesterFirstName: string;
  startTime: string;
  endTime: string;
  clockInTime: string | null;
  clockOutTime: string | null;
  workerType: string;
};


const WorkyShiftDetailsHolder = ({ isMobile, workDayShift, SRIP }: { isMobile: boolean, workDayShift: string, SRIP: SRIPResponseData[] }) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const unassignedShiftCards: ShiftDetails[] = [];
  const assignedShiftCards: ShiftDetails[] = [];
  const totalShiftCards: ShiftDetails[] = [];

  console.log("workDayShift:", workDayShift);
  console.log("Assigned Shift Cards:", assignedShiftCards);
  console.log("Unassigned Shift Cards:", unassignedShiftCards);

  // Assuming SRIP is an array of shiftGroup objects
  SRIP.forEach((shiftGroup, index) => {
    // console.log(`Processing shift group at index ${index}:`, JSON.stringify(shiftGroup, null, 2));

    // Ensure we are dealing with the right structure
    if (Array.isArray(shiftGroup)) {
      shiftGroup.forEach(group => {
        console.log("workyWorkdayShift in group:", group.workyWorkdayShift);

        // Directly accessing the workyWorkdayShift at the top level of each group
        if (group.workyWorkdayShift) {
          console.log("Extracted workyWorkdayShift: ", group.workyWorkdayShift);

          // Check if it matches workDayShift
          if (group.workyWorkdayShift === workDayShift) {
            console.log("Match found for workyWorkdayShift:", group.workyWorkdayShift);

            group.inProgressShiftRequests.forEach(shiftRequest => {
              const shiftDetails: ShiftDetails = {
                shiftRequestUnitId: shiftRequest.shiftRequestUnitId,
                jobberAssigned: shiftRequest.assignedJobberUserDetails?.firstName || null,
                jobberHourlyRate: shiftRequest.jobberHourlyRate,
                requesterFirstName: shiftRequest.requestingUserDetails?.userDetails?.firstName + " "+ shiftRequest.requestingUserDetails?.userDetails?.lastName,
                startTime: shiftRequest.startDateTime,
                endTime: shiftRequest.endDateTime,
                clockInTime: shiftRequest.startDateTime || null,
                clockOutTime: shiftRequest.endDateTime || null,
                workerType: shiftRequest.workerType
              };

              if (shiftDetails.jobberAssigned) {
                assignedShiftCards.push(shiftDetails);
              } else {
                unassignedShiftCards.push(shiftDetails);
              }
            });
          } else {
            console.log("No match for this shift group");
          }
        } else {
          console.log("workyWorkdayShift is undefined in this group");
        }
      });
    } else {
      console.log("ShiftGroup is not an array:", shiftGroup);
    }
  });


  // Determine which shift cards to show based on selected filter
  let filteredShiftCards: ShiftDetails[] = [];
  switch (selectedFilter) {
    case 'assigned':
      filteredShiftCards = assignedShiftCards;
      break;
    case 'unassigned':
      filteredShiftCards = unassignedShiftCards;
      break;
    case 'all':
    default:
      filteredShiftCards = [...assignedShiftCards, ...unassignedShiftCards];
      break;
  }

  const WorkyShiftDetailsCardBuilder = ({ shiftDetails }: { shiftDetails: ShiftDetails }) => {
    // Calculate the conditions for each case
    const isToday = new Date(shiftDetails.startTime).toDateString() === new Date().toDateString();
    const isFuture = new Date(shiftDetails.startTime) > new Date();
  
    // Unassigned shift
    if (!shiftDetails.jobberAssigned) {
      return (
        <WorkyShiftDetailsCardUnassigned
          SRID={shiftDetails.shiftRequestUnitId}
          startTime={shiftDetails.startTime}
          endTime={shiftDetails.endTime}
          shiftRequester={shiftDetails.requesterFirstName}
          workerPay={shiftDetails.jobberHourlyRate}
          workerRole={shiftDetails.workerType}
        />
      );
    }
  
    // Assigned shift for today
    if (isToday) {
      return (
        <WorkyShiftDetailsCardClocked
          SRID={shiftDetails.shiftRequestUnitId}
          startTime={shiftDetails.startTime}
          endTime={shiftDetails.endTime}
          shiftRequester={shiftDetails.requesterFirstName}
          workerPay={shiftDetails.jobberHourlyRate}
          workerRole={shiftDetails.workerType}
          clockInTime={shiftDetails.startTime}
          clockOutTime={shiftDetails.endTime}
          assignedJobberName={shiftDetails?.jobberAssigned}
          assignedJobberEmail={"asdf"}
          assignedJobberIcon={"asdf"}
        />       
      );
    }
  
    // Assigned shift for future
    if (isFuture) {
      return (
        <WorkyShiftDetailsCardClocked
            SRID={shiftDetails.shiftRequestUnitId}
            startTime={shiftDetails.startTime}
            endTime={shiftDetails.endTime}
            shiftRequester={shiftDetails.requesterFirstName}
            workerPay={shiftDetails.jobberHourlyRate}
            workerRole={shiftDetails.workerType}
            clockInTime={shiftDetails.startTime}
            clockOutTime={shiftDetails.endTime}
            assignedJobberName={shiftDetails?.jobberAssigned}
            assignedJobberEmail={"asdf"}
            assignedJobberIcon={"asdf"}
        />
      );
    }
  
    // Default case (should never reach here if all conditions are covered)
    return (
      <WorkyShiftDetailsCardUnassigned
          SRID={shiftDetails.shiftRequestUnitId}
          startTime={shiftDetails.startTime}
          endTime={shiftDetails.endTime}
          shiftRequester={shiftDetails.requesterFirstName}
          workerPay={shiftDetails.jobberHourlyRate}
          workerRole={shiftDetails.workerType}
      />
    );
  };

  return (
    <div className={isMobile ? "worky-shift-details-v2-shift-holder-mobile" : "worky-shift-details-v2-main"}>
      <div className={isMobile ? "worky-shift-details-v2-shift-holder-title-mobile" : "worky-shift-details-v2-main-left-title-holder"}>
        <div className={isMobile ? "worky-shift-details-v2-main-left-title-mobile" : "worky-shift-details-v2-main-left-title"}>My Shifts</div>
        <select
          className="worky-shift-details-v2-main-left-filter"
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
        >
          <option value="all">All Shifts</option>
          <option value="assigned">Assigned</option>
          <option value="unassigned">Unassigned</option>
        </select>
      </div>

      <div className={isMobile ? "worky-shift-details-v2-shift-container-mobile" : "worky-shift-details-v2-main-card-holder"}>
        {filteredShiftCards.map((shiftDetails, index) => (
          <WorkyShiftDetailsCardBuilder key={index} shiftDetails={shiftDetails} />
        ))}
      </div>
    </div>
  );
};


const WorkyShiftDetailsModalV2 = (
  {modalOpen, handleCloseShiftModalV2, numShifts, shiftDate, shiftTime, workDayShift, SRIPResp}
  :
  {modalOpen:boolean, handleCloseShiftModalV2: () => void, numShifts:string, shiftDate:string, shiftTime:string, workDayShift:string, SRIPResp:SRIPResponseData[]}) => {
  
  const isMobile = useIsMobile();  


  return (
    <Modal open={modalOpen} onClose={handleCloseShiftModalV2}>
      <div className={isMobile ? "worky-shift-details-v2-mobile" : "worky-shift-details-v2"}>
        <div className={isMobile ? "worky-shift-details-v2-header-holder-mobile" : "worky-shift-details-v2-header-holder"}>
          <div className={isMobile ? "worky-shift-details-v2-header-title-mobile" : "worky-shift-details-v2-header-title"}>Shift Details</div>
          <button onClick={handleCloseShiftModalV2} className={isMobile ? "worky-shift-details-v2-header-close-mobile" : "worky-shift-details-v2-header-close"}>
            <CloseIcon style={{ fontSize: "38px" }} />
          </button>
        </div>

        <div className={isMobile ? "worky-shift-details-v2-header-info-holder-mobile" : "worky-shift-details-v2-header-info-holder"}>
          <div className={isMobile ? "worky-shift-details-v2-header-info-mobile" : "worky-shift-details-v2-header-info"}>{modalShiftDateConverter(shiftDate)}</div>
          <div className={isMobile ? "worky-shift-details-v2-header-info-mobile" : "worky-shift-details-v2-header-info"}>{shiftTime}</div>
          <div className={isMobile ? "worky-shift-details-v2-header-info-mobile" : "worky-shift-details-v2-header-info"}>{numShifts} Shifts</div>
        </div>

        <WorkyShiftDetailsHolder isMobile={isMobile} workDayShift={workDayShift} SRIP={SRIPResp}/>


      </div>
    </Modal>
  );

}


const SRtoEventBuilder = (SRIP) => {
  // Define an empty array to hold the events
  const events = [];

  function eventTitleBuilder(start,end){
    const startTimeStr = start.substring(11,16)
    const endTimeStr = end.substring(11,16)
    
    const formatTime = (timeStr) => {
      const [hour, minute] = timeStr.split(':');
      const date = new Date();
      date.setHours(hour, minute);
      return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
    };
  
    // Format start and end times
    const formattedStart = formatTime(startTimeStr);
    const formattedEnd = formatTime(endTimeStr);
  
    return `${formattedStart} - ${formattedEnd}`;
  }

  // Check if SRIP is an array and not null
  if (!Array.isArray(SRIP) || SRIP.length === 0) {
    console.warn("SRIP is not an array or is empty:", SRIP);
    return events; // Return empty events array if SRIP is not an array or is empty
  }

  // Iterate over the main array
  SRIP.forEach((shiftGroup, index) => {
    // console.log(`Processing shift group at index ${index}:`, JSON.stringify(shiftGroup, null, 2));

    if (!Array.isArray(shiftGroup)) {
      console.warn("shiftGroup is not an array:", shiftGroup);
      return; // Continue to the next shiftGroup if this is not an array
    }

    shiftGroup.forEach((group) => {
      if (group == null) {
        console.warn("Group is null or undefined:", group);
        return; // Skip this group if it is null or undefined
      }

      if (group.workyWorkdayShift) {
        // Split the workyWorkdayShift into start and end times
        const [date, timeRange] = group.workyWorkdayShift.split("T");
        const [startTime, endTime] = timeRange.split("_");

        // Build start and end datetime strings with timezone (UTC-5)
        const startDateTimeStr = `${date}T${startTime}:00-05:00`;
        const endDateTimeStr = `${date}T${endTime}:00-05:00`;

        // Process inProgressShiftRequests if it exists and is an array
        if (Array.isArray(group.inProgressShiftRequests)) {
          group.inProgressShiftRequests.forEach((shiftRequest) => {
            const event = {
              title: eventTitleBuilder(startDateTimeStr,endDateTimeStr),
              start: new Date(startDateTimeStr),
              end: new Date(endDateTimeStr),
            };
            events.push(event);
          });
        } else {
          console.log("inProgressShiftRequests is not an array or is undefined:", group.inProgressShiftRequests);
        }

        // Process assignedShiftRequests if it exists and is an array
        if (Array.isArray(group.assignedShiftRequests)) {
          group.assignedShiftRequests.forEach((shiftRequest) => {
            const event = {
              title: `${shiftRequest?.startDateTime} - ${shiftRequest?.endDateTime}`,
              start: new Date(shiftRequest?.startDateTime),
              end: new Date(shiftRequest?.endDateTime),
            };
            events.push(event);
          });
        } else {
          console.log("assignedShiftRequests is not an array or is undefined:", group.assignedShiftRequests);
        }
      } else {
        console.log("workyWorkdayShift is undefined in this group");
      }
    });
  });

  console.log("events : ", JSON.stringify(events, null, 2));
  return events;
};


function eventTitleBuilder(workyWorkdayShift, orgName) {
  try {
      // Split the shift time into start and end times
      const [startTime, endTime] = workyWorkdayShift.split('_');
      
      console.log("startTime " + startTime);
      console.log("endTime " + endTime);
      
      // Prepend the date from the start time to the end time
      const [startDatePart] = startTime.split('T');
      const fullEndTime = `${startDatePart}T${endTime}`;

      // Create Date objects for start and end times
      const startDate = new Date(startTime);
      const endDate = new Date(fullEndTime);
      
      // Check if the dates are valid
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
          return 'Invalid Time Format';
      }

      // Function to format a Date object into 12-hour AM/PM format
      const formatTime = (date: Date): string => {
          const hour = date.getHours();
          const minute = date.getMinutes();
          const isPM = hour >= 12;
          const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
          const formattedMinute = minute === 0 ? '' : `:${minute < 10 ? '0' + minute : minute}`;
          const period = isPM ? 'PM' : 'AM';

          return `${formattedHour}${formattedMinute}${period}`;
      };

      // Format the start and end times
      const formattedStartTime = formatTime(startDate);
      const formattedEndTime = formatTime(endDate);
      
      // Return the formatted string
      return `${orgName} - ${formattedStartTime}-${formattedEndTime}`;
  } catch (error) {
      return 'Error formatting shift time';
  }
};

const formatEventDate = (inputString: string) => {
  console.log("Input String for Parsing:", inputString);

  // Match the time format HH:MM_HH:MM (i.e., 09:00_17:00)
  const timeRegex = /(\d{4}-\d{2}-\d{2})T(\d{1,2}):(\d{2})_(\d{1,2}):(\d{2})/;

  const match = inputString.match(timeRegex);

  if (!match) {
    console.log("String is in an unexpected format:", inputString);
    return null; // Return null if the string doesn't match
  }

  // Destructure the matched parts of the time
  const [, date, startHour, startMinute, endHour, endMinute] = match;

  // Ensure the hours and minutes are formatted with leading zeroes if necessary
  const formatTime = (hour: string, minute: string) => {
    return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  };

  // Format start and end times
  const startFormatted = formatTime(startHour, startMinute);
  const endFormatted = formatTime(endHour, endMinute);

  console.log(`Formatted Start: ${startFormatted}, Formatted End: ${endFormatted}`);

  // Construct the full ISO string for the start and end times
  const formattedStart = `${date}T${startFormatted}:00.000Z`;
  const formattedEnd = `${date}T${endFormatted}:00.000Z`;

  // Validate the parsed dates
  const startDate = new Date(formattedStart);
  const endDate = new Date(formattedEnd);

  console.log("Start Date:", startDate);
  console.log("End Date:", endDate);

  // Check if the dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    console.log("Invalid date formatting for shift:", inputString);
    return null;
  }

  // Convert EST to UTC (add 5 hours)
  const convertToUTC = (date: Date) => {
    // Adjust for EST to UTC conversion (UTC - 5)
    const offset = 5 * 60; // EST offset in minutes (for non-DST)
    date.setMinutes(date.getMinutes() + offset);
    return date;
  };

  const startInUTC = convertToUTC(new Date(startDate));
  const endInUTC = convertToUTC(new Date(endDate));

  console.log("Start in UTC:", startInUTC);
  console.log("End in UTC:", endInUTC);

  // Return the valid dates as Date objects in UTC
  return { start: startInUTC, end: endInUTC };
};

const aggregatedSRIPtoCalendarEventBuilder = (aggregatedShifts: any[]) => {
  const events = aggregatedShifts.map(shift => {
    const dateInfo = formatEventDate(shift.workyWorkdayShift);

    // Skip the shift if dateInfo is null
    if (!dateInfo) {
      console.error("Invalid date formatting for shift:", shift.workyWorkdayShift);
      return null; // Skip this shift
    }

    const { start, end } = dateInfo; // Destructure only if dateInfo is valid

    return {
      title: eventTitleBuilder(
        shift.workyWorkdayShift,
        shift.inProgressShiftRequests[0]?.orgName ?? shift.assignedShiftRequests[0]?.orgName
      ),
      start: start, // Use the start date as a valid Date object
      end: end, // Use the end date as a valid Date object
      titleForModal: shift.workyWorkdayShift,
    };
  }).filter(event => event !== null); // Remove any null events

  console.log("Parsed Events: ", events);
  return events;
};

export {
    SRModelDetailsComponent,
    SRStatusComponentCarosul,
    WorkyAppHeader,
    WorkyTopShelf,
    WorkyContactButton,
    WorkyFooter,
    WorkyShiftDetailsModalV2,
    SRtoEventBuilder,
    aggregatedSRIPtoCalendarEventBuilder
  }