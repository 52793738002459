import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { SplashPage } from "./splashComponent/SplashPage";
import { Operations } from "./holder/Operations";
import jobberIntake from "./holder/jobberIntake";
import BasicInfoPage from "./jobberViews/BasicInfoPage";
import { AuthGetCall } from "./remoteExecution/WorkyJobberSignIn";
import { WorkyHomePage } from "./jobberViews/WorkyHomePage";
import { ShiftHistory } from "./orgViews/ShiftHistory";
import { CurrentShifts } from "./orgViews/CurrentShifts";
import { JobberHomePage } from "./jobberViews/JobberHomePage";
import { JobberAccountSettings } from "./jobberViews/JobberAccountSettings";
import { JobberPreviousShifts } from "./jobberViews/JobberPreviousShifts";
import { OrgAccountSettings } from "./orgViews/OrgAccountSettings";
import { JobberImportantDocs } from "./jobberViews/JobberImportantDocs";
import { CareersPage } from "./splashComponent/CareersPage";
import { ProductsPage } from "./splashComponent/ProductsPage";
import { ResourcePage } from "./splashComponent/ResourcePage";
import { NewShiftV4 } from "./sr-ingress/NewShiftV4";
import { MyAgencyWorkforce } from "./agency-logic/agency-pages/MyAgencyWorkforce";
import { MyShifts } from "./orgViews/MyShifts";
import { PaymentsPage } from "./orgViews/PaymentsPage";
import { WorkyPayAgencyPage } from "./agency-logic/agency-pages/WorkyPayAgencyPage.tsx";
import { WorkyTimeClockAgencyPage } from "./agency-logic/agency-pages/WorkyTimeClockAgencyPage.tsx";
import { withLoadingAndErrorHandling } from "./custom-hooks/LoadingPlusErrorCheck";
import { AgencyOrgBillingPage } from "./agency-logic/agency-pages/AgencyOrgBillingPage.tsx";
import { WorkyPayJobberPage } from "./jobberViews/pages/WorkyPayJobberPage.tsx";
import { TestingExperimentPage } from "./testing-experiments/TestingExperimentPage";
import { WorkyPay } from "./orgViews/WorkyPay";
import { TimeClock } from "./orgViews/TimeClock";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryContextProvider } from "./shared-components/ErrorContextProvider.tsx";
import { GlobalErrorPage } from "./shared-components/GlobalErrorPage.tsx";
import {saveWorkySessionIdToLocalStorage} from './common/CommonUtilsMethods.tsx'
import {TrackSalesEvent} from './shared-engineering-services/SharedAPICalls.tsx'
import {WorkyAgencySales} from "./agency-logic/agency-pages/WorkyAgencySales.tsx";

function App() {
  useEffect(() => {
    const sessionId = saveWorkySessionIdToLocalStorage();
    console.log("App initialized with session ID:", sessionId);

    try{
      TrackSalesEvent({eventName:"APP_LOAD"} )
    }catch(e){
      console.log("error on APP_LOAD", e)
    }

  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <Router>
      <Switch>
        <ErrorBoundary FallbackComponent={GlobalErrorPage} onError={() => console.log("Parent error!")}>
          <ErrorBoundaryContextProvider>
            <ErrorBoundary FallbackComponent={GlobalErrorPage} onError={() => console.log("Child Error happened!")}>
              <Route path="/testing-experiments" component={TestingExperimentPage} />
              <Route path="/homepage" component={WorkyHomePage} />              
              <Route path="/new-shift" component={NewShiftV4} />
              <Route path="/shift-history" component={ShiftHistory} />
              <Route path="/current-shifts" component={CurrentShifts} />
              <Route path="/my-shifts" component={MyShifts} />
              <Route path="/account" component={OrgAccountSettings} />
              <Route path="/payments" component={PaymentsPage} />

              <Route path="/agency/workforce" component={MyAgencyWorkforce} />
              <Route path="/agency/worky-pay" component={WorkyPayAgencyPage} />
              <Route path="/agency/worky-time-clock" component={WorkyTimeClockAgencyPage} />
              <Route path="/agency/client-billing" component={AgencyOrgBillingPage} />
              <Route path="/agency/leads" component={WorkyAgencySales} />

              <Route path="/jobber/worky-pay" component={WorkyPayJobberPage} />

              {/* <Route path="/time-clock" component={TimeClock} /> */}
              <Route path="/worky-pay" component={WorkyPay} />

              <Route path="/jobber-homepage" component={JobberHomePage} />
              <Route path="/jobber-previous-shifts" component={JobberPreviousShifts} />
              <Route path="/jobber-account-settings" component={JobberAccountSettings} />
              <Route path="/jobber-important-docs" component={JobberImportantDocs} />

              <Route path="/opex" component={Operations} />
              <Route path="/jobber-onboarding" component={BasicInfoPage} />
              <Route path="/testAuth" component={AuthGetCall} />

              <Route path="/splashpage" component={SplashPage} />
              <Route path="/careers" component={CareersPage} />
              <Route path="/products" component={ProductsPage} />
              <Route path="/resources" component={ResourcePage} />              
               <Route exact path="/" component={SplashPage} />
              {/* <Redirect to="/" />  */}
            </ErrorBoundary>
          </ErrorBoundaryContextProvider>
        </ErrorBoundary>
      </Switch>
    </Router>
  );
}

export default App;
