import React, { useCallback, useState, useEffect, useRef } from 'react';
import { WorkyContactButton, WorkyFooter, WorkyTopShelf, WorkyAppHeader, } from '../agency-logic/agency-components/AgencyComponentSetOne';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import { BillingData } from './OrgUtils';
import { StyleLinks } from './OrgCommonComponents';
import { PaymentsTable } from './PaymentsTable';
import { PaymentsCards } from './PaymentsCards';

import './paymentspage.css';



const PageFooter = () => {return(<div className='worky-footer-payments'>worky</div>)}
const PageHeader = ({isMobile}:{isMobile:boolean}) => {
    
    return(
            <div className={isMobile?'payments-header-holder-mobile':'payments-header-holder'}>
                <div className='payments-header'>
                    <div className='payments-header-title-holder'>
                        <button id="payments-header-active" className='payments-header-title-switch'>Recievables</button>
                    </div>
                    <div className='payments-header-subtitle'>Manage your Inbound Bills and Payment Details</div>
                </div>
            </div>
    )
}
// const PaymentsMainComp = ({isReceivables, paymentsData, isMobile}:{isReceivables:boolean, paymentsData:BillingData, isMobile:boolean}) => {
//     console.log(JSON.stringify(paymentsData));

//     const ReceivablesPage = () => {
//         return(
//             <div className='payments-main-comp-inner'>
//                 <div className={isMobile ? 'receivables-top-card-holder-mobile':'receivables-top-card-holder'}>
//                     <div className={isMobile ? 'receivables-top-card-mobile':'receivables-top-card'}></div>
//                     <div className={isMobile ? 'receivables-top-card-mobile':'receivables-top-card'}></div>
//                 </div>
//                 <div className={isMobile ? 'receivables-main-table-holder-mobile':'receivables-main-table-holder'}>
//                     <PaymentsTable paymentsData={paymentsData}/>
//                 </div>
//             </div>
//         )
//     }

//     const PayablesPage = () => {
//         return(
//             <div className='payments-main-comp-inner'>

//                 Payables
//             </div>
//         )
//     }
    
//     return(
//         <div className='payments-main-comp-holder'>
//             {isReceivables?(<ReceivablesPage/>):(<PayablesPage/>)}
//         </div>
//     )
// }

const ReceivablesPage = ({paymentsData, isMobile}:{paymentsData:BillingData, isMobile:boolean}) => {
    return(
        <div className='payments-main-comp-inner'>
            <div className={isMobile ? 'receivables-top-card-holder-mobile':'receivables-top-card-holder'}>
                <div className={isMobile ? 'receivables-top-card-mobile':'receivables-top-card'}>
                    <PaymentsCards isMobile={isMobile} paymentsData={paymentsData}/>
                </div>
            </div>
            <div className='agency-section-title'>My Invoices</div>
            <div className={isMobile ? 'receivables-main-table-holder-mobile':'receivables-main-table-holder'}>
                <PaymentsTable paymentsData={paymentsData}/>
            </div>
        </div>
    )
}



const PaymentsPage = () => {
    const [isReceivables, setIsReceivables] = useState(true);
    const isMobile = useIsMobile();
    
    const [orgBillingData, setOrgBillingData] = useState<BillingData | null>(null);

    useEffect(() => {
        const fetchOrgBillingData = async () => {
            try {
                // const billingData = await GetOrgBillsAPICall();
                // setOrgBillingData(billingData);
                // console.log("orgBillingData: " + JSON.stringify(billingData));
            } catch (error) {
                console.error("Error fetching org billing data", error);
            }
        };

        fetchOrgBillingData();
    }, []);



     
    const sampleBillingData: BillingData = {
        billingCyclePartitionKey: "2024-08-01_2024-08-15",
        agencyName: "ShiftWork",
        orgNameSortKey: "FoodOntario",
        totalShiftCount: 2,
        totalWorkedHoursCount: 15.00,
        originalTotalAmountOwed: 377.68,
        currentOutstandingTotalAmountOwed: 377.68,
        shiftBillingDetailsMap: {
            "2024-08-07": {
                "2d880b1e-a080-4c16-9292-1ee848199d35": {
                    shiftId: "2d880b1e-a080-4c16-9292-1ee848199d35",
                    jobberDetails: {
                        userEmail: { value: "mayyarsecond+pinpointShiftWork@gmail.com" },
                        userPhoneNumber: { value: "+19056719022" },
                        lastName: "Kon",
                        firstName: "Jim"
                    },
                    startDateTimeEpochSec: 1723032000,
                    endDateTimeEpochSec: 1723064400,
                    workyBillPaymentBreakdown: {
                        workyHourlyMarginRate: 0.27,
                        jobberHourlyRate: 17.50,
                        orgHourlyRate: 21.75,
                        totalTimeWorked: 7.5,
                        totalHourlyCost: 163.125,
                        taxRate: 0.13,
                        taxAmount: 21.21,
                        wsipAmount: 4.5,
                        totalCost: 188.84,
                        originalTotalCost: 188.84,
                        currentOutstandingTotalCost: 188.84
                    }
                }
            },
            "2024-08-08": {
                "f8a03bb6-59e6-4c4b-8d20-74268bdc77d8": {
                    shiftId: "f8a03bb6-59e6-4c4b-8d20-74268bdc77d8",
                    jobberDetails: {
                        userEmail: { value: "mayyarsecond+pinpointShiftWork@gmail.com" },
                        userPhoneNumber: { value: "+19056719022" },
                        lastName: "Kon",
                        firstName: "Jim"
                    },
                    startDateTimeEpochSec: 1723118400,
                    endDateTimeEpochSec: 1723150800,
                    workyBillPaymentBreakdown: {
                        workyHourlyMarginRate: 0.27,
                        jobberHourlyRate: 17.50,
                        orgHourlyRate: 21.75,
                        totalTimeWorked: 7.5,
                        totalHourlyCost: 163.125,
                        taxRate: 0.13,
                        taxAmount: 21.21,
                        wsipAmount: 4.5,
                        totalCost: 188.84,
                        originalTotalCost: 188.84,
                        currentOutstandingTotalCost: 188.84
                    }
                }
            }
        }
    };
     
     
    return(
    <>  
        <StyleLinks/>
        <WorkyAppHeader/>
        <PageHeader isMobile={isMobile}/>
        <ReceivablesPage paymentsData={sampleBillingData} isMobile={isMobile}/>
        {/* <PageFooter/> */}
        <WorkyContactButton/>
    </>
    )
}


export {PaymentsPage}