import React, { useCallback, useState, useEffect, useRef } from "react";
import { TimeClockDataRespDTO } from "../agency-data/AgencyInterfaces";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { OnTimeTagBuilder, OnTimeStatusBuilder, CheckInTypeTagBuilder, formatDate } from "../agency-pages/AgencyComponents";
import { epochToTimeString, epochToDateString, epochToDateStringWithDay } from "../../jobberViews/pages/WorkyPayJobberPage";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useIsMobile } from "../../custom-hooks/useIsMobile";
import { withLoadingAndErrorHandling } from "../../custom-hooks/LoadingPlusErrorCheck";
import { CorpType } from "../../common/CommonInterfaces";
import { CheckInOutType } from "../agency-data/AgencyInterfaces";
import { JobberStatus } from "../../common/CommonInterfaces";
import { UserType } from "../../common/CommonInterfaces";
// CheckInOutType
const AgencyHomeTimeclock = ({isMobile,clockData,}: {isMobile: boolean;clockData: TimeClockDataRespDTO;}) => {
  
  // const sampleTimeClockData: TimeClockDataRespDTO = {
  //   timeClockOrgDetails: {
  //     iconImageS3Key: "s3://some-bucket/org-logo.png",
  //     corpJobberDetails: {
  //       corpType: CorpType.AGENCY,
  //       corpName: "Elite Staffing Agency",
  //     },
  //   },
  //   timeClockOrgDataRespDTO: [
  //     {
  //       shiftStartDate: "2025-02-09T00:00:00Z",
  //       shiftsForOrgOnThisDay: [
  //         {
  //           shiftStartDate: "2025-02-09T08:00:00Z",
  //           orgName: "Elite Staffing Agency",
  //           timeClockByOrgByDateByLocations: [
  //             {
  //               orgName: "Elite Staffing Agency",
  //               shiftStartDateTime: "2025-02-09T08:00:00Z",
  //               jobberCheckInDataByLocation: [
  //                 {
  //                   shiftStartDate: "2025-02-09T08:00:00Z",
  //                   orgName: "Elite Staffing Agency",
  //                   shiftId: "SHIFT12345",
  //                   jobberUserDetails: {
  //                     userDetails: {
  //                       firstName: "John",
  //                       lastName: "Doe",
  //                       userEmail:{value: "john.doe@example.com"},
  //                       userPhoneNumber: {value: "+1234567890"},
  //                     },
  //                     userType: UserType.AGENCY_STAFF,
  //                     jobberStatus: JobberStatus.FULLY_HIRED,
  //                     profilePicUrl: "s3://some-bucket/john-doe.jpg",
  //                     corpJobberDetails: {
  //                       corpType: CorpType.AGENCY,
  //                       corpName: "Elite Staffing Agency",
  //                     },
  //                   },
  //                   shiftDetailsClockInOutDTO: {
  //                     plannedShiftStartDateTimeEpochSec: 1707465600,
  //                     plannedShiftEndDateTimeEpochSec: 1707487200,
  //                   },
  //                   checkInData: {
  //                     checkInOutType: CheckInOutType.GEO_LOCATION,
  //                     isCheckInOutInvalid: false,
  //                     checkInOutDateTimeEpochSec: 1707465900,
  //                   },
  //                   checkOutData: {
  //                     checkInOutType: CheckInOutType.GEO_LOCATION,
  //                     isCheckInOutInvalid: false,
  //                     checkInOutDateTimeEpochSec: 1707487200,
  //                   },
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // };  

  // clockData = sampleTimeClockData;
  
  const innerIsMobile = useIsMobile();
    const buildClockCards = (clockData: TimeClockDataRespDTO) => {
      const cards = [];
      if (!clockData || !clockData.timeClockOrgDataRespDTO) {return cards;}
  
      clockData.timeClockOrgDataRespDTO.forEach((dayData) => {
        dayData.shiftsForOrgOnThisDay.forEach((shiftData) => {
          shiftData.timeClockByOrgByDateByLocations.forEach((locationData) => {
            locationData.jobberCheckInDataByLocation.forEach((jobberData) => {
              const jobberDetails = jobberData.jobberUserDetails.userDetails;
              const checkInData = jobberData.checkInData;
              const isClockIn = !!checkInData;
  
              cards.push({
                id: jobberData.shiftId,
                jobberName: `${jobberDetails.firstName} ${jobberDetails.lastName}`,
                shiftTime: isClockIn
                  ? jobberData.shiftDetailsClockInOutDTO.plannedShiftStartDateTimeEpochSec
                  : jobberData.shiftDetailsClockInOutDTO.plannedShiftEndDateTimeEpochSec,
                clockTime: isClockIn
                  ? checkInData?.checkInOutDateTimeEpochSec
                  : jobberData.checkOutData?.checkInOutDateTimeEpochSec,
                isClockIn,
              });
            });
          });
        });
      });
  
      return cards;
    };
  
    const clockCards = buildClockCards(clockData);
  
    return (
      <>
        {clockData.timeClockOrgDataRespDTO.length > 0 ?
        (<div className={isMobile? "agency-home-clock-container-mobile": "agency-home-clock-container"}>
          <div className={innerIsMobile? "worky-topshelf-card-title-mobile": "worky-top-shelf-side-title"}>Time Clock</div>
          <div className={isMobile? "agency-home-clock-holder-mobile": "agency-home-clock-holder"}>
              {clockCards.map((card) => (
                  <ClockCard key={card.id} jobberName={card.jobberName} shiftTime={card.shiftTime} clockTime={card.clockTime} isClockIn={card.isClockIn} />
              ))}
          </div>
        </div>)
        :
        (null)
        }
      </>
    );
  };
  
  const ClockCard = ({jobberName,shiftTime,clockTime,isClockIn}: {jobberName: string;shiftTime: number;clockTime: number;isClockIn: boolean;}) => {
    
    const pushToTimeclock = () => {window.location.assign("/agency/worky-time-clock");};
    console.log(shiftTime)
  
    return (
      <div className="clock-card">

        <OnTimeStatusBuilder shiftTime={shiftTime} checkTime={clockTime} />

        <div className="clock-card-inner">
          <div className="clock-card-inner-top">
            <div className="clock-card-inner-top-left">
              <div className="clock-card-inner-top-title-holder">
                <div className="clock-card-inner-top-title-checktype">
                  {isClockIn ? "Clock-In" : "Clock-Out"}
                </div>
                <div className="clock-card-inner-top-title-checkdate">
                  {epochToDateStringWithDay(shiftTime)}
                </div>
              </div>
            </div>
            <OnTimeTagBuilder shiftTime={shiftTime} checkTime={clockTime} />
          </div>
  
          {/* <div className="clock-card-inner-middle">

            <div className="clock-card-inner-middle-time-holder">
              <div className="clock-card-inner-middle-time-title">{isClockIn ? "Start Time" : "End Time"}</div>
              <div className="clock-card-inner-middle-time-value">{epochToTimeString(shiftTime)}</div>
            </div>
  
            <div className="clock-card-inner-middle-time-holder">
              <div className="clock-card-inner-middle-time-title">{isClockIn ? "Clock In" : "Clock Out"}</div>
              <div className="clock-card-inner-middle-time-value">{epochToTimeString(clockTime)}</div>
            </div>

          </div> */}
  
          <div className="clock-card-inner-bottom">
            <div className="clock-card-inner-bottom-left">
              <div className="clock-card-inner-bottom-left-icon"><AccountCircleRoundedIcon sx={{ marginLeft: "", fontSize: "15px" }}/></div>
              <div className="clock-card-inner-bottom-left-address">{jobberName}</div>
            </div>

            <button onClick={pushToTimeclock} className="clock-card-expand-inner"><ChevronRightRoundedIcon sx={{ marginLeft: "2px", fontSize: "18px" }}/></button>
          </div>
        </div>
      </div>
    );
  };
  
  export { AgencyHomeTimeclock };