import React, { useState, useEffect, useRef } from "react";
import {  AgencyObjClient } from "../agency-data/AgencyInterfaces";
import { GetAgencyWTokenOnly, GetActiveSATimeClock } from "../agency-api-calls/AgencyAPI";
import './agencyhomepage.css';
import { GetCorpSRIP, SRIPResponseData } from "../../common/ShiftReadAPIs";
import { useIsMobile } from "../../custom-hooks/useIsMobile";
import { StyleLinks } from "../../orgViews/OrgCommonComponents";
import { WorkyContactButton,WorkyTopShelf, WorkyAppHeader, } from "../agency-components/AgencyComponentSetOne";
import { GetCorpDetails } from "../../shared-engineering-services/SharedAPICalls";
import { AgencyHomeTimeclock } from "../agency-components/AgencyHomeTimeclock";
import { TimeClockDataRespDTO } from "../agency-data/AgencyInterfaces";
import { WorkyAgencyCarouselV2 } from "../agency-components/WorkyAgencyCarouselV2";



const AgencyHomePage = () => {
  const [agencyData, setAgencyData] = useState<AgencyObjClient | null>(null);
  const [orgSRIPResponse, setOrgSRIPResponse] = useState<SRIPResponseData[] | null>(null);
  const [shiftEvents, setShiftEvents] = useState([]);
  const isMobile = useIsMobile();
  const [timeClockDataRespDTO, setTimeClockDataRespDTO] = useState<TimeClockDataRespDTO | null>(null);






  

  useEffect(() => {
    // get the agency data
    const fetchAgencyData = async () => {
      // Assuming GetAgencyWTokenOnly is an async function
      const agencySourceData = await GetAgencyWTokenOnly({});
      const getcorpdata = await GetCorpDetails();
      setAgencyData(agencySourceData);
    };

    const fetchSRIPData = async () => {
      const SRIPData = await GetCorpSRIP({ setOrgSRIPResponse, setShiftEvents });
      // console.log("TESTING RETURN SRIP DATA HOMEPAGE  "+JSON.stringify(SRIPData));
    };

    const fetchTimeClockDataRespDTO = async () => {
        const timeClockAPIDataResp = await GetActiveSATimeClock();
        setTimeClockDataRespDTO(timeClockAPIDataResp);
    };
    
    
    fetchTimeClockDataRespDTO();
    fetchAgencyData();
    fetchSRIPData();
  }, []);  



  return (
    <div className={isMobile?"worky-app-mobile":"worky-app"}>
      
      <StyleLinks/>
      <WorkyAppHeader/>

      {isMobile ? null : (timeClockDataRespDTO ? (<div style={{ paddingTop: "20px" }}><AgencyHomeTimeclock isMobile={true} clockData={timeClockDataRespDTO} /></div>) : null)}
      
      <WorkyTopShelf/>

      {isMobile ?  (timeClockDataRespDTO ? (<div style={{ marginTop: "-15px" }}><AgencyHomeTimeclock isMobile={true} clockData={timeClockDataRespDTO} /></div>) : null):null} 

      {orgSRIPResponse && orgSRIPResponse.length > 0 ? (
          <WorkyAgencyCarouselV2
            SRIP={orgSRIPResponse}
            isMobile={isMobile}
          />
      ): null}

        <WorkyContactButton/>

    </div>
  );
};

export { AgencyHomePage };
