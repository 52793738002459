import { AxiosResponse } from "axios";
import { OrgMetaData } from "../../orgViews/orgConstants";
import { RemoteExecutionServiceImpl, RemoteGETCallInputObject,
   ServerResponse, RemotePOSTCallInputObject } from "../../remoteExecution/RemoteExecutionServiceImpl";
import { ShiftRequestAssignmentReqObjWJobber, 
  AgencyObjClient, CurrentAgencyPayStubsRespDTO, AgencyOrgBillingCycleSummaryDTO, TimeClockDataRespDTO,
  DownloadOrgBillReqDTO, DownloadOrgBillRespDTO,
  DownloadJobberPayStubAgencyReqDTO, DownloadJobberPayStubAgencyResDTO,JobberFullProfileDetails, 
  JobberAvalTimeWindows,
  DirectShiftAssignmentRespDTO,
  AgencySalesLeadDTO
 } from "../agency-data/AgencyInterfaces";
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();
const GetAgencyWTokenOnly = async ({}: {}) => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: backendApiBasePathLocal + "agency/",
    headers: {},
    queryParams: null,
  };
  const agencyResponse: AxiosResponse<ServerResponse<AgencyObjClient, Error>> = await remoteCallObject.remoteGETCallV2<
    ServerResponse<AgencyObjClient, Error>
  >(getCallInput);

  if (agencyResponse && agencyResponse.data && agencyResponse.data.data) {
    return agencyResponse.data.data;
  } else {
    return null;
  }
};


const GetCurrentPaystubAPICall = async (): Promise<CurrentAgencyPayStubsRespDTO | null> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: `${backendApiBasePathLocal}workypay/agency/current-paystub/`,
    headers: { },
    queryParams: null,
  };

  const paystubResponse: AxiosResponse<ServerResponse<CurrentAgencyPayStubsRespDTO, Error>> = await remoteCallObject.remoteGETCallV2<
    ServerResponse<CurrentAgencyPayStubsRespDTO, Error>
  >(getCallInput);

  if (paystubResponse && paystubResponse.data && paystubResponse.data.data) {
    return paystubResponse.data.data;
  } else {
    return null;
  }
};


const GetAllPaystubAPICall = async (): Promise<CurrentAgencyPayStubsRespDTO[] | null> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: `${backendApiBasePathLocal}workypay/agency/all/`,
    headers: { },
    queryParams: null,
  };

  const paystubResponse: AxiosResponse<ServerResponse<CurrentAgencyPayStubsRespDTO[], Error>> = await remoteCallObject.remoteGETCallV2<
    ServerResponse<CurrentAgencyPayStubsRespDTO[], Error>
  >(getCallInput);

  if (paystubResponse && paystubResponse.data && paystubResponse.data.data) {
    return paystubResponse.data.data;
  } else {
    return null;
  }
};


const GetOrgBillsAPICall = async (): Promise<AgencyOrgBillingCycleSummaryDTO[] | null> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: `${backendApiBasePathLocal}agency/org-bill/`,
    headers: { },
    queryParams: null,
  };

  const orgBillsResponse: AxiosResponse<ServerResponse<AgencyOrgBillingCycleSummaryDTO[], Error>> = await remoteCallObject.remoteGETCallV2<
    ServerResponse<AgencyOrgBillingCycleSummaryDTO[], Error>
  >(getCallInput);

  if (orgBillsResponse && orgBillsResponse.data && orgBillsResponse.data.data) {
    return orgBillsResponse.data.data;
  } else {
    return null;
  }
};


const GetActiveSATimeClock = async (): Promise<TimeClockDataRespDTO | null> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: `${backendApiBasePathLocal}timeclock/agency/get-active-shifts`,
    headers: { },
    queryParams: null,
  };

  const orgBillsResponse: AxiosResponse<ServerResponse<TimeClockDataRespDTO, Error>> = await remoteCallObject.remoteGETCallV2<
    ServerResponse<TimeClockDataRespDTO, Error>
  >(getCallInput);

  if (orgBillsResponse && orgBillsResponse.data && orgBillsResponse.data.data) {
    return orgBillsResponse.data.data;
  } else {
    return null;
  }
};


const PostRetrieveOrgAgencyBillPDF = async (downloadPDfReq: DownloadOrgBillReqDTO): Promise<DownloadOrgBillRespDTO | null> => {
  const postCallInput: RemotePOSTCallInputObject<DownloadOrgBillReqDTO> = {
    apiUrl: `${backendApiBasePathLocal}agency/download-org-bill-pdf`,
    headers: { },
    object:downloadPDfReq,    
  };

  const orgBillsResponse: AxiosResponse<ServerResponse<DownloadOrgBillRespDTO, Error>> = 
  await remoteCallObject.remotePOSTCallV2<ServerResponse<DownloadOrgBillRespDTO, Error>,DownloadOrgBillReqDTO>(postCallInput);

  if (orgBillsResponse && orgBillsResponse.data && orgBillsResponse.data.data) {
    return orgBillsResponse.data.data;
  } else {
    return null;
  }

};

const PostRetrieveJobberPayStubBillPDF = async (downloadPDfReq: DownloadJobberPayStubAgencyReqDTO): Promise<DownloadJobberPayStubAgencyResDTO | null> => {
  const postCallInput: RemotePOSTCallInputObject<DownloadJobberPayStubAgencyReqDTO> = {
    apiUrl: `${backendApiBasePathLocal}workypay/agency/download-jobber-paystub-pdf`,
    headers: { },
    object:downloadPDfReq,    
  };

  const orgBillsResponse: AxiosResponse<ServerResponse<DownloadJobberPayStubAgencyResDTO, Error>> = 
  await remoteCallObject.remotePOSTCallV2<ServerResponse<DownloadJobberPayStubAgencyResDTO, Error>,DownloadJobberPayStubAgencyReqDTO>(postCallInput);

  if (orgBillsResponse && orgBillsResponse.data && orgBillsResponse.data.data) {
    return orgBillsResponse.data.data;
  } else {
    return null;
  }

};



const GetAllJobberAvailbilities = async (): Promise<JobberFullProfileDetails | null> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: `${backendApiBasePathLocal}agency/jobber/full-profile/details`,
    headers: { },
    queryParams: null,
  };

  const orgBillsResponse: AxiosResponse<ServerResponse<JobberFullProfileDetails, Error>> = await remoteCallObject.remoteGETCallV2<
    ServerResponse<JobberFullProfileDetails, Error>
  >(getCallInput);

  if (orgBillsResponse && orgBillsResponse.data && orgBillsResponse.data.data) {
    return orgBillsResponse.data.data;
  } else {
    return null;
  }
};


const DirectReAssignShiftAPIReq = async (reAssignRequest: ShiftRequestAssignmentReqObjWJobber): Promise<DirectShiftAssignmentRespDTO | null> => {
  
  try{        
    const postCallInput: RemotePOSTCallInputObject<ShiftRequestAssignmentReqObjWJobber> = {
      apiUrl: `${backendApiBasePathLocal}shiftassignment/assign-shift-directly`,
      headers: { },
      object:reAssignRequest,    
    };
  
    const orgBillsResponse: AxiosResponse<ServerResponse<DirectShiftAssignmentRespDTO, Error>> = 
    await remoteCallObject.remotePOSTCallV2<ServerResponse<DirectShiftAssignmentRespDTO, Error>,ShiftRequestAssignmentReqObjWJobber>(postCallInput);
  
    if (orgBillsResponse && orgBillsResponse.data && orgBillsResponse.data.data) {
      return orgBillsResponse.data.data;
    } else {
      return null;
    }
  }catch(e){
    console.log("Exception during call to DirectReAssignShiftAPIReq ", reAssignRequest, e)
    throw e
  }

  

};

const GetAgencySalesLeadDTO = async (): Promise<AgencySalesLeadDTO[] | null> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: `${backendApiBasePathLocal}agency/sales/current-leads/`,
    headers: { },
    queryParams: null,
  };

  const orgBillsResponse: AxiosResponse<ServerResponse<AgencySalesLeadDTO[], Error>> = await remoteCallObject.remoteGETCallV2<
    ServerResponse<AgencySalesLeadDTO[], Error>
  >(getCallInput);

  if (orgBillsResponse && orgBillsResponse.data && orgBillsResponse.data.data) {
    return orgBillsResponse.data.data;
  } else {
    return null;
  }
};




export { GetAgencyWTokenOnly,  GetCurrentPaystubAPICall, GetAllPaystubAPICall,GetOrgBillsAPICall,
   GetActiveSATimeClock,
   PostRetrieveOrgAgencyBillPDF,
   PostRetrieveJobberPayStubBillPDF,
   GetAllJobberAvailbilities,
   DirectReAssignShiftAPIReq,
   GetAgencySalesLeadDTO};
