import { WorkyUserDetails, AddressDto } from "../../common/CommonInterfaces";
import {ShiftDetailsJobberPayStubDTO} from '../../agency-logic/agency-data/AgencyInterfaces'
import { JobberWorkerTypeDTO } from "../../sr-ingress/sr-ingress-data/SRIngressInterfaces";


interface JobberPayStubDetailsDTO {
    payStubCycleStartDateTimeEpochSec: number;
    payStubCycleEndDateTimeEpochSec: number;
    totalHoursWorked: number;
    grossEarning: number;
    netEarning: number;
    jobberObligationsDeductionMap: Map<string, number>;
    shiftDetailsJobberPayStubViewMap: Map<string, ShiftDetailsJobberPayStubDTO>;
}

interface WorkyPayJobberPayStubDTO {
    jobberDetails: WorkyUserDetails;
    currentPayStub: JobberPayStubDetailsDTO;
    historicalPayStubs: Map<string, Map<string, JobberPayStubDetailsDTO>>;
}


interface JobberSRPendingShiftUnitDto{
    shiftStartDateTimeEpochSec: number;
    shiftEndDateTimeEpochSec: number;
}

interface JobberSRPendingObj{
    parentShiftId: string;
    orgName: string;
    simpleLocation: string;
    locationName: string;
    locationAddress: AddressDto;
    jobberWorkerTypeDTO: JobberWorkerTypeDTO;
    jobberSRPendingShiftUnitDtoList: JobberSRPendingShiftUnitDto[];
}

enum JobberResponseStatusV2 {
    ACCEPT = "ACCEPT",
    DENY = "DENY"
}

interface JobberResponseDTOV2 {
    shiftStartDateTimeEpochSecs: number;
    status: JobberResponseStatusV2;
    message?: string;
}

interface JobberSRPendingDataAPIReq {
    parentShiftId: string;
    childShiftIdsResponse: JobberResponseDTOV2[];
    jobberNote?: string; // Use "?" to denote that this field is optional
}

interface JobberAssignmentResponseDTO {
    parentShiftId: string;
    status: string;
    message?: string;
    shiftStartDateTimeEpochSecs: number;
}

interface JobberSRPendingDataAPIResp{
    jobberAssignmentResponse:  JobberAssignmentResponseDTO[]
}

export { WorkyPayJobberPayStubDTO, JobberPayStubDetailsDTO,
     JobberSRPendingObj, JobberSRPendingShiftUnitDto,
     JobberSRPendingDataAPIReq, JobberResponseDTOV2, JobberResponseStatusV2,
      JobberSRPendingDataAPIResp, JobberAssignmentResponseDTO };