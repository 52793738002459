import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
	handleRetrieveNewTokens,
	ServerResponse,
	redirectToLogin,
	RemoteExecutionServiceImpl,
	ServerErrorDetails,
	AuthGetCall,
	WorkySignInResponse,
	WorkyAuthRefreshRequest,
	isExpiredCalc,
	RemoteGETCallInputObject,
	UpdateLocalCacheWOAuthInfo,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import emailjs from 'emailjs-com';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import Modal from '@mui/material/Modal';
import { AxiosResponse } from 'axios';
import './shifthistory.css';
import { parseMonthAndDayV2, ShiftRequest, calculateShiftTotals, convertTo12HourFormat, WorkyWorkdayShift, generateWorkyWorkdayShiftInput, formatTimeString, padTime, combineDateAndTimeToEpoch, getHour, getMins } from './OrgUtils';
import { TopNav } from './OrgCommonComponents'
import { SRIPResponseData } from './OrgHomepage';
import { PushToNewSr } from './OrgCommonComponents';
import { OrgMetaData,	JobberProfileBuilder, ShiftEvent } from './orgConstants';
import { WorkyTimePicker, WorkyTimePickerV2 } from '../common/TimePicker';
import { JobberProfile } from './jobberProfileBuilder';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { pushToHome } from '../jobberViews/JobberCommonComponents';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import { WorkyAppHeader, WorkyContactButton, WorkyFooter } from '../agency-logic/agency-components/AgencyComponentSetOne';
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();



// const SRIPData = null;


const sortDataByWorkyWorkdayShift = (data) => {
    if(!data) return null;
    return data.sort((a, b) => {
    const dateA = a.workyWorkdayShift.split('T')[0];
    const timeA = a.workyWorkdayShift.split('_')[0].split('T')[1];

    const dateB = b.workyWorkdayShift.split('T')[0];
    const timeB = b.workyWorkdayShift.split('_')[0].split('T')[1];

    const dateTimeA = new Date(`${dateA}T${timeA}`).getTime();
    const dateTimeB = new Date(`${dateB}T${timeB}`).getTime();

    return dateTimeA - dateTimeB;
    });
};



interface ShiftEventInfo{
  id:string;
  allDay:boolean;
  title:string;
  start:string;
  end:string;
}





interface CancelShiftRequestObj{
  shiftId: string;
}





const CurrentShifts = () => {
	const firstName = localStorage.getItem('firstName');
	const lastName = localStorage.getItem('lastName');
	const userId = localStorage.getItem('userId');
	const userEmail = localStorage.getItem('userEmail');
  const iconUrl = localStorage.getItem('orgIcon');
	const userPhoneNumber = localStorage.getItem('userPhoneNumber');
  const companyname= localStorage.getItem('orgName');
	const [orgMetaData, setOrgMetadata] = useState<OrgMetaData | null>(null);



	const [modalOpen, setModalOpen] = useState(false);
	const [modalShiftDate, setModalShiftDate] = useState('');
	const [modalShiftTime, setModalShiftTime] = useState('');
	const [modalShiftId, setModalShiftId] = useState('');
	const [modalAssignedJobbers, setModalAssignedJobbers] = useState([]);
	const [modalJobberClassification, setModalJobberClassification] = useState('');
	const [modalOrgHourlyPay, setModalOrgHourlyPay] = useState('');
	const [modalRequester, setModalRequester] = useState('');
	const [modalTotalJobbers, setModalTotalJobbers] = useState('');
  const [modalAcutalEndTime, setModalActualEndTime] = useState('');
	const [modalAcutalStartTime, setModalActualStartTime] = useState('');
	const [modalActualDate, setModalActualDate] = useState('');

  const [orgSRIPResponse, setOrgSRIPResponse] = useState<SRIPResponseData[] | null>(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const isMobile = useIsMobile();





  const ShiftsIP = (shiftArrIP) => {
    const handleLeftScroll = () =>{
			if (ref1.current) {
				ref1.current.scrollLeft -= 400; // Adjust the scroll distance as needed
			  }
		}
		const handleRightScroll = () =>{
			if (ref1.current) {
				ref1.current.scrollLeft += 400; // Adjust the scroll distance as needed
			  }
		}
      return(
          <div>
              <div className={isMobile ? 'srstatus-container-mobile':'srstatus-container'}>
                  <div className={isMobile? 'srstatus-title-mobile':'srstatus-title'}>Today's Shifts</div>
                  <button onClick={handleLeftScroll} className={isMobile ? 'srstatus-left-mobile':'srstatus-left'}><WestIcon/></button>
				          <button onClick={handleRightScroll} className={isMobile ? 'srstatus-right-mobile':'srstatus-right'}><EastIcon/></button>
                  <div ref={ref1} className={isMobile ? 'srstatus-holder-mobile':'srstatus-holder'}>
                      {shiftArrIP}
                  </div>
              </div>
          </div>
      )
  }
  
  const UpcomingShifts = (shiftArr) => {
    const handleLeftScroll = () =>{
			if (ref2.current) {
				ref2.current.scrollLeft -= 400; // Adjust the scroll distance as needed
			  }
		}
		const handleRightScroll = () =>{
			if (ref2.current) {
				ref2.current.scrollLeft += 400; // Adjust the scroll distance as needed
			  }
		}
      return(
          <div>
              <div className={isMobile ? 'srstatus-container-mobile':'srstatus-container'}>
                  <div className={isMobile? 'srstatus-title-current-mobile':'srstatus-title'}>Upcoming Shifts</div>
                  <button onClick={handleLeftScroll} className={isMobile ? 'srstatus-left-mobile':'srstatus-left'}><WestIcon/></button>
				          <button onClick={handleRightScroll} className={isMobile ? 'srstatus-right-mobile':'srstatus-right'}><EastIcon/></button>
                  <div ref={ref2} className={isMobile ? 'srstatus-holder-mobile':'srstatus-holder'}>
                      {shiftArr}
                  </div>
              </div>
          </div>
      )
  }
  



  // const sortedData = sortDataByWorkyWorkdayShift(SRIPData);
	const getOrgSRIP = async () => {
		const getCallInput = {
			apiUrl: backendApiBasePathLocal + 'shiftrequest/SRIP',
			headers: {},
			queryParams: null,
		};

		try {
			const orgSRIPResponse = await remoteCallObject.remoteGETCallV2<ServerResponse<SRIPResponseData[], Error>>(getCallInput);

			if (orgSRIPResponse !== null && orgSRIPResponse.data != null && orgSRIPResponse.data.data ) {
				console.log('step4');
				const sortedData: SRIPResponseData[] = sortDataByWorkyWorkdayShift(orgSRIPResponse.data.data);
				setOrgSRIPResponse(sortedData);
        shiftToEventBuilder(sortedData)				
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

  const handleClose = () => setModalOpen(false);
  const handleOpen = (shiftDate,shiftTime,shiftId,jobberClassification,totalJobbers,	actualEndtime, actualStartTime,actualDate, assignedJobbers,jobberHourlyRate,requester) => {
    setModalOpen(true);
    setModalShiftDate(shiftDate);
    setModalShiftTime(shiftTime);
    setModalShiftId(shiftId);
    setModalJobberClassification(jobberClassification);
    setModalOrgHourlyPay(jobberHourlyRate);
    setModalTotalJobbers(totalJobbers);
    setModalActualEndTime(actualEndtime);
		setModalActualStartTime(actualStartTime);
		setModalActualDate(actualDate)
    setModalAssignedJobbers(assignedJobbers);
    setModalRequester(requester);
	}


  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const apiBody: CancelShiftRequestObj = {
		shiftId: modalShiftId,
	};


  const handleCancel = async (e) => {
    e.preventDefault();

    console.log("HIT CANCEL BUTTON MODAL SHIFT ID :  ---- "+ modalShiftId)
    try {
			const response: AxiosResponse<ServerResponse<string, Error>> =
				await remoteCallObject.remotePOSTCallV2<ServerResponse<string, Error>,CancelShiftRequestObj>({

					apiUrl: backendApiBasePathLocal + 'shiftassignment/cancel',
					object: apiBody,
					headers: { 'Content-Type': 'application/json' }, 
				});
			console.log('!!!!!POST request successful:', response);

			if (
				response &&
				(response.status === 200 || response.status === 201 || response.status === 202)
			) {
        window.location.reload();
				console.log('200 or 201 or 202');
			}
		} catch (e) {
			if (e.response && (e.response.status === 400 || e.response.status === 500)) {
        console.log('400 or 500');
        window.location.reload();
			}
			console.error('POST request failed:', e);
		}    
  }

  const handleCancelModalOpen = () => {
    setCancelModalOpen(true)
  }

  const handleCancelModalClose = () => setCancelModalOpen(false)



    const [renderComponent, setRenderComponent] = useState(false);
    const shiftArr: JSX.Element[] = [];
    const shiftArrIP: JSX.Element[] = [];
    const shiftArrCalendar: JSX.Element[] = [];

    const dateToday = (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().slice(0, 10);




    function determineAssignedJobbers(assignedShifts: ShiftRequest[]):JobberProfileBuilder[] {      
      
      // Check if the assignedShifts array is empty
      if (assignedShifts.length <= 0) {
        return null;
      }
      
      const assignedJobbers:JobberProfileBuilder[] = [];
      
      
      assignedShifts.forEach((shiftRequest) => {
        if (shiftRequest.shiftRequestStatus === 'ASSIGNED' && shiftRequest.assignedJobberUserDetails) {
        let jobberName = shiftRequest.assignedJobberUserDetails.firstName + " " + shiftRequest.assignedJobberUserDetails.lastName;
        let jobberEmail = shiftRequest.assignedJobberUserDetails.userEmail.value;
        let jobberPhone = shiftRequest.assignedJobberUserDetails.userPhoneNumber.value;
        let jobberPic = shiftRequest.assignedJobberProfilePicS3URL;
        assignedJobbers.push({
          jobberName, jobberEmail, jobberPhone, jobberPic
        });
        }
      });
      
      return assignedJobbers;
    }

    const SRInProgressChildComponent = (srDetails: SRIPResponseData) => {
        if (!srDetails) return;
        
        const workyShift: WorkyWorkdayShift = generateWorkyWorkdayShiftInput({
            workyWorkdayShiftInput: srDetails.workyWorkdayShift,
            assignedSRCount: srDetails.assignedShiftRequests.length,
            inProgressSRCount: srDetails.inProgressShiftRequests.length,
        });
        if (!workyShift)return;
        if (
          !srDetails &&
          !srDetails.inProgressShiftRequests &&
          srDetails.inProgressShiftRequests.length === 0
        ) {
          return;
        }

        console.log('Generated: workyShift ', workyShift);

        let orgHourlyPay;
        let SRID;
        let jobberClassification;
        let jobberHourlyRate;
        let requester;
        let totalJobbers = workyShift.assigned + workyShift.unassigned;
        let actualStartTime = srDetails.workyWorkdayShift.substring(11, 16);
        let actualEndtime = srDetails.workyWorkdayShift.substring(17, 22);
        let actualDate = srDetails.workyWorkdayShift.substring(0, 10);
        let displayTime = formatTimeString(srDetails.workyWorkdayShift.substring(11, 22));
        let shiftdate = workyShift.date;
        let shifttime = displayTime;

        let assignedJobbers:JobberProfileBuilder[];
        if (srDetails.assignedShiftRequests){
          assignedJobbers = determineAssignedJobbers(srDetails.assignedShiftRequests);
        }

        try {
          if (srDetails.inProgressShiftRequests[0]) {
            // orgHourlyPay = srDetails.inProgressShiftRequests[0]?.orgHourlyRate;
            jobberHourlyRate = srDetails.inProgressShiftRequests[0]?.jobberHourlyRate;
            SRID = srDetails.inProgressShiftRequests[0]?.shiftRequestUnitId;
            jobberClassification = srDetails.inProgressShiftRequests[0]?.workerType;
            requester = srDetails?.inProgressShiftRequests[0]?.requesterFirstName + " " + srDetails?.inProgressShiftRequests[0]?.requesterLastName;
          } else if (srDetails.assignedShiftRequests[0]) {
            // orgHourlyPay = srDetails.assignedShiftRequests[0]?.orgHourlyRate;
            
            jobberHourlyRate = srDetails.assignedShiftRequests[0]?.jobberHourlyRate;
            SRID = srDetails.assignedShiftRequests[0]?.shiftRequestUnitId;
            jobberClassification = srDetails.assignedShiftRequests[0]?.workerType;
            requester = srDetails?.assignedShiftRequests[0]?.requesterFirstName + " " +srDetails?.assignedShiftRequests[0]?.requesterLastName;
          } else {
            throw new Error("No shift requests available");
          }
        } catch (error) {console.error("Error: ", error.message);}

        return (
            <button
              onClick={()=>handleOpen(shiftdate,shifttime,SRID,jobberClassification,totalJobbers,actualEndtime,actualStartTime,actualDate, assignedJobbers,jobberHourlyRate,requester)}
              className='srstatus' 
              id={(workyShift.assigned / (workyShift.assigned + workyShift.unassigned)) !== 1 ? 'sr-inprogress' : 'sr-assigned'}
            >
              <div className='srstatus-inner'>
                <div className='srstatus-date'>{workyShift.date}</div>
                <div className='srstatus-time'>{displayTime}</div>
                <div className='srstatus-ratio-holder'>
                  <div className='srstatus-ratio'>
                    {workyShift.assigned} / {workyShift.assigned + workyShift.unassigned}
                  </div>
                </div>
              </div>
            </button>
          );
    
    };

    const SRStatusComponentCarosul = (orgSRIPResponse: SRIPResponseData[]) => {
        if (!orgSRIPResponse) return;
        for (let i = 0; i < orgSRIPResponse.length; i++) {
            // console.log("orgSRIPResponse[i] ---- "+ JSON.stringify(orgSRIPResponse[i]))
            if (!orgSRIPResponse[i]) continue;
            else if ((orgSRIPResponse[i].workyWorkdayShift).slice(0, -12) === dateToday){
                shiftArrIP.push(SRInProgressChildComponent(orgSRIPResponse[i]));
            }
            else{
                shiftArr.push(SRInProgressChildComponent(orgSRIPResponse[i]));
            }
        }
    };




    interface ModifyShiftTimeRequest {
      shiftId: string;
      newShiftStartTime?: number;
      newShiftEndTime?: number;
      reason?: string;
      }
      const [modalOpenExtendShift, setModalOpenExtendShift] = useState(false);
      const handleOpenExtendShift = () => setModalOpenExtendShift(true);
      const handleCloseExtendShift = () => setModalOpenExtendShift(false);


 const handleExtendShiftSubmit = async (actualDate, newStartTime,newEndTime,modalShiftId) => {

		let epochDateStartTime; 
		let epochDateEndTime; 

		if (newStartTime.includes("null")) {
			epochDateStartTime = null;
		}else{
			epochDateStartTime = combineDateAndTimeToEpoch(actualDate,padTime(newStartTime));
		}
		if (newEndTime.includes("null")) {
			epochDateEndTime = null; 
		}else{
			epochDateEndTime = combineDateAndTimeToEpoch(actualDate,padTime(newEndTime));
		}

		const modifyShiftTimeApiBody :ModifyShiftTimeRequest={
			shiftId:modalShiftId,
			newShiftStartTime:epochDateStartTime,
			newShiftEndTime:epochDateEndTime,
		}
		

		try {
			const response: AxiosResponse<ServerResponse<string, Error>> =
				await remoteCallObject.remotePOSTCallV2<
					ServerResponse<string, Error>,
					ModifyShiftTimeRequest
				>({
					apiUrl: backendApiBasePathLocal + 'shift/modify/shiftTime',
					object: modifyShiftTimeApiBody,
					headers: { 'Content-Type': 'application/json' },
				});
			console.log('POST request successful:', response);

			if (
				response &&
				(response.status === 200 || response.status === 201 || response.status === 202)
			) {
				window.location.reload();
				console.log('200 or 201 or 202');
			}
		} catch (e) {
			if (e.response && (e.response.status === 400 || e.response.status === 500)) {
				console.log('400 or 500');
			}
			console.error('POST request failed:', e);
		}

	  }
    const [confirmShiftChangeModalOpen, setConfirmShiftChangeModalOpen] = useState(false);
    const handleConfirmShiftChangeModalOpen = () => setConfirmShiftChangeModalOpen(true);
    const handleConfirmShiftChangeModalClose = () => setConfirmShiftChangeModalOpen(false);


    const getOrgMetadata = async () => {
      const getCallInput: RemoteGETCallInputObject = {
        apiUrl: backendApiBasePathLocal + 'org/metadata',
        headers: {},
        queryParams: null,
      };
  
      const orgMetadataRes: AxiosResponse<ServerResponse<OrgMetaData, Error>> =
        await remoteCallObject.remoteGETCallV2<ServerResponse<OrgMetaData, Error>>(getCallInput);
  
      if (orgMetadataRes && orgMetadataRes.data && orgMetadataRes.data.data) {
        console.log('step 3');
        localStorage.setItem('orgName', orgMetadataRes.data.data.orgName);
        localStorage.setItem('orgData', JSON.stringify(orgMetadataRes.data.data));
        setOrgMetadata(orgMetadataRes.data.data);
      }
    };

    useEffect(() => {
        getOrgMetadata();
        getOrgSRIP();
        setRenderComponent(true);
      }, [SRStatusComponentCarosul(orgSRIPResponse)]);

      const handleGeneralEmailSubmit = async (e) => {
        e.preventDefault();
    
        const serviceID = 'service_3mlm2ls';
        const templateID = 'template_xl6xpjw';
        const userID = 'zKdgUxA7H3dMaCf-m';

        try {
          await emailjs.sendForm(serviceID, templateID, e.target, userID);
          alert('Email sent successfully!');
        } catch (error) {
          alert('An error occurred while sending the email.');
          console.error(error);
        }
      };
      const [newStartTimeHour, setNewStartTimeHour] = useState(null);
      const [newStartTimeMinute, setNewStartTimeMinute] = useState(null);
      const [newEndTimeHour, setNewEndTimeHour] = useState(null);
      const [newEndTimeMinute, setNewEndTimeMinute] = useState(null);
      const [shiftEvents, setShiftEvents] = useState([])

      const [modalOpenReportIncident, setModalOpenReportIncident] = useState(false);
      const handleOpenReportIncident = () => setModalOpenReportIncident(true);
      const handleCloseReportIncident = () => setModalOpenReportIncident(false);


      const [calendarView, setCalendarView] = useState(true);
      // events.push({
        //   title: formatTimeString("2024-03-18T09:00_17:00".substring(11, 22)),
        //   start: new Date(1711458000 * 1000),
        //   end: new Date(1711486800 * 1000),  
        //   id: "string"
        // })
        // events.push({
        //   title: formatTimeString("2024-03-18T09:00_17:00".substring(11, 22)),
        //   start: new Date(1711458000 * 1000),
        //   end: new Date(1711486800 * 1000),  
        //   id: "string"
        // })
        // events.push({
        //   title: formatTimeString("2024-03-18T09:00_17:00".substring(11, 22)),
        //   start: new Date(1711458000 * 1000),
        //   end: new Date(1711486800 * 1000),  
        //   id: "string"
        // })
        // events.push({
        //   title: formatTimeString("2024-03-18T09:00_17:00".substring(11, 22)),
        //   start: new Date(1711458000 * 1000),
        //   end: new Date(1711486800 * 1000),  
        //   id: "string"
        // })


      //Building shift here

      const shiftToEventBuilder = (srip: SRIPResponseData[]) => {
        const events = [];
        

        srip.forEach((shiftRequest) => {
          const { assignedShiftRequests, inProgressShiftRequests } = shiftRequest;
      
          // Process assigned shift requests
          assignedShiftRequests.forEach((assignedShift) => {
            const { workyWorkdayShift, startDateTimeEpochSeconds, endDateTimeEpochSeconds } = assignedShift;


            events.push({
                title: formatTimeString(workyWorkdayShift.substring(11, 22)),
                start: new Date(startDateTimeEpochSeconds * 1000),
                end: new Date(endDateTimeEpochSeconds * 1000),  
                id: JSON.stringify([workyWorkdayShift,assignedShift]),
            });
          });
      
          // Process in-progress shift requests
          inProgressShiftRequests.forEach((inProgressShift) => {
            const { workyWorkdayShift, startDateTimeEpochSeconds, endDateTimeEpochSeconds } = inProgressShift;
          
            
            events.push({
              title: formatTimeString(workyWorkdayShift.substring(11, 22)),
              start: new Date(startDateTimeEpochSeconds * 1000), // Convert seconds to milliseconds
              end: new Date(endDateTimeEpochSeconds * 1000), // Convert seconds to milliseconds
              id: JSON.stringify([workyWorkdayShift,inProgressShift]),
            });
          });
        });

        // Third loop to combine events with the same workyWorkdayShift
        const combinedEvents = events.reduce((accumulator, currentEvent) => {
          const currentWorkyWorkdayShift = JSON.parse(currentEvent.id)[0];
          const currentEventData = JSON.parse(currentEvent.id)[1];
          const existingEventIndex = accumulator.findIndex(
              (e) => JSON.parse(e.id)[0] === currentWorkyWorkdayShift
          );

          if (existingEventIndex !== -1) {
              // Combine the current event data with the existing event data
              const existingEvent = accumulator[existingEventIndex];
              const existingEventData = JSON.parse(existingEvent.id)[1];
              const combinedEventData = [existingEventData, currentEventData]; // Combine event data as an array
              const combinedEvent = {
                  ...existingEvent,
                  id: JSON.stringify([currentWorkyWorkdayShift, combinedEventData]) // Update id with combined event data array
              };
              accumulator[existingEventIndex] = combinedEvent;
          } else {
              // Add the current event as it is unique
              accumulator.push(currentEvent);
          }

          return accumulator;
        }, []);

        // Replace the events array with combinedEvents
        const updatedEvents = [...combinedEvents];
        setShiftEvents(updatedEvents);
        console.log("events cleanup   : "+JSON.stringify(events))


        // setShiftEvents(events);
      }
      
      const [mainModalv2, setMainModalv2] = useState(false);

      const closeMainModalv2 = () => setMainModalv2(false);


      const openMainModalv2 = (shiftEvent) => {
        const srEventId = JSON.parse(shiftEvent.id);
        const srDetails:ShiftRequest[] = extractElements(srEventId[1]);

        const workyWorkdayShift = srEventId[0]
        
        function extractElements(inputArrayOrObject) {
          if (Array.isArray(inputArrayOrObject)) {
              let outputArray = [];
  
              function extract(array) {
                  array.forEach(item => {
                      if (Array.isArray(item)) {
                          extract(item);
                      } else {
                          outputArray.push(item);
                      }
                  });
              }
  
              extract(inputArrayOrObject);
              return outputArray;
          } else {
              // If it's not an array, simply return it wrapped in an array
              return [inputArrayOrObject];
          }
      }



        if (!srDetails) return;

        // let SRID = srDetails.shiftRequestUnitId;
        // let jobberClassification = srDetails.workerType;
        console.log("TEST TEST 1234 :"+JSON.stringify(srDetails))
        let SRID = srDetails[0].shiftRequestUnitId;
        let jobberHourlyRate = srDetails[0].jobberHourlyRate;
        let jobberClassification = srDetails[0].workerType;
        let actualStartTime = workyWorkdayShift.substring(11, 16);
        let actualEndtime = workyWorkdayShift.substring(17, 22);
        let actualDate = workyWorkdayShift.substring(0, 10);
        let displayTime = formatTimeString(workyWorkdayShift.substring(11, 22));
        let shiftdate = parseMonthAndDayV2(workyWorkdayShift);
        let shifttime = displayTime;
        let numJobbers =srDetails.length;
        let requester = srDetails[0].requesterFirstName + " " +srDetails[0].requesterLastName;

        console.log('-----TESTING BEGIN-----');
        console.log("SR DETAILS LENGTH : " + srDetails)
        console.log("SR DETAILS LENGTH : " + (srDetails).length);
        console.log("SR jobberHourlyRate : " + jobberHourlyRate);
        console.log("SR SRID : " + SRID);
        console.log("srDetails: " + JSON.stringify(srDetails));
        console.log('-----TESTING FINISH-----');
        
        let assignedJobbers:JobberProfileBuilder[];
        if (srDetails[0].shiftRequestStatus === "ASSIGNED"){
          assignedJobbers = determineAssignedJobbers(srDetails);
        }
        
        setModalShiftDate(shiftdate);
        setModalShiftTime(shifttime);
        setModalShiftId(SRID);
        setModalJobberClassification(jobberClassification);
        setModalOrgHourlyPay(jobberHourlyRate.toString());
        setModalTotalJobbers(numJobbers.toString());
        setModalActualEndTime(actualEndtime);
        setModalActualStartTime(actualStartTime);
        setModalActualDate(actualDate)
        setModalAssignedJobbers(assignedJobbers);
        setModalRequester(requester);

        setMainModalv2(true);
      }
      
      


      const CalendarView = () => {
   
      

        return(
          <>
            <div className={isMobile? 'calendar-title-mobile':'calendar-title'}>Monthly Shifts</div>
            <div className={isMobile? 'calendar-container-mobile':'calendar-container'}>
                  <FullCalendar
                      plugins={[dayGridPlugin]}
                      height="530px"
                      initialView='dayGridMonth'
                      weekends={true}
                      events={shiftEvents}
                      themeSystem='standard'
                      eventClassNames={"shift-event"}
                      dayHeaderClassNames={'calender-header'}
                      eventTextColor={'#4c4c4c'}
                      viewClassNames={'view-calendar'}
                      moreLinkClassNames={'more-calendar'}
                      eventClick={(info)=>openMainModalv2(info.event)}
                      dayMaxEvents={2}
                  />
            </div>
          </>
        )
      }

      const handleExtendStartTimeHour = (hour) => {
        if (hour === 0 || hour === null|| isNaN(hour)){setNewStartTimeHour(getHour(modalAcutalStartTime))}
        else{setNewStartTimeHour(hour)}
      }

      const handleExtendStartTimeMinute = (minute) => {
        if (minute === null || isNaN(minute)){setNewStartTimeMinute(getMins(modalAcutalStartTime))}
        else{setNewStartTimeMinute(minute)}
      }

      const handleExtendEndTimeHour = (hour) => {
        console.log('END TIME TEST HOUR : '+ hour);
        if (hour === 0 || hour === null || isNaN(hour)){setNewEndTimeHour(getHour(modalAcutalEndTime))}
        else{setNewEndTimeHour(hour)}
          console.log("END TIME HOUR TEST 1234 : "+hour)
      }

      const handleExtendEndTimeMinute = (minute) => {
        if (minute === null || isNaN(minute)){setNewEndTimeMinute(getMins(modalAcutalEndTime))}
        else{setNewEndTimeMinute(minute)}
      }




    return(
        <div className='org-app'>
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />       



              <Modal open={mainModalv2} onClose={closeMainModalv2}>
              <div className={isMobile? 'srstatus-modal-mobile':'srstatus-modal'}>
                 <button onClick={closeMainModalv2} className='modal-close'><CloseIcon/></button>
                      <div className='srstatus-date'>{modalShiftDate}</div>
                      <div className='srstatus-time'>{modalShiftTime}</div>
                      <div className='srstatus-modal-title'>Shift Details</div>
                      <div className='srstatus-modal-details'>
                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Number of Workers:</span>
                              {modalTotalJobbers}
                          </div>
                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Worker Type:</span>
                              Warehouse Associate
                          </div>
                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Worker Pay:</span>
                              ${modalOrgHourlyPay}/hr
                          </div>




                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Requester:</span>
                              {modalRequester}
                          </div>




                          {modalAssignedJobbers? <div className='srstatus-modal-title'>Assigned Worker(s)</div>:null}
                          <div className='srstatus-assigned-jobbers-holder'>
                          {modalAssignedJobbers && modalAssignedJobbers.map((jobber, index) => (
                          <JobberProfile
                            key={index}
                            hasCheck={false}
                            jobberProfile={{
                            jobberEmail: jobber.jobberEmail,
                            jobberName: jobber.jobberName,
                            jobberPhone: jobber.jobberPhone,
                            jobberPic: jobber.jobberPic
                            }}
                          />
                          ))}
                            
                          </div>
							


                      </div>
                      <div className='srstatus-modal-title'>Modify Shift</div>
                      <div className='srstatus-modal-actions'>
                        <button onClick={PushToNewSr} className='modal-action' id='modal-action-add'>Add Workers</button>
                        <button onClick={handleOpenExtendShift} className='modal-action' id='modal-action-extend'>Extend Shift</button>
                        <button onClick={handleOpenReportIncident} className='modal-action' id='modal-action-report'>Report Incident</button>
                        <button onClick={handleCancelModalOpen} className='modal-action' id='modal-action-cancel'>Cancel Shift</button>
                      </div>
                  </div>
              </Modal>

               
              <Modal open={modalOpen} onClose={handleClose}>
                  <div className={isMobile? 'srstatus-modal-mobile':'srstatus-modal'}>
                    <button onClick={handleClose} className='modal-close'><CloseIcon/></button>

                      <div className='srstatus-date'>{modalShiftDate}</div>
                      <div className='srstatus-time'>{modalShiftTime}</div>
                      <div className='srstatus-modal-title'>Shift Details</div>
                      <div className='srstatus-modal-details'>
                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Number of Workers:</span>
                              {modalTotalJobbers}
                          </div>
                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Worker Type:</span>
                              Warehouse Associate
                          </div>
                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Worker Pay:</span>
                              ${modalOrgHourlyPay}/hr
                          </div>





                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Requester:</span>
                              {modalRequester}
                          </div>





                          {modalAssignedJobbers? <div className='srstatus-modal-title'>Assigned Worker(s)</div>:null}
                          <div className='srstatus-assigned-jobbers-holder'>
                          {modalAssignedJobbers && modalAssignedJobbers.map((jobber, index) => (
                          <JobberProfile
                            key={index}
                            hasCheck={false}
                            jobberProfile={{
                            jobberEmail: jobber.jobberEmail,
                            jobberName: jobber.jobberName,
                            jobberPhone: jobber.jobberPhone,
                            jobberPic: jobber.jobberPic
                            }}
                          />
                          ))}
                            
                          </div>
							


                      </div>
                      <div className='srstatus-modal-title'>Modify Shift</div>
                      <div className='srstatus-modal-actions'>
                        <button onClick={PushToNewSr} className='modal-action' id='modal-action-add'>Add Workers</button>
                        <button onClick={handleOpenExtendShift} className='modal-action' id='modal-action-extend'>Extend Shift</button>
                        <button onClick={handleOpenReportIncident} className='modal-action' id='modal-action-report'>Report Incident</button>
                        <button onClick={handleCancelModalOpen} className='modal-action' id='modal-action-cancel'>Cancel Shift</button>
                      </div>
                  </div>
              </Modal>

              <Modal open={cancelModalOpen} onClose={handleCancelModalClose}>
                  <div className='cancel-shift-modal'>
                    <div className='srstatus-modal-title'>Are you sure you want to cancel this shift?</div>
                    <div className='cancel-shift-button-holder'>
                      <button onClick={handleCancelModalClose} id='cancel-shift-button-no' className='cancel-shift-button'>No</button>
                      <button onClick={handleCancel} className='cancel-shift-button'>Yes</button>
                    </div>
                  </div>
              </Modal>
              
              <Modal open={modalOpenExtendShift} onClose={handleCloseExtendShift}>
                <div className='modal-extend-shifts'>
                <button onClick={handleCloseExtendShift} className='modal-close'><CloseIcon/></button>
                  <div className='srstatus-date'>Select a New Time</div>

                  <div className='new-start-time'>	
                    <div className='new-time'>
                      <div id="modal-title2" className='srstatus-time'>New Start Time</div>
                      <WorkyTimePickerV2
                        prevHour = {getHour(modalAcutalStartTime)}
                        prevMin = {getMins(modalAcutalStartTime)}
                        onFinalTimeChangeHour={(hour) => handleExtendStartTimeHour(hour)}
                        onFinalTimeChangeMinute={(minute) => handleExtendStartTimeMinute(minute)}
                      />
                    </div>
                  </div>

                  <div style={{paddingBottom:"20px"}} className='new-start-time'>	
                    <div className='new-time'>
                      <div id="modal-title2" className='srstatus-time'>New End Time</div>
                      <WorkyTimePickerV2
                        prevHour = {getHour(modalAcutalEndTime)}
                        prevMin = {getMins(modalAcutalEndTime)}
                        onFinalTimeChangeHour={(hour) => handleExtendEndTimeHour(hour)}
                        onFinalTimeChangeMinute={(minute) => handleExtendEndTimeMinute(minute)}
                      />
                    </div>
                  </div>



                  <button onClick={handleConfirmShiftChangeModalOpen} className='extend-modal-submit'>Submit</button>
                </div>
              </Modal>

              <Modal open={confirmShiftChangeModalOpen} onClose={handleConfirmShiftChangeModalClose}>
                <div className='confirm-shiftchange-modal'>
                  <button onClick={handleConfirmShiftChangeModalClose} className='modal-close'><CloseIcon/></button>
                  <div className='srstatus-modal-title'>Are you sure you want to change this shift?</div>
                  <div className='confirm-shiftchange-reciept-holder'>

                    <div className='srpage4-reciept-title'>
                      Number of Workers: <span className='reciept-value1'>1</span>
                    </div>

                    <div className='srpage4-reciept-title'>
                      Shift Date: <span className='reciept-value1'>{modalActualDate}</span>
                    </div>

                    <div className='srpage4-reciept-title'>
                      Shift Time: 
                      <span className='reciept-value1'>
                        {convertTo12HourFormat(newStartTimeHour+":"+newStartTimeMinute)} - {convertTo12HourFormat(newEndTimeHour+":"+newEndTimeMinute)}
                      </span>
                    </div>

                    <div className='srpage4-reciept-title'>
                      Worker Type: <span className='reciept-value1'>Warehouse Associate</span>
                    </div>

                    <div className='srpage4-reciept-title'>
                      Worker Pay: <span className='reciept-value1'> ${modalOrgHourlyPay}</span>
                    </div>

                    <div className='srpage4-reciept-title'>
                      Worky Charge: <span className='reciept-value1'>{orgMetaData?.orgBillingData?.workyMarkup*100}%</span>
                    </div>

                    <div className='srpage4-reciept-title'>
                      Tax: <span className='reciept-value1'>{calculateShiftTotals(newStartTimeHour,newStartTimeMinute,newEndTimeHour,newEndTimeMinute,1,1,modalOrgHourlyPay)[0]}</span>
                    </div>

                    <div className='srpage4-reciept-title'>
                      Total: <span className='reciept-value1'>{calculateShiftTotals(newStartTimeHour,newStartTimeMinute,newEndTimeHour,newEndTimeMinute,1,1,modalOrgHourlyPay)[1]}</span>
                    </div>
                    
                  </div>
                  <div className='cancel-shiftchange-button-holder'><button onClick={handleConfirmShiftChangeModalClose}  className='cancel-shiftchange-button'>No</button>
                    <button id='cancel-shiftchange-button-no' onClick={() => handleExtendShiftSubmit(modalActualDate,(newStartTimeHour+":"+newStartTimeMinute),(newEndTimeHour+":"+newEndTimeMinute),modalShiftId)} className='cancel-shiftchange-button'>Yes</button>
                  </div>
                </div>
              </Modal>

              <Modal open={modalOpenReportIncident} onClose={handleCloseReportIncident}>
                <form className='modal-contact' onSubmit={handleGeneralEmailSubmit}>
                    <button onClick={handleCloseReportIncident} className='modal-close'><CloseIcon/></button>
                    <div className='srstatus-date'>Having an Issue?</div>
                    <div id="modal-title2" className='srstatus-time'>We guarantee to get back to you in under 1 hour!</div>
                    <div className='modal-title'>Email: <span className='modal-info'>info@worky.pro</span></div>
                    <div id='modal-title-2' className='modal-title'>Phone: <span className='modal-info'>(647) 494-9090</span></div>
                    <input className='modal-email' name="user_email" defaultValue={localStorage.getItem('userEmail') === null || localStorage.getItem('userEmail') === undefined? 'info@worky.pro':localStorage.getItem('userEmail')}/>
                    <div className='modal-title'>Message:</div>
                    <textarea name="user_message" className='modal-message'></textarea>
                    <button className='modal-submit'>Send</button>
                </form>
              </Modal>


              {/* <TopNav
                firstName={firstName}
                lastname={lastName}
                companyname={companyname}
                iconUrl={iconUrl}
                orgEmail={userEmail}
              /> */}
              <WorkyAppHeader/>
              
              {isMobile? 
              (<div className='toggle-calendar-mobile'>
                  {calendarView ? <button onClick={()=>setCalendarView(false)} id='toggleClosed' className='calendar-toggle'><ViewDayOutlinedIcon/></button>:<button onClick={()=>setCalendarView(false)} className='calendar-toggle'><ViewDayOutlinedIcon/></button>}
                  {!calendarView ? <button onClick={()=>setCalendarView(true)} id='toggleClosed' className='calendar-toggle'><CalendarMonthOutlinedIcon/></button>:<button onClick={()=>setCalendarView(true)} className='calendar-toggle'><CalendarMonthOutlinedIcon/></button>}
              </div>):
              (<div className='toggle-calendar'>
                  {/* {calendarView ? <button onClick={()=>setCalendarView(false)} id='toggleClosed' className='calendar-toggle'>Carousel</button>:<button onClick={()=>setCalendarView(false)} className='calendar-toggle'>Carousel</button>}
                  {!calendarView ? <button onClick={()=>setCalendarView(true)} id='toggleClosed' className='calendar-toggle'>Calendar</button>:<button onClick={()=>setCalendarView(true)} className='calendar-toggle'>Calendar</button>} */}
                  {calendarView ? <Tooltip placement='left' title='Carousel View'><button onClick={()=>setCalendarView(false)} id='toggleClosed' className='calendar-toggle'><ViewDayOutlinedIcon/></button></Tooltip>:<button onClick={()=>setCalendarView(false)} className='calendar-toggle'><ViewDayOutlinedIcon/></button>}
                  {!calendarView ? <Tooltip placement='right' title='Calendar View'><button onClick={()=>setCalendarView(true)} id='toggleClosed' className='calendar-toggle'><CalendarMonthOutlinedIcon/></button></Tooltip>:<button onClick={()=>setCalendarView(true)} className='calendar-toggle'><CalendarMonthOutlinedIcon/></button>}
              </div>)}

              {calendarView ? (<CalendarView/>) :
                (orgSRIPResponse && orgSRIPResponse.length > 0 ? (
                    <>
                        {shiftArrIP.length === 0 ? null : ShiftsIP(shiftArrIP)}
                        {shiftArr.length === 0 ? null : UpcomingShifts(shiftArr)}
                    </>
                ) : (
                    <div className='no-shifts-container'>
                      <div className='no-shifts-title'>Looks like you don't have any shifts planned.</div>
                      <div className='no-shifts-subtitle'>Click here and well get you workers today!</div>
                      <button onClick={PushToNewSr} className='no-shifts-button'>Hire Now</button>
                    </div>
                ))
              }  
                
            <WorkyFooter/>
            <WorkyContactButton/>
            {/* <div onClick={()=>{window.location.assign('homepage')}} className='worky-footer'>worky</div> */}

        </div>
    )

}

export {CurrentShifts}