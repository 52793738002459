import React, { useState, useEffect, useRef } from 'react';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import { SplashNavbarV3Mobile } from './CareersPage';
import { SplashFooter } from './DesktopSplashV2';
import { MobileWorkyFooter } from './MobileSplashV2';
import EastIcon from '@mui/icons-material/East';
import StarIcon from '@mui/icons-material/Star';
import PaidIcon from '@mui/icons-material/Paid';
import CheckIcon from '@mui/icons-material/Check';
import Accordion from '@mui/material/Accordion';
import { MobileSplashNav } from './MobileSplashV3';
import Modal from '@mui/material/Modal';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import RadarIcon from '@mui/icons-material/Radar';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Popper from '@mui/material/Popper';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BrushIcon from '@mui/icons-material/Brush';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Fuse from 'fuse.js';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './resourcepage.css'
const workyServerIDPSIGNUPURL = process.env.REACT_APP_IDP_SIGN_UP_URL;
const workyServerIDPSIGNINURL = process.env.REACT_APP_IDP_SIGN_IN_URL;
const bookDemoLink = "https://calendly.com/worky-pro/30min";
const blogPosts = [
    {
        title: "How Technology is Revolutionizing Staffing Agencies",
        text: "The staffing industry is transforming rapidly with the help of technology. Automation tools like Worky are enabling staffing agencies to simplify operations, manage workers more effectively, and deliver faster, more reliable services. By integrating systems for scheduling, communication, payroll, and compliance, agencies reduce the administrative workload and enhance overall efficiency. Technology is the future, and staffing agencies adopting these innovations will stay competitive and deliver better value to their clients.",
        buttonText: "Learn More",
        img: "./resources-imgs/Untitled-1.jpeg"
    },
    {
        title: "Automating Scheduling & Payroll for Staffing Success",
        text: "Scheduling and payroll are some of the most time-consuming and error-prone tasks for staffing agencies. Traditional methods, such as spreadsheets, often lead to mistakes and delays. With Worky’s automated system, agencies can assign shifts based on worker availability, qualifications, and client preferences. The payroll system also calculates wages and taxes in real-time, ensuring accuracy and compliance. This automation reduces errors, saves time, and cuts costs.",
        buttonText: "Discover How",
        img: "./resources-imgs/Untitled-2.jpeg"
    },
    {
        title: "Geolocation & Check-ins: The Key to Workforce Accountability",
        text: "Staffing agencies need to ensure that their workers are at the right place at the right time. Worky’s geolocation features help track when and where workers clock in, preventing issues like time theft and absenteeism. Real-time check-ins also allow managers to monitor worker progress, ensuring smooth operations and client satisfaction. This feature is essential for industries where location and punctuality are critical.",
        buttonText: "Explore Geolocation Features",
        img: "./resources-imgs/Untitled-3.jpeg"    },
    {
        title: "Enhancing Worker Training & Compliance with Technology",
        text: "Keeping workers trained and compliant with ever-changing regulations is a challenge for staffing agencies. Worky helps agencies stay on top of this by offering integrated training modules to assign, track, and update certifications. Whether it's safety training or skills development, Worky ensures workers are qualified for their roles, reducing legal risks and helping agencies maintain valuable client contracts.",
        buttonText: "Boost Training Today",
        img: "./resources-imgs/Untitled-4.jpeg"    },
    {
        title: "Improving Client & Worker Experience with Streamlined Operations",
        text: "The success of staffing agencies depends on delivering excellent experiences for both clients and workers. Worky streamlines operations by automating scheduling, payroll, and communication. This reduces human error, speeds up processes, and increases transparency. Workers benefit from clear job expectations and faster onboarding, while clients get reliable service, faster placements, and better reporting.",
        buttonText: "Optimize Your Operations",
        img: "./resources-imgs/Untitled-5.jpeg"    },
    {
        title: "Why Real-Time Data is Crucial for Staffing Agencies",
        text: "In today’s fast-paced staffing environment, real-time data is invaluable. Worky provides staffing agencies with up-to-date information on worker availability, job placement status, and payroll. By leveraging real-time insights, agencies can make more informed decisions, identify trends, and respond quickly to client needs, helping them stay ahead of the competition and adapt to changing demands.",
        buttonText: "Get Real-Time Insights",
        img: "./resources-imgs/Untitled-6.jpeg"    },
    {
        title: "How Worky Simplifies Shift Management for Staffing Agencies",
        text: "Managing shifts can be complicated for staffing agencies. With Worky, shift scheduling is simplified. The platform automatically considers worker availability, qualifications, and location when assigning shifts. Workers can update their availability, request time off, and communicate with the agency in real-time, reducing administrative work and ensuring that the right person is in the right job at the right time.",
        buttonText: "Simplify Shift Management",
        img: "./resources-imgs/Untitled-7.jpeg"    },
    {
        title: "Boosting Worker Retention with Effective Communication Tools",
        text: "Effective communication is crucial for retaining top workers. Worky’s integrated messaging platform allows workers to easily communicate with agencies, providing feedback, asking questions, and staying updated on job opportunities. Additionally, Worky’s mobile interface ensures workers can access schedules and job details on the go, helping reduce turnover and increase overall job satisfaction.",
        buttonText: "Enhance Communication",
        img: "./resources-imgs/Untitled-8.jpeg"    },
    {
        title: "Streamlining Compliance & Reporting with Worky",
        text: "Staying compliant with labor laws, tax regulations, and client contracts is a top priority for staffing agencies. Worky automates compliance by generating real-time reports that ensure all documentation is accurate and up-to-date. Agencies can use these reports for audits, tax filings, and internal analysis, reducing the risk of fines and ensuring industry standards are met.",
        buttonText: "Stay Compliant",
        img: "./resources-imgs/Untitled-9.jpeg"    },
    {
        title: "The Power of On-Demand Staffing: Scaling Your Workforce",
        text: "The demand for on-demand staffing is growing, and agencies that can quickly scale their workforce are better equipped to meet client needs. Worky makes it easy to manage fluctuating workloads with features like automated scheduling and real-time availability tracking. Staffing agencies can scale their workforce up or down in response to market conditions, giving them an edge in an ever-changing industry.",
        buttonText: "Scale Your Workforce",
        img: "./resources-imgs/Untitled-10.jpeg"    },
    {
        title: "Artificial Intelligence in Staffing: The Future of Recruitment",
        text: "Artificial Intelligence is transforming how staffing agencies match candidates with jobs. Advanced AI algorithms analyze candidate profiles, work history, and skills to make more accurate placement recommendations. These smart matching systems reduce time-to-hire while improving placement success rates. With AI-powered tools like Worky, agencies can process more applications faster, identify the best candidates, and predict job success probability, giving them a competitive edge in the market.",
        buttonText: "Explore AI Solutions",
        img: "./resources-imgs/Untitled-11.jpeg"        },
        {
        title: "Mobile-First Staffing: Meeting Workers Where They Are",
        text: "In today's mobile-first world, successful staffing agencies need robust mobile solutions. Workers expect to search jobs, submit applications, and manage their schedules from their smartphones. Worky's mobile platform enables workers to clock in/out, access training materials, and communicate with supervisors directly from their devices. This accessibility increases worker engagement, reduces no-shows, and streamlines the entire staffing process for both agencies and workers.",
        buttonText: "Go Mobile",
        img: "./resources-imgs/Untitled-12.jpeg"        },
        {
        title: "Maximizing ROI with Data-Driven Staffing Decisions",
        text: "Data analytics is revolutionizing how staffing agencies make decisions. By analyzing historical placement data, worker performance metrics, and market trends, agencies can optimize their operations for better ROI. Worky's analytics dashboard provides actionable insights on fill rates, time-to-fill, worker retention, and client satisfaction. These metrics help agencies identify opportunities for improvement and make strategic decisions that drive business growth.",
        buttonText: "Analyze Your Data",
        img: "./resources-imgs/Untitled-13.jpeg"        },
        {
        title: "Building a Digital-First Onboarding Experience",
        text: "First impressions matter in staffing, and digital onboarding is becoming the new standard. Worky's digital onboarding system streamlines the process by automating paperwork, background checks, and training assignments. Workers can complete necessary documentation, watch orientation videos, and acknowledge policies from any device. This efficient process reduces time-to-start, improves compliance, and creates a professional first impression that helps attract top talent.",
        buttonText: "Transform Onboarding",
        img: "./resources-imgs/Untitled-14.jpeg"        },
        {
        title: "Predictive Analytics: Forecasting Staffing Demands",
        text: "Anticipating staffing needs is crucial for agency success. Predictive analytics tools analyze historical data, seasonal patterns, and market indicators to forecast future staffing demands. Worky's forecasting features help agencies prepare for busy periods, optimize worker scheduling, and ensure they have the right talent ready when clients need it. This proactive approach improves fill rates and client satisfaction while reducing last-minute scrambles.",
        buttonText: "Predict Future Needs",
        img: "./resources-imgs/Untitled-15.jpeg"        },
        {
        title: "Integrating Time & Attendance with Client Systems",
        text: "Seamless system integration is essential for modern staffing operations. Worky's time and attendance features integrate directly with client workforce management systems, eliminating double entry and reducing errors. This integration ensures accurate billing, simplifies reporting, and provides clients with real-time visibility into their temporary workforce. Agencies can focus on strategic tasks instead of managing manual data transfers.",
        buttonText: "Integrate Systems",
        img: "./resources-imgs/Untitled-16.jpeg"        },
        {
        title: "Automated Invoice Management for Staffing Agencies",
        text: "Managing invoices efficiently is critical for staffing agency cash flow. Worky's automated invoice system generates accurate bills based on actual time worked, approved overtime, and agreed-upon rates. The system flags discrepancies, tracks payment status, and integrates with popular accounting software. This automation reduces billing errors, speeds up payment collection, and improves financial visibility for better business planning.",
        buttonText: "Streamline Billing",
        img: "./resources-imgs/Untitled-17.jpeg"        },
        {
        title: "Enhancing Safety Compliance with Digital Tools",
        text: "Workplace safety is a top priority for staffing agencies. Digital safety management tools help track certifications, conduct assessments, and document incidents. Worky's safety features include digital safety orientations, hazard reporting, and automatic certification tracking. These tools ensure workers are properly trained and compliant with safety regulations, reducing workplace incidents and protecting both workers and agencies.",
        buttonText: "Improve Safety",
        img: "./resources-imgs/Untitled-18.jpeg"        },
        {
        title: "Leveraging Social Media for Staffing Success",
        text: "Social media integration is becoming crucial for modern staffing agencies. Worky's platform helps agencies manage their social media presence, share job opportunities, and engage with potential candidates. The system can automatically post new positions, share success stories, and track engagement metrics. This digital presence helps agencies build their brand, attract quality candidates, and stay connected with their workforce.",
        buttonText: "Boost Social Presence",
        img: "./resources-imgs/Untitled-19.jpeg"        },
        {
        title: "Creating Competitive Advantage with Performance Analytics",
        text: "Performance analytics give staffing agencies a competitive edge. Worky's performance tracking features measure key metrics like fill rates, time-to-fill, worker reliability, and client satisfaction. These insights help agencies identify top performers, optimize operations, and demonstrate value to clients. By making data-driven decisions, agencies can improve their service quality and grow their market share.",
        buttonText: "Track Performance",
        img: "./resources-imgs/Untitled-20.jpeg"        }
];

const ResourceBlock = ({ resourceTitle, resourceMsg, isMobile, img, buttonTitle }: { resourceTitle: string, resourceMsg: string, isMobile: boolean, img: string, buttonTitle: string }) => {
    return (
        <div className={isMobile ? 'resource-block-mobile' : 'resource-block'}>
            <div className='resource-block-textbox'>
                <div className='resource-block-textbox-title'>{resourceTitle}</div>
                <div className='resource-block-textbox-text'>{resourceMsg}</div>
                <button onClick={()=> window.open(bookDemoLink)} className='splash-overlay-button'>{buttonTitle}</button>
            </div>
            <div className='resource-block-img-holder'><img src={img} alt={resourceTitle} /></div>
        
        </div>
    );
};
  
const ResourceBlockAlt = ({ resourceTitle, resourceMsg, isMobile, img, buttonTitle }: { resourceTitle: string, resourceMsg: string, isMobile: boolean, img: string, buttonTitle: string }) => {
return (
    <div className={isMobile ? 'resource-block-mobile' : 'resource-block'}>
        <div className={isMobile?'resource-block-img-holder-mobile':'resource-block-img-holder'}>
            <img src={img} alt={resourceTitle} />
        </div>
        <div className={isMobile ?'resource-block-textbox-mobile':'resource-block-textbox'}>
            <div className='resource-block-textbox-title'>{resourceTitle}</div>
            <div className='resource-block-textbox-text'>{resourceMsg}</div>
            <button onClick={()=> window.open(bookDemoLink)} className='splash-overlay-button'>{buttonTitle}</button>
        </div>
    </div>
);
};

const ResourceBlockBuilder = (blogPosts: any[], isMobile: boolean) => {
    return blogPosts.map((post, index) => {
      if (isMobile) {
        // Always render ResourceBlock if isMobile is true
        return (
          <ResourceBlockAlt
            key={index}
            resourceTitle={post.title}
            resourceMsg={post.text}
            isMobile={isMobile}
            img={post.img}
            buttonTitle={post.buttonText}
          />
        );
      } else {
        // Alternate between ResourceBlock and ResourceBlockAlt if not mobile
        if (index % 2 === 0) {
          return (
            <ResourceBlock
              key={index}
              resourceTitle={post.title}
              resourceMsg={post.text}
              isMobile={isMobile}
              img={post.img}
              buttonTitle={post.buttonText}
            />
          );
        } else {
          return (
            <ResourceBlockAlt
              key={index}
              resourceTitle={post.title}
              resourceMsg={post.text}
              isMobile={isMobile}
              img={post.img}
              buttonTitle={post.buttonText}
            />
          );
        }
      }
    });
};


const ResourcePage = ( ) => {
    const isMobile = useIsMobile();
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredBlogPosts, setFilteredBlogPosts] = useState(blogPosts);
    const signupClick = () => {window.location.assign('/?scroll=5950');}

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
      
        if (!query) {
          // If the search query is empty, reset to show all posts
          setFilteredBlogPosts(blogPosts);
          return;
        }
      
        const fuse = new Fuse(blogPosts, {
          keys: ['title', 'text'], // The fields to search in
          threshold: 0.3, // Fuzzy search threshold (0.0 to 1.0, 0.0 is exact match)
        });
      
        const result = fuse.search(query);
        setFilteredBlogPosts(result.map(result => result.item));
    };
    
    const SplashNavbarV3 = () => {
        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const scrollPosition = urlParams.get('scroll');
            if (scrollPosition) {
                const position = parseInt(scrollPosition, 10);
                if (!isNaN(position)) {window.scrollTo({ top: position, behavior: "smooth" });}
            }
        }, []);
    
    
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
        
        const handleClick = (event: React.MouseEvent<HTMLElement>) => { 
            setAnchorEl(anchorEl ? null : event.currentTarget);
            if (!anchorEl) {setAnchorEl2(null);}
        };
        
        const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl2(anchorEl2 ? null : event.currentTarget);
            if (!anchorEl2) {setAnchorEl(null);}
        };

        const handleBusinessClick =()=>{
            window.location.assign('/?scroll=1475')
        }
        const handleResourceClick =()=>{
            window.location.assign('/resources')
        }
    
    
        
        const open = Boolean(anchorEl);
        const open2 = Boolean(anchorEl2);
        
        const id = open ? 'simple-popper' : undefined;
        const id2 = open2 ? 'simple-popper2' : undefined;
        
        return(
            <div className='splash-navbar-v3'>
                <div onClick={() => window.location.assign('/?scroll=0')} className='splash-navbar-logo'>worky</div>
                <div className='splash-navbar-menu'>
                    <button aria-describedby={id} type="button" onClick={handleClick} className='splash-navbar-option'>Products<KeyboardArrowDownIcon style={{fontSize:"18px", paddingTop:"2px"}}/></button>
                    <button onClick={handleBusinessClick} className='splash-navbar-option'>Solutions</button>
                    {/* <button onClick={handleProductClick2} className='splash-navbar-option'>Associates</button> */}
                    <button aria-describedby={id2} type="button" onClick={handleClick2}  className='splash-navbar-option'>Careers<KeyboardArrowDownIcon style={{fontSize:"18px", paddingTop:"2px"}}/></button>
                    <button onClick={handleResourceClick} className='splash-navbar-option'>Resources</button>
                    <button onClick={()=>window.open(bookDemoLink)} id='demo-option' className='splash-navbar-option'>Book Demo</button>
                    
                    <Popper id={id} open={open} anchorEl={anchorEl} style={{zIndex:"500"}}>
                        <div className='popper-drawer'>
                            <div className='popper-drawer-title'>PRODUCTS</div>
                            <div className='popper-drawer-line'></div>
    
                            <div className='popper-products-holder-inner'>
                                <button onClick={() => window.location.assign('/products?product=1')} className='popper-product'>
                                    <div className='popper-product-inner-img'>
                                        <img src='./virtual-check-in.jpg'></img>
                                    </div>
                                    <div className='popper-product-inner-info'><RadarIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Virtual Clock</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=2')} className='popper-product'>
                                    <div className='popper-product-inner-img'><img src='./worky-ipad-v2.jpg'></img></div>
                                    <div className='popper-product-inner-info'><AdsClickIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Portal</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=3')} className='popper-product'>
                                    <div className='popper-product-inner-img'>
                                        <img src='./worky-algo-mockup.jpg'/>
                                    </div>
                                    <div className='popper-product-inner-info'><AutoAwesomeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Smart Match</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=4')} className='popper-product'>
                                    <div className='popper-product-inner-img'><img src='./workypay.jpg'></img></div>
                                    <div className='popper-product-inner-info'><CurrencyExchangeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Pay</div>
                                </button>
                                <button onClick={() => window.location.assign('/products')} id='view-all-popper-product'>
                                    <div className='view-all-products-icon'><EastIcon/></div>
                                    View All
                                </button>
                            </div>
                        </div>
                    </Popper>
    
                    <Popper id={id2} open={open2} anchorEl={anchorEl2} style={{zIndex:"500"}}>
                        <div className='popper-drawer'>
                            <div className='popper-drawer-title'>CAREERS</div>
                            <div className='popper-drawer-line'></div>
                            <div className='popper-careers-holder-inner'>
                                <button onClick={() => window.location.assign('/careers?scroll=1400')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Data Platform Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=1650')} className='popper-job'>
                                    <div className='popper-job-icon'><BrushIcon/></div>
                                    <div className='popper-job-title'>Senior Product Designer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=1950')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Server Side Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2200')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Senior Front End Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                    <div className='popper-job-icon'><PhoneIphoneIcon/></div>
                                    <div className='popper-job-title'>Social Media Manager</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                    <div className='popper-job-icon'><TrendingUpIcon/></div>
                                    <div className='popper-job-title'>Regional Sales Manager</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers')} id='view-all-jobs' className='popper-job'>
                                    <div id='view-all-jobs-title' className='popper-job-title'>View All</div>
                                    <div id='view-all-jobs-icon' className='popper-job-icon'><EastIcon/></div>
                                </button>
                            </div>
                        </div>
                    </Popper>
    
                </div>
                <div className='splash-navbar-buttons'>
                    <button onClick={() => window.location.assign(workyServerIDPSIGNINURL)} className='splash-navbar-login'>Login</button>
                    <button onClick={signupClick} className='splash-navbar-trynow'>Try Now</button>
                </div>
                
            </div>
        )
    }

    const handleOpeningsClick = () =>{
    if(isMobile){
        window.scrollTo({ top: 2400, behavior: "smooth" })
    }else{
        window.scrollTo({ top: 1200, behavior: "smooth" })}
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const scrollPosition = urlParams.get('scroll');
        // console.log(window.scrollY)
        if (scrollPosition) {
            const position = parseInt(scrollPosition, 10);
            if (!isNaN(position)) {window.scrollTo({ top: position, behavior: "smooth" });}
        }
    }, []);



    return(

        <div className='splash-body'>
            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />        
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='' />
            <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"/>
 

            {isMobile? <MobileSplashNav/>:<SplashNavbarV3/>}
            <div className='careers-header'>
                <div className="products-display-title">Resources</div>
                <div style={{margin:"auto", marginTop:"5px"}} className='products-display-subtitle'>Discover Insights and Empower Your Business with Our Latest Resources!</div>
                
                <input
                    type="text"
                    className='resource-input'
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Search for blog posts"
                />
                
                <div className='resource-holder'>
                    {ResourceBlockBuilder(filteredBlogPosts, isMobile)}
                </div>

            </div>
            
            
            {isMobile? <MobileWorkyFooter/>:<SplashFooter/>}
        </div>

    )

}


export {ResourcePage}