import { CancelShiftRequestObj, WebBrowserClientSalesTrackerReqDTO, WebBrowserClientSalesTrackerResDTO } from "../common/CommonInterfaces";
import emailjs from "emailjs-com";

import {
  ServerResponse,
  RemoteExecutionServiceImpl,
  RemoteGETCallInputObject,
} from "../remoteExecution/RemoteExecutionServiceImpl";
import { AxiosResponse } from "axios";
import {OrgMetaData, CorpDataObj} from '../orgViews/orgConstants';
import {
  Email,
  JobberOrgRatingClientResp,
  JobberProfileResp,
  JobberProfileReq,
  JobberResumeReq,
  JobberResume,
  GetJobberProfileAPIResp,
} from "../orgViews/orgConstants";
import { EmailAddress } from "../common/CommonInterfaces";
import { ca } from "date-fns/locale";
import { retrieveLocalStorageAttributes, saveWorkySessionIdToLocalStorage } from "../common/CommonUtilsMethods";

const backendApiBasePathLocal =
  process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();

const CancelShiftRequestAPICall = async ({ shiftId }: { shiftId: string }) => {
  if (!shiftId) return;

  const apiBody: CancelShiftRequestObj = {
    shiftId: shiftId,
  };

  try {
    const response: AxiosResponse<ServerResponse<string, Error>> =
      await remoteCallObject.remotePOSTCallV2<
        ServerResponse<string, Error>,
        CancelShiftRequestObj
      >({
        apiUrl: backendApiBasePathLocal + "shiftassignment/cancel",
        object: apiBody,
        headers: { "Content-Type": "application/json" },
      });
    console.log("!!!!!POST request successful:", response);

    if (
      response &&
      (response.status === 200 ||
        response.status === 201 ||
        response.status === 202)
    ) {
      console.log("200 or 201 or 202");
      window.location.reload();
    }
  } catch (e) {
    if (
      e.response &&
      (e.response.status === 400 || e.response.status === 500)
    ) {
      console.log("400 or 500");
    }
    console.error("POST request failed:", e);
  }
};

const SendErrorEmailv2 = async () => {
  // e.preventDefault();

  const serviceID = "service_3mlm2ls";
  const templateID = "template_xl6xpjw";
  const userID = "zKdgUxA7H3dMaCf-m";

  const fullname =
    localStorage.getItem("firstName") + " " + localStorage.getItem("lastName");
  const orgemail = localStorage.getItem("userEmail");
  const emailReason = "!URGENT! ERROR SENDING SHIFT REQUEST";
  const emailMessage =
    fullname +
    " from  " +
    localStorage.getItem("orgName") +
    " tried sending a shift request but failed. REQUIRES IMMEDIATE ATTENTION. \n\n" +
    "First Name : " +
    localStorage.getItem("firstName") +
    "\n" +
    "Last Name : " +
    localStorage.getItem("lastName") +
    "\n" +
    "Org Name : " +
    localStorage.getItem("orgName") +
    "\n" +
    "Phone Number : " +
    localStorage.getItem("userPhoneNumber") +
    "\n" +
    "Email : " +
    orgemail +
    "\n";

  try {
    // await emailjs.sendForm(serviceID, templateID, "hello", userID);
    await emailjs.send(
      serviceID,
      templateID,
      {
        user_fullname: fullname,
        user_email: orgemail,
        user_reason: emailReason,
        user_message: emailMessage,
      },
      userID
    );

    console.log("Email Sent Successfully!");
  } catch (error) {
    console.log("An error occurred while sending the email.");
    console.error(error);
  }
};

const GetJobberResume = async (jobberEmail: EmailAddress) => {
  const jobberReq2: JobberResumeReq = {
    value: jobberEmail.value,
  };

  try {
    const response: AxiosResponse<ServerResponse<JobberResume, Error>> =
      await remoteCallObject.remotePOSTCallV2<
        ServerResponse<JobberResume, Error>,
        JobberResumeReq
      >({
        apiUrl: backendApiBasePathLocal + "v2/jobber/download/resume/",
        object: jobberReq2,
        headers: { "Content-Type": "application/json" },
      });
    console.log("POST request successful:", response);
    if (
      response &&
      (response.status === 200 ||
        response.status === 201 ||
        response.status === 202)
    ) {
      window.open(response.data.data.toString());
    }
  } catch (e) {
    console.error(
      "The worker resume could not be retrieved at this time, please try again later.",
      e
    );
    alert(
      "The worker resume could not be retrieved at this time, please try again later."
    );
  }
};

const GetJobberProfile = async (
  jobberEmail: string
): Promise<GetJobberProfileAPIResp> => {
  try {
    if (typeof jobberEmail !== "string") {
      throw new Error("Invalid jobberEmail type");
    }

    const jobberReq: JobberProfileReq = {
      jobberEmail: { value: jobberEmail },
    };

    const response: AxiosResponse<ServerResponse<JobberProfileResp, Error>> =
      await remoteCallObject.remotePOSTCallV2<
        ServerResponse<JobberProfileResp, Error>,
        JobberProfileReq
      >({
        apiUrl: backendApiBasePathLocal + "v2/jobber/profile/details/",
        object: jobberReq,
        headers: { "Content-Type": "application/json" },
      });

    console.log("POST request successful:", response);

    if (
      response &&
      (response.status === 200 ||
        response.status === 201 ||
        response.status === 202)
    ) {
      console.log("here");
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.jobberQuickSummary
      ) {
        // setProfileText(response.data.data.jobberQuickSummary);
        // setProfilePic(response.data.data.jobberProfilePicS3Url);
        return {
          jobberProfilePicS3Url: response.data.data.jobberProfilePicS3Url,
          jobberQuickSummary: response.data.data.jobberQuickSummary,
          jobberFullDetails: response.data.data.jobberFullDetails,
          workyJobberRating: response.data.data.workyJobberRating,
          userDetails: response.data.data.userDetails,
        };
      }
    }
  } catch (e) {
    console.error("Error fetching jobber profile:", e);
    // Replace the undefined function call with the correct one
  }
  return {
    jobberProfilePicS3Url: "",
    jobberQuickSummary: "",
    jobberFullDetails: "",
    workyJobberRating: "",
    userDetails: {
      userId: "",
      firstName: "",
      lastName: "",
      userEmail: { value: "" },
      userPhoneNumber: { value: "" },
    },
  };
};

const GetJobberRatingV3 = async (
  jobberEmail: string
): Promise<JobberOrgRatingClientResp> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl:
      backendApiBasePathLocal + "jobber/performance/rating/org/single-jobber",
    headers: {},
    queryParams: {
      jobberEmail: jobberEmail,
    },
  };

  try {
    const jobberRating: AxiosResponse<
      ServerResponse<JobberOrgRatingClientResp, Error>
    > = await remoteCallObject.remoteGETCallV2<
      ServerResponse<JobberOrgRatingClientResp, Error>
    >(getCallInput);

    if (jobberRating && jobberRating.data && jobberRating.data.data) {
      return jobberRating.data.data;
    }
  } catch (e) {}

  return {
    jobberDetails: {
      userId: "",
      firstName: "",
      lastName: "",
      userEmail: { value: "" },
      userPhoneNumber: { value: "" },
    },
    orgName: "",
    jobberOrgRatingDetailMap: new Map(),
    jobberProfileImageS3Url: "",
  };
};

// Your function
const GetOrgMetadata = async (): Promise<OrgMetaData | undefined> => {
    const getCallInput: RemoteGETCallInputObject = {
      apiUrl: backendApiBasePathLocal + "org/metadata",
      headers: {},
      queryParams: null,
    };
    
    try {
      const orgMetadataRes: AxiosResponse<ServerResponse<OrgMetaData, Error>> =
        await remoteCallObject.remoteGETCallV2<ServerResponse<OrgMetaData, Error>>(getCallInput);
  
      if (orgMetadataRes && orgMetadataRes.data && orgMetadataRes.data.data) {
        console.log("step 3");
        localStorage.setItem("orgName", orgMetadataRes.data.data.orgName);
        localStorage.setItem("orgData", JSON.stringify(orgMetadataRes.data.data));
        return orgMetadataRes.data.data;
      }
    } catch (error) {
      console.error('Error fetching organization metadata:', error);
      throw new Error('Error fetching organization metadata');
    }
  };



const GetCorpDetails = async (): Promise<CorpDataObj> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: backendApiBasePathLocal + "corp/details",
    headers: {},
    queryParams: null,
  };
  
  try {
    const orgMetadataRes: AxiosResponse<ServerResponse<CorpDataObj, Error>> =
      await remoteCallObject.remoteGETCallV2<ServerResponse<CorpDataObj, Error>>(getCallInput);

    if (orgMetadataRes && orgMetadataRes.data && orgMetadataRes.data.data) {      
      console.log("step 3");
      localStorage.setItem("orgName", orgMetadataRes.data.data.corpJobberDetails.corpName);
      localStorage.setItem("orgData", JSON.stringify(orgMetadataRes.data.data));
      return orgMetadataRes.data.data;
    }
  } catch (error) {
    console.error('CorpDataObj:', error);
    throw new Error('CorpDataObj metadata');
  }
};


/**
 * Logs a sales-related user interaction/event to the backend telemetry system
 * @param eventName The name of the event being tracked (e.g., "page_view", "button_click")
 * @param eventData Additional data about the event to include in tracking
 * @returns The unique tracking ID if successful, null otherwise
 */
const TrackSalesEvent = async (
  {eventName, eventData}:{eventName?: string, eventData?: Record<string, string>}
): Promise<string | null> => {
  
  try {        
    if(!eventData){eventData={}}

    // Get current count from sessionStorage
    const currentCount = parseInt(localStorage.getItem('app_load_count') || '0', 10) | 0;
    if (currentCount >= 25) {
        console.log("max reload count acheived will not make more sales calls ", currentCount)
        return "";
    }
    
    localStorage.setItem('app_load_count', (currentCount + 1).toString());
    
    // Use your existing helper method to get all localStorage data
    const localStorageData = retrieveLocalStorageAttributes();
    
    // Get the session ID from localStorage data or create a new one
    const workySessionId = localStorageData.attributes?.worky_session_id || saveWorkySessionIdToLocalStorage();
    
    // Parse UTM parameters from URL if present
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams: Record<string, string> = {};
    
    // Collect UTM parameters if present
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach(param => {
      const value = urlParams.get(param);
      if (value) {
        utmParams[param] = value;
      }
    });
    
    // Build the event data object with the event name and passed data
    const combinedEventData = {
      event_name: eventName,
      event_timestamp: Date.now().toString(),
      ...eventData,
      ...utmParams
    };
    
    // Build the payload using the structure expected by the backend
    const payload: WebBrowserClientSalesTrackerReqDTO = {
      timestamp: Date.now(),
      currentUrl: window.location.href,
      userAgent: navigator.userAgent,
      trackingReason: eventName,
      screenWidth: window.innerWidth.toString(),
      screenHeight: window.innerHeight.toString(),
      referrerUrl: document.referrer || "",
      sessionId: localStorageData.attributes?.sessionId || "",
      workySessionId: workySessionId,
      additionalData: {
        ...combinedEventData,
        // Include any user data we have in localStorage
        userId: localStorageData.attributes?.userId || "",
        userEmail: localStorageData.attributes?.userEmail || "",
        firstName: localStorageData.attributes?.firstName || "",
        lastName: localStorageData.attributes?.lastName || "",
        orgName: localStorageData.attributes?.orgName || ""
      }
    };
    
    console.log("Sending sales tracking event:", payload);
    
    // Make the API call
    const response: AxiosResponse<ServerResponse<WebBrowserClientSalesTrackerResDTO, Error>> =
      await remoteCallObject.remotePOSTCallV2<
        ServerResponse<WebBrowserClientSalesTrackerResDTO, Error>,
        WebBrowserClientSalesTrackerReqDTO
      >({
        apiUrl: backendApiBasePathLocal + "telemetry/client-tracker/sales-tracker",
        object: payload,
        headers: { "Content-Type": "application/json" },
      });
    
    console.log("Sales tracking event sent successfully:", response);
    
    // Extract and return the tracking ID
    if (
      response &&
      response.data &&
      response.data.data &&      
      response.data.data.trackingId
    ) {
      // Store the last tracking ID in case it's needed later
      
      return response.data.data.trackingId;
    }
    
    return null;
  } catch (error) {
    console.error("Failed to send sales tracking event:", error);
    return null;
  }


};


export {
  CancelShiftRequestAPICall,
  SendErrorEmailv2,
  GetJobberResume,
  GetJobberProfile,
  GetJobberRatingV3,
  GetOrgMetadata,
  GetCorpDetails,
  TrackSalesEvent
};
