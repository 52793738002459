import { AxiosResponse } from "axios";
import { RemoteExecutionServiceImpl, RemoteGETCallInputObject, RemotePOSTCallInputObject, ServerResponse } from "../../remoteExecution/RemoteExecutionServiceImpl";
import{JobberSRPendingObj, WorkyPayJobberPayStubDTO, JobberSRPendingDataAPIReq, JobberResponseDTOV2,
   JobberResponseStatusV2, JobberSRPendingDataAPIResp, JobberAssignmentResponseDTO} from '../jobber-data/JobberInterfaces'

const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();

const GetJobberPaystubAPICall = async (): Promise<WorkyPayJobberPayStubDTO | null> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: `${backendApiBasePathLocal}workypay/jobber/paystubs/`,
    headers: {      
    },
    queryParams: null,
  };

  const paystubResponse: AxiosResponse<ServerResponse<WorkyPayJobberPayStubDTO, Error>> = await remoteCallObject.remoteGETCallV2<
    ServerResponse<WorkyPayJobberPayStubDTO, Error>
  >(getCallInput);

  if (paystubResponse && paystubResponse.data && paystubResponse.data.data) {
    return paystubResponse.data.data;
  } else {
    return null;
  }
};


const GetJobberPendingSRs = async (): Promise<JobberSRPendingObj[] | null> => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: `${backendApiBasePathLocal}v2/jobber/pending-shift-requests/`,
    headers: {      
    },
    queryParams: null,
  };

  const paystubResponse: AxiosResponse<ServerResponse<JobberSRPendingObj[], Error>> = await remoteCallObject.remoteGETCallV2<
    ServerResponse<JobberSRPendingObj[], Error>
  >(getCallInput);

  if (paystubResponse && paystubResponse.data && paystubResponse.data.data) {
    return paystubResponse.data.data;
  } else {
    return null;
  }
};



const PostJobberRespToShiftRequest = async (jobberResponse: JobberSRPendingDataAPIReq): Promise<JobberSRPendingDataAPIResp | null> => {
  const postCallInput: RemotePOSTCallInputObject<JobberSRPendingDataAPIReq> = {
    apiUrl: `${backendApiBasePathLocal}v2/jobber/pending-shift-requests/resp`,
    headers: { },
    object:jobberResponse,    
  };

  const orgBillsResponse: AxiosResponse<ServerResponse<JobberSRPendingDataAPIResp, Error>> = 
  await remoteCallObject.remotePOSTCallV2<ServerResponse<JobberSRPendingDataAPIResp, Error>,JobberSRPendingDataAPIReq>(postCallInput);

  if (orgBillsResponse && orgBillsResponse.data && orgBillsResponse.data.data) {
    return orgBillsResponse.data.data;
  } else {
    return null;
  }

};


export { GetJobberPaystubAPICall, GetJobberPendingSRs, PostJobberRespToShiftRequest };