import { WorkyUserDetails, UserDetails, AddressDto, CorpJobberDetails, OrgLocationMapDetails, EmailAddress } from "../../common/CommonInterfaces";
import { ShiftIngressRequestUnitAPIObj } from "../../jobberViews/objects/ShiftRequestConstants";
import { JobberDetailsForOrg } from "../../orgViews/OrgUtils";



interface JobberWorkerTypeDTO{
    workerTypeName: string;
    workerTypeUniqueIdentifier: string;
}


enum JobberAvailMessageType {
    CAN_ACCEPT_ALL = "CAN_ACCEPT_ALL",
    CAN_ACCEPT_NONE = "CAN_ACCEPT_NONE",
    CAN_ACCEPT_MAJORITY = "CAN_ACCEPT_MAJORITY",
    CAN_ACCEPT_MINORITY = "CAN_ACCEPT_MINORITY"
}

interface JobberAvailDetailResp{
    clientFacingMessage: string;
    jobberAvailMessageType: string;
    statusCode:string;
}
interface ShiftRequestClientObjPreHook{
    orgName: string;
    agencyName: string;
    shiftRequestContent: ShiftIngressRequestUnitAPIObj[];
}

interface JobberAvailabilityResponse{
    jobberEmailAddress: EmailAddress;
    jobberAvailabilityResponse: JobberAvailDetailResp ,
    workyUserDetailsZipDTO: JobberDetailsForOrg
}

export {  JobberWorkerTypeDTO, JobberAvailabilityResponse, JobberAvailDetailResp, JobberAvailMessageType, ShiftRequestClientObjPreHook };