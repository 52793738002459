"use client";
import React, { useEffect } from "react";
import {FallbackProps} from 'react-error-boundary';
import { WorkyAppHeader} from "../agency-logic/agency-components/AgencyComponentSetOne";
import { StyleLinks } from "../orgViews/OrgCommonComponents";
import "./globalerrorpage.css"
import CottageRoundedIcon from '@mui/icons-material/CottageRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { RemoteExecutionServiceImpl, RemotePOSTCallInputObject, ServerResponse } from "../remoteExecution/RemoteExecutionServiceImpl";
import { retrieveLocalStorageAttributes } from "../common/CommonUtilsMethods";
import { EnhancedError } from "./ErrorContextProvider";



interface WebBrowserClientErrorReqDTO {
  timestamp: number;
  errorMessage: string;
  stackTrace: string;
  currentUrl: string;
  userAgent: string;
  screenWidth: string;
  screenHeight: string;
  referrerUrl: string;
  sessionId: string;
  workySessionId: string;
  additionalData: Record<string, string>;
}

interface WebBrowserClientErrorRespDTO{
  uniqueGlobalErrorId: string
}



const logErrorToBackend = async (error: Error) => {
  try {
    const remoteCallObject = RemoteExecutionServiceImpl();
    // Check for API details from enhanced error
  const enhancedError = error as EnhancedError;
  const apiUrl = enhancedError.httpUrl || 'unknown';
  const inputData = enhancedError.inputObj || {};
    
    
    // Use your existing helper method to get localStorage data
    const localStorageData = retrieveLocalStorageAttributes();
    
    // Build error payload
    const errorData: WebBrowserClientErrorReqDTO = {
      timestamp: Date.now(),
      errorMessage: error?.message || "Unknown error",
      stackTrace: error?.stack || "No stack trace available",
      currentUrl: window.location.href,
      userAgent: navigator?.userAgent,
      screenWidth: window.innerWidth.toString(),
      screenHeight: window.innerHeight.toString(),
      referrerUrl: document?.referrer || "",      
      sessionId: localStorageData.attributes?.userId || "",
      workySessionId: localStorageData.attributes?.worky_session_id || "",
      additionalData: {
        ...localStorageData.attributes,
        apiUrl: apiUrl, // Include the API URL that failed
        apiRequestData:  inputData ,
        componentPath: "GlobalErrorPage",
        reactVersion: React.version,
        viewportWidth: `${window.innerWidth}`,
        viewportHeight: `${window.innerHeight}`,
        devicePixelRatio: `${window.devicePixelRatio}`,
        url: window.location.href,
        path: window.location.pathname
      }
    };
    
    console.log("Sending error report to backend:", errorData);
    
    // Set up API call
    const postCallInput: RemotePOSTCallInputObject<WebBrowserClientErrorReqDTO> = {
      apiUrl: '/telemetry/client-tracker/global-error-details',
      headers: { 'Content-Type': 'application/json' },
      object: errorData
    };
    
    // Make the API call
    const response = await remoteCallObject.remotePOSTCallV2<ServerResponse<WebBrowserClientErrorRespDTO, Error>, WebBrowserClientErrorReqDTO>(postCallInput);

    if (response && response.data && response.data.data) {
      // Extract the error ID from the response and save it to localStorage
      const apiResp:WebBrowserClientErrorRespDTO  = response.data.data;
      localStorage.setItem('most_recent_error_id', apiResp?.uniqueGlobalErrorId);
      console.log("successfully reported to backend with ID:", apiResp?.uniqueGlobalErrorId);
    } else {
      console.warn("Error reported but no valid ID received from server");
    }

    
  } catch (loggingError) {
    console.error("Failed to log error to backend:", loggingError);
  }
};

function GlobalErrorPage(props: FallbackProps) {
  const { error, resetErrorBoundary } = props;
  console.log("global error has been caught, ", error)

  useEffect(() => {
    // Log the error when component mounts
    console.log("Global error has been caught:", error);
    try{
      logErrorToBackend(error);
    }catch(e){
      console.log("GlobalErrorPage while logErrorToBackend ", e, error)
    }
    
  }, [error]);
  


  const PushtoHomepage = () => {window.location.assign('/homepage');}
  
  
  return (
    <div style={{background:"#f5f5f7"}}>
    <StyleLinks/>
    <WorkyAppHeader />  
    <div className="global-error-holder">
        
        <div className="global-error-part">
            <div className="global-error-part-title">Page not found</div>
            {/* <div className="global-error-part-subtitle">{error.message}.</div> */}
            <div className="global-error-part-subtitle">Our team has been notified and is working on the fix.</div>
            <div className="global-error-part-subtitle">For immediate assistance, please call us at (647) 494-9090</div>
            <div className="global-error-part-subtitle">You can also email us at info@worky.pro</div>
            <div className="global-error-part-btn-holder">
                <button onClick={resetErrorBoundary} className="global-error-btn"><ReplayRoundedIcon sx={{fontSize:"20px"}}/>Reload Page</button>
                <button onClick={PushtoHomepage} id="alt-global-error-btn" className="global-error-btn"><CottageRoundedIcon sx={{fontSize:"20px"}}/>Home</button>
            </div>
        </div>


    </div>

    </div>
  );
}

export { GlobalErrorPage };
