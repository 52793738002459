import React, { useState, useEffect } from "react";
import { GetJobberPaystubAPICall } from '../jobber-api-calls/JobberAPI';
import { WorkyPayJobberPayStubDTO, JobberPayStubDetailsDTO } from '../jobber-data/JobberInterfaces';
import './jobberworkypay.css';
import { Modal, Slide, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { StyleLinks } from '../../orgViews/OrgCommonComponents';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import { DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import { MobileNavV2 } from '../JobberCommonComponents';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


function epochToDateString(epochnum) {
    const date = new Date(epochnum * 1000);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
}
function epochToDateStringWithDay(epochnum) {
    const date = new Date(epochnum * 1000);
    return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' });
}
function epochToTimeString(epochnum) {
    if (isNaN(epochnum)) {return 'N/A';}
    const date = new Date(epochnum * 1000);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
}
function plainNumberToHourlyPayNumber(pay) {
    return `$${pay.toFixed(2)}/hr`; 
  }



const JobberPaystubModal = ({ handleOpen, handleClose, currentPaystub }: { handleOpen: boolean, handleClose: () => void, currentPaystub?: JobberPayStubDetailsDTO }) => {
    if (!currentPaystub) {
        return
    }
    
    const { payStubCycleStartDateTimeEpochSec, payStubCycleEndDateTimeEpochSec, shiftDetailsJobberPayStubViewMap, jobberObligationsDeductionMap, grossEarning, netEarning, totalHoursWorked } = currentPaystub;
    const shiftEntries = Object.values(shiftDetailsJobberPayStubViewMap || {});
    const totalDeductions = Object.values(jobberObligationsDeductionMap || {}).reduce((acc, deduction) => acc + deduction, 0);

    return (
        <Modal open={handleOpen} onClose={handleClose}>
            <Slide direction="up" in={handleOpen} mountOnEnter unmountOnExit>
                <div className="jobber-paystub-modal">
                    <button onClick={handleClose} className='clock-confirmation-modal-mobile-close-mobile'></button>
                    <div className="jobber-paystub-modal-title">
                        {epochToDateString(payStubCycleStartDateTimeEpochSec)} - {epochToDateString(payStubCycleEndDateTimeEpochSec)}
                    </div>
                    <div className="jobber-paystub-modal-top-block-holder">
                        <div className="jobber-paystub-modal-top-block">
                            <div className="jobber-paystub-modal-top-block-value">{totalHoursWorked} hrs</div>
                            <div className="jobber-paystub-modal-top-block-title">Hours Worked</div>
                        </div>
                        <div className="jobber-paystub-modal-top-block">
                            <div className="jobber-paystub-modal-top-block-value">${grossEarning.toFixed(2)}</div>
                            <div className="jobber-paystub-modal-top-block-title">Gross Earnings</div>
                        </div>
                        <div className="jobber-paystub-modal-top-block">
                            <div className="jobber-paystub-modal-top-block-value">${totalDeductions.toFixed(2)}</div>
                            <div className="jobber-paystub-modal-top-block-title">Deductions</div>
                        </div>
                        <div className="jobber-paystub-modal-top-block">
                            <div className="jobber-paystub-modal-top-block-value">${netEarning.toFixed(2)}</div>
                            <div className="jobber-paystub-modal-top-block-title">Net Earnings</div>
                        </div>
                    </div>

                    <div className="jobber-paystub-modal-shift-block-holder">
                        <div/>
                        {shiftEntries.map((shift, index) => (
                            <Accordion key={shift?.shiftId} disableGutters sx={{
                                borderRadius: '15px',
                                backgroundColor: '#f5f5f7',
                                boxShadow: 'none',
                                border: 'none',
                                '&:before': { display: 'none' }
                            }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                                    <div className="jobber-paystub-modal-shift-block-date">
                                        <div className="jobber-paystub-modal-icon"><WorkRoundedIcon sx={{ fontSize: "20px" }} /></div>
                                        {epochToDateString(shift?.shiftStartDateTimeEpochSec)}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="jobber-paystub-modal-detail-container">

                                        <div className="jobber-paystub-modal-detail-holder">
                                            <div className="jobber-paystub-modal-detail-title">Start Time</div>
                                            <div className="jobber-paystub-modal-detail-value">{epochToTimeString(shift?.shiftStartDateTimeEpochSec)}</div>
                                        </div>
                                        
                                        <div className="jobber-paystub-modal-detail-holder">
                                            <div className="jobber-paystub-modal-detail-title">End Time</div>
                                            <div className="jobber-paystub-modal-detail-value">{epochToTimeString(shift?.shiftEndDateTimeEpochSec)}</div>
                                        </div>
                                        <div className="jobber-paystub-modal-detail-holder">
                                            <div className="jobber-paystub-modal-detail-title">Hourly Rate</div>
                                            <div className="jobber-paystub-modal-detail-value">${shift?.jobberHourlyRate}/hr</div>
                                        </div>

                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                        <div/>
                    </div>

                    <div className="jobber-paystub-modal-btn-holder">
                        <button style={{ background: "#ebebeb", color: "#7d7d7d" }} className="jobber-paystub-modal-btn"><QuestionMarkRoundedIcon /></button>
                        <button className="jobber-paystub-modal-btn"><FileDownloadOutlinedIcon /></button>
                    </div>
                </div>
            </Slide>
        </Modal>
    );
};

const PageHeader = ({isMobile}:{isMobile:boolean}) => {
  
    return(
            <div className={isMobile?'payments-header-holder-mobile':'payments-header-holder'}>
                <div className='payments-header'>
                    <div className='payments-header-title-holder'>
                        <button id="payments-header-active" className='payments-header-title-switch'>My Paystubs</button>
                    </div>
                    <div className='payments-header-subtitle'>View and Manage your Paystub</div>
                </div>
            </div>
    )
};

const WorkyPayJobberPage = () => {
    const [jobberPayStub, setJobberPayStub] = useState<WorkyPayJobberPayStubDTO | null>(null);
    const [jobberPaystubOpen, setJobberPaystubOpen] = useState(false);
    const [currentPaystubForModal, setCurrentPaystubForModal] = useState<JobberPayStubDetailsDTO | null>(null);

    const handleOpenJobberPaystubOpen = (payStub: JobberPayStubDetailsDTO) => {
        setCurrentPaystubForModal(payStub);
        setJobberPaystubOpen(true);
    };
    const handleCloseJobberPaystubOpen = () => {
        setJobberPaystubOpen(false);
        setCurrentPaystubForModal(null);
    };

    useEffect(() => {
        const fetchJobberPayStubData = async () => {
            const jobberPayStubData = await GetJobberPaystubAPICall();
            setJobberPayStub(jobberPayStubData);
            console.log("jobberPayStubData", JSON.stringify(jobberPayStubData));
        };

        fetchJobberPayStubData();
    }, []);

    const rows = jobberPayStub ? [{
        id: 1,
        startEpoch: jobberPayStub.currentPayStub.payStubCycleStartDateTimeEpochSec,
        endEpoch: jobberPayStub.currentPayStub.payStubCycleEndDateTimeEpochSec,
        grossEarning: jobberPayStub.currentPayStub.grossEarning,
    }] : [];

    const columns: GridColDef[] = [
        {
            field: 'startEndEpoch',
            headerName: 'Paystub Cycle',
            width: 225,
            headerClassName: 'jobber-paystub-header',
            cellClassName: 'no-overflow-cell',
            renderCell: (params: GridRenderCellParams) => (
                <div className="daterange-col">
                    {epochToDateString(params.row.startEpoch)} - {epochToDateString(params.row.endEpoch)}
                </div>
            ),
        },
        {
            field: 'grossEarning',
            headerName: 'Gross ($)',
            width: 135,
            headerAlign: 'right',
            headerClassName: 'jobber-paystub-header',
            cellClassName: 'no-overflow-cell',
            renderCell: (params: GridRenderCellParams) => (
                <div className="earnings-col" style={{ textAlign: 'right', width: '100%', overflow: 'hidden' }}>
                    <div>{`$${params.row.grossEarning.toFixed(2)}`}</div>
                </div>
            ),
        },
    ];

    return (
        <>
            <MobileNavV2/>
            <StyleLinks/>
            <PageHeader isMobile={true}/>
            <JobberPaystubModal 
                handleOpen={jobberPaystubOpen} 
                handleClose={handleCloseJobberPaystubOpen} 
                currentPaystub={currentPaystubForModal} 
            />

            <div className="jobber-paystub-container">
                <div className="jobber-paystub-holder">
                    <DataGrid
                        sx={{
                            borderRadius: '10px',
                            border: '1px solid #ebebeb',
                            background: 'white',
                            overflowX: 'hidden',
                        }}
                        rows={rows}
                        columns={columns}
                        autoHeight
                        pageSizeOptions={[5,10]}
                        onRowClick={() => handleOpenJobberPaystubOpen(jobberPayStub?.currentPayStub)}
                    />
                </div>
            </div>
        </>
    );
};

export { WorkyPayJobberPage, epochToTimeString, epochToDateString, epochToDateStringWithDay,plainNumberToHourlyPayNumber };