import React, { useCallback, useState, useEffect, useRef } from "react";
import "../../agency-logic/agency-pages/agencyhomepage.css";
import { GetCorpSRIP, SRIPResponseData } from "../../common/ShiftReadAPIs";
import { useIsMobile } from "../../custom-hooks/useIsMobile";
import { StyleLinks } from "../OrgCommonComponents";
import { WorkyAppHeader, WorkyContactButton, WorkyTopShelf } from "../../agency-logic/agency-components/AgencyComponentSetOne";
import { WorkyAgencyCarouselV2 } from "../../agency-logic/agency-components/WorkyAgencyCarouselV2";

const OrgHomePageV2 = ({}) => {
  const [orgSRIPResponse, setOrgSRIPResponse] = useState<SRIPResponseData[] | null>(null);
  const [shiftEvents, setShiftEvents] = useState([]);
  const isMobile = useIsMobile();
  
  
  
  useEffect(() => {
    

    const fetchSRIPData = async () => {
      const SRIPData = await GetCorpSRIP({ setOrgSRIPResponse, setShiftEvents });
      // console.log("TESTING RETURN SRIP DATA HOMEPAGE  "+JSON.stringify(SRIPData));
    };
            
    fetchSRIPData();
  }, []);  
  
  
  return <div className={isMobile ? "worky-app-mobile" : "worky-app"}>
    
    <StyleLinks/>
    <WorkyAppHeader/>
    <WorkyTopShelf/>

    {orgSRIPResponse && orgSRIPResponse.length > 0 ? (
          <WorkyAgencyCarouselV2
            SRIP={orgSRIPResponse}
            isMobile={isMobile}
          />
      ): null}

        <WorkyContactButton/>    
    
    </div>;
};

export { OrgHomePageV2 };
