import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useIsMobile } from '../../custom-hooks/useIsMobile';
import { StyleLinks, TopNav, PushToNewSr, PushtoSrHistory } from '../../orgViews/OrgCommonComponents';
import { WorkyAppHeader, WorkyContactButton, WorkyFooter } from '../agency-components/AgencyComponentSetOne';
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Modal, Slide } from '@mui/material';
import "./workysales.css"
import { GetAgencySalesLeadDTO } from '../agency-api-calls/AgencyAPI';
import { AgencySalesLeadDTO } from '../agency-data/AgencyInterfaces';


const PageHeader = ({isMobile}:{isMobile:boolean}) => {
  
    return(
            <div className={isMobile?'payments-header-holder-mobile':'payments-header-holder'}>
                <div className='payments-header'>
                    <div className='payments-header-title-holder'>
                        <button id="payments-header-active" className='payments-header-title-switch'>Worky Leads</button>
                    </div>
                    <div className='payments-header-subtitle'>View and search new job postings in your area</div>
                </div>
            </div>
    )
}



const WorkyLeadStatusTagBuilder = ({ leadStatus }: { leadStatus: string }) => {
    const getColor = () => {
        switch (leadStatus) {
            case 'NEW': return '#2196F3'; // Blue
            case 'CONTACTED': return '#FF9800'; // Orange 
            case 'INTERESTED': return '#4CAF50'; // Green
            case 'NOT_INTERESTED': return '#F44336'; // Red
            case 'CLOSED': return '#9E9E9E'; // Gray
            default: return '#9E9E9E'; // Default gray
        }
    };

    return (
        <div className="list-cell-icon-holder">
            <Chip
                label={leadStatus}
                sx={{
                    backgroundColor: getColor(),
                    color: 'white',
                    fontWeight: 'bold'
                }}
            />
        </div>
    );
};

const WorkyLeadsTable = ({ isMobile, leads }: { isMobile: boolean, leads: AgencySalesLeadDTO[] }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalParams, setModalParams] = useState<any>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const handleClose = () => setModalOpen(false);

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        setModalOpen(true);
        setModalParams(params.row);
    };

    const columns: GridColDef[] = [
        {
            field: 'icon',
            headerName: '',
            width: 60,
            align: 'center',
            renderHeader: () => <div className="billing-header"></div>,
            renderCell: (params: GridRenderCellParams) => (
                <div className="list-cell-icon-holder">
                    <img className="list-cell-icon" src={params.value} alt="Icon" />
                </div>
            ),
        },
        { field: 'name', headerName: 'Name', headerClassName: "agency-billing-inner-table-header", width: 110, editable: false },
        { field: 'company', headerName: 'Company Name', headerClassName: "agency-billing-inner-table-header", width: 150, editable: false },
        { field: 'city', headerName: 'City', headerClassName: "agency-billing-inner-table-header", width: 150 },
        { field: 'email', headerName: 'Email', headerClassName: "agency-billing-inner-table-header", width: 150 },
        { field: 'phone', headerName: 'Phone Number', headerClassName: "agency-billing-inner-table-header", width: 120 },
        { field: 'businessType', headerName: 'Work Type', headerClassName: "agency-billing-inner-table-header", width: 150 },
        { field: 'source', headerName: 'Source', headerClassName: "agency-billing-inner-table-header", width: 110 },
        { 
            field: 'notes', 
            headerName: 'Notes', 
            headerClassName: "agency-billing-inner-table-header", 
            width: 180,
            renderCell: (params) => params.value || '—'  // Fallback for empty notes
        },
        { 
            field: 'status', 
            headerName: 'Status',
            headerClassName: "agency-billing-inner-table-header", 
            width: 180, 
            renderCell: (params) => <WorkyLeadStatusTagBuilder leadStatus={params.value || 'Unknown'} />
        },
    ];

    // Transform AgencySalesLeadDTO array into the format expected by DataGrid
    const rows = leads.map((lead, index) => {
        // Get the first contact if available, otherwise use placeholder data
        const primaryContact = lead.contactDetails && lead.contactDetails.length > 0 
            ? lead.contactDetails[0] 
            : null;
            
        // Get the first note if available
        const firstNote = lead.agencySalesLeadNotes && lead.agencySalesLeadNotes.length > 0 
            ? lead.agencySalesLeadNotes[0].note 
            : "";
            
        return {
            id: index + 1,
            name: primaryContact?.contactName || 'No Contact',
            company: lead.businessName,
            city: lead.businessCity,
            email: primaryContact?.salesLeadContactEmailAddress || 'No Email',
            phone: primaryContact?.salesLeadContactPhoneNumber || 'No Phone',
            businessType: lead.businessDetails?.lineOfBusiness || 'N/A',
            source: lead.sourceOfLead || 'N/A',
            notes: firstNote,
            status: lead.agencySalesLeadStatus,
            // Include original data for the modal
            originalData: lead
        };
    });

    const filteredRows = rows.filter(row => 
        Object.values(row).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        })
    );

    const formatDate = (timestamp: number | undefined): string => {
        if (!timestamp) return 'N/A';
        
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
            year: 'numeric', 
            month: 'short', 
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <>
        <div className={isMobile ? 'workforce-search-container-mobile' : 'workforce-search-container-alt'}>
            <input
                type="text"
                placeholder="Search Lead"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="workforce-search-input-alt"
            />
        </div>

        <DataGrid
            rows={filteredRows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10, 20]}
            onRowClick={handleRowClick}
            sx={{
                borderRadius: '10px',
                paddingLeft:'10px',
                paddingRight:'10px',
                border: '1px solid',
                borderColor: '#ebebeb',
                background: 'white',
                maxWidth: '100%',
            }}
        />
        <Modal open={modalOpen} onClose={handleClose}>
            <Slide direction={isMobile ? "up" : "left"} in={modalOpen} mountOnEnter unmountOnExit>
                <div className={isMobile ? "agency-billing-modal-mobile" : "agency-billing-modal"}>
                    {isMobile ? (
                        <button onClick={handleClose} className='timeclock-close-mobile'/>
                    ) : (
                        <button onClick={handleClose} className="agency-billing-close">
                            <CloseRoundedIcon />
                        </button>
                    )}
                    
                    <div className='agency-billing-modal-icon-alt'>
                        <img className="list-cell-icon" src={modalParams?.icon} alt="Icon" />
                    </div>
                    
                    <div className='agency-billing-modal-inner-holder'>
                        <div className='agency-billing-modal-inner-price'>{modalParams?.company}</div>
                    </div>
                    
                    <div className='agency-billing-modal-payments-title-alt'>Lead Details</div>

                    <div className='agency-billing-modal-inner-holder-two'>
                        <div className='agency-billing-modal-inner-value-holder'>
                            <div className='agency-billing-modal-inner-title'>Status:</div>
                            <div className='agency-billing-modal-inner-value'>
                                <WorkyLeadStatusTagBuilder leadStatus={modalParams?.status || 'Unknown'} />
                            </div>
                        </div>

                        <div className='agency-billing-modal-inner-value-holder'>
                            <div className='agency-billing-modal-inner-title'>Primary Contact:</div>
                            <div className='agency-billing-modal-inner-value'>{modalParams?.name}</div>
                        </div>

                        <div className='agency-billing-modal-inner-value-holder'>
                            <div className='agency-billing-modal-inner-title'>City:</div>
                            <div className='agency-billing-modal-inner-value'>{modalParams?.city}</div>
                        </div>

                        <div className='agency-billing-modal-inner-value-holder'>
                            <div className='agency-billing-modal-inner-title'>Email:</div>
                            <div className='agency-billing-modal-inner-value'>{modalParams?.email}</div>
                        </div>

                        <div className='agency-billing-modal-inner-value-holder'>
                            <div className='agency-billing-modal-inner-title'>Phone:</div>
                            <div className='agency-billing-modal-inner-value'>{modalParams?.phone}</div>
                        </div>

                        <div className='agency-billing-modal-inner-value-holder'>
                            <div className='agency-billing-modal-inner-title'>Work Type:</div>
                            <div className='agency-billing-modal-inner-value'>{modalParams?.businessType}</div>
                        </div>

                        <div className='agency-billing-modal-inner-value-holder'>
                            <div className='agency-billing-modal-inner-title'>Source:</div>
                            <div className='agency-billing-modal-inner-value'>{modalParams?.source}</div>
                        </div>
                        
                        <div className='agency-billing-modal-inner-value-holder'>
                            <div className='agency-billing-modal-inner-title'>Generated On:</div>
                            <div className='agency-billing-modal-inner-value'>
                                {modalParams?.originalData && formatDate(modalParams.originalData.leadFirstGenerationDateTimeEpochSec)}
                            </div>
                        </div>
                        
                        <div className='agency-billing-modal-inner-value-holder'>
                            <div className='agency-billing-modal-inner-title'>Last Contacted:</div>
                            <div className='agency-billing-modal-inner-value'>
                                {modalParams?.originalData && formatDate(modalParams.originalData.lastContactedDateTimeEpochSec)}
                            </div>
                        </div>
                        
                        <div className='agency-billing-modal-inner-notes-value-holder'>
                            <div className='agency-billing-modal-inner-title'>Notes:</div>
                            <div className='agency-billing-modal-inner-notes-value'>{modalParams?.notes || 'No notes available'}</div>
                        </div>
                    </div>
                </div>
            </Slide>
        </Modal>
        </>
    );
};





const WorkyAgencySales = () => {
    const isMobile = useIsMobile();
    // Add state for storing leads
    const [leads, setLeads] = useState<AgencySalesLeadDTO[]>([]);
    // Add loading state
    const [isLoading, setIsLoading] = useState(true);
    
    // Fixed useEffect
    useEffect(() => {
      const fetchSalesLeads = async () => {
        try {
          setIsLoading(true);
          const agencySalesLeadDTO: AgencySalesLeadDTO[] = await GetAgencySalesLeadDTO();
          setLeads(agencySalesLeadDTO);
        } catch (error) {
          console.error("Failed to fetch sales leads:", error);
          // You could also set an error state here
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchSalesLeads();
    }, []);
    
    // Then pass the leads to your table component
    return (
      <>
        <div className='org-app'>
          <StyleLinks/>    
          <WorkyAppHeader/>
          <PageHeader isMobile={isMobile}/>
          <div className={isMobile?'timeclock-table-holder-mobile':'agency-billing-table-holder'}>
            {isLoading ? (
              <div className="loading-indicator">Loading...</div>
            ) : (
              <WorkyLeadsTable isMobile={isMobile} leads={leads} />
            )}
          </div>
          <WorkyContactButton/>
        </div>
      </>
    );
  };


export {WorkyAgencySales}