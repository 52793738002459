import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import {
	ServerResponse,
	RemoteExecutionServiceImpl,
	RemoteGETCallInputObject,
} from '../remoteExecution/RemoteExecutionServiceImpl';
// import Map from 'react-map-gl';
// import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { Map, Overlay } from "pigeon-maps"

interface RemoteClientRequestMetaData{
  userAgent?: string;
  screenResolution?: string;
  viewportSize?: string;
  os?: string;
  timezone?: string;
  language?: string;
  referrer?: string;
  cookiesEnabled?: boolean;
  jsEnabled?: boolean;
}

interface GeoLocationCheckInOut{
  latitude: string;
  longitude: string;
  requestMetaData?: RemoteClientRequestMetaData;

}

interface GeoLocationCheckInOutResp{
  isValid: boolean;
  timeInEpoc: number;
  corpName: string;
}

const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();


const GeoLocationJobberServiceV2 = () => {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude });
    };

    const handleError = (error) => {
      setError(error.message);
    };

    // Request permission to access the user's location
    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  }, []);

  return (
    <div>
      {location && (
        <p>
          Latitude: {location.latitude}, Longitude: {location.longitude}
        </p>
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
};


const LocationComponent = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [apiResponse, setApiResponse] = useState<GeoLocationCheckInOutResp>(null);

  useEffect(() => {
    const permission = localStorage.getItem('locationPermission');
    if (permission !== 'granted') {
      handleGetLocation();
    }
  }, []);  

  const handleGetLocation = () => {

    const makeRemoteCallForCheckingInOut = async (location) => {
      try{
        const response: AxiosResponse<ServerResponse<GeoLocationCheckInOutResp, Error>> =  
        await remoteCallObject.remotePOSTCallV2<
          ServerResponse<GeoLocationCheckInOutResp, Error>,
          GeoLocationCheckInOut
        >({
          apiUrl: backendApiBasePathLocal + "jobber/timeclock/geolocation/clock-in-out",
          object: { latitude: location.latitude,
             longitude: location.longitude,
              requestMetaData:{
                userAgent: navigator.userAgent,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        viewportSize: `${window.innerWidth}x${window.innerHeight}`,
        // os: getOS(), // You will need to implement a function to get the OS
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        language: navigator.language,
        referrer: document.referrer,
        cookiesEnabled: navigator.cookieEnabled,
        jsEnabled: true, // Assuming JS is enabled since this code is running
              }},
          headers: { "Content-Type": "application/json" },
        });
        setApiResponse(response.data.data);
        console.log('got value: ',response.data.data.isValid , 'response', response.data.data.isValid ? 'You are clocked in' : 'You are not clocked in')
      }catch(e){
        console.log('error', e)
        setApiResponse({isValid: false, timeInEpoc: null, corpName: null});
      }
    
    }
  

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          makeRemoteCallForCheckingInOut({latitude: position.coords.latitude, longitude: position.coords.longitude});
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          setError(null);
          localStorage.setItem('locationPermission', 'granted');        
          
        },
        (err) => {
          switch (err.code) {
            case err.PERMISSION_DENIED:
              setError('Please allow for location tracking in order to clock in for shift.');
              localStorage.setItem('locationPermission', 'denied');
              break;
            case err.POSITION_UNAVAILABLE:
              setError('Unable to clock in please contact Worky Team.: (647) 494-9090');
              break;
            case err.TIMEOUT:
              setError('Unable to clock in please contact Worky Team.: (647) 494-9090');
              break;            
            default:
              setError('Unable to clock in please contact Worky Team.: (647) 494-9090');
              break;
          }
        },
        {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          }
      );
    } else {
      setError('Unable to clock in please contact Worky Team.: (647) 494-9090');
    }
  };
  
  const center: [number, number] = [location.latitude, location.longitude];
  return (
    <>
      <button onClick={handleGetLocation} className='jobber-clockin-button'>Clock In</button>
      {location.latitude && location.longitude && (
        <div className='jobber-clock-location-holder'>
          {apiResponse && (
            <div className='jobber-clock-location-resp'>
              {apiResponse.isValid ? 'Clocked in Successfully' : 'You are not clocked in'}
            </div>
          )}
          <div className='jobber-clock-map-holder'>
            {/* <MapContainer center={center}zoom={16}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; OpenStreetMap contributors"
              />
              <Marker position={center}></Marker>
            </MapContainer> */}
            <Map height={160} defaultCenter={[location.latitude, location.longitude]}  defaultZoom={16}>
              <Overlay anchor={[location.latitude, location.longitude]} offset={[10, 30]}><div className='jobber-map-pin'></div></Overlay>
            </Map>

          </div>
        </div>
      )}
      {error && <p>Error: {error}</p>}

      
    </>
  );
};





export { GeoLocationJobberServiceV2, LocationComponent}