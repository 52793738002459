import React, { useState, useEffect, useRef } from "react";
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import { MdForklift } from "react-icons/md";
import { SRIPResponseData } from "../../common/ShiftReadAPIs";
import { ShiftRequestUnit } from "../../common/CommonInterfaces";


const extractDateTitleFromWorkyWorkdayShift = (str: string): string => {
    try {
      // Extract the date part from the string
      const dateStr = str.split('T')[0]; 
  
      // Create a Date object from the date string
      const dateObj = new Date(dateStr);
  
      // Handle potential timezone issues (crucial for accuracy)
      dateObj.setUTCHours(12, 0, 0, 0); // Set to 12:00 UTC 
  
      // Format the date as "Jan 20"
      return dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }); 
    } catch (error) {
      // Handle invalid input strings
      return "";
    }
  };

const extractTimeFromWorkyWorkdayShift = (str: string): string => {
    try {
      // Extract time information from the string
      const timeStr = str.split('T')[1]; 
      const [startTime, endTime] = timeStr.split('_');
  
      // Extract hours and minutes from start and end times
      const [startHour, startMinute] = startTime.split(':');
      const [endHour, endMinute] = endTime.split(':');
  
      // Format time strings
      const formatTime = (hour, minute) => {
        const amPm = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12 || 12; 
        minute = minute && minute !== '00' ? `:${minute}` : ''; 
        return `${hour}${minute}${amPm}`;
      };
  
      const formattedStartTime = formatTime(parseInt(startHour), startMinute);
      const formattedEndTime = formatTime(parseInt(endHour), endMinute);
  
      return `${formattedStartTime} - ${formattedEndTime}`;
    } catch (error) {
      return "";
    }
  };


  const extractTimeFromEpochs = (startTimeEpoch: number, endTimeEpoch: number): string => {
    try {
      // Convert epochs to Date objects
      const startDate = new Date(startTimeEpoch);
      const endDate = new Date(endTimeEpoch);
  
      // Extract hours and minutes
      const startHour = startDate.getHours();
      const startMinute = String(startDate.getMinutes()).padStart(2, '0'); 
      const endHour = endDate.getHours();
      const endMinute = String(endDate.getMinutes()).padStart(2, '0'); 
  
      // Format time strings
      const formatTime = (hour: number, minute: string): string => {
        const amPm = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12; 
        return `${hour12}:${minute}${amPm}`;
      };
  
      const formattedStartTime = formatTime(startHour, startMinute);
      const formattedEndTime = formatTime(endHour, endMinute);
  
      return `${formattedStartTime} - ${formattedEndTime}`;
    } catch (error) {
      return ""; 
    }
  };

  const formatEpochTimeRange = (startEpoch, endEpoch):String => {
    const formatTime = (epoch: number): string => {
      const date = new Date(epoch * 1000); // Convert epoch to milliseconds
      const hours = date.getHours();
      const minutes = date.getMinutes();
  
      // Convert 24-hour time to 12-hour time
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Adjust for 12-hour clock
  
      // Format minutes if they exist, else just return the hour
      const formattedMinutes = minutes > 0 ? `:${minutes.toString().padStart(2, "0")}` : "";
  
      return `${formattedHours}${formattedMinutes}${period}`;
    };
  
    const startTime = formatTime(startEpoch);
    const endTime = formatTime(endEpoch);
  
    return `${startTime} - ${endTime}`;
  };

  const WorkerTypeTagBuilder = ({ workertype }:{ workertype:string }) => {
    if(workertype === "WAREHOUSE_ASSOCIATE"){return(<div id="cluster-inner-warehouse" className="agency-shift-cluster-inner-shift-workertype"><WarehouseRoundedIcon sx={{fontSize:"17px"}}/>Warehouse Associate</div>)}
    else if(workertype === "FORKLIFT_OPERATOR"){return(<div id="cluster-inner-forklift" className="agency-shift-cluster-inner-shift-workertype"><MdForklift/>Forklift Operator</div>)}
    else if(workertype === "GENERAL_LABOUR"){return(<div id="cluster-inner-" className="agency-shift-cluster-inner-shift-workertype"><EngineeringRoundedIcon sx={{fontSize:"17px"}}/>General Labour</div>)}
}


const shelfTitleConverter = (str: string): string => {
  console.log("shelfTitleConverter : " + str)
    try {
      // Extract date part from the string
      const dateStr = str.split('T')[0]; 
  
      // Create Date object
      const date = new Date(dateStr); 
  
      // Check if date is valid
      if (isNaN(date.getTime())) { 
        return "Invalid Date"; 
      }
  
      // Handle potential timezone issues (crucial for accuracy)
      date.setUTCHours(12, 0, 0, 0); // Set to 12:00 UTC
  
      // Define formatting options
      const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };
  
      // Format and return date
      return new Intl.DateTimeFormat('en-US', options).format(date); 
  
    } catch (error) {
      return "Error parsing date";
    }
  };



  function shiftRequestAggregator(data: any[]): SRIPResponseData[] {
    if (!Array.isArray(data) || data.length === 0) {
      console.warn("SRIP is not an array or is empty:", data);
      return []; // Return empty events array if SRIP is not an array or is empty
    }
    const mergedData: Record<string, SRIPResponseData> = {};
  
    data.forEach((entry) => {
      const { workyWorkdayShift, inProgressShiftRequests, assignedShiftRequests } = entry;
  
      if (!mergedData[workyWorkdayShift]) {
        mergedData[workyWorkdayShift] = {
          workyWorkdayShift,
          inProgressShiftRequests: [],
          assignedShiftRequests: [],
        };
      }
  
      const transformShiftRequest = (request: any): ShiftRequestUnit => ({
        shiftRequestUnitId: request.shiftRequestUnitId,
        parentShiftRequestId: request.parentShiftRequestId,
        shiftRequestStatus: request.shiftRequestStatus,
        workerType: request.jobberWorkerTypes?.[0]?.workerTypeName || "",
        requestingUserDetails: request.requestingUserDetails,
        assignedAgencyName: request.assignedAgencyNameAfterSACreate || null,
        workyWorkdayShift: request.workyWorkdayShift,
        simpleLocation: request.simpleLocation,
        orgName: request.orgName,
        jobberHourlyRate: request.jobberHourlyRate || 0,
        orgHourlyRate: request.orgHourlyRate,
        startDateTimeEpochSeconds: request.startDateTimeEpochSeconds,
        endDateTimeEpochSeconds: request.endDateTimeEpochSeconds,
        startDateTime: request.startDateTime,
        endDateTime: request.endDateTime,
        startDay: request.startDay,
        startTime: request.startTime,
        submissionDateTimeEpochSeconds: request.submissionDateTimeEpochSeconds,
        submissionDateTime: request.submissionDateTime,
        orgAddress: {
          street: request.orgAddress.street,
          unitNumber: request.orgAddress.unitNumber,
          city: request.orgAddress.city,
          province: request.orgAddress.province,
          postalCode: request.orgAddress.postalCode,
          country: request.orgAddress.country,
          majorIntersection: request.orgAddress.majorIntersection,
          entranceInstructions: request.orgAddress.entranceInstructions || "",
        },
        lastJobberPoolNotifTime: request.lastJobberPoolNotifTime || null,
        lastJobberPoolNotifTimeEpochSec: request.lastJobberPoolNotifTimeEpochSec || null,
        preferredJobbers: Object.keys(request.jobberEmailsAlreadyNotified || {}).map((email) => ({ value: email })),
        updatedDateTimeEpoch: request.updatedDateTimeEpoch,
        updatedDateTime: request.updatedDateTime,
        assignedJobberProfilePicS3URL: request.assignedJobberProfilePicS3URL || "",
        assignedJobberUserDetails: request.assignedJobberUserDetailsAfterSACreate || null,
      });
  
      mergedData[workyWorkdayShift].inProgressShiftRequests.push(
        ...inProgressShiftRequests.map(transformShiftRequest)
      );
      mergedData[workyWorkdayShift].assignedShiftRequests.push(
        ...assignedShiftRequests.map(transformShiftRequest)
      );
    });
  
    return Object.values(mergedData);
  }


  type AggregatedShift = {
    workyWorkdayShift: string;
    inProgressCount: number;
    assignedCount: number;
    totalCount: number;
  };

  const aggregateShifts = (data: SRIPResponseData[]): AggregatedShift[] => {
    // console.log(JSON.stringify(data))
    
    return data.map((shiftData) => {
      const inProgressCount = shiftData?.inProgressShiftRequests?.length;
      const assignedCount = shiftData?.assignedShiftRequests?.length;
      const totalCount = inProgressCount + assignedCount;
      
      return {
        workyWorkdayShift: shiftData.workyWorkdayShift,
        inProgressCount,
        assignedCount,
        totalCount
      };
    });
  };
  
  
  



export { AggregatedShift, extractDateTitleFromWorkyWorkdayShift, extractTimeFromWorkyWorkdayShift, WorkerTypeTagBuilder, shelfTitleConverter, extractTimeFromEpochs,formatEpochTimeRange, shiftRequestAggregator, aggregateShifts}