import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import {UserDetails}  from '../common/CommonInterfaces';
import {CorpDataObj} from '../orgViews/orgConstants';
import {GetCorpDetails, GetOrgMetadata} from '../shared-engineering-services/SharedAPICalls';
import {
	ShiftRequest,
	WorkyWorkdayShift,
	calculateShiftTotals,
	extractDate,
	convertTo12HourFormat,
	generateWorkyWorkdayShiftInput,
	formatTimeString,
	padTime,
	combineDateAndTimeToEpoch,
	getHour,
	getMins,
	parseMonthAndDayV2,
} from './OrgUtils';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/joy/CircularProgress';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { TopNav, PushToNewSr, PushtoSrHistory } from './OrgCommonComponents';
import {	
	ServerResponse,
	RemoteExecutionServiceImpl,	
	RemoteGETCallInputObject,	
} from '../remoteExecution/RemoteExecutionServiceImpl';
import {
	OrgMetaData,
	JobberProfileBuilder,
	FinancialProgressTrackerClientObj,	
} from './orgConstants';
import emailjs from 'emailjs-com';
import './orghomepage.css';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import { Tooltip } from '@mui/material';
import {  WorkyTimePickerV2 } from '../common/TimePicker';
import { JobberProfile } from './jobberProfileBuilder';
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';
import { JobberProfileModalComponent } from './JobberProfileComponentOpen';
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;




interface CancelShiftRequestObj {
	shiftId: string;
}

interface ModifyShiftTimeRequest {
	shiftId: string;
	newShiftStartTime?: number;
	newShiftEndTime?: number;
	reason?: string;
}

interface SRIPResponseData {
	workyWorkdayShift: string;
	inProgressShiftRequests: ShiftRequest[];
	assignedShiftRequests: ShiftRequest[];
}

interface SRIPRespObj {
	shiftRequestWorkDayMap: Map<string, SRIPResponseData[]>;
  }

const OrgHomePage = ({ userDetails }: { userDetails: UserDetails }) => {
	const isMobile = useIsMobile();

	const [orgBudgetData, setOrgBudgetData] = useState<FinancialProgressTrackerClientObj | null>(null);

	const [orgMetaData, setOrgMetadata] = useState<OrgMetaData | null>(null);
	const [corpDetails, setCorpDetails] = useState<CorpDataObj | undefined>(undefined);
	const [orgSRIPResponse, setOrgSRIPResponse] = useState<SRIPResponseData[] | null>(null);
	const [orgIcon, setOrgIcon] = useState<string | null>(null);
	const remoteCallObject = RemoteExecutionServiceImpl();
	const ref = useRef(null);

	const [modalOpen, setModalOpen] = useState(false);
	const [modalShiftDate, setModalShiftDate] = useState('');
	const [modalShiftTime, setModalShiftTime] = useState('');
	const [modalShiftId, setModalShiftId] = useState('');
	const [modalJobberClassification, setModalJobberClassification] = useState('');
	const [modalOrgHourlyPay, setModalOrgHourlyPay] = useState('');
	const [modalRequester, setModalRequester] = useState('');
	const [modalTotalJobbers, setModalTotalJobbers] = useState('');
	const [modalAcutalEndTime, setModalActualEndTime] = useState('');
	const [modalAcutalStartTime, setModalActualStartTime] = useState('');
	const [modalActualDate, setModalActualDate] = useState('');
	const [modalAssignedJobbers, setModalAssignedJobbers] = useState([]);
	const [modalAssignedJobbers2, setModalAssignedJobbers2] = useState<JSX.Element[] | null>(null);


	const [weeklyTotalstate, setWeeklyTotalstate] = useState(0);
	const [weeklyRemainingstate, setWeeklyRemainingstate] = useState(0);

	const [sundayValstate, setsundayValstate] = useState(0);
	const [mondayValstate, setmondayValstate] = useState(0);
	const [tuesdayValstate, settuesdayValstate] = useState(0);
	const [wednesdayValstate, setwednesdayValstate] = useState(0);
	const [thursdayValstate, setthursdayValstate] = useState(0);
	const [fridayValstate, setfridayValstate] = useState(0);
	const [saturdayValstate, setsaturdayValstate] = useState(0);

    const [sundayShifts, setSundayShifts] = useState<number>(0);
    const [mondayShifts, setMondayShifts] = useState<number>(0);
    const [tuesdayShifts, setTuesdayShifts] = useState<number>(0);
    const [wednesdayShifts, setWednesdayShifts] = useState<number>(0);
    const [thursdayShifts, setThursdayShifts] = useState<number>(0);
    const [fridayShifts, setFridayShifts] = useState<number>(0);
    const [saturdayShifts, setSaturdayShifts] = useState<number>(0);

	const [shiftEvents, setShiftEvents] = useState([])
	const [mainModalv2, setMainModalv2] = useState(false);

	const closeMainModalv2 = () => setMainModalv2(false);


	const openMainModalv2 = (shiftEvent) => {
	  const srEventId = JSON.parse(shiftEvent.id);
	  const srDetails:ShiftRequest[] = extractElements(srEventId[1]);
		console.log("srDetails"+srDetails);
	  const workyWorkdayShift = srEventId[0]
	  
	  function extractElements(inputArrayOrObject) {
		if (Array.isArray(inputArrayOrObject)) {
			let outputArray = [];

			function extract(array) {
				array.forEach(item => {
					if (Array.isArray(item)) {
						extract(item);
					} else {
						outputArray.push(item);
					}
				});
			}

			extract(inputArrayOrObject);
			return outputArray;
		} else {
			// If it's not an array, simply return it wrapped in an array
			return [inputArrayOrObject];
		}
	}



	  if (!srDetails) return;

	  // let SRID = srDetails.shiftRequestUnitId;
	  // let jobberClassification = srDetails.workerType;
	  console.log("TEST TEST 1234 :"+JSON.stringify(srDetails))
	  let SRID = srDetails[0].shiftRequestUnitId;
	  let jobberHourlyRate = srDetails[0].jobberHourlyRate;
	  let requester = srDetails[0].requesterFirstName + " " +srDetails[0].requesterLastName;
	  let jobberClassification = srDetails[0].workerType;
	  let actualStartTime = workyWorkdayShift.substring(11, 16);
	  let actualEndtime = workyWorkdayShift.substring(17, 22);
	  let actualDate = workyWorkdayShift.substring(0, 10);
	  let displayTime = formatTimeString(workyWorkdayShift.substring(11, 22));
	  let shiftdate = parseMonthAndDayV2(workyWorkdayShift);
	  let shifttime = displayTime;
	  let numJobbers =srDetails.length;
	  
	  console.log('-----TESTING BEGIN-----');
	  console.log("SR DETAILS LENGTH : " + srDetails)
	  console.log("SR DETAILS LENGTH : " + (srDetails).length);
	  console.log("SR jobberHourlyRate : " + jobberHourlyRate);
	  console.log("SR SRID : " + SRID);
	  console.log("srDetails: " + JSON.stringify(srDetails));
	  console.log('-----TESTING FINISH-----');
	  
	  let assignedJobbers:JobberProfileBuilder[];
	  if (srDetails[0].shiftRequestStatus === "ASSIGNED"){
		assignedJobbers = determineAssignedJobbers(srDetails);
	  }
	  
	  setModalShiftDate(shiftdate);
	  setModalShiftTime(shifttime);
	  setModalShiftId(SRID);
	  setModalJobberClassification(jobberClassification);
	  setModalOrgHourlyPay(jobberHourlyRate.toString());
	  setModalTotalJobbers(numJobbers.toString());
	  setModalActualEndTime(actualEndtime);
	  setModalActualStartTime(actualStartTime);
	  setModalActualDate(actualDate)
	  setModalAssignedJobbers(assignedJobbers);
	  setModalRequester(requester);
	  setMainModalv2(true);
	}
	
	// const [assignedJobberInfo, setAssignedJobberInfo] = useState<ShiftRequest[] | null>(null);

	// console.log('SRIP RESPONSE :' + JSON.stringify(orgSRIPResponse));
	// console.log('step 1');

	const handleOpen = (
		shiftDate,
		shiftTime,
		shiftId,
		jobberClassification,
		orgHourlyPay,
		totalJobbers,
		actualEndtime,
		actualStartTime,
		actualDate,
		assignedJobbers,
		jobberHourlyRate,
		requester
	) => {
		setModalOpen(true);
		setModalShiftDate(shiftDate);
		setModalShiftTime(shiftTime);
		setModalShiftId(shiftId);
		setModalJobberClassification(jobberClassification);
		setModalOrgHourlyPay(jobberHourlyRate);
		setModalTotalJobbers(totalJobbers);
		setModalActualEndTime(actualEndtime);
		setModalActualStartTime(actualStartTime);
		setModalActualDate(actualDate);
		setModalAssignedJobbers(assignedJobbers);
		setModalRequester(requester);
	};

	const handleClose = () => {
		setModalOpen(false);
		setOpenAssignedJobbers(false);
	};

	const [cancelModalOpen, setCancelModalOpen] = useState(false);

	const apiBody: CancelShiftRequestObj = {
		shiftId: modalShiftId,
	};


	function determineAssignedJobbers(assignedShifts: ShiftRequest[]):JobberProfileBuilder[] {
		
		
		// Check if the assignedShifts array is empty
		if (assignedShifts.length <= 0) {
		  return null;
		}
	  
		const assignedJobbers:JobberProfileBuilder[] = [];

		assignedShifts.forEach((shiftRequest) => {
		  if (shiftRequest.shiftRequestStatus === 'ASSIGNED' && shiftRequest.assignedJobberUserDetails) {
			let jobberName = shiftRequest.assignedJobberUserDetails.firstName + " " + shiftRequest.assignedJobberUserDetails.lastName;
			let jobberEmail = shiftRequest.assignedJobberUserDetails.userEmail.value;
			let jobberPhone = shiftRequest.assignedJobberUserDetails.userPhoneNumber.value;
			let jobberPic = shiftRequest.assignedJobberProfilePicS3URL;
			assignedJobbers.push({
				jobberName, jobberEmail, jobberPhone, jobberPic
			});
		  }
		});
		
		return assignedJobbers;
	}





	const handleCancel = async (e) => {
		e.preventDefault();

		console.log('HIT CANCEL BUTTON MODAL SHIFT ID :  ---- ' + modalShiftId);
		try {
			const response: AxiosResponse<ServerResponse<string, Error>> =
				await remoteCallObject.remotePOSTCallV2<
					ServerResponse<string, Error>,
					CancelShiftRequestObj
				>({
					apiUrl: backendApiBasePathLocal + 'shiftassignment/cancel',
					object: apiBody,
					headers: { 'Content-Type': 'application/json' },
				});
			console.log('POST request successful:', response);

			if (
				response &&
				(response.status === 200 || response.status === 201 || response.status === 202)
			) {
				window.location.reload();
				console.log('200 or 201 or 202');
			}
		} catch (e) {
			if (e.response && (e.response.status === 400 || e.response.status === 500)) {
				console.log('400 or 500');
				window.location.reload();
			}
			console.error('POST request failed:', e);
		}
	};

	const handleCancelModalOpen = () => {
		setCancelModalOpen(true);
	};
	const handleCancelModalClose = () => setCancelModalOpen(false);

	const [confirmShiftChangeModalOpen, setConfirmShiftChangeModalOpen] = useState(false);
	const handleConfirmShiftChangeModalOpen = () => setConfirmShiftChangeModalOpen(true);
	const handleConfirmShiftChangeModalClose = () => setConfirmShiftChangeModalOpen(false);

	const SRInProgressChildComponent = (srDetails: SRIPResponseData) => {
		if (!srDetails) return;
		const workyShift: WorkyWorkdayShift = generateWorkyWorkdayShiftInput({
			workyWorkdayShiftInput: srDetails.workyWorkdayShift,
			assignedSRCount: srDetails.assignedShiftRequests.length,
			inProgressSRCount: srDetails.inProgressShiftRequests.length,
		});
		if (!workyShift) return;
		if (
			!srDetails &&
			!srDetails.inProgressShiftRequests &&
			srDetails.inProgressShiftRequests.length === 0
		) {
			return;
		}

		let orgHourlyPay;
		let jobberHourlyRate;
		let SRID;
		let jobberClassification;
		let requester;
		let totalJobbers = workyShift.assigned + workyShift.unassigned;
		let actualStartTime = srDetails.workyWorkdayShift.substring(11, 16);
		let actualEndtime = srDetails.workyWorkdayShift.substring(17, 22);
		let actualDate = srDetails.workyWorkdayShift.substring(0, 10);
		let displayTime = formatTimeString(srDetails.workyWorkdayShift.substring(11, 22));
		let shiftdate = workyShift.date;

		let shifttime = displayTime;
		const assignedJobbers:JobberProfileBuilder[] = determineAssignedJobbers(srDetails.assignedShiftRequests);


		try {
			if (srDetails.inProgressShiftRequests[0]) {
				orgHourlyPay = srDetails.inProgressShiftRequests[0]?.orgHourlyRate;
				jobberHourlyRate = srDetails.inProgressShiftRequests[0]?.jobberHourlyRate;
				SRID = srDetails.inProgressShiftRequests[0]?.shiftRequestUnitId;
				console.log('INPROGRESS SHIFT SRID : ' + SRID);
				jobberClassification = srDetails.inProgressShiftRequests[0]?.workerType;
				console.log('INPROGRESS SHIFT SRID : ' + jobberClassification);
				requester = srDetails?.inProgressShiftRequests[0]?.requesterFirstName + " " + srDetails?.inProgressShiftRequests[0]?.requesterLastName;
				console.log('INPROGRESS SHIFT SRID : ' + requester);

			} else if (srDetails.assignedShiftRequests[0]) {
				console.log('INSIDE ASSIGNED SHIFT');
				orgHourlyPay = srDetails.assignedShiftRequests[0]?.orgHourlyRate;
				jobberHourlyRate = srDetails.assignedShiftRequests[0]?.jobberHourlyRate;
				SRID = srDetails.assignedShiftRequests[0]?.shiftRequestUnitId;
				console.log('ASSIGNED SHIFT SRID : ' + SRID);
				jobberClassification = srDetails.assignedShiftRequests[0]?.workerType;
				requester = srDetails?.assignedShiftRequests[0]?.requesterFirstName + " " +srDetails?.assignedShiftRequests[0]?.requesterLastName;
			} else {
				throw new Error('No shift requests available');
			}
		} catch (error) {
			console.error('Error: ', error.message);
		}

		return (
			<button
				onClick={() =>
					handleOpen(
						shiftdate,
						shifttime,
						SRID,
						jobberClassification,
						orgHourlyPay,
						totalJobbers,
						actualEndtime,
						actualStartTime,
						actualDate,
						assignedJobbers,
						jobberHourlyRate,
						requester
					)
				}
				className='srstatus'
				id={
					workyShift.assigned / (workyShift.assigned + workyShift.unassigned) !== 1
						? 'sr-inprogress'
						: 'sr-assigned'
				}>
				<div className='srstatus-inner'>
					<div className='srstatus-date'>{workyShift.date}</div>
					{/* <div className='srstatus-time'>{workyShift.time}</div> */}
					<div className='srstatus-time'>{displayTime}</div>
					<div className='srstatus-ratio-holder'>
						<div className='srstatus-ratio'>
							{workyShift.assigned} / {workyShift.assigned + workyShift.unassigned}
						</div>
					</div>
				</div>
			</button>
		);
	};

	const SRStatusComponentCarosul = (orgSRIPResponse: SRIPResponseData[]) => {
		const shiftArr: JSX.Element[] = [];
		console.log("typeof " + typeof orgSRIPResponse + " orgSRIPResponse " + JSON.stringify(orgSRIPResponse))
		if (!orgSRIPResponse) return shiftArr;

		if(orgSRIPResponse.length === 0) return shiftArr;

		const handleLeftScroll = () => {
			if (ref.current) {
				ref.current.scrollLeft -= 400; // Adjust the scroll distance as needed
			}
		};
		const handleRightScroll = () => {
			if (ref.current) {
				ref.current.scrollLeft += 400; // Adjust the scroll distance as needed
			}
		};

		for (let i = 0; i < orgSRIPResponse.length; i++) {
			console.log('step 5');
			
			if (!orgSRIPResponse[i] || orgSRIPResponse[i] === undefined) continue;
			const listFromObjectValues = Object.values(orgSRIPResponse[i]);
			
			if(!listFromObjectValues || listFromObjectValues.length==0)  continue;
			
			for(let j = 0; j < listFromObjectValues.length; j++){				
				const  sr: SRIPResponseData =  listFromObjectValues[j]
				console.log("srSRIPResponseData " + JSON.stringify(sr))
				shiftArr.push(SRInProgressChildComponent(sr));
			}
			
		}

		return (
			<div className={isMobile ?'srstatus-container-hp-mobile':'srstatus-container-hp'}>
				<div className={isMobile ?'srstatus-title-mobile':'srstatus-title-hp'}>Current Shifts<button onClick={handleOpenModalCalendar} className={isMobile?'calendar-button-mobile':'calendar-button'}><CalendarMonthOutlinedIcon/></button></div>
				<button onClick={handleLeftScroll} className={isMobile ? 'srstatus-left-mobile':'srstatus-left-hp'}>
					<WestIcon />
				</button>
				<button onClick={handleRightScroll} className={isMobile ? 'srstatus-right-mobile':'srstatus-right-hp'}>
					<EastIcon />
				</button>
				<div ref={ref} className={isMobile ?'srstatus-holder-mobile':'srstatus-holder-hp'}>
					{shiftArr}
				</div>
			</div>
		);
	};
	
	const MyBudget = () =>{

		let weeklyTotal = 0;
		let weeklyRemaining = 0;

		

		let sundayVal = 0;
		let mondayVal = 0;
		let tuesdayVal = 0;
		let wednesdayVal = 0;
		let thursdayVal = 0;
		let fridayVal = 0;
		let saturdayVal = 0;
	
		if (orgBudgetData && orgBudgetData.shiftDetails) {
			weeklyRemaining = orgBudgetData.actualCurrentCost;
			weeklyTotal = orgBudgetData.expectedBudget;
			setWeeklyRemainingstate(weeklyRemaining)
			setWeeklyTotalstate(weeklyTotal)
			const { shiftDetails } = orgBudgetData;

			Object.keys(shiftDetails).forEach(day => {
				const totalSpendForThatDay = shiftDetails[day]?.totalSpendForThatDay || 0;
				switch (day) {
					case "SUNDAY":
						sundayVal = totalSpendForThatDay;
						setsundayValstate(sundayVal);
						setSundayShifts(Object.keys(shiftDetails[day]?.shiftDetails || {}).length);
						break;
					case "MONDAY":
						mondayVal = totalSpendForThatDay;
						setmondayValstate(mondayVal);
						setMondayShifts(Object.keys(shiftDetails[day]?.shiftDetails || {}).length);
						break;
					case "TUESDAY":
						tuesdayVal = totalSpendForThatDay;
						settuesdayValstate(tuesdayVal);
						setTuesdayShifts(Object.keys(shiftDetails[day]?.shiftDetails || {}).length);
						break;
					case "WEDNESDAY":
						wednesdayVal = totalSpendForThatDay;
						setwednesdayValstate(wednesdayVal);
						setWednesdayShifts(Object.keys(shiftDetails[day]?.shiftDetails || {}).length);
						break;
					case "THURSDAY":
						thursdayVal = totalSpendForThatDay;
						setthursdayValstate(thursdayVal);
						setThursdayShifts(Object.keys(shiftDetails[day]?.shiftDetails || {}).length);
						break;
					case "FRIDAY":
						fridayVal = totalSpendForThatDay;
						setfridayValstate(fridayVal);
						setFridayShifts(Object.keys(shiftDetails[day]?.shiftDetails || {}).length);
						break;
					case "SATURDAY":
						saturdayVal = totalSpendForThatDay;
						setsaturdayValstate(saturdayVal);
						setSaturdayShifts(Object.keys(shiftDetails[day]?.shiftDetails || {}).length);
						break;
					default:
						break;
				}
			});
		}

		console.log("weeklyRemaining"+ weeklyRemaining)
		console.log("weeklyTotal"+ weeklyTotal)
		const progressValue = (weeklyRemaining/weeklyTotal)*100;

		// Calculate display values for each day
		const sundayValDisplay = (sundayVal / (weeklyTotal / 7)) * 100;
		const mondayValDisplay = (mondayVal / (weeklyTotal / 7)) * 100;
		const tuesdayValDisplay = (tuesdayVal / (weeklyTotal / 7)) * 100;
		const wednesdayValDisplay = (wednesdayVal / (weeklyTotal / 7)) * 100;
		const thursdayValDisplay = (thursdayVal / (weeklyTotal / 7)) * 100;
		const fridayValDisplay = (fridayVal / (weeklyTotal / 7)) * 100;
		const saturdayValDisplay = (saturdayVal / (weeklyTotal / 7)) * 100;

		return(

			<div className={isMobile?'mybudget-mobile':'mybudget'}>
				<div className={isMobile ?'srstatus-title-mobile':'srstatus-title-hp'}>Weekly Budget</div>
				<button onClick={handleOpenModalBudget} className={isMobile?'mybudget-card-mobile':'mybudget-card'}>
					<div className='mybudget-card-left'>
						<div className='mybudget-left-title'>Current Spend:</div>
						{/* <CircularProgress  variant="determinate" size={70} thickness={4} value={progressValue}/> */}
						<Tooltip placement={'left-end'} title={'$'+weeklyRemaining +" / $"+weeklyTotal}>
						<div className='progress-holder'>
							<div className='circle-inner-value'>${weeklyRemaining.toFixed(2)}</div>
							<CircularProgress
								color="primary"
								className="circluar"
								determinate
								sx={{ '--CircularProgress-size': '90px',"--CircularProgress-trackThickness": "8px","--CircularProgress-progressThickness": "8px" }}
								value={progressValue}
								variant="soft"
							/>
						</div>
							</Tooltip>

						<div className='progress-inner'></div>
					</div>
					<div className='mybudget-card-right'>
						<div className='weekly-budget-holder'>
							<div className='weekly-progress-container'>


									<div className='weekday-holder'>
										<Tooltip placement="left-end" title={"Sunday : $"+sundayVal} >
											<div className='weekly-progress-outer'>
												<div className='weekly-progress-inner' style={{ height: sundayValDisplay + '%' }}></div>
											</div>
										</Tooltip>
										<div className='weekday'>S</div>
									</div>


									<div className='weekday-holder'>
										<Tooltip placement="left-end" title={"Monday : $"+mondayVal} >
											<div className='weekly-progress-outer'>
												<div className='weekly-progress-inner' style={{ height: mondayValDisplay + '%' }}></div>
											</div>
										</Tooltip>
										<div className='weekday'>M</div>
									</div>


									<div className='weekday-holder'>
										<Tooltip placement="left-end" title={"Tuesday : $"+tuesdayVal} >
											<div className='weekly-progress-outer'>
												<div className='weekly-progress-inner' style={{ height: tuesdayValDisplay + '%' }}></div>
											</div>
										</Tooltip>
										<div className='weekday'>T</div>
									</div>


									<div className='weekday-holder'>
										<Tooltip placement="left-end" title={"Wednesday : $"+wednesdayVal} >
											<div className='weekly-progress-outer'>
												<div className='weekly-progress-inner' style={{ height: wednesdayValDisplay + '%' }}></div>
											</div>
										</Tooltip>
										<div className='weekday'>W</div>
									</div>


									<div className='weekday-holder'>
										<Tooltip placement="left-end" title={"Thursday : $"+thursdayVal} >
											<div className='weekly-progress-outer'>
												<div className='weekly-progress-inner' style={{ height: thursdayValDisplay + '%' }}></div>
											</div>
										</Tooltip>
										<div className='weekday'>T</div>
									</div>


									<div className='weekday-holder'>
										<Tooltip placement="left-end" title={"Friday : $"+fridayVal} >
											<div className='weekly-progress-outer'>
												<div className='weekly-progress-inner' style={{ height: fridayValDisplay + '%' }}></div>
											</div>
										</Tooltip>
										<div className='weekday'>F</div>
									</div>


									<div className='weekday-holder'>
										<Tooltip placement="left-end" title={"Saturday : $"+saturdayVal} >
											<div className='weekly-progress-outer'>
												<div className='weekly-progress-inner' style={{ height: saturdayValDisplay + '%' }}></div>
											</div>
										</Tooltip>
										<div className='weekday'>S</div>
									</div>



								
							</div>
						</div>
					</div>


				</button>
			</div>
		)
	}


	const CalendarView = () => {
		console.log("CALENDAR CALENDAR"+JSON.stringify(shiftEvents))

        return(
          <>
            <div className={isMobile? 'calendar-title-mobile-hp':'calendar-title-hp'}>My Calendar</div>
            <div className={isMobile? 'calendar-container-mobile-hp':'calendar-container-hp'}>
                  <FullCalendar
                      plugins={[dayGridPlugin]}
                      height="530px"
                      initialView='dayGridMonth'
                      weekends={true}
                      events={shiftEvents}
                      themeSystem='standard'
                      eventClassNames={"shift-event"}
                      dayHeaderClassNames={'calender-header'}
                      eventTextColor={'#4c4c4c'}
                      viewClassNames={'view-calendar'}
                      moreLinkClassNames={'more-calendar'}
                      eventClick={(info)=>openMainModalv2(info.event)}
                      dayMaxEvents={2}
                  />
            </div>
          </>
        )
      }






	const getOrgIcon = async () => {
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'org/iconv2',
			headers: {},
			queryParams: null,
		};

		const orgIconRes: AxiosResponse<ServerResponse<string, Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);

		if (orgIconRes && orgIconRes.data && orgIconRes.data.data) {
			console.log('step 2');
			console.log('API provided s3 url: {} ' + orgIconRes.data.data);
			localStorage.setItem('orgIcon', orgIconRes.data.data);
			setOrgIcon(orgIconRes.data.data);
		}
	};


	const shiftToEventBuilder = (srip: SRIPResponseData[]) => {
        const events = [];
        

        srip.forEach((shiftRequest) => {
          const { assignedShiftRequests, inProgressShiftRequests } = shiftRequest;
      
          // Process assigned shift requests
          assignedShiftRequests.forEach((assignedShift) => {
            const { workyWorkdayShift, startDateTimeEpochSeconds, endDateTimeEpochSeconds } = assignedShift;


            events.push({
                title: formatTimeString(workyWorkdayShift.substring(11, 22)),
                start: new Date(startDateTimeEpochSeconds * 1000),
                end: new Date(endDateTimeEpochSeconds * 1000),  
                id: JSON.stringify([workyWorkdayShift,assignedShift]),
            });
          });
      
          // Process in-progress shift requests
          inProgressShiftRequests.forEach((inProgressShift) => {
            const { workyWorkdayShift, startDateTimeEpochSeconds, endDateTimeEpochSeconds } = inProgressShift;
          
            
            events.push({
              title: formatTimeString(workyWorkdayShift.substring(11, 22)),
              start: new Date(startDateTimeEpochSeconds * 1000), // Convert seconds to milliseconds
              end: new Date(endDateTimeEpochSeconds * 1000), // Convert seconds to milliseconds
              id: JSON.stringify([workyWorkdayShift,inProgressShift]),
            });
          });
        });

        // Third loop to combine events with the same workyWorkdayShift
        const combinedEvents = events.reduce((accumulator, currentEvent) => {
          const currentWorkyWorkdayShift = JSON.parse(currentEvent.id)[0];
          const currentEventData = JSON.parse(currentEvent.id)[1];
          const existingEventIndex = accumulator.findIndex(
              (e) => JSON.parse(e.id)[0] === currentWorkyWorkdayShift
          );

          if (existingEventIndex !== -1) {
              // Combine the current event data with the existing event data
              const existingEvent = accumulator[existingEventIndex];
              const existingEventData = JSON.parse(existingEvent.id)[1];
              const combinedEventData = [existingEventData, currentEventData]; // Combine event data as an array
              const combinedEvent = {
                  ...existingEvent,
                  id: JSON.stringify([currentWorkyWorkdayShift, combinedEventData]) // Update id with combined event data array
              };
              accumulator[existingEventIndex] = combinedEvent;
          } else {
              // Add the current event as it is unique
              accumulator.push(currentEvent);
          }

          return accumulator;
        }, []);

        // Replace the events array with combinedEvents
        const updatedEvents = [...combinedEvents];
        
        setShiftEvents(updatedEvents);

	};

	const getOrgSRIP = async () => {
		const getCallInput = {
			apiUrl: backendApiBasePathLocal + 'shiftrequest/SRIP',
			headers: {},
			queryParams: null,
		};

		try {
			const orgSRIPResponse = await remoteCallObject.remoteGETCallV2<
				ServerResponse<SRIPRespObj, Error>
			>(getCallInput);

			if (
				orgSRIPResponse !== null &&
				orgSRIPResponse.data != null &&
				orgSRIPResponse.data.data
			) {
				console.log('step4');
				
				const shiftRequestWorkDayMapValues:SRIPResponseData[] = Object.values(orgSRIPResponse.data.data.shiftRequestWorkDayMap);
				console.log("shiftRequestWorkDayMapValues " + JSON.stringify(shiftRequestWorkDayMapValues));
				const sortedData: SRIPResponseData[] = sortDataByWorkyWorkdayShift(shiftRequestWorkDayMapValues);
				setOrgSRIPResponse(sortedData);
				shiftToEventBuilder(sortedData);
				// localStorage.setItem('orgSRIPResponse', JSON.stringify(sortedData));
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const getOrgBudget = async () => {
		console.log("BUDGET BUDGET")
		const getCallInput = {
			apiUrl: backendApiBasePathLocal + 'org/budget',
			headers: {},
			queryParams: null,
		};

		try {
			const orgBudgetInfo = await remoteCallObject.remoteGETCallV2<ServerResponse<FinancialProgressTrackerClientObj, Error>>(getCallInput);

			if (orgBudgetInfo && orgBudgetInfo.data && orgBudgetInfo.data.data) {
				
				setOrgBudgetData(orgBudgetInfo.data.data);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};






	const handleGeneralEmailSubmit = async (e) => {
		e.preventDefault();

		const serviceID = 'service_3mlm2ls';
		const templateID = 'template_xl6xpjw';
		const userID = 'zKdgUxA7H3dMaCf-m';

		try {
			await emailjs.sendForm(serviceID, templateID, e.target, userID);
			alert('Email sent successfully!');
		} catch (error) {
			alert('An error occurred while sending the email.');
			console.error(error);
		}
	};

	const [modalOpenReportIncident, setModalOpenReportIncident] = useState(false);
	const handleOpenReportIncident = () => setModalOpenReportIncident(true);
	const handleCloseReportIncident = () => setModalOpenReportIncident(false);
	
	
	const [modalBudget, setModalBudget] = useState(false);
	const handleOpenModalBudget = () => setModalBudget(true);
	const handleCloseModalBudget = () => setModalBudget(false);


	const [modalCalendar, setModalCalendar] = useState(false);
	const handleOpenModalCalendar = () => setModalCalendar(true);
	const handleCloseModalCalendar = () => setModalCalendar(false);


	const [modalOpenExtendShift, setModalOpenExtendShift] = useState(false);
	const handleOpenExtendShift = () => setModalOpenExtendShift(true);
	const handleCloseExtendShift = () => setModalOpenExtendShift(false);

	const handleExtendShiftSubmit = async (
		actualDate,
		newStartTime,
		newEndTime,
		modalShiftId
	) => {
		let epochDateStartTime;
		let epochDateEndTime;

		if (newStartTime.includes('null')) {
			epochDateStartTime = null;
		} else {
			epochDateStartTime = combineDateAndTimeToEpoch(actualDate, padTime(newStartTime));
		}
		if (newEndTime.includes('null')) {
			epochDateEndTime = null;
		} else {
			epochDateEndTime = combineDateAndTimeToEpoch(actualDate, padTime(newEndTime));
		}

		const modifyShiftTimeApiBody: ModifyShiftTimeRequest = {
			shiftId: modalShiftId,
			newShiftStartTime: epochDateStartTime,
			newShiftEndTime: epochDateEndTime,
		};

		console.log('modifyShiftTimeApiBody ' + JSON.stringify(modifyShiftTimeApiBody));

		try {
			const response: AxiosResponse<ServerResponse<string, Error>> =
				await remoteCallObject.remotePOSTCallV2<
					ServerResponse<string, Error>,
					ModifyShiftTimeRequest
				>({
					apiUrl: backendApiBasePathLocal + 'shift/modify/shiftTime',
					object: modifyShiftTimeApiBody,
					headers: { 'Content-Type': 'application/json' },
				});
			console.log('POST request successful:', response);

			if (
				response &&
				(response.status === 200 || response.status === 201 || response.status === 202)
			) {
				window.location.reload();
				console.log('200 or 201 or 202');
			}
		} catch (e) {
			if (e.response && (e.response.status === 400 || e.response.status === 500)) {
				console.log('400 or 500');
			}
			console.error('POST request failed:', e);
		}

		// handleClose()
	};

	const [startTimeHour, setStartTimeHour] = useState(null);
	const [startTimeMinute, setStartTimeMinute] = useState(null);
	const [modalOpenForProfile, setModalOpenForProfile] = useState(false);
	const [refreshKey, setRefreshKey] = useState(0);
	
	const handleRefreshCallback = () => {
        console.log(" heheheheh ")
        setRefreshKey(refreshKey+1)
        // getAllJobberRating();
    }

	const v3StartTimeHourRef = useRef(null);
	const v3StartTimeMinuteRef = useRef(null);

	const getCoprDetailsHelper = async () =>{
		try {
			const details = await GetCorpDetails();
			if (details) {
			  // Assuming setCorpDetails is your method to update the state with the fetched details
			  setCorpDetails(details);
			  console.log("Corp details set successfully");
			}
		  } catch (error) {
			console.error("Failed to get corp details:", error);
		  }
		
	}

	useEffect(() => {		
		// getCoprDetailsHelper();		
		GetOrgMetadata();
		getOrgSRIP();
		getOrgBudget();
		// getOrgIcon();
		setStartTimeHour(v3StartTimeHourRef.current);
		setStartTimeMinute(v3StartTimeMinuteRef.current);
	}, []);

	const [newStartTimeHour, setNewStartTimeHour] = useState(null);
	const [newStartTimeMinute, setNewStartTimeMinute] = useState(null);
	const [newEndTimeHour, setNewEndTimeHour] = useState(null);
	const [newEndTimeMinute, setNewEndTimeMinute] = useState(null);

	const [openAssignedJobbers, setOpenAssignedJobbers] = useState(false);


	const handleExtendStartTimeHour = (hour) => {
        if (hour === 0 || hour === null|| isNaN(hour)){setNewStartTimeHour(getHour(modalAcutalStartTime))}
        else{setNewStartTimeHour(hour)}
      }

      const handleExtendStartTimeMinute = (minute) => {
        if (minute === null || isNaN(minute)){setNewStartTimeMinute(getMins(modalAcutalStartTime))}
        else{setNewStartTimeMinute(minute)}
      }

      const handleExtendEndTimeHour = (hour) => {
        console.log('END TIME TEST HOUR : '+ hour);
        if (hour === 0 || hour === null || isNaN(hour)){setNewEndTimeHour(getHour(modalAcutalEndTime))}
        else{setNewEndTimeHour(hour)}
          console.log("END TIME HOUR TEST 1234 : "+hour)
      }

      const handleExtendEndTimeMinute = (minute) => {
        if (minute === null || isNaN(minute)){setNewEndTimeMinute(getMins(modalAcutalEndTime))}
        else{setNewEndTimeMinute(minute)}
      }

	return (
		<div className='org-app'>
			<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />

			<Modal open={modalBudget} onClose={handleCloseModalBudget}>
				<div className={isMobile? 'modal-budget-mobile':'modal-budget'}>
					<button onClick={handleCloseModalBudget} className='modal-close'><CloseIcon/></button>
					<div className='srstatus-date'>My Weekly Budget</div>
					<div className={isMobile?'budget-modal-info-mobile': 'budget-modal-info'}>
						<div className={isMobile?'budget-modal-holder-top-mobile': 'budget-modal-holder-top'}>
							<div className='modal-budget-top-left'>
								<div className='modal-budget-progress-title'>My Weekly Spend:</div>
								<div className='modal-budget-progress-holder'>
									<div className='circle-inner-value-modal'>${weeklyRemainingstate.toFixed(2)}</div>

									<CircularProgress
										color="primary"
										determinate
										sx={{ '--CircularProgress-size': '130px',"--CircularProgress-trackThickness": "15px","--CircularProgress-progressThickness": "15px" }}
										value={(weeklyRemainingstate/weeklyTotalstate)*100}
										variant="soft"
									/>
								</div>
							</div>
							<div className='modal-budget-top-right'>
								<div className='modal-budget-allotment'>
									<div className='modal-budget-allotment-title'>Current Weekly Spend:</div>
									<div className='modal-budget-allotment-value'>${weeklyRemainingstate.toFixed(2)}</div>
								</div>
								<div className='modal-budget-allotment'>
									<div className='modal-budget-allotment-title'>Maximum Weekly Spend:</div>
									<div className='modal-budget-allotment-value'>${weeklyTotalstate.toFixed(2)}</div>
								</div>
							</div>
						</div>
						
						<div className='budget-modal-holder-bottom'>
							
							<Accordion  className='weekday-accordian' style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "40px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", background: "#f5f5f7", borderRadius: "20px" }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									className='weekday-spend'
									style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
								>
									<div className='weekday-acc-inner'> Sunday: <span className='weekday-spend-val'>${sundayValstate}</span></div>
								</AccordionSummary>
								<AccordionDetails style={{ marginBottom: "0px" }}>
									<div className='weekday-num-shifts-count-holder'>
										<div className='weekday-num-shifts-count-title'>Number of Shifts:</div>
										<div className='weekday-num-shifts-count-value'>{sundayShifts}</div>
									</div>
								</AccordionDetails>
							</Accordion>

							<Accordion  className='weekday-accordian' style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "40px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", background: "#f5f5f7", borderRadius: "20px" }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									className='weekday-spend'
									style={{ display: "flex", justifyContent: "spaceBetween", alignItems: "center" }}
								>
								<div className='weekday-acc-inner'>Monday: <span className='weekday-spend-val'>${mondayValstate}</span></div>
								</AccordionSummary>
								<AccordionDetails style={{ marginBottom: "0px" }}>
								<div className='weekday-num-shifts-count-holder'>
										<div className='weekday-num-shifts-count-title'>Number of Shifts:</div>
										<div className='weekday-num-shifts-count-value'>{mondayShifts}</div>
									</div>
								</AccordionDetails>
							</Accordion>

							<Accordion  className='weekday-accordian' style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "40px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", background: "#f5f5f7", borderRadius: "20px" }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									className='weekday-spend'
									style={{ display: "flex", justifyContent: "spaceBetween", alignItems: "center" }}
								>
								<div className='weekday-acc-inner'>Tuesday: <span className='weekday-spend-val'>${tuesdayValstate}</span></div>
								</AccordionSummary>
								<AccordionDetails style={{ marginBottom: "0px" }}>
								<div className='weekday-num-shifts-count-holder'>
										<div className='weekday-num-shifts-count-title'>Number of Shifts:</div>
										<div className='weekday-num-shifts-count-value'>{tuesdayShifts}</div>
									</div>
								</AccordionDetails>
							</Accordion>

							<Accordion  className='weekday-accordian' style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "40px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", background: "#f5f5f7", borderRadius: "20px" }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									className='weekday-spend'
									style={{ display: "flex", justifyContent: "spaceBetween", alignItems: "center" }}
								>
								<div className='weekday-acc-inner'>Wednesday: <span className='weekday-spend-val'>${wednesdayValstate}</span></div>
								</AccordionSummary>
								<AccordionDetails style={{ marginBottom: "0px" }}>
								<div className='weekday-num-shifts-count-holder'>
										<div className='weekday-num-shifts-count-title'>Number of Shifts:</div>
										<div className='weekday-num-shifts-count-value'>{wednesdayShifts}</div>
									</div>
								</AccordionDetails>
							</Accordion>

							<Accordion  className='weekday-accordian' style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "40px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", background: "#f5f5f7", borderRadius: "20px" }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									className='weekday-spend'
									style={{ display: "flex", justifyContent: "spaceBetween", alignItems: "center" }}
								>
								<div className='weekday-acc-inner'>Thursday: <span className='weekday-spend-val'>${thursdayValstate}</span></div>
								</AccordionSummary>
								<AccordionDetails style={{ marginBottom: "0px" }}>
								<div className='weekday-num-shifts-count-holder'>
										<div className='weekday-num-shifts-count-title'>Number of Shifts:</div>
										<div className='weekday-num-shifts-count-value'>{thursdayShifts}</div>
									</div>
								</AccordionDetails>
							</Accordion>

							<Accordion  className='weekday-accordian' style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "40px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", background: "#f5f5f7", borderRadius: "20px" }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									className='weekday-spend'
									style={{ display: "flex", justifyContent: "spaceBetween", alignItems: "center" }}
								>
								<div className='weekday-acc-inner'>Friday: <span className='weekday-spend-val'>${fridayValstate}</span></div>
								</AccordionSummary>
								<AccordionDetails style={{ marginBottom: "0px" }}>
								<div className='weekday-num-shifts-count-holder'>
										<div className='weekday-num-shifts-count-title'>Number of Shifts:</div>
										<div className='weekday-num-shifts-count-value'>{fridayShifts}</div>
									</div>
								</AccordionDetails>
							</Accordion>

							<Accordion  className='weekday-accordian' style={{ marginTop: "0px",marginBottom: "0px", boxShadow: "none", minHeight: "40px", display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", background: "#f5f5f7", borderRadius: "20px" }}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									className='weekday-spend'
									style={{ display: "flex", justifyContent: "spaceBetween", alignItems: "center" }}
								>
								<div className='weekday-acc-inner'>Saturday: <span className='weekday-spend-val'>${saturdayValstate}</span></div>
								</AccordionSummary>
								<AccordionDetails style={{ marginBottom: "0px" }}>
								<div className='weekday-num-shifts-count-holder'>
										<div className='weekday-num-shifts-count-title'>Number of Shifts:</div>
										<div className='weekday-num-shifts-count-value'>{saturdayShifts}</div>
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
					</div>
				</div>
			</Modal>













			<Modal open={modalCalendar} onClose={handleCloseModalCalendar}>
				<div className='modal-calendar'>
					<button onClick={handleCloseModalCalendar} className='modal-close'><CloseIcon/></button>
					<CalendarView/>
				</div>
			</Modal>




			<Modal open={mainModalv2} onClose={closeMainModalv2}>
              <div className={isMobile? 'srstatus-modal-mobile':'srstatus-modal'}>
                 <button onClick={closeMainModalv2} className='modal-close'><CloseIcon/></button>
                      <div className='srstatus-date'>{modalShiftDate}</div>
                      <div className='srstatus-time'>{modalShiftTime}</div>
                      <div className='srstatus-modal-title'>Shift Details</div>
                      <div className='srstatus-modal-details'>
                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Number of Workers:</span>
                              {modalTotalJobbers}
                          </div>
                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Worker Type:</span>
                              Warehouse Associate
                          </div>
                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Worker Pay:</span>
                              ${modalOrgHourlyPay}/hr
                          </div>




                          <div className='srstatus-modal-detail'>
                              <span id='modal-detail-heading'>Requester:</span>
                              {modalRequester}
                          </div>




                          {modalAssignedJobbers? <div className='srstatus-modal-title'>Assigned Worker(s)</div>:null}
                          <div className='srstatus-assigned-jobbers-holder'>
                          {modalAssignedJobbers && modalAssignedJobbers.map((jobber, index) => (


							      
							<JobberProfile
								key={index}
								hasCheck={false}
								jobberProfile={{
								jobberEmail: jobber.jobberEmail,
								jobberName: jobber.jobberName,
								jobberPhone: jobber.jobberPhone,
								jobberPic: jobber.jobberPic
								}}
							/>
                          ))}
                            
                          </div>
							


                      </div>
                      <div className='srstatus-modal-title'>Modify Shift</div>
                      <div className='srstatus-modal-actions'>
                        <button onClick={PushToNewSr} className='modal-action' id='modal-action-add'>Add Workers</button>
                        <button onClick={handleOpenExtendShift} className='modal-action' id='modal-action-extend'>Extend Shift</button>
                        <button onClick={handleOpenReportIncident} className='modal-action' id='modal-action-report'>Report Incident</button>
                        <button onClick={handleCancelModalOpen} className='modal-action' id='modal-action-cancel'>Cancel Shift</button>
                      </div>
                  </div>
              </Modal>




			<Modal open={modalOpenReportIncident} onClose={handleCloseReportIncident}>
				<form className='modal-contact' onSubmit={handleGeneralEmailSubmit}>
					<button onClick={handleCloseReportIncident} className='modal-close'><CloseIcon/></button>
					<div className='srstatus-date'>Having an Issue?</div>
					<div id='modal-title2' className='srstatus-time'>
						We guarantee to get back to you in under 1 hour!
					</div>
					<div className='modal-title'>
						Email: <span className='modal-info'>info@worky.pro</span>
					</div>
					<div id='modal-title-2' className='modal-title'>
						Phone: <span className='modal-info'>(647) 494-9090</span>
					</div>
					<input
						className='modal-email'
						name='user_email'
						defaultValue={
							localStorage.getItem('userEmail') === null ||
							localStorage.getItem('userEmail') === undefined
								? 'info@worky.pro'
								: localStorage.getItem('userEmail')
						}
					/>
					<div className='modal-title'>Message:</div>
					<textarea name='user_message' className='modal-message'></textarea>
					<button className='modal-submit'>Send</button>
				</form>
			</Modal>

			<Modal open={modalOpenExtendShift} onClose={handleCloseExtendShift}>
				<div className='modal-extend-shifts'>
					<button onClick={handleCloseExtendShift} className='modal-close'><CloseIcon/></button>
					<div className='srstatus-date'>Select a New Time</div>

					<div className='new-start-time'>
						<div className='new-time'>
							<div id='modal-title2' className='srstatus-time'>
								New Start Time
							</div>
							<WorkyTimePickerV2
								prevHour = {getHour(modalAcutalStartTime)}
								prevMin = {getMins(modalAcutalStartTime)}
								onFinalTimeChangeHour={(hour) => handleExtendStartTimeHour(hour)}
								onFinalTimeChangeMinute={(minute) => handleExtendStartTimeMinute(minute)}
							/>
						</div>
					</div>

					<div style={{ paddingBottom: '20px' }} className='new-start-time'>
						<div className='new-time'>
							<div id='modal-title2' className='srstatus-time'>
								New End Time
							</div>
							<WorkyTimePickerV2
								prevHour = {getHour(modalAcutalEndTime)}
								prevMin = {getMins(modalAcutalEndTime)}
								onFinalTimeChangeHour={(hour) => handleExtendEndTimeHour(hour)}
								onFinalTimeChangeMinute={(minute) => handleExtendEndTimeMinute(minute)}
							/>
						</div>
					</div>
					<button onClick={handleConfirmShiftChangeModalOpen} className='extend-modal-submit'>
						Submit
					</button>
				</div>
			</Modal>

			<Modal open={modalOpen} onClose={handleClose}>
				<div className={isMobile ? 'srstatus-modal-mobile':'srstatus-modal'}>
					<button onClick={handleClose} className='modal-close'><CloseIcon/></button>
					<div className='srstatus-date'>{modalShiftDate}</div>
					<div className='srstatus-time'>{modalShiftTime}</div>
					{/* {modalShiftId} */}
					<div className='srstatus-modal-title'>Shift Details</div>
					<div className='srstatus-modal-details'>
						<div className='srstatus-modal-detail'>
							<span id='modal-detail-heading'>Number of Workers:</span>
							{modalTotalJobbers}
						</div>
						<div className='srstatus-modal-detail'>
							<span id='modal-detail-heading'>Worker Type:</span>Warehouse Associate
						</div>
						<div className='srstatus-modal-detail'>
							<span id='modal-detail-heading'>Worker Pay:</span>${modalOrgHourlyPay}/hr
						</div>
						<div className='srstatus-modal-detail'>
							<span id='modal-detail-heading'>Requester:</span>{modalRequester}
						</div>
						{modalAssignedJobbers? <div className='srstatus-modal-title'>Assigned Worker(s)</div>:null}
						<div className='srstatus-assigned-jobbers-holder'>
						{modalAssignedJobbers && modalAssignedJobbers.map((jobber, index) => (
							<JobberProfile
								key={index}
								hasCheck={false}
								jobberProfile={{
								jobberEmail: jobber.jobberEmail,
								jobberName: jobber.jobberName,
								jobberPhone: jobber.jobberPhone,
								jobberPic: jobber.jobberPic
								}}
							/>
							))}
							
						</div>
							

					</div>
					<div className='srstatus-modal-title'>Modify Shift</div>
					<div className='srstatus-modal-actions'>
						<button onClick={PushToNewSr} className='modal-action' id='modal-action-add'>
							Add Workers
						</button>
						<button
							onClick={handleOpenExtendShift}
							className='modal-action'
							id='modal-action-extend'>
							Extend Shift
						</button>
						<button
							onClick={handleOpenReportIncident}
							className='modal-action'
							id='modal-action-report'>
							Report Incident
						</button>
						<button
							onClick={handleCancelModalOpen}
							className='modal-action'
							id='modal-action-cancel'>
							Cancel Shift
						</button>
					</div>
				</div>
			</Modal>

			<Modal open={cancelModalOpen} onClose={handleCancelModalClose}>
				<div className='cancel-shift-modal'>
					<div style={{marginTop:"10px"}} className='srstatus-modal-title'>
						Are you sure you want to cancel this shift?
					</div>
					<div className='cancel-shift-button-holder'>
						<button
							onClick={handleCancelModalClose}
							id='cancel-shift-button-no'
							className='cancel-shift-button'>
							No
						</button>
						<button onClick={handleCancel} className='cancel-shiftchange-button'>
							Yes
						</button>
					</div>
				</div>
			</Modal>

			<Modal open={confirmShiftChangeModalOpen} onClose={handleConfirmShiftChangeModalClose}>
				<div className='confirm-shiftchange-modal'>
					<button onClick={handleConfirmShiftChangeModalClose} className='modal-close'><CloseIcon/></button>

					<div className='srstatus-modal-title'>
						Are you sure you want to change this shift?
					</div>
					<div className='confirm-shiftchange-reciept-holder'>
						<div className='srpage4-reciept-title'>
							Number of Workers: <span className='reciept-value1'>1</span>
						</div>

						<div className='srpage4-reciept-title'>
							Shift Date: <span className='reciept-value1'>{modalActualDate}</span>
						</div>

						<div className='srpage4-reciept-title'>
							Shift Time:
							<span className='reciept-value1'>
								{convertTo12HourFormat(newStartTimeHour + ':' + newStartTimeMinute)} -{' '}
								{convertTo12HourFormat(newEndTimeHour + ':' + newEndTimeMinute)}
							</span>{' '}
						</div>

						<div className='srpage4-reciept-title'>
							Worker Type: <span className='reciept-value1'>Warehouse Associate</span>
						</div>

						<div className='srpage4-reciept-title'>
							Worker Pay: <span className='reciept-value1'> ${modalOrgHourlyPay}</span>
						</div>

						<div className='srpage4-reciept-title'>
							Worky Charge:
							<span className='reciept-value1'>
								{orgMetaData?.orgBillingData?.workyMarkup * 100}%
							</span>
						</div>

						<div className='srpage4-reciept-title'>
							Tax:{' '}
							<span className='reciept-value1'>
								{
									calculateShiftTotals(
										newStartTimeHour,
										newStartTimeMinute,
										newEndTimeHour,
										newEndTimeMinute,
										1,
										1,
										modalOrgHourlyPay
									)[0]
								}
							</span>
						</div>

						<div className='srpage4-reciept-title'>
							Total:{' '}
							<span className='reciept-value1'>
								{
									calculateShiftTotals(
										newStartTimeHour,
										newStartTimeMinute,
										newEndTimeHour,
										newEndTimeMinute,
										1,
										1,
										modalOrgHourlyPay
									)[1]
								}
							</span>
						</div>
					</div>
					<div className='cancel-shiftchange-button-holder'>
						<button
							onClick={handleConfirmShiftChangeModalClose}
							className='cancel-shiftchange-button'>
							No
						</button>
						<button
							id='cancel-shiftchange-button-no'
							onClick={() =>
								handleExtendShiftSubmit(
									modalActualDate,
									newStartTimeHour + ':' + newStartTimeMinute,
									newEndTimeHour + ':' + newEndTimeMinute,
									modalShiftId
								)
							}
							className='cancel-shiftchange-button'>
							Yes
						</button>
					</div>
				</div>
			</Modal>

			{orgIcon && orgMetaData ? (
				<TopNav
					firstName={userDetails.firstName}
					lastname={userDetails.lastName}
					companyname={orgMetaData.orgName}
					iconUrl={orgIcon}
					orgEmail={localStorage.getItem('userEmail')}
				/>
			) : (
				<TopNav
					firstName={userDetails.firstName}
					lastname={userDetails.lastName}
					companyname={''}
					iconUrl={null}
					orgEmail={localStorage.getItem('userEmail')}
				/>
			)}
			
			{orgSRIPResponse && orgSRIPResponse.length > 0 ? (<div className={isMobile?'top-hp-mobile':'top-hp'}>{SRStatusComponentCarosul(orgSRIPResponse)}<MyBudget/></div>): null}

			{/* <div className={orgSRIPResponse && orgSRIPResponse.length > 0 ? (isMobile ? 'org-bottomrow-mobile' : 'org-bottomrow') : (isMobile ? 'org-toprow-mobile' : 'org-toprow')}> */}
			<div className={orgSRIPResponse && orgSRIPResponse.length > 0 ? (isMobile ? 'org-bottomrow-mobile' : 'org-bottomrow') : (isMobile ? 'org-toprow-mobile' : 'org-toprow')}>
			<>
				<div className={isMobile ?'srnew-container-mobile':'srnew-container'}>
					<div className={isMobile ? 'srnew-title-mobile':'srnew-title'}>New Shift</div>
					<div className={isMobile ? 'srnew-holder-mobile':'srnew-holder'} onClick={PushToNewSr}>
						<div className={isMobile ? null:'srnew-icon'}></div>
						<div className='srnew-textholder'>
							<div className='srnew-subtitle'>Hire Workers Now!</div>
							<div className='srnew-subtext'>
								Get hiring! In a few simple steps, get your team hired today!
							</div>
						</div>
					</div>
				</div>
			</>
			<>
				<div className={isMobile ?'srprev-container-mobile':'srprev-container'}>
					<div className={isMobile ? 'srnew-title-mobile':'srprev-title'}>Shift History</div>
					<div onClick={PushtoSrHistory} className={isMobile ? 'srprev-holder-mobile':'srprev-holder'}>
						<div className={isMobile ? null:'srprev-icon'}></div>
						<div className='srnew-textholder'>
							<div className='srnew-subtitle'>Shifts & Payments</div>
							<div className='srnew-subtext'>
								View your previous shifts and all payments to date.
							</div>
						</div>
					</div>
				</div>
			</>

			</div>
			<div onClick={()=>{window.location.assign('homepage')}} className='worky-footer'>worky</div>
		</div>
	);
};

const sortDataByWorkyWorkdayShift = (data: SRIPResponseData[]) => {
	return data.sort((a, b) => {
		const dateA = a.workyWorkdayShift.split('T')[0];
		const timeA = a.workyWorkdayShift.split('_')[0].split('T')[1];

		const dateB = b.workyWorkdayShift.split('T')[0];
		const timeB = b.workyWorkdayShift.split('_')[0].split('T')[1];

		const dateTimeA = new Date(`${dateA}T${timeA}`).getTime();
		const dateTimeB = new Date(`${dateB}T${timeB}`).getTime();
		console.log('dateTimeA' + dateTimeA);
		console.log('dateTimeB' + dateTimeB);
		console.log('dateTimeA - dateTimeB' + (dateTimeA - dateTimeB));

		return dateTimeA - dateTimeB;
	});
};

export { OrgHomePage, SRIPResponseData, sortDataByWorkyWorkdayShift };
