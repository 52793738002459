import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { WorkyContactButton, WorkyFooter, WorkyTopShelf, WorkyAppHeader, SRtoEventBuilder } from '../agency-logic/agency-components/AgencyComponentSetOne';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import { StyleLinks } from './OrgCommonComponents';
import { PaymentsCards, PaymentsCardBuilder, PaymentsCardBuilderBar, PaymentsCardBuilderValue, WorkyPayVacationCard } from './PaymentsCards';
import './workypay.css'
import AssuredWorkloadRoundedIcon from '@mui/icons-material/AssuredWorkloadRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import BeachAccessRoundedIcon from '@mui/icons-material/BeachAccessRounded';
import { Holiday, PayrollDisplay } from '../common/CommonInterfaces';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';

const PayrollStatusTagBuilder = ({ billingTagVal }: { billingTagVal: string }) => {
    const ProcessedBillTag = () => { return (<div id='bill-paid' className='billing-tag'>Processed</div>) }
    const CompletedBillTag = () => { return (<div id='bill-paid' className='billing-tag'>Completed</div>) }
    const UnpaidBillTag = () => { return (<div id='bill-unpaid' className='billing-tag'>Unpaid</div>) }
    const PartiallyPaidBillTag = () => { return (<div id='bill-partially-paid' className='billing-tag'>Pending</div>) }
    const OverdueBillTag = () => { return (<div id='bill-overdue' className='billing-tag'>Error</div>) }
// "Processed" | "Pending" | "Error" | "Completed";
    switch (billingTagVal) {
        case "Processed": return <ProcessedBillTag/>;
        case "Completed": return <CompletedBillTag/>;
        case "Overdue": return <UnpaidBillTag/>;
        case "Pending": return <PartiallyPaidBillTag/>;
        case "Error": return <OverdueBillTag/>;
        default: return (null);
    }
}


const WorkyPayUpcomingPayments = ({payrollInfo}:{payrollInfo:PayrollDisplay[]}) => {
    return(
    <>
        <div className='section-title-container'>
            <div className='agency-section-title'>Upcoming Pay Run</div>
        </div>
        
        <div className='upcoming-payrun-container'>
            <div className='upcoming-payrun-holder'>
                <div className='upcoming-payroll-row-header'>
                    <div id='upcoming-type-title' className='upcoming-payroll-col'>Payroll Type</div>
                    <div id='upcoming-dates-title' className='upcoming-payroll-col'>Date(s)</div>
                    <div id='upcoming-status-title' className='upcoming-payroll-col'>Status</div>
                    <div id='upcoming-all-title' className='upcoming-payroll-col'><button className='upcoming-all-icon-holder'></button></div>
                </div>

                <div className='upcoming-payroll-row-holder'>
                    {/* create loop here to populate each of the rows */}
                    <div className='upcoming-payroll-row'>
                        <div id='upcoming-type' className='upcoming-payroll-col'>{payrollInfo[0].payrollType}</div>
                        <div id='upcoming-dates' className='upcoming-payroll-col'>{payrollInfo[0].dateRange}</div>
                        <div id='upcoming-status' className='upcoming-payroll-col'><PayrollStatusTagBuilder billingTagVal={payrollInfo[0].status}/></div>
                        <div id='upcoming-all' className='upcoming-payroll-col'><button className='upcoming-all-icon-holder'></button></div>
                    </div>
                    <div className='upcoming-payroll-row'>
                        <div id='upcoming-type' className='upcoming-payroll-col'>{payrollInfo[1].payrollType}</div>
                        <div id='upcoming-dates' className='upcoming-payroll-col'>{payrollInfo[1].dateRange}</div>
                        <div id='upcoming-status' className='upcoming-payroll-col'><PayrollStatusTagBuilder billingTagVal={payrollInfo[1].status}/></div>
                        <div id='upcoming-all' className='upcoming-payroll-col'><button className='upcoming-all-icon-holder'></button></div>
                    </div>
                    <div className='upcoming-payroll-row'>
                        <div id='upcoming-type' className='upcoming-payroll-col'>{payrollInfo[2].payrollType}</div>
                        <div id='upcoming-dates' className='upcoming-payroll-col'>{payrollInfo[2].dateRange}</div>
                        <div id='upcoming-status' className='upcoming-payroll-col'><PayrollStatusTagBuilder billingTagVal={payrollInfo[2].status}/></div>
                        <div id='upcoming-all' className='upcoming-payroll-col'><button className='upcoming-all-icon-holder'></button></div>
                    </div>


                </div>
            </div>
        </div>
    </>
    )
}


const WorkyPayAllJobbers = ({payrollInfo}:{payrollInfo:PayrollDisplay[]}) => {
    return(
    <>
        <div className='section-title-container'>
            <div className='agency-section-title'>My Workers</div>
        </div>

        {/* insert data grid into here */}
            {/* icon */}
            {/* first name */}
            {/* last name */}
            {/* email */}
            {/* phone */}
            {/* last pay amount */}
            {/* last pay date */}
            {/* expand btn */}


        {/* pops into modal */}
        
    </>
    )
}






const PageHeader = ({isUpcoming, setIsUpcoming, isMobile}: {isUpcoming: boolean, setIsUpcoming: (value: boolean) => void, isMobile: boolean}) => {
    
    return(
            <div className={isMobile?'payments-header-holder-mobile':'payments-header-holder'}>
                <div className='payments-header'>
                    <div className='payments-header-title-holder'>
                        <button onClick={()=>setIsUpcoming(true)} id={isUpcoming?"payments-header-active":"payments-header-inactive"} className='payments-header-title-switch'>Upcoming</button>
                        /
                        <button onClick={()=>setIsUpcoming(false)} id={!isUpcoming?"payments-header-active":"payments-header-inactive"} className='payments-header-title-switch'>Previous</button>
                    </div>
                    <div className='payments-header-subtitle'>Manage and View your Team's Payroll</div>
                </div>
            </div>
    )
}

const CardHolder = ({isMobile, holidays}:{isMobile:boolean, holidays:Holiday[]}) => {
    return(
        <div className={isMobile ? 'workypay-top-card-mobile':'workypay-top-card'}>
            <div className='payments-cards-inner'>
                <PaymentsCardBuilderValue 
                    icon={<EventRoundedIcon/>} 
                    title={'Last Pay Run'} 
                    value={"September 2"}
                />
                <PaymentsCardBuilderValue 
                    icon={<ReplyRoundedIcon sx={{ transform: 'scaleX(-1)' }}/>} 
                    title={'Next Pay Run'} 
                    value={"September 14"}
                />
                <WorkyPayVacationCard 
                    icon={<BeachAccessRoundedIcon/>} 
                    title={'Upcoming Holidays'} 
                    holiday={holidays}
                />
            </div>
        </div>
    )
}


const WorkyPay = () => {
    const isMobile = useIsMobile();
    const [isUpcoming, setIsUpcoming] = useState(true);
    const holidaysSample: Holiday[] = [
        { holidayTitle: "Labor Day", holidayDate: "Sep 2" },
        { holidayTitle: "Thanksgiving", holidayDate: "Oct 14" },
        { holidayTitle: "Christmas", holidayDate: "Dec 25" }
    ];
    const upcomingPayrollsSample: PayrollDisplay[] = [
        { payrollType: "Weekly", dateRange: "Sep 2 - Sep 9", status: "Processed" },
        { payrollType: "Bi-Weekly", dateRange: "Sep 2 - Sep 16", status: "Pending" },
        { payrollType: "One-Time", dateRange: "Sep 13", status: "Completed" }
    ];


    return(
        <>
            <StyleLinks />
            <WorkyAppHeader />
            <PageHeader isUpcoming={isUpcoming} setIsUpcoming={setIsUpcoming} isMobile={isMobile}/>
            {/* <CardHolder isMobile={isMobile} holidays={holidaysSample}/> */}
            {/* <WorkyPayUpcomingPayments payrollInfo={upcomingPayrollsSample} /> */}
            {/* <WorkyPayAllJobbers payrollInfo={upcomingPayrollsSample} /> */}

            <WorkyContactButton />
        </>
    )
}


//  share of cpp deduction
//  share of ei deduction
//  certain ppl exempt from each of ^^
//  pay period
//  last payment + previous payments to employee
//  show the cpp ei etc per employee
//  vacations + unpaid leave + personal day + sick day
//  modal for click and display all of data above
//  birthdays + policies + forms 
//  tax slips
//  doanloadable report for the employee

//  calendar -> next pay run



// section 0 : upcoming holidays

// section 1 : upcoming payments

// section 2 : last pay run

// section 3 : employees clickable w modal of all their info





export {WorkyPay}