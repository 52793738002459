import React, { useCallback, useState, useEffect, useRef } from "react";
import{ CurrentAgencyPayStubsRespDTO, DownloadJobberPayStubAgencyReqDTO, DownloadJobberPayStubAgencyResDTO } from '../agency-data/AgencyInterfaces';
import {GetCurrentPaystubAPICall, GetAllPaystubAPICall, PostRetrieveJobberPayStubBillPDF} from '../agency-api-calls/AgencyAPI';
import { StyleLinks } from "../../orgViews/OrgCommonComponents";
import { useIsMobile } from "../../custom-hooks/useIsMobile";
import { WorkyContactButton, WorkyFooter, WorkyTopShelf, WorkyAppHeader } from "../agency-components/AgencyComponentSetOne";
import './workypayagency.css';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import { DataGrid, GridRowsProp, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { Modal, Slide, Tooltip } from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';






import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DownloadPDF } from "../../shared-engineering-services/LocalExecutionService";
import { EmptyInfoState } from "./EmptyInfoState";




const PageHeader = ({isMobile}:{isMobile:boolean}) => {
  
  return(
          <div className={isMobile?'payments-header-holder-mobile':'payments-header-holder'}>
              <div className='payments-header'>
                  <div className='payments-header-title-holder'>
                      <button id="payments-header-active" className='payments-header-title-switch'>Worky Pay</button>
                  </div>
                  <div className='payments-header-subtitle'>Pay your Employees and Manage Pay Stubs</div>
              </div>
          </div>
  )
}
const AgencyCards = ({isMobile,currentAgencyPayStubsRespDTO,allAgencyPayStubsRespDTO}:{isMobile:boolean,currentAgencyPayStubsRespDTO:CurrentAgencyPayStubsRespDTO,allAgencyPayStubsRespDTO:CurrentAgencyPayStubsRespDTO[]}) => {
  console.log("currentAgencyPayStubsRespDTO "+ JSON.stringify(currentAgencyPayStubsRespDTO));
  console.log("allAgencyPayStubsRespDTO "+ JSON.stringify(allAgencyPayStubsRespDTO));
  
  return(
    <>
    <div className={isMobile?"agency-card-holder-mobile":"agency-card-holder"}>



      <div className={isMobile?"agency-card-mobile":"agency-card"}>
        <div className={isMobile?"agency-card-top-mobile":"agency-card-top"}>
          <div className={isMobile?"agency-card-icon-mobile":"agency-card-icon"}><EventRoundedIcon style={{fontSize:isMobile?"20px":""}}/></div>
          <div className={isMobile?"agency-card-title-mobile":"agency-card-title"}>Active Payrun</div>
        </div>
        <div className={isMobile?"agency-card-bottom-mobile":"agency-card-bottom"}>
          <div className={isMobile?"agency-card-value-mobile2":"agency-card-value2"}><PayCycleStringBuilder start={currentAgencyPayStubsRespDTO?.payStubCycleStartDateTimeEpochSec} end={currentAgencyPayStubsRespDTO?.payStubCycleEndDateTimeEpochSec}/></div>
        </div>
      </div>

      <div className={isMobile?"agency-card-mobile":"agency-card"}>
        <div className={isMobile?"agency-card-top-mobile":"agency-card-top"}>
          <div className={isMobile?"agency-card-icon-mobile":"agency-card-icon"}><EventRoundedIcon style={{fontSize:isMobile?"20px":""}}/></div>
          <div className={isMobile?"agency-card-title-mobile":"agency-card-title"}>Next Payrun</div>
        </div>
        <div className={isMobile?"agency-card-bottom-mobile":"agency-card-bottom"}>
          <div className={isMobile?"agency-card-value-mobile2":"agency-card-value2"}>{allAgencyPayStubsRespDTO? <PayCycleStringBuilder start={allAgencyPayStubsRespDTO[0]?.payStubCycleStartDateTimeEpochSec} end={allAgencyPayStubsRespDTO[0]?.payStubCycleEndDateTimeEpochSec}/>:null}</div>
        </div>
      </div>
    <Tooltip title="Coming Soon!" placement="right">
      <div style={{cursor:"pointer"}} id="gradient-blue" className={isMobile?"agency-card-mobile":"agency-card"}>
        <div className={isMobile?"agency-card-top-mobile":"agency-card-top"}>
          <div className={isMobile?"agency-card-icon-mobile":"agency-card-icon"}><CurrencyExchangeRoundedIcon style={{color:"white",fontSize:isMobile?"20px":""}}/></div>
          <div style={{color:"white"}} className={isMobile?"agency-card-title-mobile":"agency-card-title"}>New!</div>
        </div>
        <div className={isMobile?"agency-card-bottom-mobile":"agency-card-bottom"}>
          <div style={{color:"white"}} className={isMobile?"agency-card-value-mobile":"agency-card-value"}>Instant Pay</div>
        </div>
      </div>
  </Tooltip>


    </div>
    </>
  )
}
const PayCycleStringBuilder = ({ start, end }: { start: number, end: number }) => {
  // Helper function to convert epoch time to "MMM D" format
  const formatDate = (epoch) => {
    if (!epoch) return null; // If epoch is undefined or 0, return null

    const date = new Date(epoch * 1000); // Convert from seconds to milliseconds
    return isNaN(date.getTime()) ? null : date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);

  return (
    <>
      {formattedStart && formattedEnd ? `${formattedStart} - ${formattedEnd}` : "Loading..."}
    </>
  );
};
const payCycleStringBuilder2 = (start,end)=>{
  // Helper function to convert epoch time to "MMM D" format
  const formatDate = (epoch) => {
      const date = new Date(epoch * 1000); // Convert from seconds to milliseconds
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);

  return (formattedStart +" - "+formattedEnd);
};













const ActivePayStubInnerTable = ({ currentPayStubs }: { currentPayStubs: CurrentAgencyPayStubsRespDTO}) => {

  interface DownloadJobberPayStubReq{

  }
  const handleButtonClick = async (row: any)=>  {
    console.log("Button clicked on row: ", row, "w ", currentPayStubs);

    const downloadPDfReq: DownloadJobberPayStubAgencyReqDTO = {
      jobberEmail: row.jobberDetails.userDetails.userEmail,
      payStubCycleStartDateTimeEpochSec: currentPayStubs.payStubCycleStartDateTimeEpochSec,
      payStubCycleEndDateTimeEpochSec: currentPayStubs.payStubCycleEndDateTimeEpochSec,
    };
    const resp = await PostRetrieveJobberPayStubBillPDF(downloadPDfReq);
    
    if (resp && resp.fileDownloadURL) {
      console.log('PDF download link:', resp);
      DownloadPDF({ fileUrl: resp.fileDownloadURL, fileName: resp.fileName });
    } else {
      
      console.log('Failed to retrieve PDF ', resp);
    }
  }
  

  // Define columns for the DataGrid
  const rows = currentPayStubs.currentPayStubs.map((stub, index) => ({
    id: index, // Unique ID for each row
    ...stub,
    jobberObligationsDeductionMap: 
        stub.jobberObligationsDeductionMap instanceof Map 
            ? stub.jobberObligationsDeductionMap 
            : new Map(Object.entries(stub.jobberObligationsDeductionMap))
}));


  const columns: GridColDef[] = [
      {
          field: '',
          headerName: '',
          width: 50,headerClassName:"agency-billing-inner-table-header",
          renderCell: (params: GridRenderCellParams) => (
            <div className="grid-row">
              <img className="col-img" src={params.row.jobberDetails.profilePicUrl}/>
            </div>
          ),
      },
      {
          field: 'firstName',
          headerName: 'First Name',
          width: 100,headerClassName:"agency-billing-inner-table-header",
          renderCell: (params: GridRenderCellParams) => (
              <div>
                  <span>{params.row.jobberDetails.userDetails.firstName}</span>
                </div>
          ),
      },
      {
          field: 'lastName',
          headerName: 'Last Name',
          width: 100,headerClassName:"agency-billing-inner-table-header",
          renderCell: (params: GridRenderCellParams) => (
              <span>{params.row.jobberDetails.userDetails.lastName}</span>
          ),
      },
      {
          field: 'userEmail',
          headerName: 'Email',
          width: 200,headerClassName:"agency-billing-inner-table-header",
          renderCell: (params: GridRenderCellParams) => (
              <span>{params.row.jobberDetails.userDetails.userEmail.value}</span>
          ),
      },
      {
          field: 'userPhoneNumber',
          headerName: 'Phone',
          width: 150,headerClassName:"agency-billing-inner-table-header",
          renderCell: (params: GridRenderCellParams) => (
              <span>{params.row.jobberDetails.userDetails.userPhoneNumber.value}</span>
          ),
      },
      {
          field: 'totalHoursWorked',
          headerName: 'Total Hours Worked',
          type: 'number',headerClassName:"agency-billing-inner-table-header",
          width: 150,
          renderCell: (params: GridRenderCellParams) => (
              <span>{params.row.totalHoursWorked}</span>
          ),
      },
      {
          field: 'grossEarning',
          headerName: 'Gross Earning ($)',
          type: 'number',
          width: 150,headerClassName:"agency-billing-inner-table-header",
          renderCell: (params: GridRenderCellParams) => (
              <span>{`$${params.row.grossEarning.toFixed(2)}`}</span>
          ),
      },
      {
        field: 'taxRate',
        headerName: 'Tax Rate ($)',
        type: 'number',
        width: 150,headerClassName:"agency-billing-inner-table-header",
        renderCell: (params: GridRenderCellParams) => (
            <span>{`$${params.row.jobberObligationsDeductionMap.get('jobberTaxRate')?.toFixed(2) || '0.00'}`}</span>
        ),
    },
    {
        field: 'EI',
        headerName: 'EI ($)',
        type: 'number',
        width: 150,headerClassName:"agency-billing-inner-table-header",
        renderCell: (params: GridRenderCellParams) => (
            <span>{`$${params.row.jobberObligationsDeductionMap.get('jobberEiAmount')?.toFixed(2) || '0.00'}`}</span>
        ),
    },
    {
        field: 'CPP',
        headerName: 'CPP ($)',
        type: 'number',
        width: 150,headerClassName:"agency-billing-inner-table-header",
        renderCell: (params: GridRenderCellParams) => (
            <span>{`$${params.row.jobberObligationsDeductionMap.get('jobberCppAmount')?.toFixed(2) || '0.00'}`}</span>
        ),
    },
    {
        field: 'Download',
        headerName: '',
        width: 70,headerClassName:"agency-billing-inner-table-header",
        renderCell: (params: GridRenderCellParams) => (
          <div className='billing-tag-holder'>
            <button className='invoice-cell-btn' onClick={() => handleButtonClick(params.row)}><FileDownloadRoundedIcon sx={{ fontSize: "22px" }} /></button>
          </div>
        ),
    },
    

  ];
  

  return (
      <div style={{ height: '100%', width: '100%' }}>
          <DataGrid
              sx={{
                borderRadius: '10px',
                border: '1',
                borderColor: '#ebebeb',
                background:'white'
            }}
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 2 },
                },
            }}

          />
      </div>
  );
}

const ActivePayStubBuilder = ({isMobile, payStubData }: {isMobile:boolean, payStubData:  CurrentAgencyPayStubsRespDTO }) => {
  
  const numPayStubs = payStubData?.currentPayStubs?.length || 1;
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpand = () => {setIsExpanded(prevState => !prevState);};
  const totalCorpObligationsMap = payStubData && payStubData.totalCorpObligations ? new Map(Object.entries(payStubData?.totalCorpObligations)) : null;


  return(
    <div className="current-paystub-container">
      <div className="paystub-outer-row">
          <div id="ps-col1" className="paystub-outer-col-inner">Pay Cycle</div>
          <div id="ps-col2" className="paystub-outer-col-inner"># PayStubs</div>
          <div id="ps-col3" className="paystub-outer-col-inner">Total Due ($)</div>
      </div>
      <div id={isExpanded?"current-paystub-holder-expanded":""} className="current-paystub-holder">
          <div className="current-paystub-col-inner-holder">
            <div id="ps-col1" className="current-paystub-col-inner"><PayCycleStringBuilder start={payStubData?.payStubCycleStartDateTimeEpochSec} end={payStubData?.payStubCycleEndDateTimeEpochSec}/></div>
            <div id="ps-col2" className="current-paystub-col-inner">{numPayStubs}</div>
            <div id="ps-col3" className="current-paystub-col-inner">{}</div>
            <div onClick={handleExpand} id="ps-col5">{isExpanded ? <ExpandLessRoundedIcon/>:<ExpandMoreRoundedIcon/>}</div>
          </div>

          {isExpanded ? (
          
          <div className={isMobile ? "expanded-current-paystub-col-inner-holder-mobile1":"expanded-current-paystub-col-inner-holder"}>
            <div className={isMobile ? "expanded-current-paystub-left-mobile":"expanded-current-paystub-left"}>
              <div className={isMobile ? "expanded-current-paystub-left-corp-obligations-mobile":"expanded-current-paystub-left-corp-obligations"}>
                    
                  <div className={isMobile?"corp-obligation-mobile":"corp-obligation"}>
                    <div className="corp-obligation-title">CPP Amount:</div>
                    <div className="corp-obligation-value">${totalCorpObligationsMap.get("workyCppAmount")}</div>
                  </div>

                  <div className={isMobile?"corp-obligation-mobile":"corp-obligation"}>
                    <div className="corp-obligation-title">EI Amount:</div>
                    <div className="corp-obligation-value">${totalCorpObligationsMap.get("workyEiAmount")}</div>
                  </div>

                  <div className={isMobile?"corp-obligation-mobile":"corp-obligation"}>
                    <div className="corp-obligation-title">Public Holiday Premium:</div>
                    <div className="corp-obligation-value">${totalCorpObligationsMap.get("publicHolidayPremiumRate")}</div>
                  </div>

                  <div className={isMobile?"corp-obligation-mobile":"corp-obligation"}>
                    <div className="corp-obligation-title">Vacation Pay:</div>
                    <div className="corp-obligation-value">${totalCorpObligationsMap.get("vacationPay")}</div>
                  </div>

                  <div className={isMobile?"corp-obligation-mobile":"corp-obligation"}>
                    <div className="corp-obligation-title">Tax Rate:</div>
                    <div className="corp-obligation-value">${totalCorpObligationsMap.get("workyTaxRate")}</div>
                  </div>

                  </div>
              </div>

              <div className={isMobile?"expanded-current-paystub-right-mobile":"expanded-current-paystub-right"}>
                <div className="expanded-current-paystub-right-shift-details">
                  <div className="paystub-right-shift-details-title">Shift Details</div>
                  <div className="paystub-right-shift-details-table-holder">
                    <ActivePayStubInnerTable currentPayStubs={payStubData}/>
                  </div>
                </div>
              </div>

          </div>

          ):(null)}

      </div>
    </div>
  )
}

const ActivePayStubs = ({isMobile, currentAgencyPayStubsRespDTO}:{isMobile:boolean, currentAgencyPayStubsRespDTO:CurrentAgencyPayStubsRespDTO}) => {
    return(
        <>
          <div className="agency-paystub-title">Active Payroll</div>
          <div className={isMobile?"agency-paystub-holder-mobile":"agency-paystub-holder"}>
            <ActivePayStubBuilder isMobile={isMobile} payStubData={currentAgencyPayStubsRespDTO}/>
          </div>
        </>
    )
}




const AllPayStubTableBuilder = ({ payStubData }: { payStubData:  CurrentAgencyPayStubsRespDTO[] }) => {




  const rows: GridRowsProp = payStubData?.map((payStub, index) => ({
    id: index + 1,  // Unique id for each row
    col1: payCycleStringBuilder2(payStub.payStubCycleStartDateTimeEpochSec,payStub.payStubCycleEndDateTimeEpochSec),
    col2: payStub.currentPayStubs.length,  // Number of pay stubs in the cycle
    col3: ''  // Placeholder for total due
      
  }));

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Pay Cycle', width: 250 },
    { field: 'col2', headerName: '# PayStubs', width: 200 },
    { field: 'col3', headerName: 'Total Due', width: 150 }
  ];
  
  return (
    <DataGrid 
        rows={rows} 
        columns={columns}
        initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 5 },
            },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
            borderRadius: '10px',
            border: '1px solid',
            borderColor: '#ebebeb',
            background: 'white',
            maxWidth: '100%',
        }}
    />
  );
};

function corpObligationTitleCleanup(keyTitle){
    if (keyTitle === "workyCppAmount"){return "CPP Amount"}
    if (keyTitle === "workyEiAmount"){return "EI Amount"}
    if (keyTitle === "publicHolidayPremiumRate"){return "Public Holiday Premium"}
    if (keyTitle === "vacationPay"){return "Vacation Pay"}
    if (keyTitle === "workyTaxRate"){return "Tax Rate"}


    if (keyTitle === "Employer Sales Tax Rate:"){return "Sales Tax:"}
    if (keyTitle === "workyEiAmount"){return "EI Amount"}
    if (keyTitle === "publicHolidayPremiumRate"){return "Public Holiday Premium"}
    if (keyTitle === "vacationPay"){return "Vacation Pay"}
    if (keyTitle === "workyTaxRate"){return "Tax Rate"}
}


function Row(props: {isMobile:boolean, row: CurrentAgencyPayStubsRespDTO }) {
  const { isMobile, row } = props; // Destructure 'isMobile' from props
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, background:""  }}>
        <TableCell id="mui-table-cell" sx={{background:"", borderBottom:"none",}} component="th" scope="row">
          <PayCycleStringBuilder start={row?.payStubCycleStartDateTimeEpochSec} end={row?.payStubCycleEndDateTimeEpochSec}/>
        </TableCell>
        <TableCell id="mui-table-cell" sx={{background:"transparent", borderBottom:"none"}} align="right">{row?.currentPayStubs?.length}</TableCell>
        <TableCell id="mui-table-cell" sx={{background:"transparent", borderBottom:"none"}} align="right"></TableCell>
        <TableCell id="mui-table-cell" sx={{background:"transparent", borderBottom:"none"}} align="right">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={{background:"transparent", border:"none"}}>

        <TableCell sx={{ paddingBottom: "0px", paddingTop: "0px" , background:"white", border:"none"}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div id={isMobile?"current-paystub-holder-expanded-mui-mobile":"current-paystub-holder-expanded-mui"}>
              <div className={isMobile ? "expanded-current-paystub-col-inner-holder-mobile":"expanded-current-paystub-col-inner-holder"}>
                <div className={isMobile ? "expanded-current-paystub-left-mobile":"expanded-current-paystub-left"}>
                  <div className={isMobile ? "expanded-current-paystub-left-corp-obligations-mobile":"expanded-current-paystub-left-corp-obligations"}>
                    {Object.entries(row.totalCorpObligations).map(([key, value]) => (
                      <div key={key} className={isMobile?"corp-obligation-mobile":"corp-obligation"}>
                        <div className="corp-obligation-title">{key}:</div>
                        {/* <div className="corp-obligation-title">{corpObligationTitleCleanup(key)}:</div> */}
                        <div className="corp-obligation-value">${value.toFixed(2)}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={isMobile?"expanded-current-paystub-right-mobile":"expanded-current-paystub-right"}>
                  <div className="expanded-current-paystub-right-shift-details">
                    <div className="paystub-right-shift-details-title">Shift Details</div>
                    <div className="paystub-right-shift-details-table-holder">
                      <ActivePayStubInnerTable currentPayStubs={row}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const AllPayStubTable = ({ data, isMobile }: {isMobile:boolean, data: CurrentAgencyPayStubsRespDTO[] }) => {
  return (

      <Table sx={{
        width: '100%',
        borderRadius: '10px',          // Applies rounded corners to the entire table
        // outline: '1px solid #ebebeb',  // Adds the outline
        overflow: 'hidden',            // Hides overflow for rounded corners effect
      }}  
      aria-label="collapsible table">
        <TableHead style={{background:"transparent", color:"#bbbbbb", border:"none"}}>
          <TableRow>
            <TableCell id="mui-table-header">Pay Cycle</TableCell>
            <TableCell id="mui-table-header" align="right">#Pay Stubs</TableCell>
            <TableCell id="mui-table-header" align="right">Total Due($)</TableCell>
            <TableCell id="mui-table-header" align="right" />
          </TableRow>
        </TableHead>
       
        <TableBody sx={{background: "white", borderRadius: "10px", overflow: "hidden",}}>
          {data?.map((row, index) => (
            <Row key={index} row={row} isMobile={isMobile} />
          ))}
        </TableBody>

      </Table>

  );
};


const AllPayStubs = ({isMobile, allAgencyPayStubsRespDTO}:{isMobile:boolean, allAgencyPayStubsRespDTO:CurrentAgencyPayStubsRespDTO[]}) => {
  return(
    <>
    <div className="agency-paystub-title">All Payrolls</div>
    <div style={{paddingBottom:"100px"}} className={isMobile?"agency-paystub-holder-mobile":"agency-paystub-holder"}>
      <AllPayStubTable isMobile={isMobile} data={allAgencyPayStubsRespDTO}/>
    </div>
    </>
  )
}























const WorkyPayAgencyPage = ()=>{
    const isMobile = useIsMobile();
    const [currentAgencyPayStubsRespDTO, setCurrentAgencyPayStubsRespDTO] = useState<CurrentAgencyPayStubsRespDTO | null>(null);
    const [allAgencyPayStubsRespDTO, setAllAgencyPayStubsRespDTO] = useState<CurrentAgencyPayStubsRespDTO[] | null>(null);
    
    useEffect(() => {
        // get the agency data
        const fetchWorkyPayAgencyCurrentPayStubsData = async () => {
          // Assuming GetAgencyWTokenOnly is an async function
          const agencySourceData = await GetCurrentPaystubAPICall();
          
          setCurrentAgencyPayStubsRespDTO(agencySourceData);
          console.log("currentAgencyPayStubsRespDTO " + JSON.stringify(currentAgencyPayStubsRespDTO));        
        };                    

        const fetchWorkyPayAgencyAllPayStubsData = async () => {
          // Assuming GetAgencyWTokenOnly is an async function
          const agencySourceData = await GetAllPaystubAPICall();
          
          setAllAgencyPayStubsRespDTO(agencySourceData);
          console.log("fetchWorkyPayAgencyAllPayStubsData " + JSON.stringify(allAgencyPayStubsRespDTO));        
        };                    
    
        fetchWorkyPayAgencyCurrentPayStubsData();        
        fetchWorkyPayAgencyAllPayStubsData();
      }, []);  



  

  return(
    <>
      <StyleLinks/>
      <WorkyAppHeader/>
      <PageHeader isMobile={isMobile}/>

      {allAgencyPayStubsRespDTO?.length===0?
        (
          <div style={{background:"#f5f5f7"}}><EmptyInfoState pageName={"Worky Pay"}/></div>
        )
      :
      (<>
      <AgencyCards isMobile={isMobile} currentAgencyPayStubsRespDTO={currentAgencyPayStubsRespDTO}  allAgencyPayStubsRespDTO={allAgencyPayStubsRespDTO}/>
      <ActivePayStubs isMobile={isMobile} currentAgencyPayStubsRespDTO={currentAgencyPayStubsRespDTO}/>
      <AllPayStubs isMobile={isMobile} allAgencyPayStubsRespDTO={allAgencyPayStubsRespDTO}/>
      </>)
      }

      <WorkyContactButton/>


    </>
  )

}

export {WorkyPayAgencyPage}

