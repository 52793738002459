import React, { useCallback, useState, useEffect, useRef } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { BillingData } from './OrgUtils';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';

const PaymentsTable = ({ paymentsData }: { paymentsData: BillingData }) => {
    
    const buildRowsFromBillingData = (billingData: BillingData) => {
        const rows = [];
    
        for (const [billingDate, shifts] of Object.entries(billingData.shiftBillingDetailsMap)) {
            for (const [shiftId, shiftDetails] of Object.entries(shifts)) {
                const row = {
                    id: shiftDetails.shiftId, // Using shiftId as the unique row identifier
                    billingDate: billingDate,
                    status: determineStatus(shiftDetails.workyBillPaymentBreakdown),
                    outstandingCost: shiftDetails.workyBillPaymentBreakdown.currentOutstandingTotalCost,
                    totalCost: shiftDetails.workyBillPaymentBreakdown.originalTotalCost,
                };
                rows.push(row);
            }
        }
    
        return rows;
    };
    
    const determineStatus = (breakdown) => {
        if (breakdown.currentOutstandingTotalCost === 0) {
            return 'Paid';
        } else if (breakdown.currentOutstandingTotalCost < breakdown.originalTotalCost) {
            return 'Partially Paid';
        } else if (breakdown.currentOutstandingTotalCost > 0 && new Date() > new Date(breakdown.endDateTimeEpochSec * 1000)) {
            return 'Overdue';
        } else {
            return 'Unpaid';
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'invoice',
            headerName: 'Invoice ID',
            headerClassName: 'billing-header',
            width: 550,
            renderHeader: () => (<div className='billing-header'>Invoice ID</div>),
            renderCell: (params: GridRenderCellParams) => (
                <div className='invoice-cell-holder'>
                    <div className='invoice-cell-icon'><DescriptionRoundedIcon style={{ fontSize: "18px" }} /></div>
                    <span className='invoice-cell-text'><strong>Invoice #{params.row.id.toString().padStart(3, '0')}</strong> - {formatBillingDate(params.row.billingDate)}</span>
                </div>
            ),
        },
        {
            field: 'billingDate',
            headerName: 'Billing Date',
            headerClassName: 'billing-header',
            headerAlign: "left",
            align: "left",
            width: 110,
            renderHeader: () => (<div className='billing-header'>Billing Date</div>),
            renderCell: (params: GridRenderCellParams) => (<span className='invoice-cell-text'>{params.row.billingDate}</span>),
        },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'billing-header',
            width: 120,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (<div className='billing-header'>Status</div>),
            renderCell: (params: GridRenderCellParams) => (
                <div className='billing-tag-holder'>
                    <BillingStatusTagBuilder billingTagVal={params.row.status} />
                </div>
            ),
        },
        {
            field: 'outstandingCost',
            headerName: 'Outstanding Cost',
            headerClassName: 'billing-header',
            type: 'number',
            width: 150,
            headerAlign: "right",
            align: "right",
            renderHeader: () => (<div className='billing-header'>Outstanding Cost</div>),
            renderCell: (params: GridRenderCellParams) => (<span className='invoice-cell-text'>{"$"+params.row.outstandingCost}</span>),
        },
        {
            field: 'totalCost',
            headerName: 'Total Cost',
            headerClassName: 'billing-header',
            type: 'number',
            width: 100,
            headerAlign: "right",
            align: "right",
            renderHeader: () => (<div className='billing-header'>Total Cost</div>),
            renderCell: (params: GridRenderCellParams) => (<span className='invoice-cell-text'>{"$"+params.row.totalCost}</span>),
        },
        {
            field: 'download',
            headerName: '',
            cellClassName: "download-col",
            headerAlign: "right",
            align: "right",
            width: 90,
            renderCell: (params: GridRenderCellParams) => (
                <button className='payments-row-download-btn' onClick={() => handleDownload(params.row.id)}>
                    <FileDownloadRoundedIcon style={{ fontSize: "20px" }} />
                </button>
            ),
        },
    ];

    const rows = buildRowsFromBillingData(paymentsData);

    const handleDownload = (invoiceId: number) => {
        // Function to handle download logic for the invoice
        console.log(`Download clicked for Invoice ID: ${invoiceId}`);
    };

    const formatBillingDate = (billingDate: string) => {
        const date = new Date(billingDate);
        const options: Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    const BillingStatusTagBuilder = ({ billingTagVal }: { billingTagVal: string }) => {
        const PaidBillTag = () => { return (<div id='bill-paid' className='billing-tag'>Paid</div>) }
        const UnpaidBillTag = () => { return (<div id='bill-unpaid' className='billing-tag'>Unpaid</div>) }
        const PartiallyPaidBillTag = () => { return (<div id='bill-partially-paid' className='billing-tag'>Partially Paid</div>) }
        const OverdueBillTag = () => { return (<div id='bill-overdue' className='billing-tag'>Overdue</div>) }

        switch (billingTagVal) {
            case "Paid": return <PaidBillTag/>;
            case "Unpaid": return <UnpaidBillTag/>;
            case "Partially Paid": return <PartiallyPaidBillTag/>;
            case "Overdue": return <OverdueBillTag/>;
            default: return (null);
        }
    }

    return (
        <DataGrid
            sx={{
                borderRadius: "10px",
                border: "1",
                borderColor: "#ebebeb",
                
            }}
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10]}
            // checkboxSelection
        />
    )
}

export { PaymentsTable }