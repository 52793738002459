import React, { useRef, useState, useEffect } from "react";
import { Modal, Slide } from '@mui/material';
import "./agencyshiftclustermodal.css"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { PushToNewSr } from "../../orgViews/OrgCommonComponents";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { SRIPResponseData } from "../../common/ShiftReadAPIs";
import { epochToDateString, epochToTimeString, plainNumberToHourlyPayNumber } from "../../jobberViews/pages/WorkyPayJobberPage";
import { combineDateAndTimeToEpoch, formatWorkerType, getHourFromEpoch, getMinsFromEpoch, padTime } from "../../orgViews/OrgUtils";
import { WorkyTimePickerV3 } from "../../common/TimePicker";
import { extractDateTitleFromWorkyWorkdayShift, aggregateShifts, AggregatedShift, WorkerTypeTagBuilder, shelfTitleConverter, extractTimeFromEpochs,formatEpochTimeRange } from "./CarouselHelper";
import { CancelShiftRequestAPICall, GetCorpDetails } from "../../shared-engineering-services/SharedAPICalls";
import {DirectReAssignShiftAPIReq} from "../agency-api-calls/AgencyAPI"
import { CorpDataObj } from "../../orgViews/orgConstants";
import { EmailAddress, ModifyShiftTimeRequest, UserDetails, WorkyUserDetails } from "../../common/CommonInterfaces";
import { OrgLocationMapDetails } from "../../common/CommonInterfaces";
import Fuse from 'fuse.js'; 
import Rating from '@mui/material/Rating';
import { AxiosResponse } from "axios";
import { RemoteExecutionServiceImpl, ServerResponse } from "../../remoteExecution/RemoteExecutionServiceImpl";
import { convertDateEpochAndTimeToEpochSec, epochToDate } from "../../common/TimeUtils";
import { isValidString } from "../../common/CommonUtilsMethods";
import { useErrorBoundary } from "react-error-boundary";
const defaultPic = '/org-default-icon.png'; 

const remoteCallObject = RemoteExecutionServiceImpl();
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;

interface ShiftRequestData {
  shiftRequestUnitId: string;
  parentShiftRequestId: string;
  shiftRequestStatus: string;
  workerType: string;
  assignedJobberUserDetails: UserDetails;
  assignedJobberProfilePicS3URL: string;
  requestingUserDetails:WorkyUserDetails;
  workyWorkdayShift: string;
  simpleLocation: string;
  orgName: string;
  jobberHourlyRate: number;
  orgHourlyRate: number;
  startDateTimeEpochSeconds: number;
  endDateTimeEpochSeconds: number;
  startDateTime: string;
  endDateTime: string;
  startDay: string;
  startTime: number;
  submissionDateTimeEpochSeconds: number;
  submissionDateTime: string;
  orgAddress: {
    street: string;
    unitNumber: string;
    city: string;
    province: string;
    postalCode: string;
    country: string;
    majorIntersection: string;
    entranceInstructions: string;
  };
  lastJobberPoolNotifTime: string | null;
  lastJobberPoolNotifTimeEpochSec: number | null;
  preferredJobbers: EmailAddress[];
  updatedDateTimeEpoch: number;
  updatedDateTime: string;
}

const AgencyShiftClusterModal = ({SRIP, selectedDate, modalOpen, handleClose, isMobile}
  : {SRIP:SRIPResponseData[]; selectedDate:string; modalOpen: boolean; handleClose: () => void; isMobile:boolean}) => {
  
  
  

  
  const [selectedShiftInfoForModal, setSelectedShiftInfoForModal] = useState<ShiftInfoForModal | null>(null);
  const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isReAssignModalOpen, setIsReassignModalOpen] = useState(false);
  
  const handleOpenModifyModal = (shiftInfoForModal: ShiftInfoForModal) => {
    setSelectedShiftInfoForModal(shiftInfoForModal);
    setIsModifyModalOpen(true);
  };

  const handleOpenCancelModal = (shiftInfoForModal: ShiftInfoForModal) => { 
    setSelectedShiftInfoForModal(shiftInfoForModal);
    setIsCancelModalOpen(true);
  };

  const handleOpenReassignModal = (shiftInfoForModal: ShiftInfoForModal) => { 
    setSelectedShiftInfoForModal(shiftInfoForModal);
    setIsReassignModalOpen(true);
  };

  const handleCloseModifyModal = () => setIsModifyModalOpen(false);
  const handleCloseCancelModal = () => setIsCancelModalOpen(false);
  const handleCloseReassignModal = () => setIsReassignModalOpen(false);



  const [selectedDateState, setSelectedDateState] = useState(selectedDate);
  const handleSetSelectedDate = (selectedWorkyWorkdayShift) => {
    setSelectedDateState(selectedWorkyWorkdayShift);
  }
  console.log("selectedDateState: "+selectedDateState)


  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  
  const aggregatedData = aggregateShifts(SRIP);
  


  return (
<>
    <Modal open={modalOpen} onClose={handleClose}>
    {isMobile?(
      <Slide direction="up" in={modalOpen} mountOnEnter unmountOnExit>
      <div className="agency-shift-cluster-modal-mobile">
          <button onClick={handleClose} className="agency-shift-cluster-close-mobile"></button>
          <div className="agency-shift-cluster-modal-top-shelf">
              <div className="agency-shift-cluster-top-shelf-title">Shifts In Progress</div>
              <div className="agency-shift-cluster-top-shelf-holder-mobile">
                  <button className="agency-shift-cluster-top-shelf-arrow" onClick={scrollLeft}><ArrowBackIosNewRoundedIcon /></button>
                  <div className="agency-shift-cluster-top-shelf-inner-mobile" ref={scrollContainerRef}>
                    <ShiftClusterBuilder aggregatedData={aggregatedData} isMobile={isMobile} handleSelectDate={handleSetSelectedDate} selectedDate={selectedDateState}/>  
                  </div>
                  <button className="agency-shift-cluster-top-shelf-arrow" onClick={scrollRight}><ArrowForwardIosRoundedIcon /></button>    
              </div>
          </div>
          <div className="agency-shift-cluster-modal-bottom-shelf">
              <div className="agency-shift-cluster-top-shelf-title">
                {shelfTitleConverter(selectedDateState)}
                <button onClick={PushToNewSr} className="agency-shift-cluster-inner-expanded-modification-btn"><PersonAddRoundedIcon sx={{fontSize:"18px"}}/>Add</button>
              </div>
              
              <div className="agency-shift-cluster-bottom-shelf-inner-mobile">
                  <div style={{marginTop:"-5px"}}/>
                  <ShiftAccordionBuilder onOpenReassignModal={handleOpenReassignModal} onOpenCancelModal={handleOpenCancelModal} onOpenModifyModal={handleOpenModifyModal} SRIP={SRIP} selectedDate={selectedDateState}  isMobile={isMobile}/>
                  <div style={{marginTop:"-5px"}}/>
              </div>
          </div>
      </div>
      </Slide>

    ):(

        <div className="agency-shift-cluster-modal">
            <button onClick={handleClose} className="agency-shift-cluster-close"><CloseRoundedIcon/></button>

            <div className="agency-shift-cluster-modal-top-shelf">
                <div className="agency-shift-cluster-top-shelf-title">Shifts In Progress</div>
                <div className="agency-shift-cluster-top-shelf-holder">

                <button className="agency-shift-cluster-top-shelf-arrow" onClick={scrollLeft}><ArrowBackIosNewRoundedIcon /></button>
                

                    <div className="agency-shift-cluster-top-shelf-inner" ref={scrollContainerRef}>
                      <ShiftClusterBuilder 
                        aggregatedData={aggregatedData} 
                        isMobile={isMobile} 
                        handleSelectDate={handleSetSelectedDate} 
                        selectedDate={selectedDateState}
                      />                    
                    </div>

                    <button className="agency-shift-cluster-top-shelf-arrow" onClick={scrollRight}><ArrowForwardIosRoundedIcon /></button>    
                    
              
                </div>
            </div>

            <div className="agency-shift-cluster-modal-bottom-shelf">
              <div className="agency-shift-cluster-top-shelf-title">
                {shelfTitleConverter(selectedDateState)}
                <button onClick={PushToNewSr} className="agency-shift-cluster-inner-expanded-modification-btn"><PersonAddRoundedIcon sx={{fontSize:"18px"}}/>Add</button>
                </div>
                <div className="agency-shift-cluster-bottom-shelf-inner">
                  <div style={{marginTop:"-5px"}}/>
                  <ShiftAccordionBuilder onOpenReassignModal={handleOpenReassignModal} onOpenCancelModal={handleOpenCancelModal} onOpenModifyModal={handleOpenModifyModal} SRIP={SRIP} selectedDate={selectedDateState}  isMobile={isMobile}/>
                  <div style={{marginTop:"-5px"}}/>
                </div>
            </div>
        </div>

    )}


    </Modal>

    {isCancelModalOpen ? <CancelShiftModal isMobile={isMobile} shiftInfoForModal={selectedShiftInfoForModal} modalOpen={isCancelModalOpen} handleClose={handleCloseCancelModal}/>:null}
    {isModifyModalOpen ? <ModifyShiftModal isMobile={isMobile} shiftInfoForModal={selectedShiftInfoForModal} modalOpen={isModifyModalOpen} handleClose={handleCloseModifyModal}/>:null}
    {isReAssignModalOpen ? <ReassignShiftModal isMobile={isMobile} shiftInfoForModal={selectedShiftInfoForModal} modalOpen={isReAssignModalOpen} handleClose={handleCloseReassignModal}/>:null}
</>
  );
};




type ShiftInfoForModal = {
    shiftRequestUnitId: string;
    startTimeEpoch: number;
    endTimeEpoch: number;
    orgName: string;
    orgLocation: string;
    workerName: string;
    workerType: string;
    workerProfilePic: string;
}








const ShiftClusterBuilder = ({aggregatedData,isMobile,handleSelectDate,selectedDate}: {aggregatedData: AggregatedShift[];isMobile: boolean;handleSelectDate: (selectedWorkyWorkdayShift: string) => void; selectedDate: string;}) => {
  return (
    <>
      {aggregatedData.map((shift) => (
        isMobile ? (
          <div id={shift.workyWorkdayShift === selectedDate ? "shift-cluster-active" : ""} className="agency-shift-cluster-mobile" key={shift.workyWorkdayShift} onClick={() => handleSelectDate(shift.workyWorkdayShift)}>
            <div className="agency-shift-cluster-title-mobile">{extractDateTitleFromWorkyWorkdayShift(shift.workyWorkdayShift)}</div>
            <div className="agency-shift-cluster-number-mobile">{shift.assignedCount + "/" + shift.totalCount}</div>
          </div>
        
          ) : (

          <div id={shift.workyWorkdayShift === selectedDate ? "shift-cluster-active" : ""} className="agency-shift-cluster" key={shift.workyWorkdayShift} onClick={() => handleSelectDate(shift.workyWorkdayShift)}>
            <div className="agency-shift-cluster-title">{extractDateTitleFromWorkyWorkdayShift(shift.workyWorkdayShift)}</div>
            <div className="agency-shift-cluster-number">{shift.assignedCount + "/" + shift.totalCount}</div>
          </div>

        )
      ))}
    </>
  );
};


// bottom portion





const CancelShiftModal = ({isMobile, shiftInfoForModal, modalOpen, handleClose}
  :{isMobile:boolean; shiftInfoForModal:ShiftInfoForModal; modalOpen: boolean; handleClose: () => void;}) => {
  
  const handleCancel = async (e) => {
    e.preventDefault();
  
    console.log("inside cancel shift modal :  ---- "+ shiftInfoForModal.shiftRequestUnitId)
      await CancelShiftRequestAPICall({shiftId: shiftInfoForModal.shiftRequestUnitId})

  }
  return(
    <Modal open={modalOpen} onClose={handleClose}>
      {isMobile?
        (
          <Slide direction="up" in={modalOpen} mountOnEnter unmountOnExit>
            <div className="simple-modify-modal-mobile">
            <button onClick={handleClose} className="simple-modify-modal-close-mobile"></button>
            <div className="simple-modify-modal-title">Are you sure you want to <span className="simple-bold">Cancel this Shift?</span> </div>
            <div className="simple-modify-modal-info">
              <div className="simple-modify-modal-info-title">Shift Information:</div>
              <div className="simple-modify-modal-info-inner-mobile">

                {shiftInfoForModal.workerName.includes("undefined") ? (null):
                  <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">
                    <div className="simple-modify-modal-info-inner-item-value-icon"><PersonRoundedIcon sx={{fontSize:"10px"}}/></div>
                    {shiftInfoForModal.workerName}
                  </div>
                </div>
                }
                {!shiftInfoForModal.workerType ? (null):
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Type:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{formatWorkerType(shiftInfoForModal.workerType)}</div>
                </div>}
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Shift Date:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToDateString(shiftInfoForModal.startTimeEpoch)}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Start Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.startTimeEpoch)}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">End Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.endTimeEpoch)}</div>
                </div>
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{shiftInfoForModal.orgName}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Location:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{shiftInfoForModal.orgLocation}</div>
                </div>
                
            


              </div>
            </div>
            <div className="simple-modify-modal-btn-holder">
              <button onClick={handleClose} className="simple-modify-modal-btn">No</button>
              <button onClick={handleCancel} id="simple-modify-modal-btn-yes" className="simple-modify-modal-btn">Yes</button>
            </div>
          </div>
          </Slide>
        ):
        (
          <div className="simple-modify-modal">
            <button onClick={handleClose} className="simple-modify-modal-close"><CloseRoundedIcon/></button>
            <div className="simple-modify-modal-title">Are you sure you want to <span className="simple-bold">Cancel this Shift?</span></div>
            <div className="simple-modify-modal-info">
              <div className="simple-modify-modal-info-title">Shift Information:</div>
              <div className="simple-modify-modal-info-inner">

              {shiftInfoForModal.workerName.includes("undefined") ? (null):
                  <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">
                    <div className="simple-modify-modal-info-inner-item-value-icon"><PersonRoundedIcon sx={{fontSize:"10px"}}/></div>
                    {shiftInfoForModal.workerName}
                  </div>
                </div>
                }
                {!shiftInfoForModal.workerType ? (null):
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Type:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{formatWorkerType(shiftInfoForModal.workerType)}</div>
                </div>}
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Start Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.startTimeEpoch)}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">End Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.endTimeEpoch)}</div>
                </div>
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{shiftInfoForModal.orgName}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Location:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{formatWorkerType(shiftInfoForModal.orgLocation)}</div>
                </div>
                
            


              </div>
            </div>
            <div className="simple-modify-modal-btn-holder">
              <button onClick={handleClose} className="simple-modify-modal-btn">No</button>
              <button onClick={handleCancel} id="simple-modify-modal-btn-yes" className="simple-modify-modal-btn">Yes</button>
            </div>
          </div>
        )
      }
    </Modal>
  )
}

const ModifyShiftModal = ({isMobile, shiftInfoForModal, modalOpen, handleClose}:{isMobile:boolean; shiftInfoForModal:ShiftInfoForModal; modalOpen: boolean; handleClose: () => void;}) => {
  const [newStartTimeHour, setNewStartTimeHour] = useState(null);
	const [newStartTimeMinute, setNewStartTimeMinute] = useState(null);
	const [newEndTimeHour, setNewEndTimeHour] = useState(null);
	const [newEndTimeMinute, setNewEndTimeMinute] = useState(null);

	const handleExtendStartTimeHour = (hour) => {
    if (hour === 0 || hour === null|| isNaN(hour)){
      // setNewStartTimeHour(getHourFromEpoch(shiftInfoForModal.startTimeEpoch))
    }else{
      setNewStartTimeHour(hour)
    }
  }

  const handleExtendStartTimeMinute = (minute) => {
    if (minute === null || isNaN(minute)){
      // setNewStartTimeMinute(getMinsFromEpoch(shiftInfoForModal.startTimeEpoch))
    }else{
      setNewStartTimeMinute(minute)
    }
  }

	const handleExtendEndTimeHour = (hour) => {
    if (hour === 0 || hour === null|| isNaN(hour)){
      // setNewEndTimeHour(getHourFromEpoch(shiftInfoForModal.endTimeEpoch))
    }else{
      setNewEndTimeHour(hour)
    }
  }

  const handleExtendEndTimeMinute = (minute) => {
    if (minute === null || isNaN(minute)){
      // setNewEndTimeMinute(getMinsFromEpoch(shiftInfoForModal.endTimeEpoch))
    }else{
      setNewEndTimeMinute(minute)
    }
  }

  const handleModifyShift = async () =>  {
    const date = epochToDate(shiftInfoForModal.startTimeEpoch)


    const convertToEpochSec = (epochTimeInSeconds: number, hour: number, minute: number): number => {
      return convertDateEpochAndTimeToEpochSec({
          epochTimeInSeconds,
          hours: hour !== null ? hour : getHourFromEpoch(epochTimeInSeconds),
          minutes: minute !== null ? minute : getMinsFromEpoch(epochTimeInSeconds)
      });
  };

    let modifyShiftTimeApiBody:  ModifyShiftTimeRequest= null;
    let newStartTime: number= null;
    let newEndTime: number= null;
    
    console.log("newStartTimeHour: ", newStartTimeHour, " newStartTimeMinute: ", newStartTimeMinute,
      " newEndTimeHour: " ,newEndTimeHour, " newEndTimeMinute: ", newEndTimeMinute
    )

    if(!newStartTimeHour && !newStartTimeMinute && !newEndTimeHour
      && !newEndTimeMinute){
        console.log("nothing was selected")
        return;
      }
      
      if (newStartTimeHour !== null || newStartTimeMinute !== null) {
        newStartTime = convertToEpochSec(shiftInfoForModal.startTimeEpoch, newStartTimeHour, newStartTimeMinute);
    }

    if (newEndTimeHour !== null || newEndTimeMinute !== null) {
        newEndTime = convertToEpochSec(shiftInfoForModal.endTimeEpoch, newEndTimeHour, newEndTimeMinute);
    }
    
    if(!newStartTime && !newEndTime){
      console.log("again nothing was selected")
        return;
    }

    modifyShiftTimeApiBody = {
			shiftId: shiftInfoForModal.shiftRequestUnitId,
			newShiftStartTime: newStartTime,
			newShiftEndTime: newEndTime,
		};


    console.log('modifyShiftTimeApiBody ' + JSON.stringify(modifyShiftTimeApiBody));
    
        try {
          const response: AxiosResponse<ServerResponse<string, Error>> =
            await remoteCallObject.remotePOSTCallV2<
              ServerResponse<string, Error>,
              ModifyShiftTimeRequest
            >({
              apiUrl: backendApiBasePathLocal + 'shift/modify/shiftTime',
              object: modifyShiftTimeApiBody,
              headers: { 'Content-Type': 'application/json' },
            });
          console.log('POST request successful:', response);
    
          if (
            response &&
            (response.status === 200 || response.status === 201 || response.status === 202)
          ) {
            window.location.reload();
            console.log('200 or 201 or 202');
          }
        } catch (e) {
          if (e.response && (e.response.status === 400 || e.response.status === 500)) {
            console.log('400 or 500');
          }
          console.error('POST request failed:', e);
        }                
  }

  return(
    <Modal open={modalOpen} onClose={handleClose}>
      {isMobile?
        (
          <Slide direction="up" in={modalOpen} mountOnEnter unmountOnExit>
            <div className="simple-modify-modal-mobile">
            <button onClick={handleClose} className="simple-modify-modal-close-mobile"></button>
            <div className="simple-modify-modal-title"><span className="simple-bold">Modify Shift Time</span> </div>
            <div className="simple-modify-modal-info">
                <div style={{paddingBottom:"10px"}} className="simple-modify-modal-info-title">Shift Details</div>



                {shiftInfoForModal.workerName.includes("undefined") ? (null):
                  <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">
                    <div className="simple-modify-modal-info-inner-item-value-icon"><PersonRoundedIcon sx={{fontSize:"10px"}}/></div>
                    {shiftInfoForModal.workerName}
                  </div>
                </div>
                }
                {!shiftInfoForModal.workerType ? (null):
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Type:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{formatWorkerType(shiftInfoForModal.workerType)}</div>
                </div>}

                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Start Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.startTimeEpoch)}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">End Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.endTimeEpoch)}</div>
                </div>
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{shiftInfoForModal.orgName}</div>
                </div>
                
            </div>
            <div className="simple-modify-modal-info">
                <div style={{paddingBottom:"10px"}} className="simple-modify-modal-info-title">Change Start Time</div>
                <WorkyTimePickerV3
                    prevHour = {getHourFromEpoch(shiftInfoForModal.startTimeEpoch)}
                    prevMin = {getMinsFromEpoch(shiftInfoForModal.startTimeEpoch)}
                    onFinalTimeChangeHour={(hour) => handleExtendStartTimeHour(hour)}
                    onFinalTimeChangeMinute={(minute) => handleExtendStartTimeMinute(minute)}
                />
                <div style={{paddingBottom:"10px"}} className="simple-modify-modal-info-title">Change End Time:</div>
                <WorkyTimePickerV3
                    prevHour = {getHourFromEpoch(shiftInfoForModal.endTimeEpoch)}
                    prevMin = {getMinsFromEpoch(shiftInfoForModal.endTimeEpoch)}
                    onFinalTimeChangeHour={(hour) => handleExtendEndTimeHour(hour)}
                    onFinalTimeChangeMinute={(minute) => handleExtendEndTimeMinute(minute)}
                />
                <button onClick={PushToNewSr}  id="simple-modify-modal-btn-yes" style={{width:"100%", fontSize:"16px"}} className="simple-modify-modal-btn">Submit</button>
            </div>
            <div className="simple-modify-modal-btn-holder">
              <button onClick={PushToNewSr} style={{width:"100%", fontSize:"16px"}} className="simple-modify-modal-btn"><PersonAddRoundedIcon sx={{fontSize:"18px"}}/>Add Workers</button>
            </div>
          </div>
          </Slide>
        )
        :
        (
          <div className="simple-modify-modal">
            <button onClick={handleClose} className="simple-modify-modal-close"><CloseRoundedIcon/></button>
            <div className="simple-modify-modal-title"><span className="simple-bold">Modify Shift Time</span></div>

            <div className="simple-modify-modal-info">
                <div style={{paddingBottom:"10px"}} className="simple-modify-modal-info-title">Shift Details</div>


                {shiftInfoForModal.workerName.includes("undefined") ? (null):
                  <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">
                    <div className="simple-modify-modal-info-inner-item-value-icon"><PersonRoundedIcon sx={{fontSize:"10px"}}/></div>
                    {shiftInfoForModal.workerName}
                  </div>
                </div>
                }
                {!shiftInfoForModal.workerType ? (null):
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Type:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{formatWorkerType(shiftInfoForModal.workerType)}</div>
                </div>}



                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Start Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.startTimeEpoch)}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">End Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.endTimeEpoch)}</div>
                </div>
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{shiftInfoForModal.orgName}</div>
                </div>
                
            </div>


            <div style={{background:"white", paddingBottom:"10px"}} className="simple-modify-modal-info">
            <div style={{paddingBottom:"10px"}} className="simple-modify-modal-info-title">Change Start Time</div>
            <WorkyTimePickerV3
								prevHour = {getHourFromEpoch(shiftInfoForModal.startTimeEpoch)}
								prevMin = {getMinsFromEpoch(shiftInfoForModal.startTimeEpoch)}
								onFinalTimeChangeHour={(hour) => handleExtendStartTimeHour(hour)}
								onFinalTimeChangeMinute={(minute) => handleExtendStartTimeMinute(minute)}
            />
            <div style={{paddingBottom:"10px"}} className="simple-modify-modal-info-title">Change End Time:</div>
            <WorkyTimePickerV3
								prevHour = {getHourFromEpoch(shiftInfoForModal.endTimeEpoch)}
								prevMin = {getMinsFromEpoch(shiftInfoForModal.endTimeEpoch)}
								onFinalTimeChangeHour={(hour) => handleExtendEndTimeHour(hour)}
								onFinalTimeChangeMinute={(minute) => handleExtendEndTimeMinute(minute)}
            />
            {/* <button onClick={PushToNewSr}  id="simple-modify-modal-btn-yes" style={{width:"100%", fontSize:"16px", marginTop:"30px"}} className="simple-modify-modal-btn">Submit</button> */}
            <button onClick={handleModifyShift}  id="simple-modify-modal-btn-yes" style={{width:"100%", fontSize:"16px", marginTop:"30px"}} className="simple-modify-modal-btn">Submit</button>
            </div>
            <div className="simple-modify-modal-btn-holder">
              <button onClick={PushToNewSr} style={{width:"100%", fontSize:"16px"}} className="simple-modify-modal-btn"><PersonAddRoundedIcon sx={{fontSize:"18px"}}/>Add Workers</button>
            </div>
          </div>
        )
      }
    </Modal>
  )
}

const ReassignShiftModal = ({isMobile, shiftInfoForModal, modalOpen, handleClose}
  :{ isMobile:boolean; shiftInfoForModal:ShiftInfoForModal; modalOpen: boolean; handleClose: () => void;}) => {

    console.log("shiftInfoForModal ", shiftInfoForModal)
 
  const [corpDataObj, setCorpDataObj] = useState<CorpDataObj | null>(null);  
  const [organizations, setOrganizations] = useState<Map<string, OrgLocationMapDetails>>(new Map());
  const [selectedJobbers, setSelectedJobbers] = useState<EmailAddress[]>([]);
  console.log(JSON.stringify(selectedJobbers))
  
  useEffect(() => {
    let isMounted = true; // Prevent state update if component unmounts
    const fetchOrgMetadata = async () => {
      try {
        if (!corpDataObj && isMounted) {
          const metaData = await GetCorpDetails();
          setCorpDataObj(metaData || null);
          if (metaData) {
            setOrganizations(metaData.orgLocationMapDetailsMap);            
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

  fetchOrgMetadata();
  return () => { isMounted = false; }; // Cleanup function
}, []);

  const PrevJobberSelection = () => {
    const { jobberDetails } = corpDataObj;
    const allJobberEmails: EmailAddress[] = Object.values(jobberDetails).map((details) => details.userDetails.userEmail);

    const handleSelectAll = ({singleJobberEmail,isSelectAll,}: {singleJobberEmail?: EmailAddress;isSelectAll: boolean;}) => {
      if (isSelectAll) {
        setSelectedJobbers(
          selectedJobbers.length === allJobberEmails.length ? [] : allJobberEmails
        );
      } else {
        if (singleJobberEmail) {
          setSelectedJobbers(
            selectedJobbers.some((email) => email.value === singleJobberEmail.value)
              ? selectedJobbers.filter((jobber) => jobber.value !== singleJobberEmail.value)
              : [...selectedJobbers, singleJobberEmail]
          );
        }
      }
    };

    const [searchQuery, setSearchQuery] = useState('');
    const fuse = new Fuse(Object.values(corpDataObj.jobberDetails), {
      keys: ['userDetails.firstName', 'userDetails.lastName'],
      threshold: 0.5,
    });

    const searchResults = searchQuery
      ? fuse.search(searchQuery).map((result) => result.item)
      : Object.values(jobberDetails);

    

    const AvailablePrevJobbers = () => {
      console.log("TESTING SEARCH RESULTS"+JSON.stringify(searchResults))
      return(
        <>
          {searchResults.map((details) => {
          const { userDetails, profilePicUrl } = details; // Use 'userProfilePicUrl' instead of 'profilePicUrl'
          const jobberEmail = userDetails.userEmail;

          return (
            <div key={jobberEmail.value} className="prev-jobber-card-v5">
              <div className="prev-jobber-card-v5-side">
                <div className="prev-jobber-card-v5-icon">
                  <img src={profilePicUrl == null ? defaultPic : profilePicUrl} />
                </div>
                <div className="prev-jobber-card-v5-name">
                  {userDetails.firstName} {userDetails.lastName}
                </div>
              </div>
              <div className="prev-jobber-card-v5-side">
                <div className="prev-jobber-card-v5-rating">
                  <Rating size="small" value={5} readOnly />
                </div>
                <div className="prev-jobber-card-v5-select">
                  <input
                    type="checkbox"
                    checked={selectedJobbers.some((jobber) => jobber.value === jobberEmail.value)}
                    onChange={() => handleSelectAll({ isSelectAll: false, singleJobberEmail: jobberEmail })}
                  />
                </div>
              </div>
            </div>
          );
        })}
        </>
      );
    };

    return (
      <>
        <div style={{padding:"10px"}} className="simple-modify-modal-info-title">Select Workers for Re-assigning Shift</div>
        <div style={{border:"0px", borderRadius:"15px"}} className="prev-jobber-holder">
          <div className="prev-jobber-search-holder">
            <input
              placeholder="Search My Workforce"
              className="prev-jobber-search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button onClick={() => handleSelectAll({ singleJobberEmail: null, isSelectAll: true })}className="prev-jobber-select-all-btn">
              {selectedJobbers.length === allJobberEmails.length ? 'Deselect All' : 'Select All'}
            </button>
          </div>
          <AvailablePrevJobbers />
        </div>
      </>
    );
  };  
  
  const handleSubmissionOfSRAssignment = async(shiftInfoForModal: ShiftInfoForModal)  => {
    if(!selectedJobbers){
      console.log("no jobber selected cannot re-assign ",  selectedJobbers)
      return;
    }

    try{

      await DirectReAssignShiftAPIReq({shiftId: shiftInfoForModal.shiftRequestUnitId, jobberEmail: selectedJobbers[0],isOverrideAndDeleteFlag:true})    
      window.location.reload()
      return null;

    }catch(err){
      console.log("error in handleSubmissionOfSRAssignment ", shiftInfoForModal, " ", err)
    }
  }

  return(
    <Modal open={modalOpen} onClose={handleClose}>
      {isMobile?
        (
          <Slide direction="up" in={modalOpen} mountOnEnter unmountOnExit>
            <div className="simple-modify-modal-mobile">
            <button onClick={handleClose} className="simple-modify-modal-close-mobile"></button>
            <div className="simple-modify-modal-title"><span className="simple-bold">Re-Assign Shift</span> </div>
            <div className="simple-modify-modal-info">
              <div className="simple-modify-modal-info-title">Shift Information:</div>
              <div className="simple-modify-modal-info-inner-mobile">

                {shiftInfoForModal.workerName.includes("undefined") ? (null):
                  <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">
                    <div className="simple-modify-modal-info-inner-item-value-icon"><PersonRoundedIcon sx={{fontSize:"10px"}}/></div>
                    {shiftInfoForModal.workerName}
                  </div>
                </div>
                }
                {!shiftInfoForModal.workerType ? (null):
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Type:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{formatWorkerType(shiftInfoForModal.workerType)}</div>
                </div>}
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Shift Date:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToDateString(shiftInfoForModal.startTimeEpoch)}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Start Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.startTimeEpoch)}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">End Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.endTimeEpoch)}</div>
                </div>
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{shiftInfoForModal.orgName}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Location:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{shiftInfoForModal.orgLocation}</div>
                </div>
                
            


              </div>
            </div>


            {corpDataObj? 
              <div className="prev-jobber-holder-reassign-modal">
                <PrevJobberSelection/>
              </div>
            :null}



            <div className="simple-modify-modal-btn-holder">
              <button onClick={handleClose} className="simple-modify-modal-btn">Cancel</button>
              <button onClick={()=>console.log("YES CANCEL CLICKED")} id="simple-modify-modal-btn-yes" className="simple-modify-modal-btn">Confirm</button>
            </div>
            
           


          </div>
          </Slide>
        ):
        (
          <div className="simple-modify-modal">
            <button onClick={handleClose} className="simple-modify-modal-close"><CloseRoundedIcon/></button>
            <div className="simple-modify-modal-title">Are you sure you want to <span className="simple-bold">
              {isValidString(shiftInfoForModal.workerName) ? 
              "Re-Assign this Shift ?"
            : "Instant-Assign this Shift ?"}
              
              </span></div>
            <div className="simple-modify-modal-info">
              <div className="simple-modify-modal-info-title">Shift Information:</div>
              <div className="simple-modify-modal-info-inner">

              {shiftInfoForModal.workerName.includes("undefined") ? (null):
                  <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">
                    <div className="simple-modify-modal-info-inner-item-value-icon"><PersonRoundedIcon sx={{fontSize:"10px"}}/></div>
                    {shiftInfoForModal.workerName}
                  </div>
                </div>
                }
                {!shiftInfoForModal.workerType ? (null):
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Worker Type:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{formatWorkerType(shiftInfoForModal.workerType)}</div>
                </div>}
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Start Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.startTimeEpoch)}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">End Time:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{epochToTimeString(shiftInfoForModal.endTimeEpoch)}</div>
                </div>
                
                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Name:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{shiftInfoForModal.orgName}</div>
                </div>

                <div className="simple-modify-modal-info-inner-item">
                  <div className="simple-modify-modal-info-inner-item-title">Org Location:</div>
                  <div className="simple-modify-modal-info-inner-item-value">{formatWorkerType(shiftInfoForModal.orgLocation)}</div>
                </div>
                


              </div>
            </div>
                {corpDataObj? 
                  <div className="prev-jobber-holder-reassign-modal">
                    <PrevJobberSelection/>
                  </div>
                :null}
            <div className="simple-modify-modal-btn-holder">
              <button onClick={handleClose} className="simple-modify-modal-btn">Cancel</button>
              <button onClick={() => handleSubmissionOfSRAssignment(shiftInfoForModal)} id="simple-modify-modal-btn-yes" className="simple-modify-modal-btn">Confirm</button>
            </div>
          </div>
        )
      }
    </Modal>
  )
  
}

const ShiftStatusBuilder = ({ status }: { status: string }) => {
  if (status === "yes") {return (<div id="clockedontime" className="agency-shift-cluster-inner-shift-status" >Assigned</div>);}
  else {return (<div id="pending-shift-tag" className="agency-shift-cluster-inner-shift-status">Pending</div>);}
};

const ShiftAccordionBuilder = ({SRIP,selectedDate,isMobile,onOpenReassignModal, onOpenModifyModal, onOpenCancelModal}: {SRIP: SRIPResponseData[];isMobile: boolean;selectedDate: string; onOpenModifyModal: (string) => void; onOpenCancelModal: (string) => void; onOpenReassignModal: (string) => void;}) => {
  


  const matchingShifts = SRIP.filter((shift) => shift.workyWorkdayShift === selectedDate);

  const buildAccordionForShifts = (shifts: ShiftRequestData[],status: string) => {
    return shifts.map((shift) => (
      <Accordion key={shift.shiftRequestUnitId} disableGutters sx={{borderRadius: "20px",boxShadow: "none",marginBottom: "0px","&:before": { display: "none" },}}>

        <AccordionSummary className={isMobile?"agency-shift-cluster-inner-shift-mobile":"agency-shift-cluster-inner-shift"} expandIcon={<ExpandMoreIcon />}>
          <div className="agency-shift-cluster-inner-shift">
            <div className="agency-shift-cluster-inner-shift-icon">{shift.assignedJobberProfilePicS3URL ? <img src={shift.assignedJobberProfilePicS3URL}/> : <PersonRoundedIcon/>}</div>
            
            {isMobile && !(shift.assignedJobberUserDetails?.firstName) ? 
              <div className="agency-shift-cluster-inner-shift-time-mobile">{formatEpochTimeRange(shift.startDateTimeEpochSeconds,shift.endDateTimeEpochSeconds)}</div>
              :
              <div className={isMobile?"agency-shift-cluster-inner-shift-name-mobile":"agency-shift-cluster-inner-shift-name"}>{shift.assignedJobberUserDetails?.firstName} {shift.assignedJobberUserDetails?.lastName}</div>
            }

            {!isMobile && <WorkerTypeTagBuilder workertype={shift.workerType} />}
            {!isMobile && ( <div className="agency-shift-cluster-inner-shift-time">{formatEpochTimeRange(shift.startDateTimeEpochSeconds,shift.endDateTimeEpochSeconds)}</div>)}
            <ShiftStatusBuilder status={status} />
          </div>
        </AccordionSummary>

        <AccordionDetails sx={{padding: "10px 20px",margin: 0,}}>
            <div className="agency-shift-cluster-inner-expanded">
              <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-holder-mobile":"agency-shift-cluster-inner-expanded-detail-holder"}>
                
                <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-col-mobile":"agency-shift-cluster-inner-expanded-detail-col"}>  
                    <div className="agency-shift-cluster-inner-expanded-detail-row">
                        <div className="agency-shift-cluster-inner-expanded-detail-row-title">Org Name</div>
                        <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-row-value-mobile":"agency-shift-cluster-inner-expanded-detail-row-value"}>{shift.orgName}</div>
                    </div>
                    <div className="agency-shift-cluster-inner-expanded-detail-row">
                        <div className="agency-shift-cluster-inner-expanded-detail-row-title">Org Location</div>
                        <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-row-value-mobile":"agency-shift-cluster-inner-expanded-detail-row-value"}>{formatWorkerType(shift.simpleLocation)}</div>
                    </div>
                    {shift.jobberHourlyRate === 0 ? null:
                    <div className="agency-shift-cluster-inner-expanded-detail-row">
                        <div className="agency-shift-cluster-inner-expanded-detail-row-title">Hourly Pay</div>
                        <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-row-value-mobile":"agency-shift-cluster-inner-expanded-detail-row-value"}>{plainNumberToHourlyPayNumber(shift.jobberHourlyRate)}</div>
                    </div>}
                    <div className="agency-shift-cluster-inner-expanded-detail-row">
                        <div className="agency-shift-cluster-inner-expanded-detail-row-title">Worker Type</div>
                        <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-row-value-mobile":"agency-shift-cluster-inner-expanded-detail-row-value"}><WorkerTypeTagBuilder workertype={shift.workerType} /></div>
                    </div>
                </div>

                <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-col-mobile":"agency-shift-cluster-inner-expanded-detail-col"}>  
                    <div className="agency-shift-cluster-inner-expanded-detail-row">
                        <div className="agency-shift-cluster-inner-expanded-detail-row-title">Start Time</div>
                        <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-row-value-mobile":"agency-shift-cluster-inner-expanded-detail-row-value"}>{epochToTimeString(shift.startDateTimeEpochSeconds)}</div>
                    </div>
                    <div className="agency-shift-cluster-inner-expanded-detail-row">
                        <div className="agency-shift-cluster-inner-expanded-detail-row-title">End Time</div>
                        <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-row-value-mobile":"agency-shift-cluster-inner-expanded-detail-row-value"}>{epochToTimeString(shift.endDateTimeEpochSeconds)}</div>
                    </div>
                    {shift.jobberHourlyRate === 0 ? null:
                    <div className="agency-shift-cluster-inner-expanded-detail-row">
                        <div className="agency-shift-cluster-inner-expanded-detail-row-title">Hourly Pay</div>
                        <div className={isMobile?"agency-shift-cluster-inner-expanded-detail-row-value-mobile":"agency-shift-cluster-inner-expanded-detail-row-value"}>{plainNumberToHourlyPayNumber(shift.jobberHourlyRate)}</div>
                    </div>}
                </div>
              </div>






              <div className={isMobile?"agency-shift-cluster-inner-expanded-modification-holder-mobile":"agency-shift-cluster-inner-expanded-modification-holder"}>
                  <button onClick={() => {
                      const shiftInfo: ShiftInfoForModal = {
                        shiftRequestUnitId: shift.shiftRequestUnitId,
                        startTimeEpoch: shift.startDateTimeEpochSeconds,
                        endTimeEpoch: shift.endDateTimeEpochSeconds,
                        orgName: shift.orgName,
                        orgLocation: shift.simpleLocation,
                        workerName: `${shift.assignedJobberUserDetails?.firstName} ${shift.assignedJobberUserDetails?.lastName}`,
                        workerType: shift.workerType,
                        workerProfilePic: shift.assignedJobberProfilePicS3URL,
                      };
                      
                      onOpenReassignModal(shiftInfo);}}
                      className={isMobile?"agency-shift-cluster-inner-expanded-modification-btn-mobile":"agency-shift-cluster-inner-expanded-modification-btn"}><CachedRoundedIcon  sx={{fontSize: isMobile ? "16px" : "18px"}}/>
                      {shift.assignedJobberUserDetails 
                      ? "Re-Assign"
                      :"Instant-Assign"}
                        
                  </button>

                  <button 
                    onClick={() => {
                      const shiftInfo: ShiftInfoForModal = {
                        shiftRequestUnitId: shift.shiftRequestUnitId,
                        startTimeEpoch: shift.startDateTimeEpochSeconds,
                        endTimeEpoch: shift.endDateTimeEpochSeconds,
                        orgName: shift.orgName,
                        orgLocation: shift.simpleLocation,
                        workerName: `${shift.assignedJobberUserDetails?.firstName} ${shift.assignedJobberUserDetails?.lastName}`,
                        workerType: shift.workerType,
                        workerProfilePic: shift.assignedJobberProfilePicS3URL,
                      };
                      
                      onOpenModifyModal(shiftInfo);}}
                      className={isMobile?"agency-shift-cluster-inner-expanded-modification-btn-mobile":"agency-shift-cluster-inner-expanded-modification-btn"}><EditRoundedIcon  sx={{fontSize: isMobile ? "16px" : "18px"}}/>
                    Modify</button>

                  <button onClick={() => {
                      const shiftInfo: ShiftInfoForModal = {
                        shiftRequestUnitId: shift.shiftRequestUnitId,
                        startTimeEpoch: shift.startDateTimeEpochSeconds,
                        endTimeEpoch: shift.endDateTimeEpochSeconds,
                        orgName: shift.orgName,
                        orgLocation: shift.simpleLocation,
                        workerName: `${shift.assignedJobberUserDetails?.firstName} ${shift.assignedJobberUserDetails?.lastName}`,
                        workerType: shift.workerType,
                        workerProfilePic: shift.assignedJobberProfilePicS3URL,
                      };
                      
                      onOpenCancelModal(shiftInfo);}}
                      className={isMobile?"agency-shift-cluster-inner-expanded-modification-btn-mobile":"agency-shift-cluster-inner-expanded-modification-btn"}><CloseRoundedIcon sx={{fontSize: isMobile ? "16px" : "18px"}}/>
                  Cancel</button>

              </div>

            </div>


        </AccordionDetails>
      </Accordion>
    ));
  };


  const inProgressShifts: ShiftRequestData[] = matchingShifts.flatMap((shift) =>
    shift.inProgressShiftRequests.map((req) => ({
      shiftRequestUnitId: req.shiftRequestUnitId,
      parentShiftRequestId: req.parentShiftRequestId || "", // Assuming optional or using empty string
      shiftRequestStatus: req.shiftRequestStatus || "Pending", // Default value if not available
      workerType: req.workerType,
      assignedJobberUserDetails: req.assignedJobberUserDetails,
      assignedJobberProfilePicS3URL: req.assignedJobberProfilePicS3URL,
      requestingUserDetails: req.requestingUserDetails,
      workyWorkdayShift: req.workyWorkdayShift || "", // Assuming optional or using empty string
      simpleLocation: req.simpleLocation || "",
      orgName: req.orgName || "",
      jobberHourlyRate: req.jobberHourlyRate || 0,
      orgHourlyRate: req.orgHourlyRate || 0,
      startDateTimeEpochSeconds: req.startDateTimeEpochSeconds,
      endDateTimeEpochSeconds: req.endDateTimeEpochSeconds,
      startDateTime: req.startDateTime || "",
      endDateTime: req.endDateTime || "",
      startDay: req.startDay || "",
      startTime: req.startTime || 0,
      submissionDateTimeEpochSeconds: req.submissionDateTimeEpochSeconds || 0,
      submissionDateTime: req.submissionDateTime || "",
      orgAddress: req.orgAddress || { street: "", unitNumber: "", city: "", province: "", postalCode: "", country: "", majorIntersection: "", entranceInstructions: "" },
      lastJobberPoolNotifTime: req.lastJobberPoolNotifTime || null,
      lastJobberPoolNotifTimeEpochSec: req.lastJobberPoolNotifTimeEpochSec || null,
      preferredJobbers: req.preferredJobbers || [],
      updatedDateTimeEpoch: req.updatedDateTimeEpoch || 0,
      updatedDateTime: req.updatedDateTime || "",
    }))
  );

  const assignedShifts: ShiftRequestData[] = matchingShifts.flatMap((shift) =>
    shift.assignedShiftRequests.map((req) => ({
      shiftRequestUnitId: req.shiftRequestUnitId,
      parentShiftRequestId: req.parentShiftRequestId || "", // Assuming optional or using empty string
      shiftRequestStatus: req.shiftRequestStatus || "Assigned", // Default value if not available
      workerType: req.workerType,
      assignedJobberUserDetails: req.assignedJobberUserDetails,
      assignedJobberProfilePicS3URL: req.assignedJobberProfilePicS3URL,
      requestingUserDetails: req.requestingUserDetails ,
      workyWorkdayShift: req.workyWorkdayShift || "", // Assuming optional or using empty string
      simpleLocation: req.simpleLocation || "",
      orgName: req.orgName || "",
      jobberHourlyRate: req.jobberHourlyRate || 0,
      orgHourlyRate: req.orgHourlyRate || 0,
      startDateTimeEpochSeconds: req.startDateTimeEpochSeconds,
      endDateTimeEpochSeconds: req.endDateTimeEpochSeconds,
      startDateTime: req.startDateTime || "",
      endDateTime: req.endDateTime || "",
      startDay: req.startDay || "",
      startTime: req.startTime || 0,
      submissionDateTimeEpochSeconds: req.submissionDateTimeEpochSeconds || 0,
      submissionDateTime: req.submissionDateTime || "",
      orgAddress: req.orgAddress || { street: "", unitNumber: "", city: "", province: "", postalCode: "", country: "", majorIntersection: "", entranceInstructions: "" },
      lastJobberPoolNotifTime: req.lastJobberPoolNotifTime || null,
      lastJobberPoolNotifTimeEpochSec: req.lastJobberPoolNotifTimeEpochSec || null,
      preferredJobbers: req.preferredJobbers || [],
      updatedDateTimeEpoch: req.updatedDateTimeEpoch || 0,
      updatedDateTime: req.updatedDateTime || "",
    }))
  );

  return (
    <>
      {buildAccordionForShifts(inProgressShifts, "no")}
      {buildAccordionForShifts(assignedShifts, "yes")}
    </>
  );
};





export { AgencyShiftClusterModal };