import { CorpType } from "../../common/CommonInterfaces";
import { UserType } from "../../remoteExecution/RemoteExecutionServiceImpl";



const IsAgencyUser = ({userType}: {userType: UserType | string}) => {	
    console.log("userType ss", userType)
	return userType === CorpType.AGENCY|| userType === UserType.AGENCY_ADMIN || userType === UserType.AGENCY_STAFF;
}



const IsOrgUser = ({userType}: {userType: UserType | string}) => {	
	return userType === CorpType.ORG|| userType === UserType.ORG_MANAGER;
}

export {  IsAgencyUser, IsOrgUser}