import React, { useState, useEffect, useRef, useCallback} from 'react';
import { WorkyContactButton, WorkyAppHeader, SRtoEventBuilder, aggregatedSRIPtoCalendarEventBuilder} from '../agency-logic/agency-components/AgencyComponentSetOne';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import { StyleLinks } from './OrgCommonComponents';
import { GetCorpSRIP, SRIPResponseData } from '../common/ShiftReadAPIs';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import { EmptyInfoState } from '../agency-logic/agency-pages/EmptyInfoState';
import NightsStayRoundedIcon from '@mui/icons-material/NightsStayRounded';
import WbTwilightRoundedIcon from '@mui/icons-material/WbTwilightRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import ViewCarouselRoundedIcon from '@mui/icons-material/ViewCarouselRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import { Tooltip } from '@mui/material';
import moment from 'moment'
import './myshifts.css'
import { AggregatedShift, shiftRequestAggregator } from '../agency-logic/agency-components/CarouselHelper';
import { WorkyAgencyCarouselV2 } from '../agency-logic/agency-components/WorkyAgencyCarouselV2';
import { AgencyShiftClusterModal } from '../agency-logic/agency-components/AgencyShiftClusterModal';


const PageHeader = ({isMobile, calendarViewActive, setCalendarViewActive}:{isMobile:boolean, calendarViewActive:boolean, setCalendarViewActive: (boolean) => void}) => {
    return (
        <div className={isMobile ? 'payments-header-holder-mobile' : 'payments-header-holder'}>
            <div className='payments-header'>
                <div className='payments-header-title-holder'>
                    <div style={{marginLeft: "5px", marginTop: "5px", cursor: "auto"}} id="payments-header-active" className='payments-header-title-switch'>My Shifts</div>
                </div>
                <div className='payments-header-subtitle'>Manage and View your Shifts</div>
            </div>
            <div className='header-selection-toggle'>
                <button id={calendarViewActive ? 'header-selection-toggle-btn-active' : ''} className='header-selection-toggle-btn' onClick={() => setCalendarViewActive(true)}>
                    <EventRoundedIcon />
                </button>
                <button id={!calendarViewActive ? 'header-selection-toggle-btn-active' : ''} className='header-selection-toggle-btn' onClick={() => setCalendarViewActive(false)}>
                    <ViewCarouselRoundedIcon />
                </button>
            </div>
        </div>
    );
};



const MyShiftsCalander = ({ modalOpenShiftCluster, handleCloseShiftCluster, handleOpenShiftCluster, isMobile, localizer, SRIP }:{isMobile:boolean;localizer:any; SRIP:SRIPResponseData[]; openNewShiftModal:boolean,setOpenNewShiftModal: (boolean) => void;handleCloseShiftCluster: () => void;handleOpenShiftCluster: () => void,modalOpenShiftCluster:boolean;}) => {
    const aggregatedShifts = shiftRequestAggregator(SRIP);
    console.log("aggregatedShifts "+(JSON.stringify(aggregatedShifts)))
    // const events2 = SRtoEventBuilder(aggregatedShifts);

    const [modsOpen, setModsOpen] = useState(false);
    const toggleMods = () => {setModsOpen(prevState => !prevState);};

    // console.log("testing srip"+JSON.stringify(SRIP))
    // console.log("testing events2"+JSON.stringify(events2))

    const events = [
        {
          title: 'shift',
          start: new Date('2025-01-19T13:45:00-05:00'),   
          end: new Date('2025-01-19T14:00:00-05:00')
        }
    ]
      
    const [firstDate, setFirstDate] = useState<string | null>(null);

    useEffect(() => {
        if (SRIP && SRIP.length > 0) {
            const firstWorkdayShift = SRIP[0].workyWorkdayShift;
            if (firstWorkdayShift) {
                setFirstDate(firstWorkdayShift); 
            }
        }
    }, [SRIP]); 





    
    
    // const shiftEvents = parseShiftRequests(aggregatedShifts);
    const shiftEvents2 = aggregatedSRIPtoCalendarEventBuilder(aggregatedShifts);
    const clickRef = useRef(null)

    const onSelectEvent = useCallback((calEvent) => {
        console.log("TEST TEST" + JSON.stringify(calEvent));
        setFirstDate(calEvent.titleForModal);
        console.log("firstDatefirstDate "+firstDate);
        handleOpenShiftCluster(); // Call the function here
        window.clearTimeout(clickRef?.current);
    }, []);



    console.log("shiftEvents2 " + JSON.stringify(shiftEvents2));



    const [morningShifts, setMorningShifts] = useState(0);
    const [afternoonShifts, setAfternoonShifts] = useState(0);
    const [nightShifts, setNightShifts] = useState(0);
    useEffect(() => {
        let morningCount = 0;
        let afternoonCount = 0;
        let nightCount = 0;
    
        shiftEvents2.forEach((shift) => {
          const startHour = new Date(shift.start).getHours();
    
          if (startHour >= 5 && startHour < 12) {
            morningCount++;
          } else if (startHour >= 12 && startHour < 17) {
            afternoonCount++;
          } else {
            nightCount++;
          }
        });
    
        setMorningShifts(morningCount);
        setAfternoonShifts(afternoonCount);
        setNightShifts(nightCount);
      }, [shiftEvents2]);

    return(
        <div className='my-shifts-calendar-holder-outer'>
            <div className={isMobile?'my-shifts-calendar-holder-mobile':'my-shifts-calendar-holder'}>

            {isMobile?(null):(

                <div className={modsOpen?'shift-modification-holder':'shift-modification-holder-minimized'}>

                    {modsOpen?(<div className='shift-modification-title'>Modify Shifts</div>):(<div className='shift-modification-title-minimized'>Modify Shifts</div>)}
                    <div className='shift-modification-details-holder'>
                        
                        <Tooltip placement='right' title='Submit A Shift'>
                            <button onClick={()=>window.location.assign('/new-shift')} id='gradient-blue' className={modsOpen?'shift-modification-detail-edit':'shift-modification-detail-edit-minimized'}>
                                {modsOpen?(<div className='shift-modification-detail-icon'><AddCircleOutlineRoundedIcon/></div>):(<div className='shift-modification-detail-icon-minimized'><AddCircleOutlineRoundedIcon/></div>)}
                                {modsOpen?(<div className='shift-modification-detail-text'>New Shift</div>):(null)}
                                {modsOpen?(<div className='shift-modification-detail-value'></div>):(null)}
                            </button>
                        </Tooltip>
                        <Tooltip placement='right' title='Edit A Shift'>
                            <button onClick={handleOpenShiftCluster} className={modsOpen?'shift-modification-detail-edit':'shift-modification-detail-edit-minimized'}>
                                {modsOpen?(<div className='shift-modification-detail-icon'><BorderColorRoundedIcon/></div>):(<div className='shift-modification-detail-icon-minimized'><BorderColorRoundedIcon/></div>)}
                                {modsOpen?(<div className='shift-modification-detail-text'>Edit Shift</div>):(null)}
                                {modsOpen?(<div className='shift-modification-detail-value'></div>):(null)}
                            </button>
                        </Tooltip>
                    </div>
                    {modsOpen?(<div className='shift-modification-title'>Shifts</div>):(<div className='shift-modification-title-minimized'>Shifts</div>)}
                    <div className='shift-modification-details-holder'>
                        <Tooltip placement='right' title='Number of Morning Shifts'>
                            <button className={modsOpen?'shift-modification-detail-edit':'shift-modification-detail-edit-minimized'}>
                                {modsOpen?(<div className='shift-modification-detail-icon'><LightModeRoundedIcon style={{fontSize:"22px"}} /></div>):(<div className='shift-modification-detail-icon-minimized'><LightModeRoundedIcon style={{fontSize:"20px"}} /></div>)}
                                {modsOpen?(<div className='shift-modification-detail-text'>Morning</div>):(null)}
                                {modsOpen?(<div className='shift-modification-detail-value-active'>{morningShifts}</div>):(<div className='shift-modification-detail-value-active-minimized'>{morningShifts}</div>)}
                            </button>
                        </Tooltip>
                        <Tooltip placement='right' title='Number of Afternoon Shifts'>
                            <button className={modsOpen?'shift-modification-detail-edit':'shift-modification-detail-edit-minimized'}>
                                {modsOpen?(<div className='shift-modification-detail-icon'><WbTwilightRoundedIcon style={{fontSize:"22px"}} /></div>):(<div className='shift-modification-detail-icon-minimized'><WbTwilightRoundedIcon style={{fontSize:"20px"}} /></div>)}
                                {modsOpen?(<div className='shift-modification-detail-text'>Afternoon</div>):(null)}
                                {modsOpen?(<div className='shift-modification-detail-value-active'>{afternoonShifts}</div>):(<div className='shift-modification-detail-value-active-minimized'>{afternoonShifts}</div>)}
                            </button>
                        </Tooltip>
                        <Tooltip placement='right' title='Number of Night Shifts'>
                            <button className={modsOpen?'shift-modification-detail-edit':'shift-modification-detail-edit-minimized'}>
                                {modsOpen?(<div className='shift-modification-detail-icon'><NightsStayRoundedIcon style={{fontSize:"22px"}} /></div>):(<div className='shift-modification-detail-icon-minimized'><NightsStayRoundedIcon style={{fontSize:"20px"}} /></div>)}
                                {modsOpen?(<div className='shift-modification-detail-text'>Night</div>):(null)}
                                {modsOpen?(<div className='shift-modification-detail-value-active'>{nightShifts}</div>):(<div className='shift-modification-detail-value-active-minimized'>{nightShifts}</div>)}
                            </button>
                        </Tooltip>
                    </div>



                    <div className={modsOpen?'shift-modification-btn-holder':'shift-modification-btn-holder-minimized'}>
                        <Tooltip placement='right' title={modsOpen?'Minimize':'Expand'}>
                            <button onClick={toggleMods} className='shift-modification-btn'><KeyboardArrowLeftRoundedIcon style={{color:"7d7d7d",transition: 'transform 0.2s ease-in-out',transform: modsOpen ? 'rotate(0deg)' : 'rotate(180deg)',}}/></button>
                        </Tooltip>
                    </div>

                </div>

                )}



                    <div className={isMobile? 'my-shifts-calendar-inner-mobile': modsOpen? 'my-shifts-calendar-inner': 'my-shifts-calendar-inner-minimized'}>
                    <Calendar
                        showMultiDayTimes
                        events={shiftEvents2}
                        localizer={localizer}
                        onSelectEvent={onSelectEvent}
                    />
                    {modalOpenShiftCluster && SRIP != null?

                        <AgencyShiftClusterModal 
                            SRIP={shiftRequestAggregator(SRIP)}
                            selectedDate={firstDate}
                            modalOpen={modalOpenShiftCluster} 
                            handleClose={handleCloseShiftCluster} 
                            isMobile={isMobile}
                        />
                        :null
                    }
                </div>
            </div>
        </div>
    )
}




const MyShifts = () => {
    const isMobile = useIsMobile();
    const localizer = momentLocalizer(moment);
    const [orgSRIPResponse, setOrgSRIPResponse] = useState<SRIPResponseData[] | null>(null);
    const [shiftEvents, setShiftEvents] = useState([]);
    const [openNewShiftModal, setOpenNewShiftModal] = useState(false);
    
    const [modalOpenShiftCluster, setModalOpenShiftCluster] = useState(false);
    const handleOpenShiftCluster = () => {console.log("clocked");setModalOpenShiftCluster(true);}
    const handleCloseShiftCluster = () => setModalOpenShiftCluster(false);


    const [calendarViewActive, setCalendarViewActive] = useState(true);

    useEffect(() => {
        const fetchSRIPData = async () => {
          const SRIPData = await GetCorpSRIP({ setOrgSRIPResponse, setShiftEvents });
          console.log(SRIPData);
        };
        fetchSRIPData();
      }, []);  








    return (
        <>
          <StyleLinks />
          <WorkyAppHeader />
          <PageHeader
            isMobile={isMobile}
            calendarViewActive={calendarViewActive}
            setCalendarViewActive={setCalendarViewActive}
          />
          {calendarViewActive ? (
            <MyShiftsCalander
              modalOpenShiftCluster={modalOpenShiftCluster}
              handleCloseShiftCluster={handleCloseShiftCluster}
              handleOpenShiftCluster={handleOpenShiftCluster}
              isMobile={isMobile}
              openNewShiftModal={openNewShiftModal}
              setOpenNewShiftModal={setOpenNewShiftModal}
              localizer={localizer}
              SRIP={orgSRIPResponse}
            />
          ) : orgSRIPResponse?.length === 0 ? 
          
          (<EmptyInfoState pageName={"Shift"}/>)
          :
          (
            <div style={{background:"#f5f5f7"}}>
                <WorkyAgencyCarouselV2
                    SRIP={orgSRIPResponse}
                    isMobile={isMobile}
                />
            </div>
          )}

          <WorkyContactButton />
        
        </>
      );
}

export {MyShifts}