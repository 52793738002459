import React, { ReactNode, useCallback, useState, useEffect, useRef } from 'react';
import { BillingData } from './OrgUtils';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import AssuredWorkloadRoundedIcon from '@mui/icons-material/AssuredWorkloadRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import CrisisAlertRoundedIcon from '@mui/icons-material/CrisisAlertRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Holiday } from '../common/CommonInterfaces';
import { useIsMobile } from '../custom-hooks/useIsMobile';

const PaymentsCards = ({ paymentsData, isMobile }: { paymentsData: BillingData, isMobile:boolean }) => {

    return(
        <div className={isMobile? 'payments-cards-inner-mobile':'payments-cards-inner'}>
            
            <PaymentsCardBuilderValue 
                icon={<AssuredWorkloadRoundedIcon/>} 
                title={'Total Recieved'} 
                value={calculateTotalRecieved()}
            />

            <PaymentsCardBuilderValue 
                icon={<AccountBalanceRoundedIcon/>} 
                title={'Total Outstanding'} 
                value={calculateTotalOutstanding()}
            />

            <PaymentsCardBuilderBar
                icon={<CrisisAlertRoundedIcon />} 
                title={'Unpaid Invoices'} 
                countVal={calculateNumberOfUnpaidInvoices()} 
                totalVal={calculateTotalNumberOfInvoices()}
            />

            <PaymentsCardBuilderBar 
                icon={<CheckRoundedIcon/>}
                title={'Paid Invoices'} 
                countVal={calculateNumberOfPaidInvoices()} 
                totalVal={calculateTotalNumberOfInvoices()}
            />

            <PaymentsCardBuilderValue 
                icon={<EventRoundedIcon/>} 
                title={'Last Payment'} 
                value={"August 30"}
            />

            <PaymentsCardBuilderValue 
                icon={<EventRoundedIcon/>} 
                title={'Last Payment'} 
                value={"12/12/2024"}
            />
            
        </div>
    )
}

function calculateTotalRecieved(){
    return "$345.00"
}
function calculateTotalOutstanding(){
    return "$12,345.00"
}
function calculateTotalNumberOfInvoices(){
    return 2
}
function calculateNumberOfPaidInvoices(){
    return 1
}
function calculateNumberOfUnpaidInvoices(){
    return 1
}


const PaymentsCardBuilder = ({guageColor, title, countVal, totalVal}:{guageColor:string, title:string, countVal:number, totalVal:number}) => {
    return(
        <div className='payments-card-summary'>
            <div className='payments-card-summary-info'>
                <Gauge
                    value={countVal}
                    valueMax={totalVal}
                    startAngle={-110}
                    endAngle={110}
                    cornerRadius="50%"
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                          fontSize: 16,
                          fontFamily:'DM Sans',
                          fontWeight:"600",
                          transform: 'translate(0px, -7.5px)',
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: guageColor,
                          },
                      }}
                      text={
                         ({ value, valueMax }) => `${countVal} / ${totalVal}`
                      }
                />
            </div>
            <div className='payments-card-summary-title'>{title}</div>
        </div>
    )
}


const PaymentsCardBuilderBar = ({ icon, title, countVal, totalVal }: { icon:ReactNode, title: string, countVal: number, totalVal:number }) => {
    const valueWidth = 100 - ((countVal / totalVal) * 100);
    console.log(valueWidth);

    return (
        <div className='payments-card-summary'>

            <div className='payments-card-summary-title-holder'>
                <div className='payments-card-summary-icon-v2'>{icon}</div>
                <div className='payments-card-summary-title-v2'>{title}</div>
            </div>

            <div className='payments-card-summary-value-holder'>
                {valueWidth === 0 ?
                (<div style={{ width: `100%`, background:"#ebebeb", color:"#333333" }} className='payments-card-summary-value-inner'>
                <div style={{color:"#333333" }}  className='payments-card-summary-value-text'>{countVal}/{totalVal}</div>
                </div>)
                :    
                (<div style={{ width: `${valueWidth}%` }} className='payments-card-summary-value-inner'>
                    <div className='payments-card-summary-value-text'>{countVal}/{totalVal}</div>
                </div>)
                }
            </div>
            <div className='payments-card-summary-title-v2'></div>

        </div>
    );
}


const PaymentsCardBuilderValue = ({icon, title, value}:{icon:ReactNode, title:string, value:string}) => {


    return(
        <div className='payments-card-summary'>

            <div className='payments-card-summary-title-holder'>
                <div className='payments-card-summary-icon-v2'>{icon}</div>
                <div className='payments-card-summary-title-v2'>{title}</div>
            </div>    

            <div className='payments-card-summary-value-holder-value'>{value}</div>

        </div>
    )
}


const WorkyPayVacationCard = ({icon, title, holiday}:{icon:ReactNode, title:string, holiday:Holiday[]}) => {

    return(
        <div className='payments-card-summary'>

        <div className='payments-card-summary-title-holder'>
            <div className='payments-card-summary-icon-v2'>{icon}</div>
            <div className='payments-card-summary-title-v2'>{title}</div>
        </div>    

        <div className='holiday-holder'>
            <div className='holiday-row'>
                <div className='holiday-title'>{holiday[0].holidayTitle}:</div>    
                <div className='holiday-value'>{holiday[0].holidayDate}</div>    
            </div>
            <div className='holiday-row'>
                <div className='holiday-title'>{holiday[1].holidayTitle}:</div>    
                <div className='holiday-value'>{holiday[1].holidayDate}</div>    
            </div>
            <div className='holiday-row'>
                <div className='holiday-title'>{holiday[2].holidayTitle}:</div>    
                <div className='holiday-value'>{holiday[2].holidayDate}</div>    
            </div>
        </div>

    </div>
    )
}



export {PaymentsCards, PaymentsCardBuilder, PaymentsCardBuilderBar, PaymentsCardBuilderValue, WorkyPayVacationCard}