import React, { useState, useEffect, FormEvent } from 'react';
import './jobberhomepage2.css';
import { MobileNav,MobileNavV2 } from '../jobberViews/JobberCommonComponents';
import { Modal, Slide } from '@mui/material';
import emailjs from 'emailjs-com';
import { AxiosResponse } from 'axios';
import {
	ServerResponse,
	RemoteExecutionServiceImpl,
	RemoteGETCallInputObject,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import { DirectDepositRequest, JobberDTO } from './objects/JobberRegistrationFormConstants';
import { CancelShiftRequestAPICall} from '../shared-engineering-services/SharedAPICalls'
import {LocationComponent} from '../geo-location/JobberGeoLocationService'
import { StyleLinks } from '../orgViews/OrgCommonComponents';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { GetJobberPendingSRs, PostJobberRespToShiftRequest } from '../jobberViews/jobber-api-calls/JobberAPI';
import { convertEpochToHumanFriendlyDate, formatEpochToShortDate } from '../common/CommonUtilsMethods';
import { JobberResponseDTOV2, JobberResponseStatusV2, JobberSRPendingDataAPIReq, JobberSRPendingDataAPIResp, JobberSRPendingObj } from './jobber-data/JobberInterfaces';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { epochToDateString, epochToTimeString } from './pages/WorkyPayJobberPage';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// epoch to date string




const firstName = localStorage.getItem('firstName');
const lastName = localStorage.getItem('lastName');
const fullName = firstName + " " + lastName;
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;
const remoteCallObject = RemoteExecutionServiceImpl();

interface AssignedShift {
	shiftId: string;
	shiftStatus: string;
	orgName: string;
	jobberEmail: { value: string };
	jobberHourlyRate: number;
	requestingUser: { value: string };
	requesterFirstName: string;
	requesterLastName: string;
	orgPhoneNum : { value: string };
	orgAddress: {
		street: string;
		unitNumber: string;
		city: string;
		province: string;
		postalCode: string;
		country: string;
		entranceInstructions: string;
		majorIntersection: string;
	};
	shiftDetails: {
		workerType: string;
		simpleLocation: string;
		shiftRequestStartDayEpoch: number;
		startDateTimeEpochSeconds: number;
		endDateTimeEpochSeconds: number;
		startDateTime: string;
		endDateTime: string;
		startDay: null; // Consider modifying this based on actual data type
		workyWorkdayShift: string;
	};
}

interface DisplayAddress {
	street: string;
	unitNumber: string;
	city: string;
	province: string;
	postalCode: string;
	country: string;
	majorIntersection: string;
	entranceInstructions: string;
}

interface DisplayShift {
	shiftStartDate: string;
	shiftStartTime: number;
	shiftEndTime: number;
	shiftID: string;
	shiftHourlyPay: number;
	shiftPayTotal: number;
	shiftAddress: string;
	companyName: string;
	requesterFirstName: string;
	requesterLastName: string;
	orgPhoneNumber : { value: string };
}


const JobberGreeting = () => {
    const currentHour = new Date().getHours();
    let greeting;

    if (currentHour < 12) {greeting = "Good morning,";} 
	else if (currentHour < 18) {greeting = "Good afternoon,";} 
	else {greeting = "Good evening,";}

    return(
		<div className='jobber-greeting'>
			<div className='jobber-greeting-icon'><AccountCircleRoundedIcon sx={{fontSize:"55px"}}/></div>
			<div className='jobber-greeting-text-holder'>
				<div className='jobber-greeting-text-title'>{greeting}</div>
				<div className='jobber-greeting-text-name'>{fullName}</div>
			</div>

		</div>
	)
};

const JobberGeoLocationComponent = () => {
	return (
	<div className='jobber-geo-holder'>
		<LocationComponent/>		
	</div>
	)
}

const JobberHomePage = () => {
	const handleOpen = (
		shiftDate,
		shiftTime,
		shiftId,
		shiftHourlyPay,
		shiftPayTotal,
		shiftAddress,
		companyName, 
		requesterFirstName,
		requesterLastName,
		 requestingUserPhoneNum
	) => {
		let displayaddress: DisplayAddress = JSON.parse(shiftAddress);
		let addressString =
			displayaddress.street +
			', ' +
			(displayaddress.city.charAt(0).toUpperCase() +
				displayaddress.city.slice(1).toLowerCase()) +
			', ON ' +
			displayaddress.postalCode;


			console.log("sasa       " + requestingUserPhoneNum)
		setModalOpen(true);
		setModalShiftDate(shiftDate);
		setModalShiftTime(shiftTime);
		setModalShiftId(shiftId);
		setModalShiftHourlyPay(shiftHourlyPay);
		setModalShiftTotalPay(shiftPayTotal);
		setModalShiftAddress(addressString);
		setModalCompanyName(companyName);
		setShiftRequestingUserFirstName(requesterFirstName)
		setShiftRequestingUserLastName(requesterLastName)
		setShiftRequestingUserPhoneNum(requestingUserPhoneNum?.value)
		setModalEntranceInstructions(displayaddress.entranceInstructions);
	};
	const handleClose = () => {
		setModalOpen(false);
	};
	const handleCancelClose = () => {
		setCancelModalOpen(false);
	};
	const handleCancelOpen = () => {
		setCancelModalOpen(true);
	};

	const [jobberDTO, setJobberDTO] = useState<JobberDTO | null>(null);
	const [jobberStatus, setJobberStatus] = useState('');
	const [assignedShifts, setAssignedShifts] = useState<AssignedShift[] | null>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalShiftDate, setModalShiftDate] = useState('');
	const [modalShiftTime, setModalShiftTime] = useState('');
	const [modalCancelOpen, setCancelModalOpen] = useState(false);
	const [isReasonProvided, setIsReasonProvided] = useState<boolean>(false);
	const [modalCancelReason, setModalCancelReason] = useState<string>('');

	const [modalShiftId, setModalShiftId] = useState('');
	const [modalShiftHourlyPay, setModalShiftHourlyPay] = useState('');
	const [modalShiftTotalPay, setModalShiftTotalPay] = useState('');
	const [modalShiftRequestingUserFirstName, setShiftRequestingUserFirstName] = useState('');
	const [modalShiftRequestingUserLastName, setShiftRequestingUserLastName] = useState('');
	const [modalShiftRequestingUserPhoneNum, setShiftRequestingUserPhoneNum] = useState('');
	const [modalShiftAddress, setModalShiftAddress] = useState('');
	const [modalCompanyName, setModalCompanyName] = useState('');
	const [modalEntranceInstructions, setModalEntranceInstructions] = useState('');
	const [hasShiftToday, setHasShiftToday] = useState(false);
	const jobberEmail = localStorage.getItem('userEmail');
	const todayShiftBlocks: DisplayShift[] = [];
	const upcomingShiftBlocks: DisplayShift[] = [];
	const [pendingSRs, setPendingSRs] = useState<JobberSRPendingObj[] | null>([]);


	const getAssignedShifts = async () => {
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'v1/jobber/assignedshifts',
			headers: {},
			queryParams: null,
		};

		const assignedShiftsResp: AxiosResponse<ServerResponse<AssignedShift[], Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<AssignedShift[], Error>>(
				getCallInput
			);

		if (
			assignedShiftsResp &&
			assignedShiftsResp.data &&
			assignedShiftsResp.data.data &&
			assignedShiftsResp.data.data.length > 0
		) {
			//  TODO : GET THIS DATA WORKING
			console.log('assigned Shifts Resp : ' + JSON.stringify(assignedShiftsResp.data.data));
			setAssignedShifts(assignedShiftsResp.data.data);
		} else {
			console.log('No Shifts Assigned');
		}
	};

	const ShiftBlock = (displayShiftArr: DisplayShift[]) => {

		console.log("sasa  asassa displayShiftArr "  + JSON.stringify(displayShiftArr))
		function correctDate(inputDate: string): string {
			const adjustedDate = new Date(
				new Date(inputDate).getTime() + new Date(inputDate).getTimezoneOffset() * 60 * 1000
			);
			return adjustedDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
		}

		function correctEpoch(epoch: number): string {
			const date = new Date(epoch * 1000);
			let hours = date.getHours();
			const minutes = date.getMinutes().toString().padStart(2, '0');
			const amPM = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12 || 12;
			return `${hours}:${minutes} ${amPM}`;
		}

		const shiftBlockArr: JSX.Element[] = [];

		for (let i = 0; i < displayShiftArr.length; i++) {
			let shiftDate = formatEpochToShortDate(displayShiftArr[i].shiftStartTime);
			let shiftTime =
				correctEpoch(displayShiftArr[i].shiftStartTime) +
				' - ' +
				correctEpoch(displayShiftArr[i].shiftEndTime);
			let shiftId = displayShiftArr[i].shiftID;
			let shiftHourlyPay = displayShiftArr[i].shiftHourlyPay;
			let shiftPayTotal = displayShiftArr[i].shiftPayTotal;
			let shiftAddress = displayShiftArr[i].shiftAddress;
			let companyName = displayShiftArr[i].companyName;
			let requestingUserFirstName =displayShiftArr[i].requesterFirstName;
			let requestingUserlastName =displayShiftArr[i].requesterLastName;
			let requestingUserPhoneNum =displayShiftArr[i].orgPhoneNumber;
			console.log("requestingUserPhoneNum   " + requestingUserPhoneNum)

			shiftBlockArr.push(
				<div
					className='jobber-shift-block'
					onClick={() =>
						handleOpen(
							shiftDate,
							shiftTime,
							shiftId,
							shiftHourlyPay,
							shiftPayTotal,
							shiftAddress,
							companyName,
							requestingUserFirstName,
							requestingUserlastName,
							requestingUserPhoneNum
						)
					}>
					<div className='jobber-shift-block-date'>{shiftDate}</div>
					<div className='jobber-shift-block-time'>{shiftTime}</div>
					{/* <div className='jobber-shift-block-total-holder'>
						<div className='jobber-shift-block-total'>${shiftPayTotal.toFixed(2)}</div>
					</div> */}
				</div>
			);
		}
		return shiftBlockArr;
	};

	const ShiftsToday = (sampleShifts: AssignedShift[]) => {
		let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
			.toISOString()
			.substring(0, 10);
		console.log('inside shifts today');

		for (let i = 0; i < sampleShifts.length; i++) {
			if (sampleShifts[i].shiftDetails.startDateTime.substring(0, 10) === today) {
				// build the array full of DisplayShift elements
				let todayShift: DisplayShift = {
					shiftStartDate: sampleShifts[i].shiftDetails.startDateTime,
					shiftStartTime: sampleShifts[i].shiftDetails.startDateTimeEpochSeconds,
					shiftEndTime: sampleShifts[i].shiftDetails.endDateTimeEpochSeconds,
					shiftID: sampleShifts[i].shiftId,
					shiftHourlyPay: sampleShifts[i].jobberHourlyRate,
					shiftPayTotal: 0,
					shiftAddress: JSON.stringify(sampleShifts[i].orgAddress),
					companyName: sampleShifts[i].orgName,
					requesterFirstName: sampleShifts[i].requesterFirstName,
					requesterLastName: sampleShifts[i].requesterLastName,
					orgPhoneNumber: sampleShifts[i].orgPhoneNum
				};
				todayShiftBlocks.push(todayShift);
			}
		}
		return todayShiftBlocks && todayShiftBlocks.length > 0 ? (
			<>
				<div
					style={{ marginTop: '0px', paddingTop: '100px' }}
					className='mobile-section-title'>
					Today's Shift (s)
				</div>
				<div className='shifts-container'>{ShiftBlock(todayShiftBlocks)}</div>
			</>
		) : null;
	};

	const ShiftsUpcoming = (sampleShifts: AssignedShift[]) => {
		let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
			.toISOString()
			.substring(0, 10);
		console.log('inside shifts upcoming  sampleShifts ' + JSON.stringify(sampleShifts));

		for (let i = 0; i < sampleShifts.length; i++) {
			if (sampleShifts[i].shiftDetails.startDateTime.substring(0, 10) !== today) {
				// build the array full of DisplayShift elements

				console.log("sampleShifts[i].orgPhoneNumber " + sampleShifts[i].orgPhoneNum)

				let upcomingShift: DisplayShift = {
					shiftStartDate: sampleShifts[i].shiftDetails.startDateTime,
					shiftStartTime: sampleShifts[i].shiftDetails.startDateTimeEpochSeconds,
					shiftEndTime: sampleShifts[i].shiftDetails.endDateTimeEpochSeconds,
					shiftID: sampleShifts[i].shiftId,
					shiftHourlyPay: sampleShifts[i].jobberHourlyRate,
					shiftPayTotal: 0,
					shiftAddress: JSON.stringify(sampleShifts[i].orgAddress),
					companyName: sampleShifts[i].orgName,
					requesterFirstName: sampleShifts[i].requesterFirstName,
					requesterLastName: sampleShifts[i].requesterLastName,
					orgPhoneNumber: sampleShifts[i].orgPhoneNum
				};
				upcomingShiftBlocks.push(upcomingShift);
			}
		}
		

		return upcomingShiftBlocks && upcomingShiftBlocks.length > 0 ? (
			<>
				{hasShiftToday ? (
					<div style={{ paddingTop: '50px' }} className='mobile-section-title'>
						Upcoming Shifts
					</div>
				) : (
					<div className='mobile-section-title'>Upcoming Shifts</div>
				)}
				<div className='shifts-container'>{ShiftBlock(upcomingShiftBlocks)}</div>
			</>
		) : null;
	};

	const getJobberDTO = async (): Promise<AxiosResponse<ServerResponse<JobberDTO, Error>>> => {
		console.log('clicked get Jobber button');
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'v1/jobber/get',
			headers: {},
			queryParams: {},
		};

		const jobberDTOResp: AxiosResponse<ServerResponse<JobberDTO, Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<JobberDTO, Error>>(getCallInput);

		if (
			jobberDTOResp &&
			jobberDTOResp.data &&
			jobberDTOResp.data.data &&
			jobberDTOResp.data.data.jobberStatus
		) {
			// console.log("jobberDTOResp: " + JSON.stringify(jobberDTOResp.data.data))
			console.log('JOBBER STATUS: ' + jobberDTOResp.data.data.jobberStatus);
			setJobberDTO(jobberDTOResp.data.data);
			setJobberStatus(jobberDTOResp.data.data.jobberStatus);
		} else {
			console.log('there was an error');
		}
		return jobberDTOResp;
	};

	const openLinkInNewTab = (link) => {
		window.open(link);
	};

	const getWorkyWorksDoc = async () => {
		console.log('clicked JobberContract button');
		const getCallInput: RemoteGETCallInputObject = {
			apiUrl: backendApiBasePathLocal + 'v1/jobber/document/jobberonboardingdoc',
			headers: {},
			queryParams: null,
		};

		const WorkyWorks: AxiosResponse<ServerResponse<string, Error>> =
			await remoteCallObject.remoteGETCallV2<ServerResponse<string, Error>>(getCallInput);

		if (WorkyWorks && WorkyWorks.data && WorkyWorks.data.data) {
			console.log('WorkyWorks : ' + JSON.stringify(WorkyWorks.data.data));
			openLinkInNewTab(WorkyWorks.data.data);
		} else {
			console.log('there was an error');
		}
	};

	const getPendingSRs = async () =>{
		const pendingSRz = await GetJobberPendingSRs();
		console.log("pendingSRz ", pendingSRz)
		setPendingSRs(pendingSRz);
		// setPendingSRs(sampleJobberSRPendingObjs);
	}

	useEffect(() => {
		// GetJobberPendingSRs();
		getPendingSRs();
		getAssignedShifts();
		getJobberDTO();
		if (todayShiftBlocks.length > 0) {
			setHasShiftToday(true);
		}
	}, []);

	const HowWorkyWorks = () => {
		return (
			<div className='howworkyworks'>
				<button onClick={getWorkyWorksDoc} className='howworkyworks-button'>
					How Worky Works
				</button>
			</div>
		);
	};

	const [modalOpen2, setModalOpen2] = React.useState(false);
	const handleOpen2 = () => setModalOpen2(true);
	const handleClose2 = () => setModalOpen2(false);

	const JobberScreen = () => {
		const [modalOpen2, setModalOpen2] = React.useState(false);
		const handleOpen2 = () => setModalOpen2(true);
		const handleClose2 = () => setModalOpen2(false);
		
		const handleGeneralEmailSubmit = async (e) => {
			e.preventDefault();

			const serviceID = 'service_3mlm2ls';
			const templateID = 'template_xl6xpjw';
			const userID = 'zKdgUxA7H3dMaCf-m';

			try {
				await emailjs.sendForm(serviceID, templateID, e.target, userID);
				alert('Email sent successfully!');
			} catch (error) {
				alert('An error occurred while sending the email.');
				console.error(error);
			}
		};


		
		
		return (
			<>
				<StyleLinks/>
				<MobileNavV2 />
				<JobberGreeting/>
				{/* <HowWorkyWorks /> */}
				<JobberGeoLocationComponent />
				<JobberContactModal modalOpen2={modalOpen2} handleClose2={handleClose2}/>
				

				{!assignedShifts || pendingSRs.length === 0 && (
					<>
						

						<div className='empty-shifts-container'>
							<div className='empty-shifts'>
								<div className='empty-shifts-logo'>worky</div>
								<div className='empty-shifts-holder'>
									<div className='empty-shifts-title'>Welcome to Worky!</div>
									<div className='empty-shifts-text'>
										Looks like you don't have any shifts coming up. Contact us. Let's get you a
										shift today!
									</div>
									<button onClick={handleOpen2} className='empty-shifts-button'>
										Contact Us
									</button>
								</div>
							</div>
						</div>
					</>
				)}
				{assignedShifts && ShiftsToday(assignedShifts)}
				{assignedShifts && ShiftsUpcoming(assignedShifts)}
				{pendingSRs.length!=0 ? <PendingSR pendingSRdata={pendingSRs}/>:null}
				{jobberRespModalOpen.open ? <JobberShiftRespModal pendingSRdata={pendingSRs} jobberRespModalOpen={jobberRespModalOpen} setJobberRespModalOpen={setJobberRespModalOpen} />:null}

			</>
		);
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		const isValidInput = inputValue.length <= 200;
		setModalCancelReason(isValidInput ? inputValue : modalCancelReason);
		setIsReasonProvided(isValidInput && inputValue.trim() !== '');
	  };

	const handleCancel = async (e) => {
		e.preventDefault();

		console.log("HIT CANCEL BUTTON MODAL SHIFT ID :  ---- "+ modalShiftId)
		await CancelShiftRequestAPICall({shiftId: modalShiftId})
	
	}

	const sampleJobberSRPendingObjs: JobberSRPendingObj[] = [
		{
		  parentShiftId: "SHIFT12345",
		  orgName: "Elite Staffing Solutions",
		  simpleLocation: "Downtown Office",
		  locationName: "Elite Staffing HQ",
		  locationAddress: {
			street: "123 Main St",
			unitNumber: "Suite 400",
			city: "Toronto",
			province: "ON",
			postalCode: "M1A 2B3",
			country: "Canada",
			majorIntersection: "Main St & 2nd Ave",
			entranceInstructions: "Enter through the main lobby and check in at reception",
		  },
		  jobberWorkerTypeDTO: {
			workerTypeName: "General Labour",
			workerTypeUniqueIdentifier: "GLB-001",
		  },
		  jobberSRPendingShiftUnitDtoList: [
			{
			  shiftStartDateTimeEpochSec: 1706745600, // Example epoch timestamp
			  shiftEndDateTimeEpochSec: 1706767200,
			},
			{
			  shiftStartDateTimeEpochSec: 1706832000,
			  shiftEndDateTimeEpochSec: 1706853600,
			},
		  ],
		},
		{
		  parentShiftId: "SHIFT67890",
		  orgName: "ProTech Staffing",
		  simpleLocation: "Warehouse A",
		  locationName: "ProTech Logistics Center",
		  locationAddress: {
			street: "456 Industrial Rd",
			unitNumber: "Bay 12",
			city: "Mississauga",
			province: "ON",
			postalCode: "L5T 1A1",
			country: "Canada",
			majorIntersection: "Industrial Rd & 5th Line",
			entranceInstructions: "Use loading dock entrance on the north side",
		  },
		  jobberWorkerTypeDTO: {
			workerTypeName: "Forklift Operator",
			workerTypeUniqueIdentifier: "FORK-002",
		  },
		  jobberSRPendingShiftUnitDtoList: [
			{
			  shiftStartDateTimeEpochSec: 1706928000,
			  shiftEndDateTimeEpochSec: 1706953200,
			},
		  ],
		},
	];

	const [jobberRespModalOpen, setJobberRespModalOpen] = useState<{ open: boolean; data: JobberSRPendingDataAPIResp }>({
		open: false,
		data: null,
	});


	const JobberShiftRespModal = ({pendingSRdata, jobberRespModalOpen, setJobberRespModalOpen,}: {pendingSRdata:JobberSRPendingObj[],jobberRespModalOpen: { open: boolean; data: any };setJobberRespModalOpen: React.Dispatch<React.SetStateAction<{ open: boolean; data: any }>>;}) => {
	
		const { open, data } = jobberRespModalOpen;
		const handleClose = () => setJobberRespModalOpen({ open: false, data: null });
	  
		// Ensure data exists before proceeding
		if (!data || !data.jobberAssignmentResponse) return null;
	  
		// Match jobberAssignmentResponse shifts with pendingSRdata
		const matchedShifts = data.jobberAssignmentResponse.map((respShift: any) => {
		  const matchedParentShift = pendingSRdata.find(
			(pendingShift) => pendingShift.parentShiftId === respShift.parentShiftId
		  );
	  
		  if (!matchedParentShift) return null;
	  
		  // Find the shiftEndEpochSec for this start time
		  const matchedShiftUnit = matchedParentShift.jobberSRPendingShiftUnitDtoList.find(
			(shiftUnit) => shiftUnit.shiftStartDateTimeEpochSec === respShift.shiftStartDateTimeEpochSecs
		  );
	  
		  return {
			parentShiftId: respShift.parentShiftId,
			shiftStartDateTimeEpochSec: respShift.shiftStartDateTimeEpochSecs,
			shiftEndDateTimeEpochSec: matchedShiftUnit ? matchedShiftUnit.shiftEndDateTimeEpochSec : "N/A",
			message: respShift.message,
		  };
		}).filter(Boolean); // Remove null values
	  
		return (
		  <Modal open={open} onClose={handleClose}>
			<Slide direction="up" in={open} mountOnEnter unmountOnExit>
			  <div className="jobber-shift-resp-modal">
				<button className="jobber-modal-close-mobile" onClick={handleClose} />
				<div className='jobber-shift-resp-modal-title'>Shift Info</div>
				{matchedShifts.length > 0 ? (
				  <div className='jobber-shift-resp-modal-shift-block-holder'>
					{matchedShifts.map((shift, index) => (
					  <div className='jobber-shift-resp-modal-shift-block' key={index}>
						{/* <p><strong>Shift ID:</strong> {shift.parentShiftId}</p> */}
						<div className='jobber-shift-resp-modal-shift-block-top'>
							<span className='pending-sr-unit-summary-date'>{epochToDateString(shift.shiftStartDateTimeEpochSec)}</span>
							<span className='pending-sr-unit-summary-time'>{epochToTimeString(shift.shiftStartDateTimeEpochSec)} - {epochToTimeString(shift.shiftEndDateTimeEpochSec)}</span>
						</div>
						<div className='jobber-shift-resp-modal-shift-block-bottom'>{shift.message}</div>
						
					  </div>
					))}
				  </div>
				) : (
				  <p>Error getting Shift Info. Please Try again shortly...</p>
				)}
			  </div>
			</Slide>
		  </Modal>
		);
	  };



	const handleDenyShift = async (shiftDetails:JobberSRPendingObj) => {
		console.log("CLICKED DENY : "+shiftDetails.parentShiftId)

		const jobberAPIBodyChild: JobberResponseDTOV2[] = shiftDetails.jobberSRPendingShiftUnitDtoList.map((shiftUnit) => {
			const childShiftIdsResponse: JobberResponseDTOV2= {
			  shiftStartDateTimeEpochSecs: shiftUnit.shiftStartDateTimeEpochSec,
			  status: JobberResponseStatusV2.DENY, // Default status, can be modified as needed
			};

			return childShiftIdsResponse
		})
		

		const apiRespOfJobberInput: JobberSRPendingDataAPIResp = await PostJobberRespToShiftRequest({
			parentShiftId: shiftDetails.parentShiftId,
			childShiftIdsResponse: jobberAPIBodyChild
		})		
		setJobberRespModalOpen({ open: true, data: apiRespOfJobberInput });

	}
	const handleAcceptShift = async (shiftDetails:JobberSRPendingObj) => {
		

		console.log("shiftDetails ", shiftDetails)
		
		const jobberAPIBodyChild: JobberResponseDTOV2[] = shiftDetails.jobberSRPendingShiftUnitDtoList.map((shiftUnit) => {
			const childShiftIdsResponse: JobberResponseDTOV2= {
			  shiftStartDateTimeEpochSecs: shiftUnit.shiftStartDateTimeEpochSec,
			  status: JobberResponseStatusV2.ACCEPT, // Default status, can be modified as needed
			};

			return childShiftIdsResponse
		})

		console.log("jobberAPIBodyChild ", jobberAPIBodyChild)

		const apiRespOfJobberInput: JobberSRPendingDataAPIResp = await PostJobberRespToShiftRequest({
			parentShiftId: shiftDetails.parentShiftId,
			childShiftIdsResponse: jobberAPIBodyChild
		})		


		// console.log("apiRespOfJobberInput ", apiRespOfJobberInput)
		setJobberRespModalOpen({ open: true, data: apiRespOfJobberInput });
	}
	// const handleAcceptAllShift = () => {
	// 	console.log("CLICKED ACCEPT ALL")
	// }




	const PendingSR = ({pendingSRdata}:{pendingSRdata:JobberSRPendingObj[]}) => {
		console.log(pendingSRdata)
		return (
			<div className='pending-sr-container'>
				
				
				<div className='pending-sr-title'>
					Pending Shifts 
					{/* <button onClick={handleAcceptAllShift} className='pending-shifts-accept-all'>Accept All</button> */}
				</div>
				 

				<div className='pending-sr-holder'>
					<div/>
					{pendingSRdata.map((shift) =>
						shift.jobberSRPendingShiftUnitDtoList.map((unit, index) => {
							

							return (
							<>
							<Accordion key={`${shift.parentShiftId}-${index}`} className="pending-sr-unit" disableGutters sx={{borderRadius: "17.5px",boxShadow: "none",marginBottom: "0px","&:before": { display: "none" },}}>
								<AccordionSummary className="pending-sr-accordion-summary" expandIcon={<ExpandMoreIcon />}>
									<div className='pending-sr-unit-summary'>
										<div className='pending-sr-unit-summary-date-time'>
											<span className='pending-sr-unit-summary-date'>{epochToDateString(unit.shiftStartDateTimeEpochSec)}</span>
											<span className='pending-sr-unit-summary-time'>{epochToTimeString(unit.shiftStartDateTimeEpochSec)} - {epochToTimeString(unit.shiftEndDateTimeEpochSec)}</span>
										</div>
										<div className='pending-sr-unit-summary-btn-holder'>
											<button onClick={()=>handleDenyShift(shift)} id="deny-shift" className='pending-sr-unit-summary-btn'><CloseRoundedIcon sx={{fontSize:"20px"}}/></button>
											<button onClick={()=>handleAcceptShift(shift)} id="accept-shift" className='pending-sr-unit-summary-btn'><CheckRoundedIcon sx={{fontSize:"20px"}}/></button>
										</div>
									</div>
								</AccordionSummary>

								<AccordionDetails sx={{padding: "10px 20px",margin: 0}}>
									<div className='pending-sr-unit-details'>
										<div className='pending-sr-unit-detail'>Org Name: <span className='simple-bold'>{shift.orgName}</span></div>
										<div className='pending-sr-unit-detail'>Location: <span className='simple-bold'>{shift.simpleLocation}</span></div>
										<div className='pending-sr-unit-detail'>Address: <span className='simple-bold'>{shift.locationAddress.street}, {shift.locationAddress.city}</span></div>
										<div className='pending-sr-unit-detail'>Work Type: <span className='simple-bold'>{shift.jobberWorkerTypeDTO.workerTypeName}</span></div>
										<div className='pending-sr-unit-detail'>Entrance: <span className='simple-bold'>{shift.locationAddress.entranceInstructions}</span></div>
									</div>
								</AccordionDetails>
							</Accordion>
							</>
							);
						})
					)}
					<div/>
				</div>
			</div>
		)
	}
	


	
	  
	
	return (
		<>
			<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />
			<Modal open={modalCancelOpen} onClose={handleCancelClose}>
			<div className='shiftblock-modal'>
				<div className='shiftblock-modal-title'>Are you sure you want to CANCEL this Shift?</div>
				<input type="text" className="cancelReason" value={modalCancelReason} onChange={handleInputChange}/>
				<div className='cancel-button-holder'>
					<button onClick={handleCancelClose} className='modal-action' id='modal-action-cancel'>No</button>
					<button onClick={handleCancel} className='modal-action' id='modal-action-cancel' disabled={!isReasonProvided}>Yes</button>
				</div>
			</div>
			</Modal>
			<Modal open={modalOpen} onClose={handleClose}>
				<div className='shiftblock-modal'>
					<div className='shiftblock-date'>{modalShiftDate}</div>
					<div className='shiftblock-time'>{modalShiftTime}</div>
					<div className='shiftblock-modal-title'>Shift Details</div>
					<div className='shiftblock-modal-details'>
						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Company Name:</span>
							{modalCompanyName}
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Hourly Pay:</span>${modalShiftHourlyPay}/hr
						</div>

						{/* <div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Total Pay:</span>${modalShiftTotalPay}
						</div> */}

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Address:</span>
							{modalShiftAddress}
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Job Type:</span>Warehouse Associate
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Entrance Instructions:</span>
							{modalEntranceInstructions}
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Shift Manager Name:</span>
							{modalShiftRequestingUserFirstName + " " + modalShiftRequestingUserLastName}
						</div>

						<div className='shiftblock-modal-detail'>
							<span id='modal-detail-heading'>Shift Manager Phone Number:</span>
							{modalShiftRequestingUserPhoneNum}
						</div>
					</div>

					<div className='shiftblock-modal-title'>Modify</div>
					<div className='shiftblock-modal-actions'>
						<button className='modal-action' id='modal-action-report' onClick={handleOpen2}> 
							Report Problem
						</button>
						<button onClick={handleCancelOpen} className='modal-action' id='modal-action-cancel'>
							Cancel Shift
						</button>
						</div>

				</div>
			</Modal>


			<div className='mobile-app'>
				<JobberScreen />
			</div>
		</>
	);
};

const JobberContactModal = ({modalOpen2,handleClose2,}: {modalOpen2: boolean;handleClose2: () => void;}) => {
	const handleGeneralEmailSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
	  e.preventDefault();
  
	  const serviceID = 'service_3mlm2ls';
	  const templateID = 'template_xl6xpjw';
	  const userID = 'zKdgUxA7H3dMaCf-m';
  
	  try {
		await emailjs.sendForm(serviceID, templateID, e.target as HTMLFormElement, userID);
		alert('Email sent successfully!');
	  } catch (error) {
		alert('An error occurred while sending the email.');
		console.error(error);
	  }
	};
  
	const jobberEmail = localStorage.getItem('userEmail');
  
	return (
	  <Modal open={modalOpen2} onClose={handleClose2}>
		<Slide direction="up" in={modalOpen2} mountOnEnter unmountOnExit>
		  <form className="modal-contactv2" onSubmit={handleGeneralEmailSubmit}>
			<button type="button" onClick={handleClose2} className="jobber-modal-close-mobile" />
			<div className="jobber-modal-title">Having an Issue?</div>
			<div className="jobber-modal-subtitle">
			  Send a message or call us, and we'll get back to you as soon as we can.
			</div>
			<div style={{ marginTop: '10px' }} className="jobber-modal-subtitle">
			  E: info@worky.pro
			</div>
			<div style={{ marginBottom: '10px' }} className="jobber-modal-subtitle">
			  P: (647) 494-9090
			</div>
			<div className="jobber-input-modal-holder">
			  <div className="jobber-modal-input-title">Email:</div>
			  <input
				className="jobber-modal-email"
				name="user_email"
				defaultValue={jobberEmail || 'info@worky.pro'}
			  />
			  <div style={{ marginTop: '10px' }} className="jobber-modal-input-title">
				Message:
			  </div>
			  <textarea name="user_message" className="jobber-modal-message" />
			  <button type="submit" className="jobber-modal-submit">
				Send
			  </button>
			</div>
		  </form>
		</Slide>
	  </Modal>
	);
  };

export { JobberHomePage, JobberContactModal };
