import React, { useState, useEffect, useCallback, useRef } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import { Fade, Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './desktopsplashv2.css';
import { useIsMobile } from '../custom-hooks/useIsMobile';
import Modal from '@mui/material/Modal';
import RadarIcon from '@mui/icons-material/Radar';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import Popper from '@mui/material/Popper';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import DevicesIcon from '@mui/icons-material/Devices';
import BoltIcon from '@mui/icons-material/Bolt';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import VerifiedIcon from '@mui/icons-material/Verified';
import emailjs from 'emailjs-com';
import CheckIcon from '@mui/icons-material/Check';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BrushIcon from '@mui/icons-material/Brush';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BusinessIcon from '@mui/icons-material/Business';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import TextField from '@mui/material/TextField';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import AdsClickRoundedIcon from '@mui/icons-material/AdsClickRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import {CalendlyComponent, persistSalesMetaDataCalendlyRedirect} from '../calendly-integration/CalendlyIntegrationMasterClass'
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';

const workyServerIDPSIGNUPURL = process.env.REACT_APP_IDP_SIGN_UP_URL;
const workyServerIDPSIGNINURL = process.env.REACT_APP_IDP_SIGN_IN_URL;
const bookDemoLink = "https://calendly.com/worky-pro/30min";

const SplashTitle = ({title}:{title:string}) => {return(<div className='splash-title-desktop'>{title.toUpperCase()}</div>)}


// new
const SplashSection1V2 = () => {
    const TEXTS = ['FASTER','EASIER','CHEAPER'];
    const [index, setIndex] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => setIndex((index) => index + 1),2000,);
        return () => clearTimeout(intervalId);
    }, []);
           
    return(
        <> 
            <div className='splash-section1-holder-v2'>
                <div className='splash-slider-container'>
                    
                    
                    <>
                    <div className='splash-overlay'>
                        <div className='splash-overlay-title'><span className='gradient-blue'>Accelerate</span> your Workforce!</div>
                        {/* <div className='splash-overlay-subtitle'>Worky, the world's first subscription service for warehouse solutions & temporary staff!</div> */}
                        {/* <div className='splash-overlay-subtitle'>Equip your agency with<span className='gradient-blue'> Big Tech Innovation!</span> </div> */}
                        <div className='splash-overlay-subtitle'>Save over<span className='gradient-blue'> 20% </span> and <span className='gradient-blue'>optimize</span> your Client Relations</div>
                        <div className='splash-overlay-buttons'>
                            <button onClick={()=>window.scrollTo({ top: 5975, behavior: "smooth" })} className='splash-overlay-button'>Get Started <div className='rocket-icon'><RocketLaunchIcon/></div></button>
                            <button onClick={()=>window.open(bookDemoLink)} className='splash-overlay-button'>Book a Demo <div className='devices-icon'><DevicesIcon/></div></button>
                        </div>
                    </div>
                    <div className='splash-behind'>
                        <div className='splash-behind-third-img'>
                            <div className='splash-behind-third-img-overlay'>
                                <div className='third-img-overlay-title'>SHIFT SUBMITTED <CheckCircleRounded style={{fontSize:"16px",color:"#29D637"}}/></div>
                                <div className='third-img-overlay-bottom'>
                                    <div className='third-img-overlay-bottom-date'>June 30th</div>
                                    <div className='third-img-overlay-bottom-time'>
                                        <div className='third-img-overlay-bottom-time-inner'>9AM </div>-
                                        <div className='third-img-overlay-bottom-time-inner'> 5PM</div>
                                    </div>
                                </div>
                            </div>
                            <img src='./booking-shift-splash.jpg'></img>
                        </div>

                        <div className='splash-behind-sec-img'>
                            <div className='splash-behind-sec-img-overlay'>
                                    <div className='third-img-overlay-title'>SHIFT ACCEPTED <CheckCircleRounded style={{fontSize:"16px",color:"#29D637"}}/></div>
                                    <div className='third-img-overlay-middle'>
                                        <div className='third-img-overlay-middle-tag'><AutoAwesomeIcon style={{fontSize:"14px"}}/>TOP ASSOCIATE</div>
                                    </div>
                                    <div className='third-img-overlay-bottom'>
                                        <div className='third-img-overlay-bottom-date'>June 30th</div>
                                        <div className='third-img-overlay-bottom-time'>
                                            <div className='third-img-overlay-bottom-time-inner'>9AM </div>-
                                            <div className='third-img-overlay-bottom-time-inner'> 5PM</div>
                                        </div>
                                    </div>
                                </div>
                            <div className='jobber-accept-img-holder'>
                                <img src='./jobber-accept.jpg'></img>
                            </div>
                        </div>

                        <div className='splash-behind-main-img'>
                            <img src='./worky-laptop-mockup.jpg'></img>
                        </div>
                    </div>
                    </>

                </div>

                <div className='splash-our-partners'>
                    {/* <div className='splash-our-partners-overlay'>Our<br/>Partners</div> */}
                    <div className='splash-our-partners-top'>Trusted by 100+ agencies in America & Canada</div>
                    <div className='splash-our-partners-carousel'>
                        <div className='splash-our-partners-carousel-partner'>
                            <img src='./company1.jpg'/>
                        </div>
                        <div className='splash-our-partners-carousel-partner'>
                            <img src='./company2.jpg'/>
                        </div>
                        <div className='splash-our-partners-carousel-partner'>
                            <img src='./company3.jpg'/>
                        </div>
                        <div className='splash-our-partners-carousel-partner'>
                            <img src='./company4.jpg'/>
                        </div>
                    </div>
                </div>

            </div>        
            <div className='worky-mission'>
                Worky empowers teams to <span className='gradient-blue'>do more with less.</span>
            </div>
            <div className='worky-mission-details'>
                <div className='worky-mission-block'>
                    <div className='worky-mission-block-icon'><BoltIcon/></div>
                    <div className='worky-mission-block-title'>Fast</div>
                    <div className='worky-mission-block-text'>We know the<span className='gradient-blue'> importance of speed!</span> That's why Worky insures all your shifts are filled within<span className='gradient-blue'> minutes!</span></div>
                </div>

                <div className='worky-mission-block'>
                    <div className='worky-mission-block-icon'><VerifiedIcon/></div>
                    <div className='worky-mission-block-title'>Reliable</div>
                    <div className='worky-mission-block-text'>When you succeed, we succeed! Worky's <span className='gradient-blue'>configurable algorithm </span> ensures <span className='gradient-blue'>optimal shift matching!</span></div>
                </div>

                <div className='worky-mission-block'>
                    <div className='worky-mission-block-icon'><HealthAndSafetyIcon/></div>
                    <div className='worky-mission-block-title'>Safe</div>
                    <div className='worky-mission-block-text'>Worky abides by all <span className='gradient-blue'>payroll and scheduling laws</span>, and our platform is <span className='gradient-blue'>encrypted end-to-end.</span></div>
                {/* Safety and security are our top priorities at Worky.  */}
                </div>
            </div>
        </>
    )
}

// new
const ProductOne = () => {
    const portaltext="Presenting our location-based clock-in and clock-out system for effortless time tracking. With real-time GPS verification and an intuitive interface, it enhances efficiency, ensuring precise time logs. Completely adaptable to your business requirements."
    return(
        <div className='worky-product-container'>
            <div className='worky-product-img-container'>
                <img src='./virtual-check-in.jpg'></img>
            </div>

            <div className='worky-product-content-container'>
                <div className='worky-product-title'><RadarIcon style={{fontSize:"45px", color:"#2c68bd"}}/>Virtual Clock</div>
                <div className='worky-product-subtitle'>{portaltext}</div>
                <button onClick={() => window.location.assign('/products?product=1')} className='worky-product-button-reverse'><WestIcon/>Learn More</button>
            </div>
        </div>
    )
}

// new
const ProductTwo = () => {
    const portaltext="Introducing our check-in and check-out tablet for seamless time tracking. With real-time updates, user-friendly design, and advanced security, it streamlines operations, ensuring accurate records. Fully customizable to fit your business needs."
    return(
        <div className='worky-product-container'>

            <div className='worky-product-content-container'>
                <div className='worky-product-title'><AdsClickIcon style={{fontSize:"45px", color:"#2c68bd"}}/>Worky Portal</div>
                <div className='worky-product-subtitle'>{portaltext}</div>
                <button onClick={() => window.location.assign('/products?product=2')} className='worky-product-button'>Learn More<EastIcon/></button>
            </div>
            <div className='worky-product-img-container'>
                <img src='./worky-ipad-v2.jpg'/>
            </div>

            
        </div>
    )
}

// new
const ProductThree = () => {
    const portaltext="Discover our AI-powered job matching system for optimal staffing. Utilizing cutting-edge algorithms, it swiftly pairs the most qualified worker to each shift based on numerous criteria. Efficient, intelligent, and tailored to your specific business needs."
    return(
        <div className='worky-product-container'>
            <div className='worky-product-img-container'>
                <img src='./worky-algo-mockup.jpg'/>
            </div>
            <div className='worky-product-content-container'>
                <div className='worky-product-title'><AutoAwesomeIcon style={{fontSize:"45px", color:"#2c68bd"}}/>Smart Match</div>
                <div className='worky-product-subtitle'>{portaltext}</div>
                <button onClick={() => window.location.assign('/products?product=3')} className='worky-product-button-reverse'><WestIcon/>Learn More</button>
            </div>
        </div>
    )
}

// new
const ProductFour = () => {
    const portaltext="Introducing our comprehensive shift management and payroll system. Offering real-time shift tracking, precise logging of payments due and received, and detailed records of past shifts. Easily manage payroll and pay employees in one click!"
    return(
        <div className='worky-product-container'>


            <div className='worky-product-content-container'>
                <div className='worky-product-title'><CurrencyExchangeIcon style={{fontSize:"45px", color:"#2c68bd"}}/>Worky Pay</div>
                <div className='worky-product-subtitle'>{portaltext}</div>
                <button onClick={() => window.location.assign('/products?product=4')} className='worky-product-button'>Learn More<EastIcon/></button>
            </div>
            
            <div className='worky-product-img-container'>
            <img src='./workypay.jpg'/>
            </div>
        </div>
    )
}

const ProductFive = () => {
    const portaltext="Discover our AI-powered job matching system for optimal staffing. Utilizing cutting-edge algorithms, it swiftly pairs the most qualified worker to each shift based on numerous criteria. Efficient, intelligent, and tailored to your specific business needs."
    return(
        <div className='worky-product-container'>
            <div className='worky-product-img-container'>
                <img src='./worky-algo-mockup.jpg'/>
            </div>
            <div className='worky-product-content-container'>
                <div className='worky-product-title'><AutoAwesomeIcon style={{fontSize:"45px", color:"#2c68bd"}}/>Smart Match</div>
                <div className='worky-product-subtitle'>{portaltext}</div>
                <button onClick={() => window.location.assign('/products?product=3')} className='worky-product-button-reverse'><WestIcon/>Learn More</button>
            </div>
        </div>
    )
}

// new
const ProductsSection = () => {

    const images = [
        "./splash/desktop-slide/pic1-new.jpg",
        "./splash/desktop-slide/pic2-new.jpg",
        "./splash/desktop-slide/pic3-new.jpg",
        "./splash/desktop-slide/pic4-new.jpg",
        "./splash/desktop-slide/pic5-new.jpg",
        "./splash/desktop-slide/pic6-new.jpg",
        "./splash/desktop-slide/pic7-new.jpg",
        "./splash/desktop-slide/pic8-new.jpg",
        "./splash/desktop-slide/pic12-new.jpg",
        "./splash/desktop-slide/pic13-new.jpg",
    ];

    return(
        <>
            <div style={{marginTop:""}} className='worky-mission'><span className='gradient-blue'>Scale effortlessly </span>with Worky's <span className='gradient-blue'> business solutions</span></div>
            <div className='img-carousel'>
                <div className='carousel-img-holder'><img style={{transform:"translatex(-34.5%)"}} className='carousel-inner-img' src={images[5]}></img></div>
                <div className='carousel-img-holder'><img style={{transform:"translatex(-40%)"}} className='carousel-inner-img' src={images[7]}></img></div>
                <div className='carousel-img-holder'><img style={{transform:"translatex(-33%)"}} className='carousel-inner-img' src={images[8]}></img></div>
                <div className='carousel-img-holder'><img style={{transform:"translatex(-34%)"}} className='carousel-inner-img' src={images[3]}></img></div>
                <div className='carousel-img-holder'><img style={{transform:"translatex(-29%)"}} className='carousel-inner-img' src={images[1]}></img></div>
            </div>
        </>
    )
}

const SliderTop =()=>{
    const images = [
        // "./splash/desktop-slide/pic1-new.jpg",
        // "./splash/desktop-slide/pic2-new.jpg",
        // "./splash/desktop-slide/pic3-new.jpg",
        // "./splash/desktop-slide/pic4-new.jpg",
    ];

    return (
        <Slide infinite={true} canSwipe easing='linear' duration={2000} arrows={false} autoplay={true} pauseOnHover={false}>
            
            <div className="each-slidev2">
                <div className='slide-title'>Tired of Staffing Agencies?</div>                    
                <img className="slide-img" src={images[0]}/>
            </div>

            <div className="each-slide">
                <img className="slide-img" src={images[8]} />
            </div>

            <div className="each-slidev2">
                <img className="slide-img" src={images[9]} />
            </div>

            <div className="each-slide">
                <img className="slide-img" src={images[2]} />
            </div>

        </Slide>
      );
}

const SplashNavbarV2 = () => {
    useEffect(() => {
        // Check if the query parameter 'scroll' is present in the URL
        const urlParams = new URLSearchParams(window.location.search);
        const scrollPosition = urlParams.get('scroll');

        if (scrollPosition) {
            // Scroll to the specified position
            window.scrollTo({ top: parseInt(scrollPosition), behavior: "smooth" });
        }
    }, []);

    return(
        <div className='splash-navbar'>
            <div onClick={() => window.scrollTo({ top: 1, behavior: "smooth" })} className='splash-navbar-logo'>worky</div>
            <div className='splash-navbar-menu'>
                <button onClick={() => window.scrollTo({ top: 2500, behavior: "smooth" })} className='splash-navbar-option'>Employers</button>
                <button onClick={() => window.scrollTo({ top: 3300, behavior: "smooth" })} className='splash-navbar-option'>Employees</button>
                <button onClick={() => window.location.assign('/careers')} className='splash-navbar-option'>Careers</button>
                <button onClick={() => window.scrollTo({ top: 4150, behavior: "smooth" })} className='splash-navbar-option'>Contact Us</button>
            </div>
            <div className='splash-navbar-buttons'>
                <button onClick={() => window.location.assign(workyServerIDPSIGNINURL)} className='splash-navbar-login'>Login</button>
                <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='splash-navbar-trynow'>Try Now</button>
            </div>
        </div>
    )
}

const Slider = () => {
    const images = [
        "./splash/desktop-slide/pic1-new.jpg",
        "./splash/desktop-slide/pic2-new.jpg",
        "./splash/desktop-slide/pic3-new.jpg",
        "./splash/desktop-slide/pic4-new.jpg",
        "./splash/desktop-slide/pic5-new.jpg",
        "./splash/desktop-slide/pic6-new.jpg",
        "./splash/desktop-slide/pic7-new.jpg",
        "./splash/desktop-slide/pic8-new.jpg",
        "./splash/desktop-slide/pic12-new.jpg",
        "./splash/desktop-slide/pic13-new.jpg",
    ];

    return (
        <Fade cssClass='fade' infinite={true} duration={2000} arrows={false} autoplay={true} pauseOnHover={false}>
            <div className="each-slide">                    
                <img className="slide-img" src={images[0]}/>
            </div>

            <div className="each-slide">
                <img className="slide-img" src={images[8]} />
            </div>

            <div className="each-slide">
                <img className="slide-img" src={images[9]} />
            </div>

            <div className="each-slide">
                <img className="slide-img" src={images[2]} />
            </div>

            <div className="each-slide">
                <img className="slide-img" src={images[7]} />
            </div>

            <div className="each-slide">
                <img className="slide-img" src={images[1]} />
            </div>
            
            <div className="each-slide">
                <img className="slide-img" src={images[3]} />
            </div>

            <div className="each-slide">
                <img className="slide-img" src={images[4]} />
            </div>

            <div className="each-slide">
                <img className="slide-img" src={images[5]} />
            </div>
        </Fade>
      );
}

const Partners = () => {

    return(
        <div className='splash-our-partners'>
            <div className='splash-partners-title'>Our Trusted Partners</div>
            <div className='splash-partners-subtitle'>Join our Team of Employers</div>

            <div className='splash-partners-container'>
                <div className='splash-partner'><img src='./splash/regal.jpg'/></div>
                <div className='splash-partner'><img src='./splash/logo4.png'/></div>
                <div className='splash-partner'><img src='./splash/logo2.png'/></div>
                {/* <div className='splash-partner'><img src='./splash/logo3.png'/></div> */}
            </div>
        </div>
        
    )
}

const SliderEmployers = () => {
    const images = [
        "./splash/Mockup.png",
        // "./laptop2.png",
    ];

    return (<>
        {/* <Fade cssClass='fade-employee' infinite={true} duration={6000} arrows={false} autoplay={true}> */}
            <div className="each-employer-slide">                    
                <img src={images[0]}/>
            </div>

            {/* <div className="each-employer-slide">
                <img src={images[1]} />
            </div> */}
            
        {/* </Fade> */}
        </>
      );
}

const SliderWorkers = () => {
    const images = [
        "./splash/phonearray.jpg",
        // "./splash/desktop-slide/pic2-new.png",
    ];

    return (<>
        {/* <Fade cssClass='fade-worker' infinite={true} duration={6000} arrows={false} autoplay={true}> */}
            <div className="each-worker-slide">                    
                <img src={images[0]}/>
            </div>

            {/* <div className="each-worker-slide">
                <img src={images[1]} />
            </div> */}
            
        {/* </Fade> */}
        </>
      );
}

const SplashSection1 = () => {
    const TEXTS = ['General Labourers','Warehouse Associates', 'Forklift Drivers', 'Material Handlers','Food Processors', 'Movers', 'Order Pickers', 'Machine Operators'];
    const [index, setIndex] = useState(0);
    
    useEffect(() => {
        const intervalId = setInterval(
          () => setIndex((index) => index + 1),
          2000, 
        );
        return () => clearTimeout(intervalId);
      }, []);

    return(
        <div className='splash-section1'>
            <div className='splash-section1-title-holder'>
                <div className='splash-section1-subtitle'>The Worlds First</div>
                <div className='splash-section1-title'>Instant Staffing Subscription</div>
                <div className='splash-section1-text'>Hire <TextTransition style={{ margin: "7px 4px 0px 4px", color:"#2c68bd", fontWeight:600 }} springConfig={presets.default} inline>{TEXTS[index % TEXTS.length]}</TextTransition> with <span style={{fontWeight:"700", color:"#2c68bd"}}>worky</span></div>
            </div>
            <div className='splash-section1-button-holder'>
                <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='splash-section1-button'>For Businesses</button>
                <button onClick={() => window.scrollTo({ top: 4150, behavior: "smooth" })} className='splash-section1-button'>For Businesses</button>
            </div>
            <div className='splash-section1-slider'><Slider/></div>
        </div>
    )
}

const SplashSection2 = () => {

    return(
        <div className='splash-section7-container'>
            <div className='section7-title'>Instant Staffing. Anywhere. Anytime.</div>
            <div className='section7-subtitle'>Get your shifts filled within seconds!<br/>Worky Gives you access to over 10,000 active employees available at your fingertips.<br/>The Best Workers, Fastest Response. Guaranteed.</div>
            <button onClick={() => window.scrollTo({ top: 4150, behavior: "smooth" })} id='section7-button' className='splash-section1-button'>Try Now</button>
            <div className='section7-info-holder'>
                <div className='section7-info-container'>
                    <div className='section7-info-icon'><img className='section7-img' src='./splash/fast.png'></img></div>
                    <div className='section7-info-title'>Fast</div>
                    <div className='section7-info-content'>At Worky, we prioritize <span className='simple-bold'>Speed</span> and <span className='simple-bold'>Efficiency</span>.<br/> We deliver <span className='simple-bold'>Instant</span> results. <br/>Hire your employees within <span className='simple-bold'>1 Hour!</span> <br/>Hire Fast. Worky It.</div>
                </div>

                <div className='section7-info-container'>
                    <div className='section7-info-icon'><img className='section7-img' src='./splash/reliable.png'></img></div>
                    <div className='section7-info-title'>Reliable</div>
                    <div className='section7-info-content'>At Worky, we're on a mission: deliver the <span className='simple-bold'>perfect</span> candidate <span className='simple-bold'>EVERY</span> time. <br/>We guarantee clients the top candidates at highly competitive rates.</div>
                </div>

                <div className='section7-info-container'>
                    <div className='section7-info-icon'><img className='section7-img' src='./splash/safe.png'></img></div>
                    <div className='section7-info-title'>Safe</div>
                    <div className='section7-info-content'>At Worky, we <span className='simple-bold'>prioritize safety</span>. We ensure the well-being of our employees and clients by guaranteeing <span className='simple-bold'>safe work environments</span>, with <span className='simple-bold'>regular training</span> on the latest safety standards.</div>
                </div>
            </div>
        </div>
    )
}

const SplashForEmployers = () => {
    const employersText = "Empowering businesses of every scale, Worky is the World's First  seamless on-demand labor solution. From a single worker to a workforce of 100, our platform works for you! With Worky, your shifts are all filled in under an hour!";
    return(
        <div className='splash-employers'>
            <div className='splash-text-holder'>
                <div className='splash-employers-title'>For<br/>Employers</div>
                <div className='splash-employers-text'>{employersText}</div>
                <button onClick={() => window.scrollTo({ top: 4150, behavior: "smooth" })} className='splash-employers-button'>Hire Now</button>
            </div>
            <div className='splash-img-holder'><SliderEmployers/></div>
        </div>
    )
}

const SplashForWorkers = () => {
    const workersText = "Say goodbye to rigid schedules—get paid on your own terms with Worky. We offer the flexibility you need for a hassle-free work-life balance. Take control as your own boss and start earning today!";
    return(
        <div className='splash-employers'>
            
            <div className='splash-img-holder-worker'><SliderWorkers/></div>
            <div className='splash-text-holder'>
                <div className='splash-employees-title'>For<br/>Workers</div>
                <div className='splash-employees-text'>{workersText}</div>
                <button onClick={() => window.location.assign(workyServerIDPSIGNUPURL)} className='splash-employees-button'>Join Now</button>
            </div>
        </div>
    )
}

const SplashSection5 = () => {
    return(
        <div className='splash-section3'>
            <div className='splash-section3-inner'>
                <div className='worky-stats-container'>
                    <div className='worky-stats-title'>Stat Title 1</div>
                    <div className='worky-stats-value'>
                        <div className='worky-stats-value-icon'></div>
                        <div className='worky-stats-value-info'></div>
                    </div>
                </div>

                <div className='worky-stats-container'>
                    <div className='worky-stats-title'>Stat Title 1</div>
                    <div className='worky-stats-value'>
                        <div className='worky-stats-value-icon'></div>
                        <div className='worky-stats-value-info'></div>
                    </div>
                </div>

                <div className='worky-stats-container'>
                    <div className='worky-stats-title'>Stat Title 1</div>
                    <div className='worky-stats-value'>
                        <div className='worky-stats-value-icon'></div>
                        <div className='worky-stats-value-info'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SplashContact = () => {
    const [isFormSubmitted, setFormSubmitted] = useState(false);

    const handleGeneralEmailSubmit = async (e) => {
        e.preventDefault();

        if (isFormSubmitted) {
            return;
          }
          setFormSubmitted(true);

    
        const serviceID = 'service_3mlm2ls';
        const templateID = 'template_xl6xpjw';
        const userID = 'zKdgUxA7H3dMaCf-m';
    
        try {
            await emailjs.sendForm(serviceID, templateID, e.target, userID);
            alert('Email sent successfully!');
          } catch (error) {
            alert('An error occurred while sending the email.');
            console.error(error);
          } finally {
            setFormSubmitted(false);
          }
    };


    return(
        <div className='splash-contact'>
            <div className='splash-contact-title'>Get in Touch</div>
            <div className='splash-contact-subtitle'>Have a Question? Want to Hire today?</div>
            <div className='splash-contact-contactinfo-holder'>
                <div className='splash-contact-contactinfo'>
                    <EmailOutlinedIcon/>
                    <div className='splash-contact-contactinfo-info'>info@worky.pro</div>
                </div>
                <div className='splash-contact-contactinfo'>
                    <PhoneOutlinedIcon/>
                    <div className='splash-contact-contactinfo-info'>(647) 494 9090</div>
                </div>
            </div>
            <div className='splash-contact-container'>
                <form onSubmit={handleGeneralEmailSubmit}>

                <div className='splash-input-title'>Full Name:</div>
                <input type="text" name="user_fullname" className='splash-input' required/>     
                <div className='splash-input-title'>Email Address:</div>         
                <input type="email" name="user_email" className='splash-input' required/>

                <div className='splash-input-title'>Reason:</div>
                <select id='splash-reason' className="splash-input" name="user_reason" required>
                    <option value="" disabled selected>Select a reason</option>
                    <option value="General Inquiry">General Inquiry</option>
                    <option value="Technical Support">Technical Support</option>
                    <option value="Feedback">Feedback</option>
                    <option value="Other">Other</option>
                </select>

            


                <div className='splash-input-title'>Message:</div>
                <textarea id='splash-message' className='splash-input' name="user_message" required></textarea>
                <div className='splash-button-holder'>
                    <button type="submit" value="Submit" className='splash-submit'>{isFormSubmitted ? 'Submitting...' : 'Submit'}</button>
                </div>
                </form>

                
            </div>
        </div>
    )
}

const Ontime = () => {
    return(
        <div className='ontime-holder'>
            <div className='ontime-inner'>
                
                <div className='ontime'>
                    <div className='ontime-title'>Average Savings Per Shift</div>
                    <div className='ontime-value'>30%</div>
                </div>
                <div className='ontime'>
                    <div className='ontime-title'>Available In</div>
                    <div className='ontime-value'><img src='./uscanada.jpg'/></div>
                </div>
                <div className='ontime'>
                    <div className='ontime-title'>Shifts Filled in Under</div>
                    <div className='ontime-value'>60s</div>
                </div>
            </div>

        </div>
    )
}
// new
const SplashContactV2 = () => {
    const [isFormSubmitted, setFormSubmitted] = useState(false);

    const handleGeneralEmailSubmit = async (e) => {
        e.preventDefault();

        if (isFormSubmitted) {
            return;
        }
        setFormSubmitted(true);

        const serviceID = 'service_3mlm2ls';
        const templateID = 'template_xl6xpjw';
        const userID = 'zKdgUxA7H3dMaCf-m';

        try {
            await emailjs.sendForm(serviceID, templateID, e.target, userID);
            alert('Email sent successfully!');
        } catch (error) {
            alert('An error occurred while sending the email.');
            console.error(error);
        } finally {
            setFormSubmitted(false);
        }
    };

    return (
        <>
            <div className='splash-contact-container'>
                <div className='splash-contact-title-v2'><span className='gradient-blue'>Lets Get Started!</span></div>
                <div className='splash-contact-subtitle-v2'>A live agent is waiting for you on the other side!</div>
                <div className='splash-contact-buttons-v2'>
                    <button className='splash-contact-button-v2'><CallIcon/>(647) 494-9090</button>
                    <button className='splash-contact-button-v2'><MailIcon/>info@worky.pro</button>
                </div>
                {/* <div className='splash-contact-inner'> */}
                    <form className='splash-contact-inner' onSubmit={handleGeneralEmailSubmit}>
                        <div className='splash-contact-inner-row'>
                            <TextField id="standard-basic" label="First Name" variant="standard" className='standard-basic-input' name="user_firstname" required />
                            <TextField id="standard-basic" label="Last Name" variant="standard" className='standard-basic-input' name="user_lastname" required />
                        </div>
                        <div className='splash-contact-inner-row'>
                            <TextField id="standard-basic" label="Email" type="email" variant="standard" className='standard-basic-input' name="user_email" required />
                            <TextField id="standard-basic" label="Phone Number" type="number" variant="standard" className='standard-basic-input' name="user_phone" required />
                        </div>
                        <div className='splash-contact-inner-row'>
                            <TextField id="standard-basic" label="Business Name" variant="standard" className='standard-basic-input' name="user_businessname" required />
                            <TextField id="standard-basic" label="Province" variant="standard" className='standard-basic-input' name="user_province" required />
                        </div>
                        <TextField
                            id="standard-multiline-static"
                            label="Tell Us About Your Business"
                            multiline
                            rows={4}
                            defaultValue=""
                            variant="standard"
                            className='standard-basic-message'
                            name="user_message"
                            required
                        />
                        <div className='get-started-button-holder'>
                            <button onClick={()=>window.open(bookDemoLink)} id='get-started-demo' className='get-started-button'>Book A Demo</button>
                            <button type="submit" value="Submit" className='get-started-button'>{isFormSubmitted ? 'Submitting...' : 'Send'}</button>
                        </div>
                    </form>
                {/* </div> */}
            </div>
        </>
    )
}

const SplashFooter = () => {
    return(
        <>
            <div className='splash-footer'>
                <div onClick={()=>window.location.assign('/')} className='splash-footer-title'>worky</div>
                <div className='splash-footer-socials'>
                    <div id='social-facebook' className='splash-footer-social'></div>
                    <a href='https://www.instagram.com/worky.pro/'><div id='social-instagram' className='splash-footer-social'></div></a>
                    <div id='social-twitter' className='splash-footer-social'></div>
                    <a href='https://www.linkedin.com/company/worky-inc/'><div id='social-linkedin' className='splash-footer-social'></div></a>
                    <div id='social-tiktok' className='splash-footer-social'></div>
                    <div id='social-email' className='splash-footer-social'><MailIcon sx={{fontSize:"17px"}}/>info@worky.pro</div>
                    <div id='social-email' className='splash-footer-social'><CallIcon sx={{fontSize:"17px"}}/>(647) 494-9090</div>
                </div>
            </div>
            <div className='splash-footer-copyright'>Worky © 2024. All rights reserved.</div>
        </> 
    )
}


const WorkySolutions = React.memo(() => {
    const [optionVal, setOptionVal] = useState(0);

    const solutionsImgInfo = [
        { img: './worky-pay-mockup2-1.jpg' },
        { img: './worky-geotracking2.jpg' },
        { img: './mockups-assign2-5.jpg' },
        { img: './macbook-mockup2-3.jpg' }
    ];

    const handleOptionClick = useCallback((index) => {setOptionVal(index);}, []);

    useEffect(() => {
        solutionsImgInfo.forEach((info) => {
            const img = new Image();
            img.src = info.img;
        });
    }, [solutionsImgInfo]);

    return (
        <div className='worky-solutions'>
            <div className='worky-solutions-options-holder'>
                {[
                    { icon: <PaymentsRoundedIcon sx={{ fontSize: "30px" }} />, title: "Pay" },
                    { icon: <AdsClickRoundedIcon sx={{ fontSize: "30px" }} />, title: "Track" },
                    { icon: <EditCalendarRoundedIcon sx={{ fontSize: "30px" }} />, title: "Assign" },
                    { icon: <SettingsRoundedIcon sx={{ fontSize: "30px" }} />, title: "Manage" }
                ].map((option, index) => (
                    <div key={index} onClick={() => handleOptionClick(index)} className='worky-solutions-option'>
                        <div className='worky-solutions-option-icon' id={optionVal === index ? "solutions-option-icon-active" : ""}>
                            {option.icon}
                        </div>
                        <div className='worky-solutions-option-title' id={optionVal === index ? "solutions-option-title-active" : ""}>
                            {option.title}
                        </div>
                    </div>
                ))}
            </div>

            <div className='worky-solutions-options-img-holder'>
                <div className='worky-solutions-options-img'>
                    <img 
                        src={solutionsImgInfo[optionVal].img} 
                        alt={`solution-${optionVal}`} 
                        loading="lazy" 
                        style={optionVal === 3 ? { maxWidth: "125%", height: "auto", marginLeft: "15%" } : {}}
                    />
                </div>
            </div>
        </div>
    );
});



// const WorkerEmployer = () => {
        
//     return(
//         <div className='worker-employer-container'>
//             {/* <div className='product-tabs-container'> */}
//                 {/* <div className='product-tabs-container-inner'> */}
//                     {/* {currentProduct == 1 ? (<button onClick={handleProductClick1} className='product-tab' id='product-tab-active'>For Businesses</button>):(<button onClick={handleProductClick1} className='product-tab'>For Businesses</button>)} */}
//                     {/* {currentProduct == 2 ? (<button onClick={handleProductClick2}  className='product-tab' id='product-tab-active'>Find Work</button>):(<button onClick={handleProductClick2} className='product-tab'>Find Work</button>)} */}
//                 {/* </div> */}
//             {/* </div> */}
//             <div className='worker-employer-inner'>
//                 <div className='products-display-title'><span className='gradient-blue'>{currentProduct == 1 ? ("For Businesses"):("For Associates")}</span></div>
//                 {currentProduct == 1 ? 
//                 (<div className='worker-employer-box'>
//                     <div className='worker-employer-box-info'>

//                         <div className='worker-employer-box-info-section'>
//                             <div className='worker-employer-box-info-title'><LooksOneIcon/>Dispatch + Track Employees</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Submit shifts for employees using AI</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Ensures only the best workers</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Track Real-time location</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View Virtual Check-ins</div>
//                         </div>

//                         <div className='worker-employer-box-info-section'>
//                             <div className='worker-employer-box-info-title'><LooksTwoIcon/>Budget for the week</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Set a Weekly budget</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Track Real-time spend</div>
//                         </div>
                        
//                         <div className='worker-employer-box-info-section'>
//                             <div className='worker-employer-box-info-title'><Looks3Icon/>View your Payments</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View all previous shifts</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View all previous payments</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View all previous WorkyPays</div>
//                         </div>

//                         <div className='worker-employer-box-info-section'>
//                             <div className='worker-employer-box-info-title'><Looks4Icon/>Pay Employees Directly</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Pay workers directly</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Set your Pay Schedule</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Save on Payroll Costs</div>
//                         </div>

//                     </div>
//                     <div className='worker-employer-box-img'>
//                         <img src='./worky-mockup-v2.jpg' />
//                     </div>
//                 </div>):
//                 (<div className='worker-employee-box'>
//                     <div className='worker-employee-box-img'>
//                         <img src='./phonearray2.jpg' />
//                     </div>
//                     <div className='worker-employer-box-info'>
//                         <div className='worker-employer-box-info-section'>
//                             <div className='worker-employer-box-info-title'><LooksOneIcon/>Take Control of YOUR Time</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>YOUR schedule!</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>YOUR area!</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>YOUR field!</div>
//                         </div>

//                         <div className='worker-employer-box-info-section'>
//                             <div className='worker-employer-box-info-title'><LooksTwoIcon/>Build Your Profile</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Set a Profile to get noticed</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Build your skills</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Get more Shifts!</div>
//                         </div>
                        
//                         <div className='worker-employer-box-info-section'>
//                             <div className='worker-employer-box-info-title'><Looks3Icon/>View all Previous Shifts</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Track your Past Shifts</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Ensure Correct Hours</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Ensure Correct Payments</div>
//                         </div>

//                         <div className='worker-employer-box-info-section'>
//                             <div className='worker-employer-box-info-title'><Looks4Icon/>View all Upcoming Shifts</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>View your Next Shift</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Pick up more Shifts!</div>
//                             <div className='worker-employer-box-info-text'><CheckIcon style={{fontSize:"15px"}}/>Cancel Shifts</div>
//                         </div>


//                     </div>
//                 </div>)
//                 }
//             </div>
//         </div>

//     )
// }




const DesktopSplashV2 = () => {
    const isMobile = useIsMobile();
    const [currentProduct, setCurrentProduct] = useState(1);
    // Create refs for each section
    const solutionsRef = useRef(null);
    const productsRef = useRef(null);
    const contactRef = useRef(null);

    const handleResourceClick =()=>{
        window.location.assign('/resources')
    }
    // Scroll to the solutions section
    const handleProductClick1 = () => {
        solutionsRef.current?.scrollIntoView({ behavior: "smooth" });
        setCurrentProduct(1);
    };

    // Scroll to the products section
    const handleProductsClick = () => {
        productsRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Scroll to the contact section
    const handleContactClick = () => {
        contactRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const signupClick = () => {
        contactRef.current?.scrollIntoView({ behavior: "smooth" });
        // setTryNowModal(false);
    };


    const SplashNavbarV3 = () => {
        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const scrollPosition = urlParams.get('scroll');
            if (scrollPosition) {
                const position = parseInt(scrollPosition, 10);
                if (!isNaN(position)) {window.scrollTo({ top: position, behavior: "smooth" });}
            }
        }, []);
    
    
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
        
        const handleClick = (event: React.MouseEvent<HTMLElement>) => { 
            setAnchorEl(anchorEl ? null : event.currentTarget);
            if (!anchorEl) {setAnchorEl2(null);}
        };
        
        const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl2(anchorEl2 ? null : event.currentTarget);
            if (!anchorEl2) {setAnchorEl(null);}
        };
        
        const open = Boolean(anchorEl);
        const open2 = Boolean(anchorEl2);
        
        const id = open ? 'simple-popper' : undefined;
        const id2 = open2 ? 'simple-popper2' : undefined;
        
        return(
            <div className='splash-navbar-v3'>
                <div onClick={() => window.scrollTo({ top: 1, behavior: "smooth" })} className='splash-navbar-logo'>worky</div>
                <div className='splash-navbar-menu'>
                    <button aria-describedby={id} type="button" onClick={handleClick} className='splash-navbar-option'>Products<KeyboardArrowDownIcon style={{ fontSize: "18px", paddingTop: "2px" }} /></button>
                    <button onClick={handleProductClick1} className='splash-navbar-option'>Solutions</button>
                    <button aria-describedby={id2} type="button" onClick={handleClick2} className='splash-navbar-option'>Careers<KeyboardArrowDownIcon style={{ fontSize: "18px", paddingTop: "2px" }} /></button>
                    <button onClick={handleResourceClick} className='splash-navbar-option'>Resources</button>
                    <button onClick={() => window.open(bookDemoLink)} id='demo-option' className='splash-navbar-option'>Book Demo</button>
                    
                    <Popper id={id} open={open} anchorEl={anchorEl} style={{zIndex:"500"}}>
                        <div className='popper-drawer'>
                            <div className='popper-drawer-title'>PRODUCTS</div>
                            <div className='popper-drawer-line'></div>
    
                            <div className='popper-products-holder-inner'>
                                <button onClick={() => window.location.assign('/products?product=1')} className='popper-product'>
                                    <div className='popper-product-inner-img'>
                                        <img src='./virtual-check-in.jpg'></img>
                                    </div>
                                    <div className='popper-product-inner-info'><RadarIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Virtual Clock</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=2')} className='popper-product'>
                                    <div className='popper-product-inner-img'><img src='./worky-ipad-v2.jpg'></img></div>
                                    <div className='popper-product-inner-info'><AdsClickIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Portal</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=3')} className='popper-product'>
                                    <div className='popper-product-inner-img'>
                                        <img src='./worky-algo-mockup.jpg'/>
                                    </div>
                                    <div className='popper-product-inner-info'><AutoAwesomeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Smart Match</div>
                                </button>
                                <button onClick={() => window.location.assign('/products?product=4')} className='popper-product'>
                                    <div className='popper-product-inner-img'><img src='./workypay.jpg'></img></div>
                                    <div className='popper-product-inner-info'><CurrencyExchangeIcon style={{fontSize:"15px", marginRight:"3.5px", color:"#2c68bd"}}/>Worky Pay</div>
                                </button>
                                <button onClick={() => window.location.assign('/products')} id='view-all-popper-product'>
                                    <div className='view-all-products-icon'><EastIcon/></div>
                                    View All
                                </button>
                            </div>
                        </div>
                    </Popper>
    
                    <Popper id={id2} open={open2} anchorEl={anchorEl2} style={{zIndex:"500"}}>
                        <div className='popper-drawer'>
                            <div className='popper-drawer-title'>CAREERS</div>
                            <div className='popper-drawer-line'></div>
                            <div className='popper-careers-holder-inner'>
                                <button onClick={() => window.location.assign('/careers?scroll=1400')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Data Platform Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=1650')} className='popper-job'>
                                    <div className='popper-job-icon'><BrushIcon/></div>
                                    <div className='popper-job-title'>Senior Product Designer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=1950')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Server Side Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2200')} className='popper-job'>
                                    <div className='popper-job-icon'><DataObjectIcon/></div>
                                    <div className='popper-job-title'>Senior Front End Engineer</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                    <div className='popper-job-icon'><PhoneIphoneIcon/></div>
                                    <div className='popper-job-title'>Social Media Manager</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers?scroll=2480')} className='popper-job'>
                                    <div className='popper-job-icon'><TrendingUpIcon/></div>
                                    <div className='popper-job-title'>Regional Sales Manager</div>
                                </button>
                                <button onClick={() => window.location.assign('/careers')} id='view-all-jobs' className='popper-job'>
                                    <div id='view-all-jobs-title' className='popper-job-title'>View All</div>
                                    <div id='view-all-jobs-icon' className='popper-job-icon'><EastIcon/></div>
                                </button>
                            </div>
                        </div>
                    </Popper>
    
                </div>

                <div className='splash-navbar-buttons'>
                    <button onClick={() => window.location.assign(workyServerIDPSIGNINURL)} className='splash-navbar-login'>Login</button>
                    <button onClick={signupClick} className='splash-navbar-trynow'>Try Now</button>
                </div>
                
            </div>
        )
    }




    

    persistSalesMetaDataCalendlyRedirect()
    return(
        <>
            

            <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />        
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin='' />
            <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"/>

            <div className='splash-body'>
                <SplashNavbarV3/>
                <SplashSection1V2/>

                <div ref={solutionsRef} className='splash-title-desktop'>SOLUTIONS</div>
                <WorkySolutions/>

                <ProductsSection/>

                <div ref={productsRef} className='splash-title-desktop'>PRODUCTS</div>
                <ProductOne/>
                <ProductTwo/>
                <ProductThree/>
                <ProductFour/>
                
                <div ref={contactRef} style={{ paddingBottom: "50px" }} className='splash-title-desktop'>CONTACT</div>
                <SplashContactV2/>
                <SplashFooter/>
            </div>

        </>
    )
}

export { DesktopSplashV2, SplashNavbarV2, SplashFooter}