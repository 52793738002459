import React, { useState, useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { CorpDataObj } from "../orgViews/orgConstants";
import {
  CalcWorkyChargeNotIncludingTax,
  CalcShiftDuration,
  CalcTax,
  BuildShiftRequestUnitClientObjRequest,
  BuildShiftRequestUnitClientObj,
} from "../common/ShiftServiceUtils";

import { useIsMobile } from "../custom-hooks/useIsMobile";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import Rating from "@mui/material/Rating";
import Fuse from "fuse.js";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import CloseIcon from "@mui/icons-material/Close";
import { EmailAddress, ShiftStartEndDetails, OrgLocation, OrgLocationMapDetails } from "../common/CommonInterfaces";
import { TimePicker, WorkyTimeObj, FormatTime, DetermineHourForTweelve } from "./TimePicker";
import "./newshift.css";
import { TopNav, PushToNewSr, PushtoHomepage, PushtoSrHistory } from "../orgViews/OrgCommonComponents";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetOrgBillsAPICall, SRIngressPreHookPOSTAPICall } from "./sr-ingress-api-calls/SRIngressLookupAPIs";

import { IsAgencyUser, IsOrgUser } from "../shared-components/shared-utils/UserPermissionsUtils";
import { JobberAvailMessageType, JobberAvailabilityResponse, JobberWorkerTypeDTO } from "./sr-ingress-data/SRIngressInterfaces";
import { JobberDetailsForOrg } from "../orgViews/OrgUtils";
import { convertDatesToEpochSec } from "../common/TimeUtils";
import { ShiftIngressRequestUnitAPIObj } from "../jobberViews/objects/ShiftRequestConstants";
import { ca } from "date-fns/locale";
import { Tooltip } from "@mui/material";
const defaultPic = "./org-default-icon.png";

interface ModelComponentsProps {
  modalOpen: boolean;
  handleClose: () => void;
  modalErrOpen: boolean;
  setModalErrOpen: (isOpen: boolean) => void;
  handleErrModalClose: () => void;
}

const ModelComponenetsOnSubmission = ({ modelComponentsProps }: { modelComponentsProps: ModelComponentsProps }) => {
  const modalOpen = modelComponentsProps.modalOpen;
  const handleClose = modelComponentsProps.handleClose;
  const modalErrOpen = modelComponentsProps.modalErrOpen;
  const handleErrModalClose = modelComponentsProps.handleErrModalClose;

  return (
    <div>
      <link rel="stylesheet" href="https://use.typekit.net/rjq7odq.css" />
      <Modal open={modalOpen} onClose={handleClose}>
        <div className="submit-success">
          <button onClick={handleClose} className="modal-close">
            <CloseIcon />
          </button>
          <div className="submit-success-icon">
            <CheckCircleRoundedIcon style={{ fontSize: "100px", height: "100px", color: "#6DBB3A" }} />
          </div>
          <div className="submit-success-title">Success</div>
          <div className="submit-success-subtitle">Shift Submitted Successfully!</div>
          <div className="submit-success-subtitle">Want to Assign More Workers? Click below</div>
          <div className="submit-success-button-holder">
            <button onClick={PushToNewSr} className="submit-success-button">
              Assign More
            </button>
            <button onClick={PushtoHomepage} className="submit-success-button-home">
              <HomeRoundedIcon />
            </button>
          </div>
        </div>
      </Modal>

      <Modal open={modalErrOpen} onClose={handleErrModalClose}>
        <div className="submit-success">
          <button onClick={handleClose} className="modal-close">
            <CloseIcon />
          </button>
          <div className="submit-success-icon">
            <ErrorRoundedIcon style={{ fontSize: "100px", height: "100px", color: "#D32F2F" }} />
          </div>
          <div className="submit-success-title">Error</div>
          <div className="submit-success-subtitle">There was an issue!</div>
          <div className="submit-success-subtitle">Want to start again? Click below</div>
          <button style={{ marginTop: "20px" }} onClick={PushToNewSr} className="submit-success-button">
            Try Again
          </button>
        </div>
      </Modal>
    </div>
  );
};

const formatJobTitle = (jobTitle: string): string => {
  return jobTitle
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const ShiftRecieptPage = ({
  workyStartTimeRefObj,
  selectedDaysRefObj,
  finalNumJobbers,
  workyEndTimeRefObj,
  selectedDays,
  modelComponentsProps,
  selectedOrgLocationClient,
  selectedJobbers,
  selectedWorkerType,
  corpData: corpData,
  selectOrgName,
  agencyName,
  setSelectedJobbers,
}: {
  workyStartTimeRefObj: WorkyTimeObj;
  workyEndTimeRefObj: WorkyTimeObj;
  selectedDaysRefObj: Date[];
  finalNumJobbers: number;
  selectedDays: Date[];
  modelComponentsProps: ModelComponentsProps;
  selectedOrgLocationClient: OrgLocation;
  selectedJobbers: EmailAddress[];
  selectedWorkerType: string;
  corpData: CorpDataObj;
  selectOrgName: string;
  agencyName: string;
  setSelectedJobbers: (jobbers: EmailAddress[]) => void;
}) => {
  // console.log("orgLocationClient " + JSON.stringify(prevJobbers));
  const isMobile = useIsMobile();
  console.log("selectedOrgLocationClient " + JSON.stringify(selectedOrgLocationClient));



  const jobberDetailsFromCorpObjDefault = corpData.jobberDetails;
  const buildShiftRequestUnitClientObjRequest = {
    numOfJobbers: finalNumJobbers,
    shiftStartEndDetails: convertDatesToEpochSec({
      dates: selectedDays,
      workyStartTime: workyStartTimeRefObj,
      workyEndTime: workyEndTimeRefObj,
    }),
  };
  const preHookInputObj: ShiftIngressRequestUnitAPIObj[] = BuildShiftRequestUnitClientObj(buildShiftRequestUnitClientObjRequest);

  const [preHookJobbers, setPreHookJobbers] = useState<JobberAvailabilityResponse[]>([]);

  console.log(" warm up obj: ", preHookInputObj);
  useEffect(() => {
    const retrieveValidJobbersList = async () => {
      console.log("the method got called ");
      try {
        const apiRes = await SRIngressPreHookPOSTAPICall({ orgName: selectOrgName, agencyName: agencyName, shiftRequestContent: preHookInputObj });
        console.log("heres we are ");
        if (apiRes) {
          console.log("found it: ", apiRes);
          const innerBoundedResp: JobberAvailabilityResponse[] = determineFinalJobberList(jobberDetailsFromCorpObjDefault, apiRes);
          console.log("location 1", innerBoundedResp);
          setPreHookJobbers(innerBoundedResp);
        } else {
          const defaultResp = determineFinalJobberList(jobberDetailsFromCorpObjDefault, []);
          console.log("location 2", defaultResp);
          setPreHookJobbers(defaultResp);
        }
      } catch (e) {
        setPreHookJobbers(determineFinalJobberList(jobberDetailsFromCorpObjDefault, []));
      }
    };
    retrieveValidJobbersList();
  }, []);

  const fuse = new Fuse(Object.values(corpData.jobberDetails), {
    keys: ["userDetails.firstName", "userDetails.lastName"],
    threshold: 0.5,
  });

  // TODO

  const PrevJobberSelectionComponent = ({ preHookJobbers }: { preHookJobbers: JobberAvailabilityResponse[] }) => {
    return (
      <div>
        {/* <MemoizedPrevJobberSelection jobberDetails={preHookJobbers}/> */}
        <PrevJobberSelection jobberDetails={preHookJobbers} />
        {/* <PrevJobberSelectionOld  jobberDetails={jobberDetailsFromCorpObjDefault}/> */}
      </div>
    );
  };

  const PrevJobberSelectionOld = ({ jobberDetails }: { jobberDetails: Record<string, JobberDetailsForOrg> }) => {
    const allJobberEmails: EmailAddress[] = Object.values(jobberDetails).map((details) => details.userDetails.userEmail);

    const handleSelectAll = ({ singleJobberEmail, isSelectAll }: { singleJobberEmail?: EmailAddress; isSelectAll: boolean }) => {
      if (isSelectAll) {
        setSelectedJobbers(selectedJobbers.length === allJobberEmails.length ? [] : allJobberEmails);
      } else {
        if (singleJobberEmail) {
          setSelectedJobbers(
            selectedJobbers.some((email) => email.value === singleJobberEmail.value)
              ? selectedJobbers.filter((jobber) => jobber.value !== singleJobberEmail.value)
              : [...selectedJobbers, singleJobberEmail]
          );
        }
      }
    };

    const [searchQuery, setSearchQuery] = useState("");

    const searchResults = searchQuery ? fuse.search(searchQuery).map((result) => result.item) : Object.values(jobberDetails);

    const AvailablePrevJobbers = () => {
      console.log("TESTING SEARCH RESULTS" + JSON.stringify(searchResults));
      return (
        <>
          {searchResults.map((details) => {
            const { userDetails, profilePicUrl } = details; // Use 'userProfilePicUrl' instead of 'profilePicUrl'
            const jobberEmail = userDetails.userEmail;

            return (
              <div key={jobberEmail.value} className="prev-jobber-card-v5">
                <div className="prev-jobber-card-v5-side">
                  <div className="prev-jobber-card-v5-icon">
                    <img src={profilePicUrl == null ? defaultPic : profilePicUrl} alt="Profile" />
                  </div>
                  <div className="prev-jobber-card-v5-name">
                    {userDetails.firstName} {userDetails.lastName}
                  </div>
                </div>
                <div className="prev-jobber-card-v5-side">
                  <div className="prev-jobber-card-v5-rating">
                    <Rating size="small" value={5} readOnly />
                  </div>
                  <div className="prev-jobber-card-v5-select">
                    <input
                      type="checkbox"
                      checked={selectedJobbers.some((jobber) => jobber.value === jobberEmail.value)}
                      onChange={() => handleSelectAll({ isSelectAll: false, singleJobberEmail: jobberEmail })}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    };

    return (
      <div className="prev-jobber-holder">
        <div className="prev-jobber-search-holder">
          <input
            placeholder="Search My Workforce"
            className="prev-jobber-search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button onClick={() => handleSelectAll({ singleJobberEmail: null, isSelectAll: true })} className="prev-jobber-select-all-btn">
            {selectedJobbers.length === allJobberEmails.length ? "Deselect All" : "Select All"}
          </button>
        </div>
        <AvailablePrevJobbers />
      </div>
    );
  };

  const PrevJobberSelection = ({ jobberDetails }: { jobberDetails: JobberAvailabilityResponse[] }) => {
    // console.log("jobberDetails123 " + jobberDetails)

    const allJobberEmails: EmailAddress[] = [];

    for (const details of jobberDetails) {
      allJobberEmails.push(details.jobberEmailAddress);
    }

    const fuseSecondary = new Fuse(jobberDetails, {
      keys: ["workyUserDetailsZipDTO.userDetails.firstName", "workyUserDetailsZipDTO.userDetails.lastName"],
      threshold: 0.5,
    });

    const handleSelectAll = ({ singleJobberEmail, isSelectAll }: { singleJobberEmail?: EmailAddress; isSelectAll: boolean }) => {
      if (isSelectAll) {
        const selectableJobbers = jobberDetails
          .filter((details) => Number(details.jobberAvailabilityResponse.statusCode) === 1)
          .map((details) => details.jobberEmailAddress);
        setSelectedJobbers(selectedJobbers.length === selectableJobbers.length ? [] : selectableJobbers);
      } else {
        if (singleJobberEmail) {
          setSelectedJobbers(
            selectedJobbers.some((email) => email.value === singleJobberEmail.value)
              ? selectedJobbers.filter((jobber) => jobber.value !== singleJobberEmail.value)
              : [...selectedJobbers, singleJobberEmail]
          );
        }
      }
    };

    const [searchQuery, setSearchQuery] = useState("");

    const searchResults = searchQuery ? fuseSecondary.search(searchQuery).map((result) => result.item) : jobberDetails;

    const AvailablePrevJobbers = () => {
      // console.log("TESTING SEARCH RESULTS" + JSON.stringify(searchResults));
      return (
        <>
          {searchResults.map((details) => {
            // console.log("we are details: ", details)
            // console.log("we are details123: ", details.jobberAvailabilityResponse.clientFacingMessage)

            if (!details) return <div></div>;
            // console.log("at loctioan lsk")
            const { workyUserDetailsZipDTO: jobberDetailsForOrg, jobberEmailAddress: jobberEmail } = details;
            if (!jobberDetailsForOrg || !jobberEmail) return <div></div>;
            // console.log("at loctioan ss")
            const { userDetails, profilePicUrl } = jobberDetailsForOrg;
            if (!userDetails) return <div></div>;
            // console.log("at loctioan hjh2")

            return (
              <Tooltip title={details?.jobberAvailabilityResponse?.clientFacingMessage} placement="left">
                <div
                  key={jobberEmail.value}
                  className="prev-jobber-card-v5"
                  id={Number(details.jobberAvailabilityResponse.statusCode) === 0 ? "prev-jobber-card-v5-disabled" : ""}
                >
                  <div className="prev-jobber-card-v5-side">
                    <div className="prev-jobber-card-v5-icon">
                      <img src={profilePicUrl == null ? defaultPic : profilePicUrl} alt="Profile" />
                    </div>
                    <div className="prev-jobber-card-v5-name">
                      {userDetails.firstName} {userDetails.lastName}
                    </div>
                  </div>
                  <div className="prev-jobber-card-v5-side">
                    <div className="prev-jobber-card-v5-rating">
                      <Rating size="small" value={5} readOnly />
                    </div>
                    <div className="prev-jobber-card-v5-select">
                      <input
                        type="checkbox"
                        checked={selectedJobbers.some((jobber) => jobber.value === jobberEmail.value)}
                        onChange={() => handleSelectAll({ isSelectAll: false, singleJobberEmail: jobberEmail })}
                      />
                    </div>
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </>
      );
    };

    return (
      <div className="prev-jobber-holder">
        <div className="prev-jobber-search-holder">
          <input
            placeholder="Search My Workforce"
            className="prev-jobber-search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button onClick={() => handleSelectAll({ singleJobberEmail: null, isSelectAll: true })} className="prev-jobber-select-all-btn">
            {selectedJobbers.length === allJobberEmails.length ? "Deselect All" : "Select All"}
          </button>
        </div>
        <AvailablePrevJobbers />
      </div>
    );
  };
  const MemoizedPrevJobberSelection = React.memo(PrevJobberSelection);

  const Reciept = () => {
    return (
      <div className={isMobile ? "reciept-v5-mobile" : "reciept-v5"}>
        {/* <div className="reciept-col1-mobile"> */}
        <div className="reciept-info-holder-v5">
          <div className="reciept-item">Number of Workers:</div>
          <div className="reciept-value">{finalNumJobbers}</div>
        </div>

        {/* MY SELECTED WORKERS */}
        {selectedJobbers.length >= 1 ? (
          <Accordion
            disableGutters
            className="reciept-accordian"
            style={
              isMobile
                ? {
                    height: "auto",
                    marginBottom: "0px",
                    boxShadow: "none",
                    minHeight: "50px",
                    width: "100%",
                    background: "white",
                    borderRadius: "20px",
                  }
                : {
                    height: "auto",
                    marginBottom: "0px",
                    boxShadow: "none",
                    minHeight: "50px",
                    width: "100%",
                    background: "white",
                    borderRadius: "20px",
                  }
            }
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" style={{ padding: "0px" }}>
              <>
                <span className="accordian-title2">Selected Workers:</span>{" "}
                <span style={{ marginLeft: "auto", paddingRight: "10px" }} className="reciept-value">
                  {selectedJobbers.length}
                </span>
              </>
            </AccordionSummary>

            <AccordionDetails style={{ marginBottom: "0px" }}>
              <div className="accordian-shift-dates">
                {selectedJobbers.map((email, index) => (
                  <div className={isMobile ? "accordian-shift-dates-each-mobile" : "accordian-shift-dates-each"} key={index}>
                    <div className="accordian-shift-dates-each-icon">
                      <PersonRoundedIcon sx={{ fontSize: "15px" }} />
                    </div>
                    {email.value}
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ) : null}

        {/* SHIFT DATES ACCORDIAN */}
        <Accordion
          disableGutters
          className="reciept-accordian"
          style={
            isMobile
              ? {
                  height: "auto",
                  marginBottom: "0px",
                  boxShadow: "none",
                  minHeight: "50px",
                  width: "100%",
                  background: "white",
                  borderRadius: "20px",
                }
              : {
                  height: "auto",
                  marginBottom: "0px",
                  boxShadow: "none",
                  minHeight: "50px",
                  width: "100%",
                  background: "white",
                  borderRadius: "20px",
                }
          }
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" style={{ padding: "0px" }}>
            <>
              <span className="accordian-title2">Shift Date(s):</span>{" "}
              <span style={{ marginLeft: "auto", paddingRight: "10px" }} className="reciept-value">
                {selectedDaysRefObj.length}
              </span>
            </>
          </AccordionSummary>

          <AccordionDetails style={{ marginBottom: "0px" }}>
            <div className="accordian-shift-dates">
              {selectedDaysRefObj.map((date, index) => (
                <div className={isMobile ? "accordian-shift-dates-each-mobile" : "accordian-shift-dates-each"} key={index}>
                  {date.toDateString()}
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>

        <div className="reciept-info-holder-v5">
          <div className="reciept-item">Shift Time:</div>
          <div className="reciept-value">
            {FormatTime(workyStartTimeRefObj)} - {FormatTime(workyEndTimeRefObj)}
          </div>
        </div>

        <div className="reciept-info-holder-v5">
          <div className="reciept-item">Worker Type:</div>
          <div className="reciept-value">{formatJobTitle(selectedWorkerType)}</div>
        </div>

        <ModelComponenetsOnSubmission modelComponentsProps={modelComponentsProps} />
      </div>
    );
  };

  return (
    <div className={isMobile ? "new-shift-v5-shift-page-1" : "new-shift-v5-shift-page-01"}>
      <div className={isMobile ? "new-shift-v5-shift-page-1-side-mobile" : "new-shift-v5-shift-page-1-side"}>
        <Reciept />
      </div>

      <div className={isMobile ? "new-shift-v5-shift-page-1-side-mobile" : "new-shift-v5-shift-page-1-side"}>
        <div className="new-shift-v5-shift-page-1-selection-holder">
          <label
            style={isMobile ? { paddingTop: "20px", paddingBottom: "10px", textAlign: "center" } : null}
            className="new-shift-v5-shift-page-1-selection-title"
          >
            Select My Workers
          </label>
          <PrevJobberSelectionComponent preHookJobbers={preHookJobbers} />
        </div>
      </div>
    </div>
  );
};

const JobberSelectionPage = ({
  selectCorpName,
  finalNumJobbers,
  setFinalNumJobbers,
  setSelectWorkerType,
  selectWorkerType,
}: {
  selectCorpName: string;
  finalNumJobbers: number;
  setFinalNumJobbers: (number) => void;
  setSelectWorkerType: (workerType: JobberWorkerTypeDTO) => void;
  selectWorkerType: JobberWorkerTypeDTO;
}) => {
  const isMobile = useIsMobile();

  const decrementNumJobbers = () => {
    if (finalNumJobbers > 1) {
      setFinalNumJobbers((prevNum) => prevNum - 1);
    }
  };

  const incrementNumJobbers = () => {
    setFinalNumJobbers((prevNum) => prevNum + 1);
  };

  const NumJobbers = () => (
    <div className="num-jobbers-v5">
      <button className="num-jobbers-button-v5" onClick={decrementNumJobbers}>
        -
      </button>

      <input
        className="num-jobbers-value-v5"
        min="1"
        value={finalNumJobbers}
        type="number"
        onChange={(e) => setFinalNumJobbers(parseInt(e.target.value) || 0)}
      />

      <button className="num-jobbers-button-v5" onClick={incrementNumJobbers}>
        +
      </button>
    </div>
  );

  const NumJobbersMobile = () => (
    <div className="num-jobbers-v5-mobile">
      <button className="num-jobbers-button-v5-mobile" onClick={decrementNumJobbers}>
        -
      </button>

      <input
        className="num-jobbers-value-v5-mobile"
        min="1"
        value={finalNumJobbers}
        type="number"
        onChange={(e) => setFinalNumJobbers(parseInt(e.target.value) || 0)}
      />

      <button className="num-jobbers-button-v5-mobile" onClick={incrementNumJobbers}>
        +
      </button>
    </div>
  );

  const JobberWorkerTypeComponent = ({
    selectedOrgName,
    setSelectWorkerType,
    selectWorkerTypeLocal,
  }: {
    selectedOrgName: string;
    setSelectWorkerType: (JobberWorkerTypeDTO) => void;
    selectWorkerTypeLocal: JobberWorkerTypeDTO;
  }) => {
    const [workerTypes, setWorkerTypes] = useState<JobberWorkerTypeDTO[]>([]);
    const [isMobile, setIsMobile] = useState<boolean>(false); // Assuming you have logic to set this
    const [selectedWorkerType, setSelectedWorkerType] = useState<string>("");

    useEffect(() => {
      const fetchWorkerTypes = async () => {
        const data = await GetOrgBillsAPICall(selectedOrgName);
        if (data) {
          setWorkerTypes(data);
        }
      };

      fetchWorkerTypes();
    }, [selectedOrgName, selectWorkerTypeLocal]);

    const handleWorkerTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const workerTypeUniqueIdentifier = event.target.value;
      setSelectWorkerType(workerTypeUniqueIdentifier);

      const selectedWorker = workerTypes.find((workerType) => workerType.workerTypeUniqueIdentifier === workerTypeUniqueIdentifier);
      if (selectedWorker) {
        setSelectWorkerType(selectedWorker);
        setSelectedWorkerType(selectedWorker.workerTypeName);
      }
    };

    return (
      <div className="new-shift-v5-shift-page-1-selection-holder">
        <label className="new-shift-v5-shift-page-1-selection-title">
          Select Type of Worker <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={isMobile ? "new-shift-v5-shift-page-1-selection-select-mobile" : "new-shift-v5-shift-page-1-selection-select"}
          onChange={handleWorkerTypeChange}
          value={selectWorkerTypeLocal?.workerTypeName}
          defaultValue={selectWorkerTypeLocal?.workerTypeName}
        >
          <option value={selectWorkerTypeLocal?.workerTypeName}>
            {selectWorkerTypeLocal ? selectWorkerTypeLocal.workerTypeName : "Worker Type"}
          </option>
          {workerTypes.map((workerType) => (
            <option key={workerType.workerTypeUniqueIdentifier} value={workerType.workerTypeUniqueIdentifier}>
              {formatJobTitle(workerType.workerTypeName)}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div className={isMobile ? "new-shift-v5-shift-page-1" : "new-shift-v5-shift-page-01"}>
      <div className={isMobile ? "new-shift-v5-shift-page-1-side-mobile" : "new-shift-v5-shift-page-1-side"}>
        <div className="new-shift-v5-shift-page-1-selection-holder">
          <label className="new-shift-v5-shift-page-1-selection-title">
            Select Number of Workers <span style={{ color: "red" }}>*</span>
          </label>
          {isMobile ? <NumJobbersMobile /> : <NumJobbers />}
        </div>
        <JobberWorkerTypeComponent
          selectWorkerTypeLocal={selectWorkerType}
          selectedOrgName={selectCorpName}
          setSelectWorkerType={setSelectWorkerType}
        />
      </div>
    </div>
  );
};

/**
 * Component to select the org(for agency) and the location(for all users)
 * @returns
 */

const DynamicDropdown = ({
  names,
  onSelect,
  placeholder,
  defaultValue,
}: {
  names: string[];
  onSelect: (name: string) => void;
  placeholder: string;
  defaultValue?: string;
}) => {
  const [selectedName, setSelectedName] = useState<string>(defaultValue || "");

  useEffect(() => {
    if (defaultValue) {
      setSelectedName(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newName = event.target.value;
    setSelectedName(newName);
    onSelect(newName);
  };

  return (
    <select className="new-shiftv5-selection-field" value={selectedName} onChange={handleChange}>
      <option value="">{placeholder}</option>
      {names.map((name) => (
        <option key={name} value={name}>
          {name}
        </option>
      ))}
    </select>
  );
};

const SelectOrgLocationDetailsPage = ({
  orgMetaData,
  setOrgLocationHelper,
  existingSelectedOrgLocation,
}: {
  orgMetaData: CorpDataObj;
  setOrgLocationHelper: (org: OrgLocation | null) => void;
  existingSelectedOrgLocation: OrgLocation | null;
}) => {
  // determine num of orgs
  const [orgLocations, setOrgLocations] = useState<string[]>([]); // Step 1: Manage orgLocations state
  const setNextPageAndLocationDetails = ({
    orgName,
    orgLocationMap,
    pageNum,
    setOrgName,
    setCurrentPage,
    setSelectedLocation,
  }: {
    orgName: string;
    orgLocationMap: Map<string, OrgLocation>;
    pageNum: number;
    setOrgName: (orgName: string) => void;
    setCurrentPage: (pageNum: number) => void;
    setSelectedLocation: (location: string) => void;
  }) => {
    // Step 2: Update orgLocations based on selectedOrgName
    const locations = orgMetaData.orgLocationMapDetailsMap[selectedOrgName]?.orgLocationMap;
    if (locations) {
      setOrgLocations(Array.from(Object.keys(locations)));
    } else {
      setOrgLocations([]);
    }

    // console.log("locationNames.current " + JSON.stringify(locationNames.current));
    setOrgName(orgName);
    setCurrentPage(pageNum);
    // setSelectedLocation('');
  };

  const determineStartPage = ({
    orgMetaData,
    setOrgNameLocal,
  }: {
    orgMetaData: CorpDataObj;
    setOrgNameLocal: (string) => void;
  }): number => {
    if (IsOrgUser({ userType: orgMetaData.corpJobberDetails.corpType })) {
      setOrgNameLocal(orgMetaData.corpJobberDetails.corpName);
      return 1;
    } else {
      return 0;
    }
  };

  const allOrgNames =
    orgMetaData && orgMetaData.orgLocationMapDetailsMap ? Array.from(Object.keys(orgMetaData.orgLocationMapDetailsMap)) : [];
  const [selectedOrgName, setSelectedOrgName] = useState<string>("");
  const [selectedLocationName, setSelectedLocationName] = useState<string>("");
  const startPage: number = determineStartPage({ orgMetaData, setOrgNameLocal: setSelectedOrgName });
  const [currentPage, setCurrentPage] = useState(startPage);
  const orgNames = useRef<string[]>([]);
  const [orgNamesState, setOrgNamesState] = useState<string[]>([]);
  const locationNames = useRef<string[]>([]);

  useEffect(() => {
    // console.log("selected org name " + selectedOrgName + " selected location name " + selectedLocationName);

    orgNames.current =
      orgMetaData && orgMetaData.orgLocationMapDetailsMap ? Array.from(Object.keys(orgMetaData.orgLocationMapDetailsMap)) : [];
    // setOrgNamesState(orgNames.current)

    if (existingSelectedOrgLocation) {
      setSelectedLocationName(existingSelectedOrgLocation.locationName); // Update the selected location name
      setSelectedOrgName(existingSelectedOrgLocation.orgName); // Update the s
      const locations = orgMetaData.orgLocationMapDetailsMap[selectedOrgName]?.orgLocationMap;
      if (locations) {
        setOrgLocations(Array.from(Object.keys(locations)));
      } else {
        setOrgLocations([]);
      }
    } else if (orgNames && orgNames.current && orgNames.current.length === 1) {
      setNextPageAndLocationDetails({
        orgName: orgNames.current[0],
        orgLocationMap: orgMetaData.orgLocationMapDetailsMap[orgNames.current[0]].orgLocationMap,
        pageNum: 1,
        setOrgName: setSelectedOrgName,
        setCurrentPage: setCurrentPage,
        setSelectedLocation: setSelectedOrgName,
      });
    } else if (selectedOrgName) {
      setNextPageAndLocationDetails({
        orgName: selectedOrgName,
        orgLocationMap: orgMetaData.orgLocationMapDetailsMap[selectedOrgName].orgLocationMap,
        pageNum: 1,
        setOrgName: setSelectedOrgName,
        setCurrentPage: setCurrentPage,
        setSelectedLocation: setSelectedOrgName,
      });
    } else if (selectedOrgName && selectedLocationName) {
    } else {
      setCurrentPage(0);
    }
  }, [orgMetaData, selectedOrgName]);

  const handleSelectionOrgNameNLocation = ({
    orgName,
    locationName,
    orgLocationMap,
  }: {
    orgName: string;
    locationName: string;
    orgLocationMap: Map<string, OrgLocation>;
  }) => {
    const selectedLocationLocal: OrgLocation = orgLocationMap[locationName];
    if (!selectedLocationLocal) return undefined;

    // console.log("selectedLocation " + JSON.stringify(selectedLocationLocal));
    setOrgLocationHelper(selectedLocationLocal);
    setSelectedLocationName(locationName); // Update the selected location name
    setSelectedOrgName(orgName); // Update the selected org name
    // setCurrentPage(2);
  };

  // console.log("currentPage111 " + currentPage);

  return (
    <div className="tester2">
      {selectedOrgName && <div>Selected Organization: {selectedOrgName}</div>}
      {selectedLocationName && <div>Selected Location: {selectedLocationName}</div>}
      {currentPage === 0 && !selectedOrgName ? (
        allOrgNames && allOrgNames.length > 0 ? (
          <DynamicDropdown
            names={allOrgNames}
            onSelect={(name) => {
              setSelectedOrgName(name);
              setCurrentPage(1); // Assuming you want to move to the next page after selection
            }}
            placeholder="Select Organization"
          />
        ) : (
          <div>Loading organizations...</div> // Placeholder for loading state
        )
      ) : (
        <DynamicDropdown
          names={orgLocations}
          defaultValue={selectedLocationName} // Set the default value to the selected location name
          onSelect={(locationName) => {
            // Handle location selection
            handleSelectionOrgNameNLocation({
              orgName: selectedOrgName,
              locationName: locationName,
              orgLocationMap: orgMetaData.orgLocationMapDetailsMap[selectedOrgName].orgLocationMap,
            });
            // console.log(locationName); // Placeholder action
          }}
          placeholder="Select Location"
        />
      )}
      <div>
        <button
          onClick={() => {
            setCurrentPage(0);
            setSelectedOrgName(null);
            setSelectedLocationName(null);
            setOrgLocationHelper(null);
          }}
          className="new-shift-back"
        >
          Reset
        </button>
      </div>
    </div>
  );
};

const SelectAgencyComponentPage = ({
  corpDataObj,
  agencyNames,
  setSelectedAgencyName,
}: {
  corpDataObj: CorpDataObj;
  agencyNames: string[];
  setSelectedAgencyName: (agencyName: string) => void;
}) => {
  if (IsAgencyUser({ userType: corpDataObj.corpJobberDetails.corpType })) {
    setSelectedAgencyName(corpDataObj.corpJobberDetails.corpName);
    // console.log("selectedAgencyName909 " + corpDataObj.corpJobberDetails.corpName);
    return;
  }

  return (
    <div>
      <DynamicDropdown
        names={agencyNames}
        defaultValue={"selectedLocationName"} // Set the default value to the selected location name
        onSelect={(agencyName) => {
          // Handle location selection
          setSelectedAgencyName(agencyName);
          // console.log(agencyName); // Placeholder action
        }}
        placeholder="Select Agency Name"
      />
    </div>
  );
};

export { ShiftRecieptPage, JobberSelectionPage, SelectOrgLocationDetailsPage, SelectAgencyComponentPage };

const determineFinalJobberList = (
  jobberDetailsFromCorpObjDefault: Record<string, JobberDetailsForOrg>,
  jobberRespFromAPI: JobberAvailabilityResponse[]
) => {
  const innerBoundedResp: JobberAvailabilityResponse[] = [];
  if (!jobberRespFromAPI || jobberRespFromAPI.length == 0) {
    return fullConvertRawJobberDetailToLocalAvail(jobberDetailsFromCorpObjDefault);
  }

  for (const jobberRespFromAPIElement of jobberRespFromAPI) {
    if (jobberRespFromAPIElement) {
      innerBoundedResp.push(jobberRespFromAPIElement);
    }
  }

  return innerBoundedResp && innerBoundedResp.length > 0
    ? innerBoundedResp
    : fullConvertRawJobberDetailToLocalAvail(jobberDetailsFromCorpObjDefault);
};
const fullConvertRawJobberDetailToLocalAvail = (
  jobberDetailsFromCorpObjDefault: Record<string, JobberDetailsForOrg>
): JobberAvailabilityResponse[] => {
  const innerBoundedResp: JobberAvailabilityResponse[] = [];

  for (const [jobberEmail, jobberDetails] of Object.entries(jobberDetailsFromCorpObjDefault)) {
    innerBoundedResp.push({
      jobberEmailAddress: { value: jobberEmail },
      jobberAvailabilityResponse: {
        clientFacingMessage: "Available",
        jobberAvailMessageType: JobberAvailMessageType.CAN_ACCEPT_ALL,
        statusCode: "1",
      },
      workyUserDetailsZipDTO: jobberDetails,
    });
  }

  return innerBoundedResp;
};
