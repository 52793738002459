import React, { useState, useEffect, useRef } from "react";
import CheckIcon from '@mui/icons-material/Check';

const StepperSequence = ({pageNumber}:{pageNumber: number;}) =>{
    type StepButtonProps = {
      idName: 'active-step' | 'passed-step' | 'future-step';
      number: 1 | 2 | 3 | 4 | 5;
      title: string;
      subtitle: string;
    };
    
    const StepButton = ({ idName, number, title, subtitle }: StepButtonProps) => {
      return (
        <button id={idName === 'active-step' ? 'new-shift-v5-stepper-step-container-active' : ''} className="new-shift-v5-stepper-step-container" disabled={idName === 'future-step'}>
          <div id={idName} className="new-shift-v5-stepper-step-icon">{idName==='passed-step'?(<CheckIcon style={{fontSize:"22px"}}/>):(number)}</div>
          <div className="new-shift-v5-stepper-step-info">
            <div className="new-shift-v5-stepper-step-title">{title}</div>
            <div className="new-shift-v5-stepper-step-subtitle">{subtitle}</div>
          </div>
        </button>
      );
    };
  
  
  
  
  
    if(pageNumber===1){
      return(
      <>
        <StepButton idName="active-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="future-step" number={2} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="future-step" number={3} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="future-step" number={4} title="Time" subtitle="Select Time" />
        <StepButton idName="future-step" number={5} title="Confirm" subtitle="Confirm Details" />
      </>
      )
    }
    if(pageNumber===2){
      return(
      <>
        <StepButton idName="passed-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="active-step" number={2} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="future-step" number={3} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="future-step" number={4} title="Time" subtitle="Select Time" />
        <StepButton idName="future-step" number={5} title="Confirm" subtitle="Confirm Details" />
      </>
      )
    }
    if(pageNumber===3){
      return(
      <>
        <StepButton idName="passed-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="passed-step" number={2} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="active-step" number={3} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="future-step" number={4} title="Time" subtitle="Select Time" />
        <StepButton idName="future-step" number={5} title="Confirm" subtitle="Confirm Details" />
      </>
      )
    }
    if(pageNumber===4){
      return(
      <>
        <StepButton idName="passed-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="passed-step" number={2} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="passed-step" number={3} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="active-step" number={4} title="Time" subtitle="Select Time" />
        <StepButton idName="future-step" number={5} title="Confirm" subtitle="Confirm Details" />
      </>
      )
    }
    if(pageNumber===5){
      return(
      <>
        <StepButton idName="passed-step" number={1} title="Location" subtitle="Select Location" />
        <StepButton idName="passed-step" number={2} title="Worker-Type" subtitle="Select Worker Type" />
        <StepButton idName="passed-step" number={3} title="Date" subtitle="Select Date(s)" />
        <StepButton idName="passed-step" number={4} title="Time" subtitle="Select Time" />
        <StepButton idName="active-step" number={5} title="Confirm" subtitle="Confirm Details" />
      </>
      )
    }
  }
  type PageDetailsSequenceProps = {
    pageNumber: number;
  };
  
const PageDetailsSequence: React.FC<PageDetailsSequenceProps> = ({ pageNumber }) => {
    const PageHeaders = (number: number, title: string, subtitle: string) => (
      <div className="new-shift-v5-shift-page">
        <div className="new-shift-v5-shift-page-title-holder">
          <div className="new-shift-v5-shift-page-step">Step {number}/5</div>
          <div className="new-shift-v5-shift-page-title">{title}</div>
          <div className="new-shift-v5-shift-page-subtitle">{subtitle}</div>
        </div>      
      </div>
    );
  
    switch (pageNumber) {
      case 1:
        return PageHeaders(1, "Select Facility", "Choose your Organization and Location");
      case 2:
        return PageHeaders(2, "Select Worker Type", "Choose the Worker Type");
      case 3:
        return PageHeaders(3, "Select Date", "Choose the Individual Date(s) or Recurring Date(s)");
      case 4:
        return PageHeaders(4, "Select Time", "Choose the Timing for your Shift(s)");
      case 5:
        return PageHeaders(5, "Submit", "Submit Your Shift(s)");
      default:
        return PageHeaders(1, "Select Facility", "Choose your Organization and Location");
    }
  };

  interface LocationAddress {
    street: string;
    city: string;
    province: string;
    postalCode: string;
    country: string;
    majorIntersection: string | null;
    unitNumber: string;
    entranceInstructions: string | null;
  }
  
  interface Location {
    locationName: string;
    locationAddress: LocationAddress;
  }
  
  interface Organization {
    orgName: string;
    locations: Location[];
  }  

const StepperSequenceMobile = ({pageNumber}:{pageNumber: number;}) =>{
  const StepperPageDetailsMobile = () => {
    switch (pageNumber) {
      case 1:
        return (<div className="new-shift-v5-stepper-holder-mobile">
        <div className="new-shift-v5-stepper-step-mobile"><div className="new-shift-v5-shift-page-step">Step {pageNumber}/5</div></div>
        <div className="new-shift-v5-stepper-title-mobile">Select Facility</div>
        <div className="new-shift-v5-stepper-subtitle-mobile">Choose your Organization and Location</div>
      </div>);
      case 2:
        return (<div className="new-shift-v5-stepper-holder-mobile">
        <div className="new-shift-v5-stepper-step-mobile"><div className="new-shift-v5-shift-page-step">Step {pageNumber}/5</div></div>
        <div className="new-shift-v5-stepper-title-mobile">Select Worker Type</div>
        <div className="new-shift-v5-stepper-subtitle-mobile">Choose your Worker-Type or Auto-Assign Worker-Type</div>
      </div>);
      case 3:
        return (<div className="new-shift-v5-stepper-holder-mobile">
        <div className="new-shift-v5-stepper-step-mobile"><div className="new-shift-v5-shift-page-step">Step {pageNumber}/5</div></div>
        <div className="new-shift-v5-stepper-title-mobile">Select Date</div>
        <div className="new-shift-v5-stepper-subtitle-mobile">Choose the Individual Date(s) or Recurring Date(s)</div>
      </div>);
      case 4:
        return (<div className="new-shift-v5-stepper-holder-mobile">
        <div className="new-shift-v5-stepper-step-mobile"><div className="new-shift-v5-shift-page-step">Step {pageNumber}/5</div></div>
        <div className="new-shift-v5-stepper-title-mobile">Select Time</div>
        <div className="new-shift-v5-stepper-subtitle-mobile">Choose the Timing for your Shift(s)</div>
      </div>);
      case 5:
        return (<div className="new-shift-v5-stepper-holder-mobile">
        <div className="new-shift-v5-stepper-step-mobile"><div className="new-shift-v5-shift-page-step">Step {pageNumber}/5</div></div>
        <div className="new-shift-v5-stepper-title-mobile">Submit</div>
        <div className="new-shift-v5-stepper-subtitle-mobile">Submit Your Shift(s)</div>
      </div>);
      default:
        return (
      <div className="new-shift-v5-stepper-holder-mobile">
        <div className="new-shift-v5-stepper-step-mobile"><div className="new-shift-v5-shift-page-step">Step {pageNumber}/5</div></div>
        <div className="new-shift-v5-stepper-title-mobile">Select Facility</div>
        <div className="new-shift-v5-stepper-subtitle-mobile">Choose your Organization and Location</div>
      </div>);
    }
  }
  return(
    <>
      <div className="new-shift-v5-stepper-bottom-mobile">
        <div id={`progress-step${pageNumber}`} className="new-shift-v5-stepper-bottom-progress"></div>
      </div>
      <StepperPageDetailsMobile/>
      
    </>
  )
}



  


  export{
    StepperSequence,
    PageDetailsSequence,
    LocationAddress,
    Location,
    Organization,
    StepperSequenceMobile,

    }