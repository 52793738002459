import React, { useState, useEffect, useRef } from "react";
import { OrgLocation } from "../common/CommonInterfaces";
import { Workertype } from "../jobberViews/objects/ShiftRequestConstants";
import { SendErrorEmailv2 } from "../shared-engineering-services/SharedAPICalls";
import { JobberDetailsForOrg } from "../orgViews/OrgUtils";
import { TopNav, PushToNewSr, PushtoSrHistory } from "../orgViews/OrgCommonComponents";
import {
  RemoteExecutionServiceImpl,
  ServerResponse,
  getGlobalUserDetails,
  RemoteGETCallInputObject,
} from "../remoteExecution/RemoteExecutionServiceImpl";
import { AxiosResponse } from "axios";
import {
  CreateShiftRequestAPIObj,
  ShiftReqResponse,
  ShiftIngressRequestAPIObj,
  ShiftIngressRequestUnitAPIObj,
} from "../jobberViews/objects/ShiftRequestConstants";
import { populateShiftRequestContentV2, BuildSRIngressRequestObj, RequestInputSourceData } from "../common/ShiftServiceUtils";
import { convertDatesToEpochSec } from "../common/TimeUtils";
import { EmailAddress } from "../common/CommonInterfaces";
import { JobberWorkerTypeDTO } from "./sr-ingress-data/SRIngressInterfaces";
const remoteCallObject = RemoteExecutionServiceImpl();
const backendApiBasePathLocal = process.env.REACT_APP_BACK_END_SERVER_BASE_PATH_LOCAL;

const DefaultComponents = () => {
  const userInfo = getGlobalUserDetails();
  return (
    <div>
      {TopNav({
        firstName: userInfo.firstName,
        lastname: userInfo.lastName,
        companyname: localStorage.getItem("orgName"),
        iconUrl: localStorage.getItem("orgIcon"),
        orgEmail: localStorage.getItem("userEmail"),
      })}
    </div>
  );
};

const RetrievePreviousJobbersAPICall = async ({ setPrevJobbers }: { setPrevJobbers: (jobbers: JobberDetailsForOrg[]) => void }) => {
  const getCallInput: RemoteGETCallInputObject = {
    apiUrl: backendApiBasePathLocal + "org/previousJobbers",
    headers: {},
    queryParams: null,
  };

  setPrevJobbers([]); // TODO figure out how to make this API call
};


const handleSRSubmit = async ({
  e,
  selectedRecurringDays,
  numJobbers,
  selectedDaysRefObj,
  v3StartTimeHourRef,
  v3StartTimeMinuteRef,
  v3EndTimeHourRef,
  v3EndTimeMinuteRef,
  handleModelCallback,
  handleModelCallbackError,
  selectedJobbers,
  selectedOrgLocationClient,
  selectedAgencyName,
  selectedJobberWorkerType,
}: {
  e?: React.MouseEvent<HTMLButtonElement, MouseEvent>;
  selectedRecurringDays: number[];
  numJobbers: number;
  selectedDaysRefObj: Date[];
  v3StartTimeHourRef: number;
  v3StartTimeMinuteRef: number;
  v3EndTimeHourRef: number;
  v3EndTimeMinuteRef: number;
  handleModelCallback: (boolean) => void;
  handleModelCallbackError: (boolean) => void;
  selectedJobbers: EmailAddress[];
  selectedOrgLocationClient: OrgLocation;
  selectedAgencyName: string;
  selectedJobberWorkerType: JobberWorkerTypeDTO;
}) => {
  
  console.log("selectedOrgLocationClient" + JSON.stringify(selectedOrgLocationClient));
  const apiBody: ShiftIngressRequestAPIObj = BuildSRIngressRequestObj({
    requestInputSourceData: {
      selectedAgencyName: selectedAgencyName,
       orgName: selectedOrgLocationClient.orgName ,
       orgLocationName: selectedOrgLocationClient.locationName,
       workertype: Workertype.WAREHOUSE_ASSOCIATE,
       numJobbers: numJobbers,
       shiftStartEndDetails:  convertDatesToEpochSec({
        dates: selectedDaysRefObj,
        workyStartTime: {
          hour: v3StartTimeHourRef,
          minute: v3StartTimeMinuteRef,
        },
        workyEndTime: {
          hour: v3EndTimeHourRef,
          minute: v3EndTimeMinuteRef,
        },
      }),
      jobberHourlyRate:17.75,
      selectedJobbersSubset: selectedJobbers,
      isTestData: true,
      jobberWorkerTypes: [selectedJobberWorkerType],
      },
  });

  // e.preventDefault();
  console.log("apiBody" + JSON.stringify(apiBody));
  try {
    const response: AxiosResponse<ServerResponse<ShiftReqResponse, Error>> = await remoteCallObject.remotePOSTCallV2<
      ServerResponse<ShiftReqResponse, Error>,
      ShiftIngressRequestAPIObj
    >({
      apiUrl: backendApiBasePathLocal + "shiftrequest/create/",
      object: apiBody,
      headers: { "Content-Type": "application/json" },
    });
    
    console.log("POST request successful:", response);

    if (response && (response.status === 200 || response.status === 201 || response.status === 202)) {
      console.log("200 or 201 or 202");
      handleModelCallback(true);
    }
    // handleModelCallback(true);
  } catch (e) {
    if (e.response && (e.response.status === 400 || e.response.status === 500)) {
      console.log("400 or 500");
      await SendErrorEmailv2();
      handleModelCallbackError(true);
    }
    console.error("POST request failed:", e);
  }
};

export { DefaultComponents, handleSRSubmit, RetrievePreviousJobbersAPICall };
