import React, { useState } from "react";
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { TimeClockDataRespDTO } from "../agency-data/AgencyInterfaces";
import { epochToTimeString } from "../../jobberViews/pages/WorkyPayJobberPage";
import { Modal, Slide } from '@mui/material';
import { OnTimeTagBuilder, CheckInTypeTagBuilder, formatDate } from "./AgencyComponents";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';



const TimeClockTable = ({isMobile, clockData }: {isMobile:boolean, clockData: TimeClockDataRespDTO }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalParams, setModalParams] = useState<any>(null);
    console.log(JSON.stringify(modalParams))

    const handleClose = () => setModalOpen(false);

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        setModalOpen(true);
        setModalParams(params.row);
    };
    
    const columns: GridColDef[] = [
        {
            field: 'shiftStartDate',
            headerName: 'Shift Date',
            width: 115,
            renderHeader: () => (<div className='billing-header'>Shift Date</div>),
            renderCell: (params: GridRenderCellParams) => (
                <span style={{fontWeight:"700"}} className="grid-inner-holder">{formatDate(params.row.shiftStartDate)}</span>
            ),
        },
        {
            field: 'orgName',
            headerName: 'Organization',
            width: 120,
            renderHeader: () => (<div className='billing-header'>Organization</div>),
            renderCell: (params: GridRenderCellParams) => (
                <span className="grid-inner-holder">{params.row.orgName}</span>
            ),
        },
        {
            field: 'profilePicUrl',
            headerName: '',
            width: 50,
            renderCell: (params: GridRenderCellParams) => (
                <div className="grid-icon-holder">
                    {params.row.profilePicUrl?(<img className="col-img" src={params.row.profilePicUrl}/>):(<AccountCircleRoundedIcon sx={{fontSize:"35px", color:"#ddd"}}/>)}
                </div>
            ),
        },
        {
            field: 'jobberName',
            headerName: 'Jobber Name',
            width: 150,
            renderHeader: () => (<div className='billing-header'>Jobber Name</div>),
            renderCell: (params: GridRenderCellParams) => (
                <span className="grid-inner-holder">{params.row.jobberName}</span>
            ),
        },
        {
            field: 'plannedShiftStart',
            headerName: 'Shift Start Time',
            width: 150,
            renderHeader: () => (<div className='billing-header'>Shift Start Time</div>),
            renderCell: (params: GridRenderCellParams) => (
                <span className="grid-inner-holder">{epochToTimeString(params.row.plannedShiftStart)}</span>
            ),
        },
        {
            field: 'plannedShiftEnd',
            headerName: 'Shift End Time',
            width: 150,
            renderHeader: () => (<div className='billing-header'>Shift End Time</div>),
            renderCell: (params: GridRenderCellParams) => (
                <span className="grid-inner-holder">{epochToTimeString(params.row.plannedShiftEnd)}</span>
            ),
        },
        {
            field: 'checkInType',
            headerName: 'Clock-In Type',
            width: 150,
            renderHeader: () => (<div className='billing-header'>Clock-In Type</div>),
            renderCell: (params: GridRenderCellParams) => (
                <span className="grid-icon-holder">{<CheckInTypeTagBuilder checkintype={params.row.checkInType}/>}</span>
            ),
        },
        {
            field: 'checkInDate',
            headerName: 'Clock-In Time',
            width: 150,
            renderHeader: () => (<div className='billing-header'>Clock-In Time</div>),
            renderCell: (params: GridRenderCellParams) => (
                <span className="grid-inner-holder">{epochToTimeString(params.row.checkInDate)}</span>
            ),
        },
        {
            field: 'checkOutDate',
            headerName: 'Clock-Out Time',
            width: 150,
            renderHeader: () => (<div className='billing-header'>Clock-Out Time</div>),
            renderCell: (params: GridRenderCellParams) => (
                <span className="grid-inner-holder">{epochToTimeString(params.row.checkOutDate)}</span>
            ),
        },
        {
            field: 'checkOutType',
            headerName: 'Check-Out Type',
            width: 150,
            renderHeader: () => (<div className='billing-header'>Clock-Out Type</div>),
            renderCell: (params: GridRenderCellParams) => (
                <span className="grid-icon-holder">{<CheckInTypeTagBuilder checkintype={params.row.checkOutType}/>}</span>
            ),
        },
    ];
    
    const buildRowsFromClockData = (clockData: TimeClockDataRespDTO) => {
        const rows = [];
    
        if (!clockData || !clockData.timeClockOrgDataRespDTO) {
            return rows;
        }
    
        clockData.timeClockOrgDataRespDTO.forEach(dayData => {
            dayData.shiftsForOrgOnThisDay.forEach(shiftData => {
                shiftData.timeClockByOrgByDateByLocations.forEach(locationData => {
                    locationData.jobberCheckInDataByLocation.forEach(jobberData => {
                        const jobberDetails = jobberData.jobberUserDetails.userDetails;
                        const checkInData = jobberData.checkInData;
    
                        rows.push({
                            id: jobberData.shiftId,
                            shiftStartDate: jobberData.shiftStartDate,
                            orgName: shiftData.orgName,  // Add organization name here
                            jobberName: `${jobberDetails.firstName} ${jobberDetails.lastName}`,
                            profilePicUrl: jobberData.jobberUserDetails.profilePicUrl,  // Assuming the URL is stored in jobberDetails
                            plannedShiftStart: jobberData.shiftDetailsClockInOutDTO.plannedShiftStartDateTimeEpochSec,  // Assuming planned shift start is available
                            plannedShiftEnd:jobberData.shiftDetailsClockInOutDTO.plannedShiftEndDateTimeEpochSec,    // Assuming planned shift end is available
                            checkInType: checkInData?.checkInOutType || "N/A",   // Default to "N/A" if no data
                            checkInDate: checkInData?.checkInOutDateTimeEpochSec || "N/A",
                            checkOutType: jobberData.checkOutData?.checkInOutType || "N/A",  // Default to "N/A" if no check-out data
                            checkOutDate: jobberData.checkOutData?.checkInOutDateTimeEpochSec || "N/A"
                        });
                    });
                });
            });
        });
    
        return rows;
    };

    const rows = buildRowsFromClockData(clockData);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 4 },
                    },
                }}
                pageSizeOptions={[4, 8]}
                sx={{
                    borderRadius: '10px',
                    border: '1px solid',
                    borderColor: '#ebebeb',
                    background: 'white',
                    maxWidth: '100%',
                }}
                onRowClick={handleRowClick}
            />
            
            
          
            <Modal open={modalOpen} onClose={handleClose}>
                <Slide direction={isMobile?"up":"left"} in={modalOpen} mountOnEnter unmountOnExit>
                    <div className={isMobile?"agency-billing-modal-mobile":"agency-billing-modal"}>
                        {isMobile?(<button onClick={handleClose} className='timeclock-close-mobile'/>):(<button onClick={handleClose} className="agency-billing-close"><CloseRoundedIcon /></button>)}
                        {modalParams?.profilePicUrl?(<div className='agency-billing-modal-icon'><img className="col-img" src={modalParams?.profilePicUrl}/></div>):(<AccountCircleRoundedIcon sx={{fontSize:"75px", marginBottom:"10px", color:"#ddd"}}/>)}
                        <div className='agency-billing-modal-orgname'>{modalParams?.jobberName}</div>

                        <div className='timeclock-modal-inner-holder'>
                            <div className='agency-billing-modal-inner-price'>{modalParams?.orgName}</div>
                            <div className='agency-billing-modal-inner-daterange'></div>
                        </div>
                        

                        <div style={{paddingTop:"30px"}} className='agency-billing-modal-inner-holder-two'>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'>Check-In Type:</div>
                                <div className='agency-billing-modal-inner-value'><CheckInTypeTagBuilder checkintype={modalParams?.checkInType}/></div>
                            </div>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'>Shift Start Time:</div>
                                <div className='agency-billing-modal-inner-value'>{epochToTimeString(modalParams?.plannedShiftStart)}</div>
                            </div>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'>Check-In Time:</div>
                                <div className='agency-billing-modal-inner-value'>{epochToTimeString(modalParams?.checkInDate)}</div>
                            </div>
                            
                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'></div>
                                <div className='agency-billing-modal-inner-value'><OnTimeTagBuilder shiftTime={modalParams?.plannedShiftStart} checkTime={modalParams?.checkInDate}/></div>
                            </div>

                        </div>

                        <div style={{paddingTop:"15px"}} className='agency-billing-modal-inner-holder-two'>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'>Check-Out Type:</div>
                                <div className='agency-billing-modal-inner-value'><CheckInTypeTagBuilder checkintype={modalParams?.checkOutType}/></div>
                            </div>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'>Shift End Time:</div>
                                <div className='agency-billing-modal-inner-value'>{epochToTimeString(modalParams?.plannedShiftEnd)}</div>
                            </div>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'>Check-Out Time:</div>
                                <div className='agency-billing-modal-inner-value'>{epochToTimeString(modalParams?.checkOutDate)}</div>
                            </div>

                            <div className='agency-billing-modal-inner-value-holder'>
                                <div className='agency-billing-modal-inner-title'></div>
                                <div className='agency-billing-modal-inner-value'><OnTimeTagBuilder shiftTime={modalParams?.plannedShiftEnd} checkTime={modalParams?.checkOutDate}/></div>
                            </div>

                        </div>


                   
                        <div className='timeclock-modal-inner-holder-two'>
                            <button className="timeclock-modal-inner-btn">Clock-In</button>
                            <button className="timeclock-modal-inner-btn">Clock-Out</button>
                        </div>
                        

                    </div>
                </Slide>
            </Modal>
        </div>
    );
};


export {TimeClockTable}