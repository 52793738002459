import React, { useCallback, useState, useEffect, useRef } from "react";
import { StyleLinks } from "../../orgViews/OrgCommonComponents";
import { useIsMobile } from "../../custom-hooks/useIsMobile";
import { WorkyContactButton, WorkyAppHeader } from "../agency-components/AgencyComponentSetOne";
import {GetActiveSATimeClock} from '../agency-api-calls/AgencyAPI';
import { TimeClockDataRespDTO} from '../agency-data/AgencyInterfaces';
import './agencytimeclock.css';
import { EmptyInfoState } from "./EmptyInfoState";
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { TimeClockTable } from "./TimeClockTable";

const PageHeader = ({isMobile}:{isMobile:boolean}) => {
  
    return(
            <div className={isMobile?'payments-header-holder-mobile':'payments-header-holder'}>
                <div className='payments-header'>
                    <div className='payments-header-title-holder'>
                        <button id="payments-header-active" className='payments-header-title-switch'>Time Clock</button>
                    </div>
                    <div className='payments-header-subtitle'>Manage and View your Employees Time</div>
                </div>
            </div>
    )
}

const AgencyCards = ({isMobile, clockInCount, clockOutCount}:{isMobile:boolean, clockInCount:number, clockOutCount:number}) => {
    return(
      <div className={isMobile?"agency-card-holder-mobile":"agency-card-holder"}>
  
            <div className={isMobile?"agency-card-mobile":"agency-card"}>
                <div className={isMobile?"agency-card-top-mobile":"agency-card-top"}>
                    <div className={isMobile?"agency-card-icon-mobile":"agency-card-icon"}><LoginRoundedIcon style={{fontSize:isMobile?"20px":""}}/></div>
                    <div className={isMobile?"agency-card-title-mobile":"agency-card-title"}>Check-In's</div>
                </div>
                <div className={isMobile?"agency-card-bottom-mobile":"agency-card-bottom"}>
                    <div className={isMobile?"agency-card-value-mobile":"agency-card-value"}>{clockInCount}</div>
                </div>
            </div>
    
            <div className={isMobile?"agency-card-mobile":"agency-card"}>
                <div className={isMobile?"agency-card-top-mobile":"agency-card-top"}>
                    <div className={isMobile?"agency-card-icon-mobile":"agency-card-icon"}><LogoutRoundedIcon style={{fontSize:isMobile?"20px":""}}/></div>
                    <div className={isMobile?"agency-card-title-mobile":"agency-card-title"}>Check-Out's</div>
                </div>
                <div className={isMobile?"agency-card-bottom-mobile":"agency-card-bottom"}>
                    <div className={isMobile?"agency-card-value-mobile":"agency-card-value"}>{clockOutCount}</div>
                </div>
            </div>

      </div>
    )
}

const WorkyTimeClockAgencyPage = () => {
    const [checkInCount, setCheckInCount] = useState(0);
    const [checkOutCount, setCheckOutCount] = useState(0);
    const [timeClockDataRespDTO, setTimeClockDataRespDTO] = useState<TimeClockDataRespDTO | null>(null);
    const isMobile = useIsMobile();

    useEffect(() => {
        const fetchTimeClockDataRespDTO = async () => {
            const timeClockAPIDataResp = await GetActiveSATimeClock();
            setTimeClockDataRespDTO(timeClockAPIDataResp);
            
            if (timeClockAPIDataResp) {
                countCheckInsAndCheckOuts(timeClockAPIDataResp);
            }
        };
        fetchTimeClockDataRespDTO();
    }, []);

    const countCheckInsAndCheckOuts = (data: TimeClockDataRespDTO) => {
        let checkInCountt = 0;
        let checkOutCountt = 0;
    
        data.timeClockOrgDataRespDTO.forEach((orgData) => {
            orgData.shiftsForOrgOnThisDay.forEach((shiftData) => {
                shiftData.timeClockByOrgByDateByLocations.forEach((locationData) => {
                    locationData.jobberCheckInDataByLocation.forEach((jobberData) => {
                        if (jobberData.checkInData?.checkInOutDateTimeEpochSec) {checkInCountt++;}
                        if (jobberData.checkOutData?.checkInOutDateTimeEpochSec) {checkOutCountt++;}
                    });
                });
            });
        });

        setCheckInCount(checkInCountt);
        setCheckOutCount(checkOutCountt);
    };

    // console.log("timeClockDataRespDTO test : "+JSON.stringify(timeClockDataRespDTO.timeClockOrgDataRespDTO.length === 0 ? "yes":"no"))
// timeClockOrgDataRespDTO
    return (
        <div style={{background:"#f5f5f7"}}>
            <StyleLinks />
            <WorkyAppHeader />
            <WorkyContactButton />
            <PageHeader isMobile={isMobile} />
            
            {timeClockDataRespDTO?.timeClockOrgDataRespDTO?.length === 0 || timeClockDataRespDTO === null ||timeClockDataRespDTO?.timeClockOrgDataRespDTO === null ? (
                <EmptyInfoState pageName={"Time Clock"}/>
            ):(
            <>
                <div className="agency-paystub-title">Today's Time Clocks</div>

                <div className={isMobile?"timeclock-table-holder-mobile":"timeclock-table-holder"}>
                    <TimeClockTable isMobile={isMobile} clockData={timeClockDataRespDTO} />
                </div>
            </>
            )}
        </div>
    );
};

export { WorkyTimeClockAgencyPage };
