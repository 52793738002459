import React, { useState, useEffect, useLayoutEffect } from 'react';
import {AgencyHomePage} from '../agency-logic/agency-pages/AgencyHomePage';
import { signUserInService} from '../auth/WorkySignInService';
import './jobberhomepage.css';
import UserDetailsForm from '../remoteExecution/jobber-ingress';
import { IsAgencyUser, IsOrgUser} from '../shared-components/shared-utils/UserPermissionsUtils';
import { JobberHomePageDesktop } from './JobberHomePageDesktop';
import {
	UserType,
	handleRetrieveNewTokens,
	ServerResponse,
	redirectToLogin,
	RemoteExecutionServiceImpl,
	ServerErrorDetails,
	AuthGetCall,
	WorkySignInResponse,
	WorkyAuthRefreshRequest,
	isExpiredCalc,
	RemoteGETCallInputObject,
	UpdateLocalCacheWOAuthInfo,
	setGlobalUserDetails,
} from '../remoteExecution/RemoteExecutionServiceImpl';
import { OrgHomePage } from '../orgViews/OrgHomepage';
import { JobberDTO } from './objects/JobberRegistrationFormConstants';
import { OrgHomePageV2 } from '../orgViews/org-sr-ingress/OrgHomePageV2';

const remoteCallObject = RemoteExecutionServiceImpl();

const redirectURL = process.env.REACT_APP_BACK_END_SERVER_SIGN_IN_REDIRECT_URL;
const workyServerIDPSIGNINURL = process.env.REACT_APP_IDP_SIGN_IN_URL;

const WorkyHomePage = () => {
	const [userType, setUserType] = useState(null);
	console.log("on first render we have userType: " + userType)
	console.log('here in home page');
	const [loading, setLoading] = useState(true);
	const [jobberStatus, setJobberStatus] = useState(null);
	const [sfirstName, setFirstName] = useState(null);
	const [slastName, setLastName] = useState(null);
	const [userDetails, setUserDetails] = useState(null);	

	useEffect(() => {
		console.log('re render ');
		try {
			const loadData = async () => {
				const url = new URL(window.location.href);
				const code = url.searchParams.get('code');
				const accessToken = localStorage.getItem('access_token');
				const expireTime = localStorage.getItem('expire_time');
				const idToken = localStorage.getItem('id_token');
				const refreshToken = localStorage.getItem('refresh_token');
				const userTypeFromStorage = localStorage.getItem('user_type');
				const respFromSignIn: WorkySignInResponse = await signUserInService({
					code: code,
					accessToken: accessToken,
					expireTime: expireTime,
					refreshToken: refreshToken,
					userType: userTypeFromStorage,
					idToken: idToken,
				});

				setUserType(respFromSignIn.basicJobberDetails.userType);
				setJobberStatus(respFromSignIn.basicJobberDetails.jobberStatus);
				setUserDetails(respFromSignIn.basicJobberDetails.userDetails);
				UpdateLocalCacheWOAuthInfo({
					basicDetails: respFromSignIn.basicJobberDetails,
					input: respFromSignIn.tokens,
				});
				setGlobalUserDetails({ userInfo: respFromSignIn.basicJobberDetails.userDetails });
				// setLoading(false);
			};

			loadData();
			console.log("we have set after loadData: " + userType)
		} catch (e) {
			console.log('Error while attempting to retrieve jobber details', e);
			redirectToLogin();
		}
	}, []);

	return (
    <>
        {!userType ? (
            <div className='loading-screen'>
                <link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />
                <button className='worky-loading-icon'>w</button>
                <div className='loading-text'>Loading</div>
            </div>
        ) : (() => {			
			if(!userType || userType === undefined){
					console.log('no user type');
			}else if(IsAgencyUser({userType: userType})){
				console.log('user type is agency');
				return <AgencyHomePage/>;
			}else if(IsOrgUser({userType: userType})){
				// return <OrgHomePage userDetails={userDetails} />;
				return <OrgHomePageV2/>
			}else{
				return (
					<div className='App'>
						<link rel='stylesheet' href='https://use.typekit.net/rjq7odq.css' />
						<JobberHomePageDesktop jobberStatus={jobberStatus} />
					</div>
				);
			}            
        })()}
    </>
);
};


export { WorkyHomePage };
