import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios, { AxiosResponse } from 'axios';
import { StyleLinks, TopNav, PushToNewSr, PushtoSrHistory } from '../../orgViews/OrgCommonComponents';
import Rating from '@mui/material/Rating';
import ViewModuleRoundedIcon from '@mui/icons-material/ViewModuleRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import { convertToTitleCase } from '../../orgViews/OrgUtils';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import NightsStayRoundedIcon from '@mui/icons-material/NightsStayRounded';
import WbTwilightRoundedIcon from '@mui/icons-material/WbTwilightRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import Fuse from 'fuse.js'; 
import { EmptyInfoState } from './EmptyInfoState';
import { WorkyAppHeader, WorkyContactButton, WorkyFooter } from '../../agency-logic/agency-components/AgencyComponentSetOne';
import { useIsMobile } from '../../custom-hooks/useIsMobile';
import '../../orgViews/myworkforce.css'
import{ JobberProfileComponent} from '../../orgViews/JobberProfileComponentOpen';
import {GetJobberResume, GetJobberProfile} from '../../shared-engineering-services/SharedAPICalls'
import { Tooltip } from '@mui/material';
import { extractRatingFromRatingCategory } from '../../common/CommonUtilsMethods';
import {GetAllJobberAvailbilities}  from '../agency-api-calls/AgencyAPI'
import {JobberFullProfileDetails, JobberAvalTimeWindows} from '../agency-data/AgencyInterfaces'
const defaultPic = '/org-default-icon.png'; 


const copyToClipboard = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      console.log("Copied to clipboard: " + value);
    } catch (err) {
      console.error("Unable to copy to clipboard:", err);
    }
};



const WorkforceTagBuilder = ({ isMobile, timeString }: { isMobile: boolean; timeString: string }) => {
    if (!timeString) { return null; }

    const times = timeString.toString().split(',').map(time => time.trim().toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()));

    return (
      <>
        {times.map((time, index) => {
            // Conditionally render based on time value
            if (time === "Morning") {
                return (
                    <div key={index} className="card-availability-icon">
                        {isMobile ? (
                            <LightModeRoundedIcon style={{ fontSize: "14px" }} />
                        ) : (
                            <><LightModeRoundedIcon style={{ fontSize: "14px" }} />Morning</>
                        )}
                    </div>
                );
            } else if (time === "Noon") {
                return (
                    <div key={index} className="card-availability-icon">
                        {isMobile ? (
                            <WbTwilightRoundedIcon style={{ fontSize: "14px" }} />
                        ) : (
                            <><WbTwilightRoundedIcon style={{ fontSize: "14px" }} />Afternoon</>
                        )}
                    </div>
                );
            } else if (time === "Night") {
                return (
                    <div key={index} className="card-availability-icon">
                        {isMobile ? (
                            <NightsStayRoundedIcon style={{ fontSize: "14px" }} />
                        ) : (
                            <><NightsStayRoundedIcon style={{ fontSize: "14px" }} />Night</>
                        )}
                    </div>
                );
            } else if (time === "Unavailable") { 
                return null;
            }

            return (
                <div key={index} className="card-availability-icon">{time}</div>
            );
        })}
      </>
    );
};



const MyAgencyWorkforce = () => {
    const isMobile = useIsMobile();
    const [jobberData, setJobberData] = useState<JobberFullProfileDetails[]>([]);
    const [gridViewActive, setGridViewActive] = useState(true);

    const PageHeader = ({isMobile, gridViewActive, setGridViewActive}:{isMobile:boolean, gridViewActive:boolean, setGridViewActive: (boolean) => void}) => {
        return (
            <div className={isMobile ? 'payments-header-holder-mobile' : 'payments-header-holder'}>
                <div className='payments-header'>
                    <div className='payments-header-title-holder'>
                        <div style={{marginLeft: "5px", marginTop: "5px", cursor: "auto"}} id="payments-header-active" className='payments-header-title-switch'>
                            My Workforce
                        </div>
                    </div>
                    <div className='payments-header-subtitle'>Manage and View your Workers</div>
                </div>
                <div className='header-selection-toggle'>
                    <button 
                        id={gridViewActive ? 'header-selection-toggle-btn-active' : ''} 
                        className='header-selection-toggle-btn'
                        onClick={() => setGridViewActive(true)}>
                        <ViewModuleRoundedIcon />
                    </button>
                    <button 
                        id={!gridViewActive ? 'header-selection-toggle-btn-active' : ''} 
                        className='header-selection-toggle-btn'
                        onClick={() => setGridViewActive(false)}>
                        <ViewListRoundedIcon />
                    </button>
                </div>
            </div>
        );
    };
    
    const fetchJobberData = async () => {
        try {
            const data = await GetAllJobberAvailbilities();
            if (data && Array.isArray(data)) { // Ensure data is an array
                setJobberData(data);
            }
        } catch (error) {
            console.error('Error fetching jobber data:', error);
        }
    };
	
    useEffect(() => {GetAllJobberAvailbilities(); fetchJobberData();}, []);


    const JobberWorkforceGridBuilder = ({firstName, lastName, rating, email, phoneNumber, icon, availability, city, isMobile}:{ firstName:string, lastName:string, rating:number, email:string, phoneNumber:string, icon:string, availability:string, city:string, isMobile:boolean})=>{
        
        console.log(availability);
        
        return(
            <>
            {isMobile?(
                <div className='jobber-workforce-card-mobile'>
                    
                    <div className='jobber-workforce-card-icon-holder-mobile'>
                        <div className='jobber-workforce-card-icon-mobile'><img src={icon}/></div>
                        <div className='jobber-workforce-card-rating-mobile'><Rating readOnly defaultValue={rating} style={{ fontSize: '13px'}}/></div>
                    </div>

                    <div className='jobber-workforce-card-details-holder-mobile'>
                        <div className='jobber-workforce-card-detail-top-holder-mobile'>

                            <div className='jobber-workforce-card-name-mobile'>{firstName + " " + lastName}</div>
                            <div className='jobber-workforce-card-availability-mobile'>
                                {availability !== "Unavailable" ? <div className='jobber-workforce-card-availability'><WorkforceTagBuilder isMobile={isMobile} timeString={availability} /></div>:null}
                            </div>

                        </div>

                        <div className='jobber-workforce-card-detail-holder-mobile'>

                            {city?<div className='jobber-workforce-card-detail-mobile'>
                                <div className='jobber-workforce-card-detail-title-mobile'><PlaceRoundedIcon style={{fontSize:"17px"}}/></div>
                                <div className='jobber-workforce-card-detail-value-mobile'>{convertToTitleCase(city)}</div>
                            </div>:null}

                            <div className='jobber-workforce-card-detail-mobile'>
                                <div className='jobber-workforce-card-detail-title-mobile'><AlternateEmailRoundedIcon style={{fontSize:"17px"}}/></div>
                                <div onClick={() => copyToClipboard(email)} className='jobber-workforce-card-detail-value-mobile'>{email}</div>
                            </div>
                            
                            <div className='jobber-workforce-card-detail-mobile'>
                                <div className='jobber-workforce-card-detail-title-mobile'><LocalPhoneRoundedIcon style={{fontSize:"17px"}}/></div>
                                <div onClick={() => copyToClipboard(phoneNumber)} className='jobber-workforce-card-detail-value-mobile'>{phoneNumber}</div>
                            </div>

                        </div>
                        
                    </div>
                </div>
            )
            :
            (<div className='jobber-workforce-card'>
                <div className='jobber-workforce-card-inner'>
                    <div className='jobber-workforce-card-icon-holder'>
                        <div className='jobber-workforce-card-icon'><img src={icon}/></div>
                        <div className='jobber-workforce-card-rating'><Rating readOnly defaultValue={rating} style={{ fontSize: '13px'}}/></div>
                        <div className='jobber-workforce-card-name'>{firstName + " " + lastName}</div>
                        {city?<div className='jobber-workforce-card-city'>{convertToTitleCase(city)}</div>:null}
                        {availability !== "Unavailable" ? <div className='jobber-workforce-card-availability'><WorkforceTagBuilder isMobile={isMobile} timeString={availability} /></div>:null}    
                    </div>
                    <div className='jobber-workforce-card-details-holder'>
                        <div className='jobber-workforce-card-detail'>
                            <div className='jobber-workforce-card-detail-title'><AlternateEmailRoundedIcon style={{fontSize:"17px"}}/></div>
                            <Tooltip sx={{ fontFamily: 'DM Sans', color: 'white',}} title="Click to Copy" placement='left'><div onClick={() => copyToClipboard(email)} className='jobber-workforce-card-detail-value'>{email}</div></Tooltip>
                        </div>
                        
                        <div className='jobber-workforce-card-detail'>
                            <div className='jobber-workforce-card-detail-title'><LocalPhoneRoundedIcon style={{fontSize:"17px"}}/></div>
                            <Tooltip title="Click to Copy" placement='left'><div onClick={() => copyToClipboard(phoneNumber)} className='jobber-workforce-card-detail-value'>{phoneNumber}</div></Tooltip>
                        </div>
                        
                    </div>
                </div>
            </div>)}</>
        )
    }

    const GridViewV2 = ({ isMobile, jobberData }: { isMobile: boolean, jobberData: JobberFullProfileDetails[] }) => {
        const [searchQuery, setSearchQuery] = useState<string>('');
    
        const jobbers = jobberData;
    
        const filterJobbers = (query: string) => {
            if (!query) return jobbers; // Return all jobbers if query is empty
    
            const lowerCaseQuery = query.toLowerCase();
    
            return jobbers.filter((jobber) => {
                const { firstName, lastName, userEmail, userPhoneNumber } = jobber.jobberDetails.userDetails;
                const fullName = `${firstName} ${lastName}`.toLowerCase();
                const city = jobber.jobberAvailability?.cities?.join(', ').toLowerCase() || '';
                return (
                    fullName.includes(lowerCaseQuery) ||
                    userEmail.value.toLowerCase().includes(lowerCaseQuery) ||
                    userPhoneNumber.value.includes(lowerCaseQuery) ||
                    city.includes(lowerCaseQuery) // Add city search
                );
            });
        };
    
        const filteredJobbers = filterJobbers(searchQuery);
    
        return (
            <>
                <div className={isMobile ? 'workforce-search-container-mobile' : 'workforce-search-container'}>
                    <input
                        type="text"
                        placeholder="Search By Worker, Email, Phone Number, City"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="workforce-search-input"
                    />
                </div>
    
                <div className={isMobile ? 'workforce-container-grid-mobile' : 'workforce-container-grid'}>
                    {filteredJobbers.map((jobber, index) => {
                        const userDetails = jobber.jobberDetails.userDetails;
                        const profilePicUrl = jobber.jobberDetails.profilePicUrl;
                        const city = jobber.jobberAvailability?.cities?.join(', ') || '';
                        const availability = jobber.jobberAvailability?.dayIntervals?.join(', ') || 'Unavailable';
    
                        return (
                            <JobberWorkforceGridBuilder
                                key={index}
                                firstName={userDetails.firstName}
                                lastName={userDetails.lastName}
                                rating={5} 
                                email={userDetails.userEmail.value}
                                phoneNumber={userDetails.userPhoneNumber.value}
                                icon={profilePicUrl || defaultPic}
                                availability={availability}
                                city={city}
                                isMobile={isMobile}
                            />
                        );
                    })}
                </div>
            </>
        );
    };

    const ListViewV2 = ({ isMobile, jobberData }: { isMobile: boolean; jobberData: JobberFullProfileDetails[] }) => {
        const [searchQuery, setSearchQuery] = useState('');
        const [filteredRows, setFilteredRows] = useState<JobberFullProfileDetails[]>([]);
    
        const columns: GridColDef[] = [
            {
                field: 'icon',
                headerName: '',
                width: 60,
                align: 'center',
                renderHeader: () => <div className="billing-header"></div>,
                renderCell: (params: GridRenderCellParams) => (
                    <div className="list-cell-icon-holder">
                        <img className="list-cell-icon" src={params.row.jobberDetails.profilePicUrl} />
                    </div>
                ),
            },
            {
                field: 'firstName',
                headerName: 'First Name',
                width: 130,
                renderHeader: () => <div className="billing-header">First Name</div>,
                renderCell: (params: GridRenderCellParams) => (
                    <span className="invoice-cell-text">{params.row.jobberDetails.userDetails.firstName}</span>
                ),
            },
            {
                field: 'lastName',
                headerName: 'Last Name',
                width: 130,
                renderHeader: () => <div className="billing-header">Last Name</div>,
                renderCell: (params: GridRenderCellParams) => (
                    <span className="invoice-cell-text">{params.row.jobberDetails.userDetails.lastName}</span>
                ),
            },
            {
                field: 'rating',
                headerName: 'Rating',
                type: 'number',
                align: 'center',
                headerAlign: 'center',
                width: 130,
                renderHeader: () => <div className="billing-header">Rating</div>,
                renderCell: (params: GridRenderCellParams) => (
                    <div className="list-cell-icon-holder"><Rating readOnly defaultValue={5} style={{ fontSize: '18px' }} /></div>
                ),
            },
            {
                field: 'email',
                headerName: 'Email',
                width: 200,
                renderHeader: () => <div className="billing-header">Email</div>,
                renderCell: (params: GridRenderCellParams) => (
                    <Tooltip title="Click to Copy" placement="top">
                        <span
                            onClick={() => copyToClipboard(params.row.jobberDetails.userDetails.userEmail.value)}
                            style={{ cursor: 'pointer' }}
                            className="invoice-cell-text"
                        >
                            {params.row.jobberDetails.userDetails.userEmail.value}
                        </span>
                    </Tooltip>
                ),
            },
            {
                field: 'phoneNumber',
                headerName: 'Phone Number',
                width: 160,
                renderHeader: () => <div className="billing-header">Phone Number</div>,
                renderCell: (params: GridRenderCellParams) => (
                    <Tooltip title="Click to Copy" placement="top">
                        <span onClick={() => copyToClipboard(params.row.phoneNumber)} style={{ cursor: 'pointer' }} className="invoice-cell-text">
                            {params.row.jobberDetails.userDetails.userPhoneNumber.value}
                        </span>
                    </Tooltip>
                ),
            },
            {
                field: 'city',
                headerName: 'City',
                width: 150,
                renderHeader: () => <div className="billing-header">Cities</div>,
                renderCell: (params: GridRenderCellParams) => (
                    <div className="invoice-tag-holder"><WorkforceTagBuilder isMobile={isMobile} timeString={params.row.jobberAvailability.cities.join(',')} /></div>
                ),
            },
            {
                field: 'TimeAvailability',
                headerName: 'Time Availability',
                width: 350,
                renderHeader: () => (<div className='billing-header'>Time Availability</div>),
                renderCell: (params: GridRenderCellParams) => (
                    <div className='list-cell-availability-holder'><WorkforceTagBuilder isMobile={isMobile} timeString={params.row.jobberAvailability.dayIntervals.join(',')}/></div>
                ),
            },
            {
                field: 'DaysAvailability',
                headerName: 'Days Availability',
                width: 350,
                renderHeader: () => (<div className='billing-header'>Days Availability</div>),
                renderCell: (params: GridRenderCellParams) => (
                    <div className='list-cell-availability-holder'><WorkforceTagBuilder isMobile={isMobile} timeString={params.row.jobberAvailability.dayOfTheWeek.join(',')}/></div>
                ),
            },
            
        ];
    
        const rows: JobberFullProfileDetails[] = jobberData.map((jobber,index) => ({
            id: index + 1,
            jobberDetails: {
                userDetails: jobber.jobberDetails.userDetails,
                userType: jobber.jobberDetails.userType,
                jobberStatus: jobber.jobberDetails.jobberStatus,
                profilePicUrl: jobber.jobberDetails.profilePicUrl || defaultPic,
                corpJobberDetails: jobber.jobberDetails.corpJobberDetails,
            },
            jobberAvailability: {
                cities: jobber.jobberAvailability?.cities || [],
                dayIntervals: jobber.jobberAvailability?.dayIntervals || [],
                dayOfTheWeek: jobber.jobberAvailability?.dayOfTheWeek || [],
            },
        }));
        
        const fuse = new Fuse(rows, {
            keys: [
                'jobberDetails.userDetails.firstName',
                'jobberDetails.userDetails.lastName',
                'jobberDetails.userDetails.email',
                'jobberDetails.userDetails.phoneNumber',
                'jobberAvailability.cities',
            ],
            threshold: 0.3,
        });
    
        const handleSearch = (query: string) => {
            setSearchQuery(query);
            if (query) {
                const result = fuse.search(query).map((r) => r.item);
                setFilteredRows(result as JobberFullProfileDetails[]); // Cast result to JobberFullProfileDetails[]
            } else {
                setFilteredRows(rows);
            }
        };
        
        return (
            <>
                <div className={isMobile ? 'workforce-search-container-mobile' : 'workforce-search-container'}>
                    <input
                        type="text"
                        placeholder="Search By  Worker,  Email,  Phone Number,  City"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                        className="workforce-search-input"
                    />
                </div>
                <div className={isMobile ? 'workforce-container-list-mobile' : 'workforce-container-list'}>
                    <DataGrid
                        sx={{
                            borderRadius: '10px',
                            border: '1px solid #ebebeb',
                        }}
                        rows={searchQuery ? filteredRows : rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 15, 25, 50]}
                    />
                </div>
            </>
        );
    };

    return(
        <>
            <div className='org-app'>
                <StyleLinks/>    
                <WorkyAppHeader/>
                <PageHeader isMobile={isMobile} gridViewActive={gridViewActive} setGridViewActive={setGridViewActive}/>
                
                {jobberData ? 
                    (gridViewActive ? 
                        (<GridViewV2 isMobile={isMobile} jobberData={jobberData} />)
                        :
                        (<ListViewV2 isMobile={isMobile} jobberData={jobberData} />)
                    )
                    : 
                    (<EmptyInfoState pageName={"Workforce"} />)
                }

                <WorkyContactButton/>
            </div>
        </>
    )
}


export { MyAgencyWorkforce}