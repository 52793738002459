import { UserType } from "../remoteExecution/RemoteExecutionServiceImpl";

interface EmailAddress {
  value: string;
}

interface PhoneNumber {
	value: string;
}

interface UserDetails {
	userId?: string | null;
	createdDateTime?: string | null;
	userEmail: EmailAddress;
	userPhoneNumber: PhoneNumber;
	lastName: string;
	firstName: string;
}

enum ServicedCity {
	MISSISSAUGA = 'MISSISSAUGA',
	TORONTO = 'TORONTO',
	BRAMPTON = 'BRAMPTON',
	OAKVILLE = 'OAKVILLE',
	VAUGHAN = 'VAUGHAN',
	MARKHAM = 'MARKHAM',
	MILTON = 'MILTON',
}

interface WorkyObjectMetaData {
  updatedDateTimeEpoch?: number | null;
  updatedDateTime?: string | null;
  version?: number | null;
  submissionDateTimeEpochSeconds?: number | null;
  submissionDateTime?: string | null;
  testData?: boolean | null;
}

interface WorkyTime {
  hour: number;
  minute: number;
}

/**
 * Enum representing various jobber statuses.
 */
enum JobberStatus {
  /**
   * For any user in Worky, if they belong to an Org/Client or is a Worky
   * Admin this is their jobber status
   */
  NOT_JOBBER = "NOT_JOBBER",
  /**
   * This indicates that the user has been added automatically to the system
   * by their employer(typically external org), now ew need to complete
   * specific onboarding requirements
   */
  REGISTERED_BY_ORG_AGENCY_NEEDS_TO_ON_BOARD = "REGISTERED_BY_ORG_AGENCY_NEEDS_TO_ON_BOARD",

  /**
   * Jobber has successfully submitted their details to AWS Cognito
   */
  REGISTERED_ON_COGNITO_COMPLETED = "REGISTERED_ON_COGNITO_COMPLETED",

  /**
   * Jobber has successfully submitted their details to AWS Cognito.
   * AND the jobber has submitted the first Jobber On-boarding form
   */
  REGISTERED_SUBMITTED_JOBBER_REG_FORM = "REGISTERED_SUBMITTED_JOBBER_REG_FORM",

  /**
   * THE Jobber at this point has fully registered all
   * their details on worky,
   * registered successfully: email, phone, SR Options
   *
   * Now they just need to submit the docs(tax stuff, id stuff)
   */
  REGISTERED_FULLY_VERIFIED_STILL_NEEDS_TO_SUBMIT_FORMS = "REGISTERED_FULLY_VERIFIED_STILL_NEEDS_TO_SUBMIT_FORMS",

  /**
   * The jobber is registered and has uploaded resume
   */
  REGISTERED_FULLY_VERIFIED_UPLOADED_RESUME = "REGISTERED_FULLY_VERIFIED_UPLOADED_RESUME",

  /**
   * The jobber is registered and has uploaded gov id,
   * Now the jobber registration status should show
   * that the jobber is in the interview pool
   * Tell jobber that application is being processed and to wait for contact
   */
  REGISTERED_FULLY_VERIFIED_UPLOADED_GOV_ID = "REGISTERED_FULLY_VERIFIED_UPLOADED_GOV_ID",

  /**
   * The jobber is registered and now need to upload
   * valid legal proof that they are authorized to work in Canada
   * This can be:
   * Canadian Passport, Canadian Permit Resident Card, Student_Visa, Refugee_Doc, Other
   */
  REGISTERED_FULLY_VERIFIED_UPLOADED_LEGAL_PROOF_OF_WORK = "REGISTERED_FULLY_VERIFIED_UPLOADED_LEGAL_PROOF_OF_WORK",

  /**
   * Jobber needs to upload a personal profile picture that is business appropriate
   */
  REGISTERED_FULLY_VERIFIED_UPLOAD_PROFILE_PICTURE = "REGISTERED_FULLY_VERIFIED_UPLOAD_PROFILE_PICTURE",

  //////////////////////////////////////

  HIRED_START_OF_ON_BOARDING = "HIRED_START_OF_ON_BOARDING",

  HIRED_SUBMITTED_WHIMS_CONFIRMATION = "HIRED_SUBMITTED_WHIMS_CONFIRMATION",

  HIRED_UPLOADED_TD1_CAD = "HIRED_UPLOADED_TD1_CAD",
  HIRED_UPLOADED_TD1_ON = "HIRED_UPLOADED_TD1_ON",
  HIRED_SUBMITTED_WORKY_JOBBER_CONTRACT = "HIRED_SUBMITTED_WORKY_JOBBER_CONTRACT",
  HIRED_SUBMITTED_DIRECT_DEPOSIT = "HIRED_SUBMITTED_DIRECT_DEPOSIT",

  /**
   * Jobber has submitted the necessary accounting/regulation info to be considered for SRs
   */
  FULLY_HIRED = "FULLY_HIRED",

  CANCELLED = "CANCELLED",

  DROPPED = "DROPPED",
}

interface CancelShiftRequestObj {
  shiftId: string;
}

interface ShiftStartEndDetails {
  startDateTimeEpochSec: number;
  endDateTimeEpochSec: number;
}

enum CorpType {
  AGENCY = "AGENCY",
  ORG = "ORG",
  WORKY = "WORKY",
}
interface CorpJobberDetails {
  corpType: CorpType;
  corpName: string;
}

enum CorpStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

enum PreferredCommunicationMethod {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  SMS = "SMS",
  ALL = "ALL",
}

interface CommunicationPreferences {
  defaultEmail: EmailAddress;
  isEmailVerified: boolean;
  defaultPhoneNumber: PhoneNumber;
  preferredCommunicationMethod: PreferredCommunicationMethod;
}

interface AddressDto {
	street: string;
	unitNumber: string;
	city: string;
	province: string;
	postalCode: string;
	country: string;
	majorIntersection: string;
	entranceInstructions: string;
}



interface OrgLocation {
  orgName: string;
  locationName: string;
  locationAddress?: AddressDto;
  servicedCity?: ServicedCity;
  primaryShiftManagerContact?: UserDetails;
  primaryHRManagerContact?: UserDetails;
  orgUserContactsForLocation?: UserDetails[];
}


interface OrgLocationMapDetails {
  orgLocationMap: Map<string, OrgLocation>;
}

interface WorkyUserDetails {
  userDetails: UserDetails;
  userType: UserType;
  jobberStatus?: JobberStatus;
  profilePicUrl:string;
  corpJobberDetails: CorpJobberDetails;
}

interface ShiftRequestUnit {
	shiftRequestUnitId: string;
	parentShiftRequestId: string;
	shiftRequestStatus: string;
	workerType: string;
  requestingUserDetails: WorkyUserDetails
  assignedAgencyName?: string;
	workyWorkdayShift: string;
	simpleLocation: string;
	orgName: string;
	jobberHourlyRate: number;
	orgHourlyRate: number;
	startDateTimeEpochSeconds: number;
	endDateTimeEpochSeconds: number;
	startDateTime: string;
	endDateTime: string;
	startDay: string;
	startTime: number;
	submissionDateTimeEpochSeconds: number;
	submissionDateTime: string;		
	orgAddress: {
		street: string;
		unitNumber: string;
		city: string;
		province: string;
		postalCode: string;
		country: string;
		majorIntersection: string;
		entranceInstructions: string;
	};
	lastJobberPoolNotifTime: null | string;
	lastJobberPoolNotifTimeEpochSec: null | number;
	preferredJobbers: {
		value: string;
	}[];
	updatedDateTimeEpoch: number;	
	updatedDateTime: string;		
	assignedJobberProfilePicS3URL:string;
	assignedJobberUserDetails:UserDetails;
}


interface Holiday {
  holidayTitle: string;
  holidayDate: string;  // e.g., "September 2"
}

interface PayrollDisplay {
  payrollType: "Weekly" | "Bi-Weekly" | "Monthly" | "One-Time";
  dateRange: string;  // e.g., "Sep 2 - Sep 9", "Sep 13"
  status: "Processed" | "Pending" | "Error" | "Completed" | "Overdue";
}

interface ModifyShiftTimeRequest {
  shiftId: string;
  newShiftStartTime?: number;
  newShiftEndTime?: number;
  reason?: string;
  }

interface LocalStorageObjects{
  // keyName, value
  attributes: Record<string, string>
}



// Add these interface definitions for request and response
interface WebBrowserClientSalesTrackerReqDTO {
  timestamp: number;
  currentUrl: string;
  userAgent: string;
  screenWidth: string;
  screenHeight: string;
  referrerUrl: string;
  sessionId: string;
  trackingReason: string;
  workySessionId: string;
  additionalData: Record<string, string>;
}

interface WebBrowserClientSalesTrackerResDTO {
  trackingId: string;
}

interface CorpDataObjSimpleFromLocalStorage{
    corpJobberDetails?: CorpJobberDetails
    corpIconPublicURL?: string
}

export {
  ModifyShiftTimeRequest,
  WorkyObjectMetaData,
  JobberStatus,
  WorkyTime,
  CancelShiftRequestObj,
  ShiftStartEndDetails,
  OrgLocationMapDetails, 
  OrgLocation,
  EmailAddress,
  PhoneNumber,
  CorpType,
  CorpJobberDetails,
  CorpStatus,
  CommunicationPreferences,
  ServicedCity,
  UserDetails,
  AddressDto,
  ShiftRequestUnit,
  WorkyUserDetails,
  Holiday,
  PayrollDisplay,
  UserType,
  LocalStorageObjects,
  WebBrowserClientSalesTrackerReqDTO,
  WebBrowserClientSalesTrackerResDTO,
  CorpDataObjSimpleFromLocalStorage
};
